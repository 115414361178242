import TipoParticipanteEnum from './enumeradores/TipoParticipanteEnum';
import StatusOrcamentoEnum from './enumeradores/StatusOrcamentoEnum';
import TipoPessoaEnum from './enumeradores/TipoPessoaEnum';
import PapelFornecedorEnum from './enumeradores/PapelFornecedorEnum';
import TipoDescontoEnum from './enumeradores/TipoDescontoEnum';
import FormaSolicitacaoOrcamentoEnum from './enumeradores/FormaSolicitacaoOrcamentoEnum';
import DiasEnvioMaterialEnum from './enumeradores/DiasEnvioMaterialEnum';
import TipoEnderecoEnum from './enumeradores/TipoEnderecoEnum';
import TipoRequisitoEnum from './enumeradores/TipoRequisitoEnum';
import BoleanoEnum from './enumeradores/BoleanoEnum';
import TipoProcedimentoEletronicoEnum from './enumeradores/TipoProcedimentoEletronicoEnum';
import SituacaoOrdemServicoEnum from './enumeradores/SituacaoOrdemServicoEnum';
import SituacaoContratoEnum from './enumeradores/SituacaoContratoEnum';
import TipoContratoEnum from './enumeradores/TipoContratoEnum';
import TipoColunaEnum from './enumeradores/TipoColunaEnum';
import ArredondamentoEnum from './enumeradores/ArredondamentoEnum';
import ValorAtulizarEnum from './enumeradores/ValorAtualizarEnum';
import ChecklistEnum from './enumeradores/ChecklistEnum';
import TipoDescontoAcrescimoEnum from './enumeradores/TipoDescontoAcrescimoEnum';
import SituacaoCertificadoEnum from './enumeradores/SituacaoCertificadoEnum';
import SituacaoAvaliacaoEnum from './enumeradores/SituacaoAvaliacaoEnum';
import AtivoInativoEnum from './enumeradores/AtivoInativoEnum';
import PosicaoSequenciaSetorEnum from './enumeradores/PosicaoSequenciaSetorEnum';
import TipoPontoEnum from './enumeradores/TipoPontoEnum';
import OrigemPontoEnum from './enumeradores/OrigemPontoEnum';
import TipoCampoAdicionaisEnum from './enumeradores/TipoCampoAdicionaisEnum';
import EdicaoValorCampoAdicionaisEnum from './enumeradores/EdicaoValorCampoAdicionaisEnum';
import ContatoResponsabilidadeEnum from './enumeradores/ContatoResponsabilidadeEnum';
import TipoDataEnum from './enumeradores/TipoDataEnum';
import TipoSetorEnum from './enumeradores/TipoSetorEnum';
import EnumFormulaValidacaoCriterioValidacao from './enumeradores/FormulaValidacaoCriterioValidacaoEnum';
import TipoReferenciaEnum from './enumeradores/TipoReferenciaEnum';
import MesesEnum from './enumeradores/MesesEnum';
import SituacaoVerificacaoEnum from './enumeradores/SituacaoVerificacaoEnum';
import FuncionalidadesEnum from './enumeradores/FuncionalidadesEnum';
import FuncionalidadesAcoesEnum from './enumeradores/FuncionalidadesAcoesEnum';
import SituacaoEnvioMaterialEnum from './enumeradores/SituacaoEnvioMaterialEnum';
import GatilhoMovimentacaoSetorEnum from './enumeradores/GatilhoMovimentacaoSetorEnum';
import TipoRelatorioEnum from './enumeradores/TipoRelatorioEnum';
import CodigoRelatorioEnum from './enumeradores/CodigoRelatorioEnum';
import TipoEnvioMaterial from './enumeradores/TipoEnvioMaterial';
import FormulaValidacaoEnum from './enumeradores/FormulaValidacaoEnum';
import LidoNaoLidoEnum from './enumeradores/LidoNaoLidoEnum';
import MetodoEnum from './enumeradores/MetodoEnum';
import TipoPermissaoEnum from './enumeradores/TipoPermissaoEnum';
import TipoAprovacaoAnaliseTecnicaEnum from './enumeradores/TipoAprovacaoAnaliseTecnicaEnum';
import TipoAprovacaoEnum from './enumeradores/TipoAprovacaoEnum';
import ApiEnum from './enumeradores/ApiEnum';
import ModuloEnum from './enumeradores/ModuloEnum';

export default {
  ModuloEnum,
  ApiEnum,
  TipoAprovacaoEnum,
  LidoNaoLidoEnum,
  CodigoRelatorioEnum,
  GatilhoMovimentacaoSetorEnum,
  TipoSetorEnum,
  ContatoResponsabilidadeEnum,
  TipoPontoEnum,
  OrigemPontoEnum,
  PosicaoSequenciaSetorEnum,
  AtivoInativoEnum,
  StatusOrcamentoEnum,
  TipoDataEnum,
  TipoParticipanteEnum,
  TipoPessoaEnum,
  PapelFornecedorEnum,
  TipoDescontoEnum,
  FormaSolicitacaoOrcamentoEnum,
  DiasEnvioMaterialEnum,
  TipoEnderecoEnum,
  TipoRequisitoEnum,
  BoleanoEnum,
  TipoProcedimentoEletronicoEnum,
  SituacaoOrdemServicoEnum,
  TipoColunaEnum,
  ArredondamentoEnum,
  ValorAtulizarEnum,
  ChecklistEnum,
  TipoDescontoAcrescimoEnum,
  TipoContratoEnum,
  SituacaoContratoEnum,
  SituacaoCertificadoEnum,
  TipoCampoAdicionaisEnum,
  EdicaoValorCampoAdicionaisEnum,
  SituacaoAvaliacaoEnum,
  EnumFormulaValidacaoCriterioValidacao,
  TipoReferenciaEnum,
  MesesEnum,
  SituacaoVerificacaoEnum,
  FuncionalidadesEnum,
  FuncionalidadesAcoesEnum,
  SituacaoEnvioMaterialEnum,
  TipoRelatorioEnum,
  TipoEnvioMaterial,
  FormulaValidacaoEnum,
  TipoPermissaoEnum,
  MetodoEnum,
  TipoAprovacaoAnaliseTecnicaEnum,
  //Buscar enum pelo value
  getEnum(v) {
    let Enum = [...TipoParticipanteEnum];
    if (v == -1) return { value: -1, text: 'Total' };
    return Enum.find((el) => el.value == v);
  },
};
