import { BasicoApi } from '@common/api';
const EscopoServicoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/escopo', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/escopo/${id}`);
  },
  buscarFaixas: function (id) {
    return BasicoApi.get()(`/escopo/${id}/faixas`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/escopo/${form.id}`, form);
    return BasicoApi.post()('/escopo', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/escopo/${id}`);
  },
};

export default EscopoServicoService;
