import i18n from '@/i18n';

export default [
  {
    text: i18n.t('modulos.servico_manutencao.modal_atualizar_preco.inputs.valor_serviço'),
    value: 1,
  },
  {
    text: i18n.t('modulos.servico_manutencao.modal_atualizar_preco.inputs.valor_ponto'),
    value: 2,
  },
  {
    text: i18n.t('modulos.servico_manutencao.modal_atualizar_preco.inputs.valor_hora'),
    value: 3,
  },
];
