export default {
  codigo: 'Código',
  tabela: {
    codigo: 'Código',
    data_inicio: 'Data de Início',
    valor: 'Valor',
    valor_mensal: 'Valor Mensal'
  },
  titulo_modal: 'Contratos',
};
