<template>
  <div>
    <tabela-padrao
      :dados="tabela.dados"
      class="mt-2"
      sem-acoes
      sem-paginacao
      :colunas="tabela.colunas"
      :por-pagina="-1"
      :mostrar-checkbox="false"
    >
      <template v-slot:item.nome="{ item }">
        <strong>{{ item.item.nome }}</strong>
      </template>
      <template v-slot:item.valor="{ item }">
        <input-condicional-modal
          v-model="buscarValorInput(item.item).valorInput"
          :tipo-input="item.item.tipoCampo"
          :opcoes="item.item.itens"
        />
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import CampoAdicionalService from "@common/services/cadastros/CampoAdicionalService";
import InputCondicionalModal from "./InputCondicionalModal.vue";
export default {
  components: { InputCondicionalModal },
  props: {
    multiplos: { type: Boolean, default: true },
    value: {},
  },
  data() {
    return {
      conteudo: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: "nome",
            text: this.$t("componentes.input_campos_adicionais.tabela.campo"),
            sortable: false,
            width: 240,
          },
          {
            value: "valor",
            text: this.$t("componentes.input_campos_adicionais.tabela.valor"),
            sortable: false,
          },
        ],
      },
    };
  },

  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    listar: function (tipoInstrumentos) {
      let parametros = {
        entidade: 1,
      };

      let tipoInstrumentosIds = tipoInstrumentos
        .map((id) => {
          return `TiposInstrumentosIds=${id}`;
        })
        .join("&");

      this.$store.dispatch("Layout/iniciarCarregamento");
      CampoAdicionalService.listar(parametros, tipoInstrumentosIds)
        .then((res) => {
          this.tabela.dados = res.data;
        })
        .finally(() => {
          this.$store.dispatch("Layout/terminarCarregamento");
        });
    },
    buscarValorInput: function (item) {
      let obj = this.conteudo.find((conteudo) => conteudo.value == item.id);

      if (obj == null) {
        obj = {
          value: item.id,
          text: item.nome,
          valorInput: "",
        };
        this.conteudo.push(obj);
      }
      obj.tipoCampo = item.tipoCampo;
      return obj;
    },
  },
};
</script>
