import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

// Plugins
import createPersistedState from 'vuex-persistedstate';

// Modules
import ModuleErrors from './modules/errors'; //todo remover

import Autenticacao from './autenticacao';
import Layout from './layout';
import Filtros from './filtros';
import Roteiro from './roteiro';

Vue.use(Vuex);

const modules = {
  Layout,
  Autenticacao,
  Filtros,
  Roteiro,
  ModuleErrors, //todo remover
};

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  strict: debug,
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state,
  actions,
  mutations,
  getters,
  modules,
});
