<template>
  <div
    class="tabela-padrao"
    :class="`
      ${!semAcoes ? 'tabela-padrao-com-acoes' : ''}
      ${mostrarCheckbox || mostrarExpandir ? 'tabela-padrao-com-seletor' : ''}
    `"
  >
    <v-data-table
      v-model="selecionados"
      v-sortable-data-table="ordenavel"
      dense
      :show-select="mostrarCheckbox"
      :show-expand="mostrarExpandir"
      :headers="colunas"
      :page.sync="paginaAtual"
      :items="dadosManipulaveis"
      :group-by="groupBy"
      :items-per-page="semPaginacao ? dadosManipulaveis.length : porPagina"
      hide-default-footer
      no-data-text=""
      :single-select="selecionarApenasUm"
      :mobile-breakpoint="mobileBreakpoint"
      :item-key="itemKey"
      @toggle-select-all="selecionarTodos"
      @sorted="saveOrder"
    >
      <template
        v-for="header in colunas.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value, item }"
      >
        {{ header.formatter(value, item) }}
      </template>
      <template v-slot:header.data-table-select>
        <v-simple-checkbox
          v-if="!selecionarApenasUm && !semSelecionarTodos && mostrarCheckbox"
          :disabled="disabled"
          :value="todosSelecionados"
          @input="selecionarTodos"
        />
      </template>
      <template v-slot:item.data-table-select="item">
        <div>
          <v-simple-checkbox
            v-if="validacaoSelecionar(item.item) && mostrarCheckbox"
            v-model="item.isSelected"
            :disabled="disabled"
            @input="selecionar(item.item)"
          />
        </div>
      </template>
      <template
        v-for="slot in slots"
        v-slot:[`${slot}`]="item"
      >
        <slot
          :name="slot"
          :item="item"
        />
        <span :key="slot" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="!semPaginacao"
      v-model="paginaAtual"
      class="paginacao-padrao"
      :class="cssPagination"
      color="#F2F4F7"
      :length="quantidadePaginas"
      :total-visible="10"
    />
  </div>
</template>
<script>
import Sortable from 'sortablejs';
export default {
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        if (!binding.value) return;
        const options = {
          animation: 150,
          onUpdate(event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },
  props: {
    id: {
      type: String,
      default: 'tabela',
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    colunas: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dados: {
      type: Array,
      default: () => {
        return [];
      },
    },
    semPaginacao: {
      type: Boolean,
      default: false,
    },
    backendPaginado: {
      type: Boolean,
      default: false,
    },
    porPagina: {
      type: Number,
      default: 10,
    },
    quantidadeItens: {
      type: Number,
      default: 0,
    },
    quantidadePaginas: {
      type: Number,
      default: 0,
    },
    semSelecionarTodos: {
      type: Boolean,
      default: false,
    },
    semAcoes: {
      type: Boolean,
      default: false,
    },
    selecionarApenasUm: {
      type: Boolean,
      default: false,
    },
    ordenavel: {
      type: Boolean,
      default: false,
    },
    mostrarCheckbox: {
      type: Boolean,
      default: true,
    },
    mostrarExpandir: {
      type: Boolean,
      default: false,
    },
    groupBy: {
      type: String,
      default: null,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    validacaoSelecionar: {
      type: Function,
      default: function () {
        return true;
      },
    },
    cssPagination: {
      type: String,
      default: '',
    },
    mobileBreakpoint:{
      type: Number,
      default: 600,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selecionados: [],
      todosSelecionado: false,
      opcoesPaginas: [10, 25, 50, 100],
      quantidadePorPagina: null,
      paginaAtual: 1,
      dadosManipulaveis: [],
    };
  },
  computed: {
    slots() {
      let slots = [];
      slots.push(...Object.keys(this.$slots));
      slots.push(...Object.keys(this.$scopedSlots));
      return slots;
    },
    todosSelecionados: function () {
      return this.dadosManipulaveis.every((el) => {
        return this.selecionados.find((el2) => el2.id == el.id) != null;
      });
    },
  },
  watch: {
    selecionados() {
      this.$emit('input', this.selecionados);
    },
    paginaAtual() {
      this.$emit('paginar', this.paginaAtual, this.porPagina);
    },
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    dados: {
      handler() {
        this.dadosManipulaveis = this.dados;
      },
      immediate: true,
    },
    colunas() {
      this.$forceUpdate();
    },
  },
  methods: {
    selecionar: function (item) {
      if (!this.validacaoSelecionar(item)) return;
      let selecionado = this.selecionados.find((el) => el.id == item.id);
      if (selecionado) {
        this.selecionados.splice(
          this.selecionados.indexOf(
            this.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        if (this.selecionarApenasUm) this.selecionados = [];
        this.selecionados.push(item);
      }
    },
    saveOrder(event) {
      const movedItem = this.dadosManipulaveis.splice(event.oldIndex, 1)[0];
      this.dadosManipulaveis.splice(event.newIndex, 0, movedItem);
      this.$emit('ordenar', this.dadosManipulaveis);
    },
    selecionarTodos() {
      this.$emit('selecionarTodos', {
        value: !this.todosSelecionados,
        items: this.dadosManipulaveis,
      });
      if (this.todosSelecionados) {
        this.selecionados = [];
      } else {
        this.selecionados = this.dadosManipulaveis;
      }
    },
    alterarPagina(paginaAtual, porPagina = 10) {
      this.paginaAtual = paginaAtual;
      this.porPagina = porPagina;
    },
  },
};
</script>
<style lang="scss">
.paginacao-padrao {
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none !important;
  }
}

.tabela-padrao-com-acoes td:nth-child(2),
.tabela-padrao-com-acoes th:nth-child(2) {
  width: 0;
}
.tabela-padrao-com-seletor td:nth-child(1),
.tabela-padrao-com-seletor th:nth-child(1) {
  width: 0;
}
</style>
