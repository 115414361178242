import helpers from '@router/helpers';
const menuId = 'registro-informativo';

export default [
  helpers.criarRota(
    menuId,
    'registro-informativo',
    menuId,
    () => import('@/views/modulos/cadastros/registro_informativo')
  ),
  helpers.criarRota(
    menuId,
    'registro-informativo-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/registro_informativo/formulario')
  ),
  helpers.criarRota(
    menuId,
    'registro-informativo-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/registro_informativo/formulario'),
    true
  ),
];
