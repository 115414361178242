import helpers from '@router/helpers';
const menuId = 'procedimentos-eletronicos';

export default [
  helpers.criarRota(
    menuId,
    'procedimentos-eletronicos',
    menuId,
    () => import('@/views/modulos/cadastros/procedimentos_eletronicos')
  ),
  helpers.criarRota(
    menuId,
    'procedimento-eletronico-novo',
    `${menuId}/novo`,
    () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/formulario')
  ),
  helpers.criarRota(
    menuId,
    'procedimento-eletronico-editar',
    `${menuId}/:id?`,
    () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'procedimento-eletronico-revisoes',
    `${menuId}/revisoes/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/procedimentos_eletronicos/TabelaRevisoes'
      ),
    true
  ),
  helpers.criarRota(
    menuId,
    'procedimento-eletronico-revisao',
    `${menuId}/revisao/:id?`,
    () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'procedimento-eletronico-revisao-nao-publicada',
    `${menuId}/revisao/:id?`,
    () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'procedimento-eletronico-usuarios',
    `${menuId}/usuarios/:id?`,
    () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/usuarios'),
    true
  ),
];
