import helpers from '@router/helpers';
const menuIdCertificado = 'certificado';
const menuIdOperacao = 'operacao';

export default [
  helpers.criarRota(
    menuIdCertificado,
    'calibracao-certificado',
    'calibracao/certificado/:id?',
    () => import('@/views/modulos/cadastros/calibracao/components/Certificado'),
    true
  ),
  helpers.criarRota(
    menuIdOperacao,
    'calibracao-editar',
    'calibracao/:ordemServicoId?/:instrumentoId?/:instrumentoAnaliseTecnicaId?',
    () => import('@/views/modulos/cadastros/calibracao/formulario'),
    true
  ),
  helpers.criarRota(
    menuIdOperacao,
    'calibracao-visualizar',
    'calibracao_visualizar/:calibracaoId?',
    () => import('@/views/modulos/cadastros/calibracao/formulario'),
    true
  ),
];
