import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Tree from 'primevue/tree';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import Paginator from 'primevue/paginator';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';

import locale from '@/i18n/pt-BR/locale.js';

import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

export default {
  install(Vue) {
    Vue.use(PrimeVue, { locale });

    Vue.component('DataTable', DataTable);
    Vue.component('Tree', Tree);
    Vue.component('Dropdown', Dropdown);
    Vue.component('Card', Card);
    Vue.component('Row', Row);
    Vue.component('Column', Column);
    Vue.component('ColumnGroup', ColumnGroup);
    Vue.component('Paginator', Paginator);
    Vue.component('MultiSelect', MultiSelect);
    Vue.component('InputText', InputText);
    Vue.component('InputNumber', InputNumber);
    Vue.component('Calendar', Calendar);
    Vue.component('Button', Button);
    Vue.component('Checkbox', Checkbox);
    Vue.component('OverlayPanel', OverlayPanel);
    Vue.component('Divider', Divider);
  },
};
