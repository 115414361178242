import { BasicoApi } from '@common/api';
const RequisitosService = {
  listar: function (parametros) {
    return BasicoApi.get()('/requisito', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/requisito/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/requisito/${form.id}`, form);
    return BasicoApi.post()('/requisito', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/requisito/${id}`);
  },
    salvarOrdenacao: function (listaDados) {
    return BasicoApi.patch()('/requisito/sequencia', {
      ids: listaDados.map((el) => el.id),
    });
  },
};

export default RequisitosService;
