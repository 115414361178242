import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.forma_colicitacao_orcamento.nao_informado'),
    value: 'NaoInformado',
  },
  {
    text: i18n.t('enums.forma_colicitacao_orcamento.email'),
    value: 'Email',
  },
  {
    text: i18n.t('enums.forma_colicitacao_orcamento.telefone'),
    value: 'Telefone',
  },
  {
    text: i18n.t('enums.forma_colicitacao_orcamento.pessoalmente'),
    value: 'Pessoalmente',
  },
  {
    text: i18n.t('enums.forma_colicitacao_orcamento.marketing'),
    value: 'Marketing',
  },
  {
    text: i18n.t('enums.forma_colicitacao_orcamento.outro'),
    value: 'Outro',
  },
];
