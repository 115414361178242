<template>
  <div :class="dropW100 ? 'w-100' : ''">
    <v-menu
      ref="menu"
      class="dropdown-padrao w-100"
      :offset-y="offsetY"
      :offset-x="offsetX"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="ativador" :on="on">
          <botao-padrao
            class="botao-padrao-fill"
            :class="submenu ? 'botao-padrao-sem-borda' : ''"
            :depressed="depressed"
            :outlined="outlined"
            :text="ehText"
            :color="color"
            :tooltip="tooltip"
            v-bind="attrs"
            @click="onClick"
            v-on="on"
          >
            <div class="w-100 d-flex align-center justify-space-between">
              <slot name="botao" />
              <v-icon v-if="submenu"> $menuRight </v-icon>
              <v-icon v-if="seta"> $downArrow </v-icon>
            </div>
          </botao-padrao>
        </slot>
      </template>
      <v-list-item-group no-action style="overflow-y: auto">
        <slot />
      </v-list-item-group>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    tooltip: { type: String, default: null },
    depressed: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
    submenu: { type: Boolean, default: false },
    dropW100: { type: Boolean, default: true },
    seta: { type: Boolean, default: false },
  },
  computed: {
    slots: function () {
      let slots = [];
      slots.push(...Object.keys(this.$slots));
      return slots;
    },
    offsetY: function () {
      return !this.submenu;
    },
    offsetX: function () {
      return this.submenu;
    },
    ehText: function () {
      if (this.submenu) return true;
      return this.text;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
