<template lang="">
  <div>
    <div
      v-if="podeEmprestar"
      class="d-flex align-center mb-2"
    >
      <input-checkbox
        v-model="emprestar"
        :label="'Emprestar de outros participantes'"
      />
    </div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      style="min-height: 300px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      linha-hover
      :mostrar-seletor="false"
      mostrar-seletor-check-box
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      @paginar="listar"
      @filtrarGlobal="filtrar"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
    >
      <template v-slot:seletor="{ slotProps }">
        <div>
          <v-simple-checkbox
            v-if="!podeSelecionar(slotProps.data)"
            v-model="slotProps.data.isSelected"
            @input="
              selecionarInstrumento(slotProps.data, slotProps.data.isSelected)
            "
          />
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    clienteSelecionado: { type: Object, default: null },
    tiposInstrumentos: { type: [Array, Object], default: null },
    verificacaoIntermediaria: { type: Boolean, default: false },
    participanteId: { type: String, default: '' },
    somenteAtivos: { type: Boolean, default: false },
    instrumentoPadrao: { type: Boolean, default: null },
    dataHoraAgendamentoInicial: { type: String, default: '' },
    dataHoraAgendamentoFinal: { type: String, default: '' },
    podeEmprestar: { type: Boolean, default: false },
  }, //Código, Tipo de Instrumento, Marca, Modelo de Instrumento e Número de Série.
  data() {
    return {
      emprestar: false,
      filtro: {
        codigoTag: '',
        nomeTipoInstrumento: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('componentes.input_instrumento.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.tipo'),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t('componentes.input_instrumento.tabela.setor'),
            sortable: false,
          },
          {
            value: 'validacaoCertificadoInstrumentoPadrao',
            text: this.$t(
              'componentes.input_instrumento.tabela.situacaoCertificado'
            ),
            sortable: false,
            formatter: (v) => {
              return this.$t(`enums.EnumInstrumentoPadraoValidacao.${v}`);
            },
          },
          {
            value: 'modeloInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.modelo'),
            sortable: false,
          },
          {
            value: 'agendamento.codigo',
            text: this.$t('componentes.input_instrumento.tabela.agendamento'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      watchEmprestar: null,
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
  },
  mounted() {
    this.listar();
    if (this.watchEmprestar) this.watchEmprestar();
    this.watchEmprestar = this.$watch('emprestar', () => {
      this.listar(1, 10, this.emprestar);
    });
  },
  methods: {
    focusFiltroGlobal: async function () {
      await this.$nextTick();
      await this.$nextTick();
      this.$refs['tabela'].focusFiltroGlobal();
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (
      paginaAtual = 1,
      porPagina = 10,
      listarTodosParticipantes
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: 'codigoTag',
        ...this.filtroAplicado,
      };

      if (this.clienteSelecionado && !this.emprestar)
        parametros.ParticipanteId = this.clienteSelecionado?.value;

      if (this.verificacaoIntermediaria)
        parametros.flagVerificacaoIntermediaria = true;

      if (this.participanteId) {
        parametros.participanteId = this.participanteId;
      }

      let TiposInstrumentosIds = [];

      if (this.tiposInstrumentos) {
        TiposInstrumentosIds = `TiposInstrumentosIds=${this.tiposInstrumentos.value}`;
      }

      if (this.somenteAtivos) {
        parametros.flagAtivo = true;
      }

      if (this.instrumentoPadrao) {
        parametros.FlagPadrao = this.instrumentoPadrao;
      }

      if (this.dataHoraAgendamentoInicial)
        parametros.dataHoraAgendamentoInicial = this.dataHoraAgendamentoInicial;

      if (this.dataHoraAgendamentoFinal)
        parametros.dataHoraAgendamentoFinal = this.dataHoraAgendamentoFinal;

      if (listarTodosParticipantes) {
        parametros.ParticipanteId = '';
      }

      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.listar(parametros, TiposInstrumentosIds)
        .then((res) => {
          this.tabela.dados = res.data.items.map((item) => {
            if (this.selecionados.some((el) => el.id === item.id)) {
              item.isSelected = true;
            }
            return item;
          });

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    selecionarInstrumento: function (item, selecionado) {
      if (!selecionado) {
        this.selecionados.splice(
          this.selecionados.indexOf(
            this.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.selecionados.push(item);
      }
    },
    podeSelecionar: function (item) {
      return item.agendamento !== null;
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = this.EnumAgendamento.find((x) => x.value === situacao)
          ?.text?.icon;
        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoEnum(situacao) {
      if (situacao) {
        const Enum = this.EnumAgendamento.find((x) => x.value === situacao)
          ?.text?.enum;
        return Enum;
      }
    },
  },
};
</script>
