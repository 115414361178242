export default {
  token: null,
  refreshToken: null,
  buscandoRefreshToken: false,
  usuarioId: null,
  usuario: null,
  participanteSelecionado: null,
  refreshTokenValidity: null,
  participanteLogadoConta: null,
  encodedData: null,
  colaborador:null,
};
