import { BasicoApi } from '@common/api';

const ServicoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/servico', parametros);
  },
  // listar: function (parametros, InstrumentoId) {
  //   return BasicoApi.get()(
  //     `/servico${InstrumentoId ? `?${InstrumentoId}` : ''
  //     }`,
  //     parametros
  //   );
  // },
  buscar: function (id) {
    return BasicoApi.get()(`/servico/${id}`);
  },
  buscarTipoServico: function (params, tipoServico, InstrumentoId) {
    return BasicoApi.get()(`/servico/tipoServico/${tipoServico}${InstrumentoId ? `?${InstrumentoId}` : ''
      }`,
      params
    );
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/servico/${form.id}`, form.request);
    return BasicoApi.post()('/servico', form.request);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/servico/${id}`);
  },
  duplicar: function (form) {
    if (form.id) return BasicoApi.post()(`/servico/${form.id}/duplicate`, form);
  },
  atualizarPreco: function (form) {
    return BasicoApi.put()(`/servico/updateprecobyservicos`, form);
  },
  pesquisaServicosCalibracaoInstrumentos: function (params) {
    return BasicoApi.post()(`/servico/listServicoCalibracaoTreeview`, params);
  },
  listaServicosPeloPontoFaixaInstrumento: function (params) {
    return BasicoApi.get()(
      `/servico/ListServicoByPontoFaixaInstrumento/${params.instrumentoId}`,
      params
    );
  },
  listaServicosPeloPontoFaixaInstrumentoOrdemServico: function (params) {
    return BasicoApi.get()(
      `/servico/ListServicoOrdemServicoByPontoFaixaInstrumento/${params.ordemServicoId}`,
      params
    );
  },
};

export default ServicoService;
