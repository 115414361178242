import { BasicoApi } from '@common/api';
const RegistroInformativoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/registroInformativo', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/registroInformativo/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/registroInformativo/${form.id}`, form);
    return BasicoApi.post()('/registroInformativo', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/registroInformativo/${id}`);
  },
};

export default RegistroInformativoService;
