import helpers from '@router/helpers';
const menuId = 'checklist';

export default [
  helpers.criarRota(menuId, 'checklist', menuId, () =>
    import('@/views/modulos/cadastros/checklist')
  ),
  helpers.criarRota(menuId, 'checklist-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/checklist/formulario')
  ),
  helpers.criarRota(
    menuId,
    'checklist-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/checklist/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'checklist-duplicar',
    `${menuId}/duplicar/:id?`,
    () => import('@/views/modulos/cadastros/checklist/formulario'),
    true
  ),
];
