export default {
  titulos: {
    listagem: 'Comentários',
    novo: 'Novo Comentário',
    editar: 'Editar Comentário',
  },
  cadastro_sucesso: 'Comentário salvo com sucesso!',
  exclusao_sucesso: 'Comentário excluído com sucesso!',
  encerrado_sucesso: 'Comentário encerrado com sucesso!',
  tabela: {
    novo_comentario: 'Novo Comentário',
      usuario:'Usuário',
      comentario: 'Comentário',
      data: 'Data',
      situacao: 'Situação',
      aberto: 'Aberto',
      encerrado: 'Encerrado'
  },
  formulario: {
    usuario:'Usuário',
    comentario: 'Comentário',
    data: 'Data',
    situacao: 'Situação',
    privado: 'Privado',
    encerrar: 'Encerrar',
    notificar: 'Notificar',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido: 'É necessário validar o formulário antes de salvar o registro.'
  },
  filtro: {
    nome: 'Nome',
  },
  sem_comentario: 'Adicione uma resposta a esse comentário.'

};
