import { AuthApi } from '@common/api';
const PermissaoService = {
  listar: function (parametros) {
    return AuthApi.get()('permissao', parametros);
  },
  buscar: function (id) {
    return AuthApi.get()(`/permissao/${id}`);
  },
  salvar: function (form) {
    if (form.id) return AuthApi.put()(`/permissao/${form.id}`, form);
    return AuthApi.post()('/permissao', form);
  },
  excluir: function (id) {
    return AuthApi.delete()(`/permissao/${id}`);
  },
};

export default PermissaoService;
