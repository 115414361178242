export default {
  autenticar({ commit }, form) {
    commit('ALTERAR_AUTENTICACAO', form);
  },
  alterarRefreshToken({ commit }, form) {
    commit('ALTERAR_REFRESH_TOKEN', form);
  },
  limparAutenticacao(context, payload) {
    context.commit('LIMPAR_AUTENTICACAO', payload);
  },
  alterarParticipanteSelecionado(context, payload) {
    context.commit('ALTERAR_PARTICIPANTE_SELECIONADO', payload);
  },
  alterarColaborador(context, payload) {
    context.commit('ALTERAR_COLABORADOR', payload);
  },
  alterarBearerToken(context, payload) {
    context.commit('ALTERAR_BEARER', payload);
  },
  iniciarBloqueioRequisicao(context) {
    context.commit('ALTERAR_VALIDACAO_GET_REFRESH', true);
  },
  pararBloqueioRequisicao(context) {
    context.commit('ALTERAR_VALIDACAO_GET_REFRESH', false);
  },
};
