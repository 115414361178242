import BigNumber from 'bignumber.js';
import moment from 'moment';

import Enum from '../enum';

const adicionarDiasUteis = (data, dias) => {
  let diaAtual = data.clone();
  let diasAdicionados = 0;

  for (let i = 1; diasAdicionados < dias; i++) {
    diaAtual.add(1, 'days');
    if (diaAtual.day() !== 0 && diaAtual.day() !== 6) {
      diasAdicionados++;
    }
  }

  return diaAtual;
};

const formatarDataBr = (data, hora = false) => {
  let dataHora = data.split('T');
  let dataSplit = dataHora[0].split('-');
  let dataFormatada = `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
  if (hora) {
    let horaSplit = dataHora[1].split('.');
    dataFormatada = `${dataFormatada} ${horaSplit[0]}`;
  }
  return dataFormatada;
};

const formatarDataUS = (data) => {
  let dataHora = data.split('T');
  let dataSplit = dataHora[0].split('-');
  let dataFormatada = `${dataSplit[0]}-${dataSplit[1]}-${dataSplit[2]}`;
  return dataFormatada;
};

const formatarDataBd = (data, hora = false) => {
  let formato = !hora ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
  return moment(data, !hora ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm').format(
    formato
  );
};

const formatarMoeda = (value) => {
  if (!value) return 'R$ 0,00';
  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

const formatarDescricao = (value) => {
  return String(value).replaceAll('.', ',');
};

const removerAcentuacao = (value) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const removerPluralString = (value) => {
  return value
    .split(/\s+/)
    .map((word) => word.replace(/s$/, ''))
    .join(' ');
};

const formatarNumeroComPrecisao = (value, valuePodeVazio = false) => {
  if (value == '' || value == null) return valuePodeVazio ? '' : 0;
  if (typeof value === 'string') value = value.replaceAll(',', '.');
  const valor = new BigNumber(value).toFixed();
  return valor.toString().replaceAll('.', ',');
};

const removerValor = (valor) => {
  return valor.replace('R$', '').replace(/\./g, '').replace(',', '.').trim();
};

const baixarBase64 = (arquivo, nomeArquivo) => {
  var a = document.createElement('a');
  a.href = `${arquivo}`;
  a.download = nomeArquivo;
  a.click();
  a.remove();
};

const removerMascara = (str) => {
  if (str == null || str == undefined || str == '') return '';
  return str.replace(/[^\d]+/g, '');
};

const removerMascaraValor = (str) => {
  if (str == null || str == undefined || str == '') return '';
  str = str.replace(/,/g, '.');
  while (str.split('.').length > 2) {
    str = str.replace('.', '');
  }
  return str.replace(/[^\d.]+/g, '');
};

const primeiraLetraMaiuscula = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatarColunasComPonto = (string) => {
  return string.split('.')
    .map(part => primeiraLetraMaiuscula(part))
    .join('.');
};

const validarEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validarCpfCnpj = (cpfCnpj) => {
  cpfCnpj = removerMascara(cpfCnpj);
  return cpfCnpj.length == 11 ? validarCPF(cpfCnpj) : validarCNPJ(cpfCnpj);
};

const validarEstados = (estado) => {
  let listaEstados = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' },
  ];

  for (const uf of listaEstados) {
    if (estado.toUpperCase() == uf.sigla) return true;
  }
  return false;
};

const validarCPF = (cpf) => {
  cpf = removerMascara(cpf);
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  return rev == parseInt(cpf.charAt(10));
};

const validarCNPJ = (cnpj) => {
  cnpj = removerMascara(cnpj);

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultado == digitos.charAt(1);
};

const validarCep = (cep) => {
  cep = removerMascara(cep);
  let objER = /^[0-9]{2}[0-9]{3}[0-9]{3}$/;

  if (cep.length == 0) return false;
  return objER.test(cep);
};

const maskCnpj = (v) => {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return v;
};

const maskCpf = (cpf) => {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
};

const formatarDocumento = (doc) => {
  if (doc.length > 11) return maskCnpj(doc);
  return maskCpf(doc);
};
const removerMascaraDocumento = (doc) => {
  return `${doc}`.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
};
const removerMascaraCEP = (doc) => {
  return `${doc}`.replaceAll('.', '');
};

function formatarTelefone(v) {
  if (!v) return '';
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
  v = v.replace(/(\d)(\d{4})$/, '$1-$2');
  return v;
}

const retornarValorClaim = (claim) => {
  let claims = JSON.parse(localStorage.getItem('claims'));
  return claims.find((el) => el.type == claim)?.value;
};

const validarAcesso = (acesso) => {
  let claims = JSON.parse(localStorage.getItem('claims'));
  claims = claims.sort((a, b) => {
    return a.value.length - b.value.length;
  });
  let acessos = {};

  for (const key in claims) {
    if (Object.hasOwnProperty.call(claims, key)) {
      if (!acessos[claims[key].type]) {
        acessos[claims[key].type] = {
          type: claims[key].type,
          visualizar: false,
          criar: false,
          editar: false,
          deletar: false,
        };
      }

      acessos[claims[key].type].visualizar =
        claims[key].value.indexOf('R') > -1;
      acessos[claims[key].type].criar = claims[key].value.indexOf('C') > -1;
      acessos[claims[key].type].editar = claims[key].value.indexOf('U') > -1;
      acessos[claims[key].type].deletar = claims[key].value.indexOf('D') > -1;
    }
  }

  return acessos[acesso] || {};
};

const transposeJson = (keyT, array, reduce) => {
  if (reduce) return array.reduce((a, v) => ({ ...a, [v]: v }), {});
  let json = {};

  for (const key in array) {
    if (Object.hasOwnProperty.call(array, key)) {
      if (!json[array[key][keyT]]) {
        json[array[key][keyT]] = { ...array[key] };
      }
    }
  }
  return json || {};
};

const formatarCep = (v) => {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{5})(\d)/, '$1-$2');

  return v;
};

const clonarObjeto = (v) => {
  return Object.assign({}, v);
};

const filterByValue = (array, value) => {
  return array.filter(
    (data) =>
      JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
};

const formatarDataTime = (data) => {
  var m = data;
  return (
    ('0' + m.getDate()).slice(-2) +
    '/' +
    ('0' + (m.getMonth() + 1)).slice(-2) +
    '/' +
    m.getFullYear() +
    ' ' +
    ('0' + m.getHours()).slice(-2) +
    ':' +
    ('0' + m.getMinutes()).slice(-2) +
    ':' +
    ('0' + m.getSeconds()).slice(-2)
  );
};

const objectToQueryString = (obj, parentKey = '') => {
  return Object.keys(obj)
    .map((key) => {
      const fullKey = parentKey ? `${parentKey}` : key;
      const value = obj[key];

      if (value && typeof value === 'object' && Array.isArray(value)) {
        return objectToQueryString(value, fullKey);
      } else {
        return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
};

const serialize = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });

  return (
    '?' +
    Object.keys(obj)
      .reduce(function (a, k) {
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
      }, [])
      .join('&')
  );
};

const getCnpjFromClaim = () => {
  const claims = JSON.parse(localStorage.claims);
  return claims.find((claim) => claim.type === 'CNPJ')?.value;
};

const adicionarData = (dataInput, quantidade, tipo = 'days') => {
  let data = moment();

  if (dataInput != null) data = moment(dataInput);

  return data.add(quantidade, tipo).format('YYYY-MM-DDT12:mm:ss.SSS');
};

const gerarParcelas = (valor, quantidadeParcelas) => {
  let parcelas = [
    {
      value: 1,
      text: 'À Vista',
    },
  ];

  for (let parcela = 2; parcela <= quantidadeParcelas; parcela++) {
    parcelas.push({
      value: parcela,
      text: `Parcelado em ${parcela} vezes de ${formatarMoeda(
        valor / parcela
      )}`,
    });
  }
  return parcelas;
};

const gerarNumeroAleatorio = function () {
  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
};

//Não utilizar abaixo, a função abaixo randomUUID só funciona com https, todos lugares que utilizavam gerarUUIDAleatorio foram substituidos por gerarNumeroAleatorio
// const gerarUUIDAleatorio = function () {
//   const crypto = window.crypto || window.msCrypto;
//   return crypto.randomUUID();
// };

const converterBase64 = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const converterNumeroParaTexto = (value) => {
  return value ? String(value).replaceAll('.', ',') : value;
};

const converterTextoParaNumero = (value) => {
  if (typeof value === 'string')
    value = value.replaceAll('.', '').replaceAll(',', '.');
  return value ? parseFloat(value) : value;
};

const converterDataParaIsoString = (value) => {
  if (!value) return null;
  return new Date(value).toISOString();
};

function converterBase64ParaFile(url, filename, mimeType) {
  if (url.startsWith('data:')) {
    var arr = url.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

const delay = function (milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

const decimalParaAngulo = function (decimalString) {
  let decimal = converterTextoParaNumero(decimalString);
  let graus = Math.floor(decimal);
  let minutosDecimal = (decimal - graus) * 60;
  let minutos = Math.floor(minutosDecimal);
  let segundos = Math.round((minutosDecimal - minutos) * 60);
  if (segundos >= 60) {
    segundos -= 60;
    minutos++;
  }
  if (minutos >= 60) {
    minutos -= 60;
    graus++;
  }
  return `${graus}° ${`${minutos}`.padStart(2, '0')}' ${`${segundos}`.padStart(
    2,
    '0'
  )}"`;
};

const anguloParaDecimal = function (anguloString) {
  let anguloTratado = anguloString.replace(/°/g, '');
  anguloTratado = anguloTratado.replace(/'/g, '');
  anguloTratado = anguloTratado.replace(/"/g, '');
  anguloTratado = anguloTratado.split(' ');
  var anguloDecimal =
    parseInt(anguloTratado[0]) +
    parseInt(anguloTratado[1]) / 60 +
    parseInt(anguloTratado[2]) / 3600;
  return anguloDecimal;
};

const converterFlagDiasParaDiasSemana = function (arrayDiasBooleanos) {
  const diasSemana = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'];
  const diasSelecionados = [];
  for (let i = 0; i < arrayDiasBooleanos.length; i++) {
    if (arrayDiasBooleanos[i]) {
      diasSelecionados.push(diasSemana[i]);
    }
  }
  return diasSelecionados;
};
const ordenarDropdown = (a, b) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

const formatarFaixa = (value) => {
  if (value) {
    return value.replace(/\./g, ',');
  }
};

const buscarStateFiltros = function (vueComponent) {
  return vueComponent.$store.getters['Filtros/filtros'][
    vueComponent.$route.name
  ];
};

const salvarStateFiltros = function (vueComponent, dados) {
  vueComponent.$store.dispatch('Filtros/adicionarFiltro', {
    rota: vueComponent.$route.name,
    dados,
  });
};

const adicionaCifraoValor = (valor) => `R$ ${valor.toFixed(2)}` || 'R$ 0,00';

const buscarStatePermissoes = function (
  vueComponent,
  funcionalidade,
  funcionalidadeAcao
) {
  if (
    vueComponent.$store.getters['Autenticacao/usuario']?.papel?.nome ==
    'Administrador'
  )
    return true;

  return vueComponent.$store.getters[
    'Autenticacao/usuario'
  ]?.papel?.permissoes.some(
    (permissao) =>
      permissao.funcionalidade == funcionalidade &&
      permissao.funcionalidadeAcao == funcionalidadeAcao
  );
};

const redirecionarSemPermissao = function (
  vueComponent,
  funcionalidade,
  funcionalidadeAcao
) {
  if (
    buscarStatePermissoes(vueComponent, funcionalidade, funcionalidadeAcao) ===
    false
  ) {
    vueComponent.$router.push({ name: 'erropermissao' });
  }
};

export default {
  redirecionarSemPermissao,
  buscarStatePermissoes,
  buscarStateFiltros,
  salvarStateFiltros,
  ordenarDropdown,
  adicionarDiasUteis,
  formatarDataBr,
  formatarDataTime,
  formatarDataBd,
  formatarDataUS,
  objectToQueryString,
  primeiraLetraMaiuscula,
  formatarColunasComPonto,
  removerMascara,
  validarEmail,
  clonarObjeto,
  validarCpfCnpj,
  transposeJson,
  validarCPF,
  filterByValue,
  validarCNPJ,
  validarCep,
  validarAcesso,
  maskCnpj,
  maskCpf,
  formatarTelefone,
  validarEstados,
  formatarMoeda,
  formatarNumeroComPrecisao,
  formatarFaixa,
  formatarDescricao,
  formatarCep,
  serialize,
  removerMascaraValor,
  getCnpjFromClaim,
  removerValor,
  baixarBase64,
  formatarDocumento,
  adicionarData,
  gerarParcelas,
  retornarValorClaim,
  removerMascaraDocumento,
  removerMascaraCEP,
  gerarNumeroAleatorio,
  converterBase64,
  converterNumeroParaTexto,
  converterTextoParaNumero,
  converterDataParaIsoString,
  removerAcentuacao,
  removerPluralString,
  converterBase64ParaFile,
  delay,
  decimalParaAngulo,
  anguloParaDecimal,
  converterFlagDiasParaDiasSemana,
  adicionaCifraoValor,
  ...Enum,
};
