export default {
  nome: 'Nome',
  buscar: 'Buscar',
  marca: 'Marca',
  tabela: {
    nome: 'Nome',
    marca: 'Marca',
  },
  titulo_modal: 'Associar Modelo de Instrumento',
};
