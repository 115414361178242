export default {
  codigo: 'Código',
  nome: 'Nome',
  faixa: 'Faixa',
  tipo_instrumento: 'Tipo Instrumento',
  tabela: {
    codigo: 'Código',
    tipo: 'Tipo do Instrumento',
    numero_serie: 'Número de Série',
    modelo: 'Modelo do Instrumento',
    marca: 'Marca',
    situacaoCertificado: 'Situação do Certificado',
    agendamento: 'Agendamento',
    setor: 'Setor',
    observacao: 'Observação'
  },
  titulo_modal: 'Instrumentos',
  titulo_modal_selecionar: 'Selecionar Instrumentos'
};
