import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_coluna.texto'),
    value: 'Texto',
  },
  {
    text: i18n.t('enums.tipo_coluna.referencia'),
    value: 'Referencia',
  },
  {
    text: i18n.t('enums.tipo_coluna.smc'),
    value: 'SMC',
  },
  {
    text: i18n.t('enums.tipo_coluna.smp'),
    value: 'SMP',
  },
  {
    text: i18n.t('enums.tipo_coluna.resultado'),
    value: 'Resultado',
  },
  {
    text: i18n.t('enums.tipo_coluna.unidade'),
    value: 'Unidade',
  },
  {
    text: i18n.t('enums.tipo_coluna.auxiliar'),
    value: 'TabelaAuxiliar',
  }
];
