import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumTipoPermissao.Desabilitado'),
    value: 'Desabilitado',
  },
  {
    text: i18n.t('enums.EnumTipoPermissao.Anonimizado'),
    value: 'Anonimizado',
  },
];