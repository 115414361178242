import { BasicoApi} from '@common/api';
const RoteiroEnvioMaterialService = {
  listar: function (parametros) {
    return BasicoApi.get()('/roteiro', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/roteiro/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/roteiro/${form.id}`, form);
    return BasicoApi.post()('/roteiro', form);
  },
  excluir: function (ids) {
    return BasicoApi.delete()(`/roteiro`, ids);
  },
  buscarRoteiroDia: async function () {
    return BasicoApi.get()('/roteiro/roteiro-do-dia');
  },
  iniciarRoteiro: function (id,form) {
    return BasicoApi.put()(`/roteiro/${id}/iniciar-execucao`,form);
  },
  checkInCliente: function (roteiroId, clienteId){
    return BasicoApi.put()(`/roteiro/${roteiroId}/clientes/${clienteId}/checkin`);
  },
  checkOutCliente: function (roteiroId, envioMaterialId,form){
    return BasicoApi.put()(`/roteiro/${roteiroId}/clientes/envios-materiais/${envioMaterialId}/checkout`,form);
  },
  finalizarRoteiro: function (id,form) {
    return BasicoApi.put()(`/roteiro/${id}/finalizar-execucao`,form);
  },
  recebimentoSemAgendamento : function (roteiroId,clientId,form) {
    return BasicoApi.post()(`/roteiro/${roteiroId}/clientes/${clientId}/recebimento-sem-agendamento`,form);
  },
  desfazerCheckInCliente: function (roteiroId, clienteId){
    return BasicoApi.put()(`/roteiro/${roteiroId}/clientes/${clienteId}/desfazer-checkin`);
  }

};

export default RoteiroEnvioMaterialService;
