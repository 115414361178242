export default {
  titulos: {
    listagem: 'Solicitações de Cadastros',
    novo: 'Nova Solicitação de Cadastro',
    editar: 'Editar Solicitação de Cadastro',
  },
  situacao_atualizada: 'Situação atualizada com sucesso!',
  cadastro_sucesso: 'Solicitação de Cadastro salva com sucesso!',
  exclusao_sucesso: 'Solicitação de Cadastro excluída com sucesso!',
  tabela: {
    tipo_cadastro: 'Tipo de Cadastro',
    texto: 'Texto',
    usuario_solicitante: 'Usuário Solicitante',
    data_solicitacao: 'Data de Solicitação',
    situacao: 'Situação',
  },
  formulario: {
    tipo_cadastro: 'Tipo de Cadastro',
    usuario_solicitante: 'Usuário Solicitante',
    situacao: 'Situação',
    data_solicitacao: 'Data de Solicitação',
    texto: 'Texto',
    observacao: 'Observação',
    usuario_responsavel_resolucao: 'Usuário Responsável pela Resolução',
    data_resolucao: 'Data da Resolução',
    descricao_resolucao: 'Descrição da Resolução',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    tipo_cadastro: 'Tipo de Cadastro',
    usuario_solicitante: 'Usuário Solicitante',
    data_solicitacao: 'Data de Solicitação',
    status: ' Status',
  },
};
