export default {
  procedimento: 'Procedimento',
  tabela_registro: 'Tabela',
  faixa: 'Faixa',
  filtrar: 'Filtrar',
  tabela: {
    procedimento: 'Procedimento',
    tabela_registro: 'Tabela',
    faixa: 'Faixa',
  },
  titulo_modal: 'Tabela de Registros',
};
