import { BasicoApi } from '@common/api';
const ColaboradorService = {
  listar: function (parametros) {
    return BasicoApi.get()('/colaborador', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/colaborador/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/colaborador/${form.id}`, form);
    return BasicoApi.post()('/colaborador', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/colaborador/${id}`);
  },
};

export default ColaboradorService;
