export default {
  carregamentos: {
    carregando_tabela: 'Carregando tabela',
    salvando_tabela: 'Salvando tabela',
  },
  titulos: {
    pagina: 'Calibração',
    visualizar: 'Visualizar Calibração',
    calibracao: 'Calibração',
    temperatura: 'Temperatura',
    umidade: 'Umidade Relativa',
    pressao: 'Pressão Atmosférica',
    usuarioSignatario: 'Usuário Signatário',
    usuarioTecnicoResponsavel: 'Usuário Técnico Executante',
  },
  dados: {
    cadastro_sucesso: 'Dados da calibração salvos com sucesso',
  },
  tabela_calibracao: {
    procedimento: 'Procedimento',
    tabela: 'Tabela',
    tabela_auxiliar: 'Tabela Auxiliar',
    faixa: 'Faixa da Tabela',
    pontos: 'Pontos de Calibração do Instrumento',
    selecione_linha: 'Selecione pelo menos uma tabela',
    cadastro_sucesso: 'Tabelas de calibração salvas com sucesso',
    campo_adicional_restrito:
      'O instrumento possui Campos Adicionais que restringem o uso às Tabelas abaixo',
    instrumento_padrao:
      'Instrumentos padrão podem ter somente uma tabela por faixa na calibração.',
  },
  valores: {
    calculando: 'Realizando calculos da linha',
    titulos: {
      confirmar_instrumento_padrao: 'Editar Instrumentos Padrão',
      confirmar_limpar_instrumento_padrao: 'Limpar Instrumentos Padrão',
      variavel: 'Variável',
      valor: 'Valor',
      depuracao_modal: 'Depuração de Valores do Cálculo',
    },
    pontos_invalidos:
      'Existem pontos de calibração inválidos para o instrumento padrão associado aos mesmos',
    ponto_invalido:
      'O ponto de calibração é invalido para os instrumentos padrões associados',
    cabecalho: 'Cabeçalho',
    ponto_calibracao: 'Ponto de Calibração',
    sentido: 'Sentido',
    variavel: 'Variável',
    cadastro_sucesso: 'Valores salvos com sucesso',
    finalizado_sucesso: 'Calibração finalizada com sucesso',
    alertas: {
      confirmar_salvar_ir_proxima:
        'Deseja salvar e ir para a próxima tabela de calibração?',
      confirmar_salvar: 'Deseja salvar tabela de calibração?',
      confirmar_finalizar: 'Deseja finalizar a calibração?',
      checkbox_modal: 'Visualizar Certificado',
      confirmar_instrumento_padrao:
        'Todos os dados preenchidos na tabela serão apagados caso um novo Instrumento Padrão for definido, deseja continuar ?',
      confirmar_limpar_instrumento_padrao:
        'Deseja limpar os instrumentos padrões e retornar para os configurados originalmente na tabela de registro?',
      limpar_instrumento_sucesso:
        'Instrumentos padrões redefinidos com sucesso!',
      confirmar_limpar_smp:
        'Todos os dados de SMP preenchidos nesta tabela serão apagados, deseja continuar?',
      confirmar_limpar_smc:
        'Todos os dados de SMC preenchidos nesta tabela serão apagados, deseja continuar?',
      mensagem_instrumentos:
        'Os instrumentos abaixos estão invalidos e foram removidos da caibração:<br>',
      confirmar_proxima_tabela: 'Ir para próxima tabela?',
      confirmar_sair_sem_salvar_calibracao:
        'Existem tabelas de calibração não salvas. Deseja realmente sair sem salva-las?',
    },
    botoes: {
      finalizar: 'Finalizar Calibração',
      limpar: 'Limpar SMP',
      editar: 'Editar SMP',
      limpar_smp: 'Limpar Valores SMP',
      limpar_smc: 'Limpar Valores SMC',
      copiar_smp: 'Copiar Valores SMP',
      copiar_smc: 'Copiar Valores SMC',
      copiar_historico: 'Copiar do Histórico',
      depurar: 'Depurar Valor de Cálculo',
      iniciar_ocr: 'Iniciar OCR',
      parar_ocr: 'Interromper OCR',
      tooltip: {
        conversor_unidades: 'Conversor de Unidades',
        instrumento: 'Instrumento',
        visualizar_historico: 'Visualizar Dados - Histórico',
        anexar_arquivo: 'Anexar Arquivos',
        weight: 'SMP',
      },
    },
    instrumento_padrao: 'Instrumento Padrão',
    modal: {
      variaveis: 'Variável',
      pontos: 'Ponto de Calibração',
      instrumento_atual: 'Instrumento Padrão Atual',
      novo_instrumento: 'Novo Instrumento Padrão',
      tabela: {
        codigo: 'Código',
        tipo_instrumento: 'Tipo Instrumento',
        setor: 'Setor',
        situacao: 'Situação',
      },
      tooltip:
        'É necessário escolher uma variável e um novo Instrumento Padrão para prosseguir.',
      mais_um_instrumento:
        'Existem diferentes instrumentos padrão associados para cada ponto',
    },
  },
  formulario: {
    abas: {
      requisitos: 'Requisitos do Cliente',
      dados: 'Dados da Calibração',
      tabelas: 'Tabelas de Calibração',
      valores: 'Valores',
      anexos: 'Anexos',
      faixas: 'Faixas do Instrumento',
      derivas: 'Derivas',
    },
    alerta_escopo: 'A tabela está fora do escopo de serviço',
    codigo_certificado: 'Cód. do Certificado',
    data: 'Data de Calibração',
    tipo_calibracao: 'Tipo de Calibração',
    local_execucao: 'Local de Execução',
    tecnico_executante: 'Usuário Técnico Executante',
    tecnico_responsavel: 'Usuário Signatário',
    inicio_c: 'Início °C',
    meio_c: 'Meio °C',
    fim_c: 'Fim °C',
    mais_menos_c: '+/- °C',
    inicio_porcentagem: 'Início %',
    meio_porcentagem: 'Meio %',
    fim_porcentagem: 'Fim %',
    mais_menos_porcentagem: '+/- %',
    inicio: 'Início',
    inicio_pressao: 'Início hPa',
    meio: 'Meio',
    meio_pressao: 'Meio hPa',
    fim: 'Fim',
    fim_pressao: 'Fim hPa',
    mais_menos: '+/-',
    mais_menos_pressao: '+/- hPa',
    observacao: 'Observação',
    usuarioSignatario: 'Usuário Signatário',
    usuarioTecnicoResponsavel: 'Usuário Técnico Executante',
  },
  erros: {
    id_invalido: 'O id da ordem de serviço ou instrumento é invalido',
  },
  validacoes: {},
  botoes: {},
  anexos: {
    titulo_modal: 'Anexar arquivo',
    formulario: {
      nome_arquivo: 'Nome arquivo',
      descricao: 'Descrição',
    },
    botoes: {
      anexar_arquivo: 'Anexar arquivo',
    },
    anexo_sucesso: 'Arquivo anexado com sucesso',
    exclusao_sucesso: 'Anexo excluído com sucesso',
  },
  linhas_modal: {
    titulo_finalizar: 'Linhas com erro no cálculo',
    mensagem_finalizar:
      'Calibração não pode ser finalizada, pois as linhas abaixo apresentam erros nos cálculos. Corrija as operações antes de concluir a calibração.',
    titulo_salvar: 'Linhas sem valor',
    mensagem_salvar:
      'Calibração não pode ser finalizada, pois as linhas abaixo estão sem valor. Corrija os valores antes de finalizar a calibração.',
  },
};
