export default {
  titulos: {
    listagem: 'Ordens de Serviço',
    novo: 'Nova Ordem de Serviço',
    editar: 'Editar Ordem de Serviço',
    visualizar: 'Visualizar Ordem de Serviço',
    envios_materiais_pendentes: 'Envios de Materiais registrados pendentes de abertura de OS',
    os_parcial: 'Nova Ordem de Serviço Parcial',
    os_parcial_titulo: 'Ordem de Serviço Parcial',
    os_movimentar_situacao: 'Ordem Serviço - Movimentar Situação',
    impressao: 'Ordem de Serviço'
  },
  cadastro_sucesso: 'Ordem de Serviço salva com sucesso!',
  cadastro_sucesso_parcial: 'Ordem de Serviço Parcial salva com sucesso!',
  exclusao_sucesso: 'Ordem de Serviço excluída com sucesso!',
  tabela: {
    codigo: 'Código',
    data_recebimento: 'Recebimento',
    cliente_participante: 'Contratante',
    cliente_contato: 'Contato',
    situacao: 'Situação',
    setores: 'Setores',
    previsao_entrega: 'Previsão de Entrega',
    prioridade: 'Prioridade',
    participante_solicitante: 'Solicitante',
    local_execucao: 'Local de Execução',
    entrega: 'Entrega',
  },
  formulario: {
    abas: {
      dados_ordem_servico: 'Dados da Ordem de Serviço',
      instrumentos: 'Instrumentos',
      requisitos: 'Requisitos',
      anexos: 'Anexos',
    },
    dados_ordem_servico: {
      cliente: 'Contratante',
      contato: 'Contato',
      solicitante: 'Solicitante',
      data_recebimento: 'Data de Recebimento',
      data_previsao: 'Data da Previsão de Entrega',
      entrega: 'Entrega',
      prioridade: 'Prioridade',
      nota_fiscal: 'Nota Fiscal',
      tipo_embalagem: 'Tipo de Embalagem',
      tipo_envio: 'Tipo de Envio',
      tipo_envio_recebimento: 'Tipo de Envio do Recebimento',
      tipo_envio_entrega: 'Tipo de Envio da Entrega',
      local_execucao: 'Local de Execução',
      situação: 'Situação',
      nome_transportadora: 'Nome da Transportadora'
    },
    instrumento: {
      selecionar_instrumentos: 'Selecionar Instrumentos',
      codigo: 'Código',
      tipo_instrumento: 'Tipo de Instrumento',
      numero_serie: 'Número de Série',
      qtd_faixas: 'Qtd de Faixas',
      novo_instrumento: 'Novo Instrumento',
      novo_cliente: 'Novo Cliente',
      novo_contato: 'Novo Contato',
      instrumento: 'Instrumento',
      visualizar_analise_tecnica: 'Visualizar Análise Técnica',
    },
  },

  filtro: {
    codigo: 'Código',
    data_recebimento: 'Data de Recebimento',
    tipo_envio: 'Tipo de Envio',
    cliente: 'Contratante',
    contato: 'Contato',
    solicitante: 'Solicitante',
    prioridade: 'Prioridade',
    situação: 'Situação',
  },
  erros: {
    id_invalido: 'O id do Ordem de Serviço é inválido',
    instrumento_ja_esta_em_uma_ordem_servico:
      'Instrumento já inserido em uma Ordem de Serviço não encerrada',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
    cadastrar_instrumento: 'É necessário cadastrar um instrumento',
  },
  botoes: {
    os_parcial: 'OS Parcial',
    movimentar_situacao: 'Movimentar para outra Situação'
  },
  os_movimentar_situacao: {
    ordem_servico: 'Ordem de Serviço',
    revert_sucesso: 'Situação da Ordem de Serviço alterada com sucesso!',
    confirmar_movimentar: 'Tem certeza que deseja movimentar a situação da ordem de serviço?',
    tabela:
    {
      codigo: 'Código',
      instrumento: 'Instrumento',
      tipo_instrumento: 'Tipo de Instrumento',
      situacao_atual: 'Situação Atual',
      situacao_nova: 'Nova Situação',
      setor: 'Setor',
      prazo: 'Prazo',
      tipo_aprovacao: 'Tipo Aprovação',
      sem_aprovacao: 'Sem Aprovação',
      sem_prazo: 'Sem Prazo',
    }
  },
  os_parcial: {
    data_entrega: 'Data Previsão de Entrega',
    prioridade: 'Prioridade',
    tabela:
    {
      codigo: 'Código',
      tipo_instrumento: 'Tipo de Instrumento',
      situacao: 'Situação',
      setor: 'Setor',
      prazo: 'Prazo',
    }
  },
  email_os_criada:{
    titulo: 'Email de Criação da Ordem de Serviço',
    de: 'De',
    responder_para: 'Responder para',
    para: 'Para',
    assunto: 'Assunto',
    visualizar_email: 'Visualizar Email',
    atualizar_email: 'Atualizar Email',
    enviar_email: 'Enviar',
    nao_enviar_email: 'Não Enviar',
    email_enviado_sucesso: 'Email enviado com sucesso!',
  }
};
