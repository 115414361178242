import helpers from '@router/helpers';
const menuId = 'requisitos';

export default [
  helpers.criarRota(menuId, 'requisitos', menuId, () =>
    import('@/views/modulos/cadastros/requisitos')
  ),
  helpers.criarRota(menuId, 'requisitos-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/requisitos/formulario')
  ),
  helpers.criarRota(
    menuId,
    'requisitos-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/requisitos/formulario'),
    true
  ),
];
