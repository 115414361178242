export default [
  {
    text: 'Análise Técnica',
    value: 'AnaliseTecnica',
  },
  {
    text: 'Expedição',
    value: 'Expedicao',
  },
];
