import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.boleano.sim'),
    value: true,
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.boleano.nao'),
    value: false,
    cor: 'primary-error-700',
  },
];
