import helpers from '@router/helpers';
const menuId = 'local-execucao';

export default [
  helpers.criarRota(menuId, 'local-execucao', menuId, () =>
    import('@/views/modulos/cadastros/local_execucao')
  ),
  helpers.criarRota(
    menuId,
    'local-execucao-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/local_execucao/formulario'),
    false,
    false
  ),
  helpers.criarRota(
    menuId,
    'local-execucao-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/local_execucao/formulario'),
    true,
    false
  ),
];
