export default {
  titulos: {
    listagem: 'Tipo de Envio',
    novo: 'Novo Tipo de Envio',
    editar: 'Editar Tipo de Envio',
  },
  cadastro_sucesso: 'Tipo de Envio salvo com sucesso!',
  exclusao_sucesso: 'Tipo de Envio excluído com sucesso!',
  filtro: {
    nome: 'Nome',
    visivel_envio_material: 'Visível em Envio de Material',
    visivel_roteirizacao: 'Visível na Roteirização',
  },
  tabela: {
    nome: 'Nome',
    visivel_envio_material: 'Visível em Envio de Material',
    visivel_roteirizacao: 'Visível na Roteirização',
  },
  formulario: {
    nome: 'Nome',
    visivel_envio_material: 'Visível em Envio de Material',
    visivel_roteirizacao: 'Visível na Roteirização',
  },
  erros: {
    id_invalido: 'O id do Tipo de Envio é invalido',
    input_empty: 'Não aceita espaço vazio'
  },
};
