<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    :flat="flat"
    :solo="solo"
    :outlined="outlined"
    :sem-label="semLabel"
    :background-color="backgroundColor"
    @abrir-modal="abrirModal"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="880"
        :titulo="tituloModal"
        :titulo-ok="$t('geral.botoes.confirmar')"
        @ok="aplicarSelecionados"
      >
        <modal
          ref="modal"
          v-model="selecionados"
          :multiplos="multiplos"
          :tipo-instrumento-id="tipoInstrumentoId"
          @fechar-modal-click-linha="aplicarSelecionados"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { InputMixin } from '../../InputMixin';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import Modal from './modal';
import _ from 'lodash';
export default {
  name: 'InputAreaAcreditacao',
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    tituloModal: { type: String, default: '' },
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    tipoInstrumentoId: { type: String, default: null },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: [],
      selecionados: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
    methods: {
      emitirInput: function () {
        this.$emit('input', this.conteudo);
      },
      textoOpcao: function (opcao) {
        if (!opcao) return '';
        return opcao.text || opcao;
      },
      removerOpcao: function (indice) {
        this.conteudo.splice(indice, 1);
      },
      abrirModal: async function () {
        this.$refs['modal-input'].abrirModal();
        if (!this.conteudo) return false;
  
        let conteudoClonado = _.cloneDeep(this.conteudo);
        if (!this.multiplos) conteudoClonado = [conteudoClonado];
  
        this.selecionados = conteudoClonado.map((el) => {
          return {
            id: el.value,
            email: el.text,
          };
        });

        await this.$nextTick();
        this.$refs.modal?.listar();
      },
      aplicarSelecionados: function () {
        this.$refs['modal-input'].fecharModal();
        let selecionados = new DropdownModel(this.selecionados, 'email');
        if (!this.multiplos) {
          selecionados = null;
          if (this.selecionados.length > 0)
            selecionados = new DropdownModel(this.selecionados, 'email')[0];
        }
        this.conteudo = selecionados;
      },
    },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      let selecionados = new DropdownModel(this.selecionados, 'email');
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropdownModel(this.selecionados, 'email')[0];
      }
      this.conteudo = selecionados;
    },
}
</script>
