// TheTipoModeloInstrumentoModal.js

export default {
  theTipoModeloInstrumentoModal: {
    title: 'Tipos e Modelos de Instrumento',
    steps: {
      one: {
        title: 'Tipos de Instrumento',
        table: {
          nome: 'Nome',
          areaAcreditacao: 'Área de Acreditação',
          responsavelSetor: 'Setor Responsável',
          tags: 'Tags',
        },
      },
      two: {
        title: 'Modelos de Instrumento',
        table: {
          nome: 'Nome',
          tipoInstrumento: 'Tipo de Instrumento',
          marca: 'Marca',
        },
      },
    },
    buttons: {
      cancel: 'Cancelar',
      previous: 'Voltar',
      confirm: 'Confirmar',
      next: 'Avançar',
    },
    inputs: {
      nome: {
        text: 'Nome',
        placeholder: 'Nome',
      },
    },
  },
};
