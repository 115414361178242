<template>
  <div>
    <stimulsoft-visualizador ref="visualizador" />
  </div>
</template>
<script>
import { StimulsoftVisualizador } from '@components/misc';
import { RelatorioService } from '@common/services';
export default {
  components: {
    StimulsoftVisualizador,
  },
  data: function () {
    return {
      parametros: [],
      endpoint: {},
      modelo: null,
      idVerInter: null,
    };
  },
  methods: {
    buscarRelatorioVerInter(id, codigo) {
      this.idVerInter = id;
      this.inicializarVisualizadorPorCodigo(codigo);
    },
    inicializarVisualizador: function (relatorioId, parametros = []) {
      this.parametros = parametros;
      this.buscar(relatorioId);
    },
    inicializarVisualizadorPorCodigo: function (
      codigoRelatorio,
      parametros = []
    ) {
      this.parametros = parametros;
      this.buscar(null, codigoRelatorio);
    },
    buscar: function (relatorioId, codigoRelatorio) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RelatorioService.buscar(relatorioId, codigoRelatorio)
        .then((res) => {
          this.endpoint = res.data.endpoint;
          if (!res.data.arquivoRelatorio) {
            this.toastErro(
              this.$t(
                'componentes.gerador_relatorio.arquivo_relatorio_nao_encontrado'
              )
            );
            return;
          }
          this.modelo = res.data.arquivoRelatorio;
          let requisicao = this.montarRequisicao();
          this.buscarDatasource(requisicao);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
          this.$emit('houve-erro');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    montarRequisicao: function () {
      let url = this.endpoint.url + '?';
      let form = {};
      this.parametros.forEach((p) => {
        let parametro = this.endpoint.endpointsParametros.find(
          (el) => el.simbolo == p.simbolo
        );
        if (parametro) {
          switch (parametro.tipoPassagemParametro) {
            case 'Url':
              url = url.replace(`{${p.simbolo}}`, p.valor);
              break;
            case 'Body':
              form[p.simbolo] = p.valor;
              break;
            case 'Querystring':
              url = `${url}&${p.simbolo}=${p.valor}`;
          }
        }
      });
      return {
        url: url,
        form: form,
      };
    },
    buscarDatasource: function (requisicao) {

      if(this.endpoint?.api == 'Sql'){
        this.$refs.visualizador.carregarDataSource(JSON.parse(this.endpoint.jsonSql));
        this.$refs.visualizador.montarComponente(this.modelo);
        return;
      }

      this.$store.dispatch('Layout/iniciarCarregamento');
      RelatorioService.buscarDatasource(
        this.endpoint.api,
        this.endpoint.verboHttp,
        requisicao
      )
        .then((res) => {
          setTimeout(() => {
            this.$refs.visualizador.carregarDataSource(res.data);
            this.$refs.visualizador.montarComponente(this.modelo);
          }, 500);
        })
        .catch(() => {
          this.toastErro(
            this.$t('componentes.gerador_relatorio.erro_ao_buscar_datasource')
          );
          this.$emit('houve-erro');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
