import { BasicoApi } from '@common/api';
const UnidadeMedidaService = {
  listar: function (parametros) {
    return BasicoApi.get()('/unidademedida', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/unidademedida/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/unidademedida/${form.id}`, form);
    return BasicoApi.post()('/unidademedida', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/unidademedida/${id}`);
  },
  listarUnidadeMediaPorIdsGrandezaFisica: function (grandezasFisicasIds, parametros) {
    return BasicoApi.get()(`/unidademedida${grandezasFisicasIds ? `?${grandezasFisicasIds}` : ''
      }`, parametros)
  },
  alterarUnidadeMedidaPadrao: function (id) {
    return BasicoApi.patch()(`/UnidadeMedida/${id}/unidade-medida-padrao`);
  }

};

export default UnidadeMedidaService;
