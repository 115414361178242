import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_desconto_acrescimo.percentual'),
    value: 'Percentual',
  },
  {
    text: i18n.t('enums.tipo_desconto_acrescimo.valor'),
    value: 'Valor',
  },
];
