export default {
  baseContatoParticipanteForm: {
    title:{
      titleNew: 'Novo contato',
      titleEdit: 'Editar contato'
    },
    inputs: {
      nome: {
        text: 'Nome*',
        placeholder: 'Nome do contato'
      },
      setor: {
        text: 'Setor',
        placeholder: 'Setor do contato'
      },
      cargo: {
        text: 'Cargo',
        placeholder: 'Cargo do contato'
      },
      telefone: {
        text: 'Telefone',
        placeholder: 'Telefone contato'
      },
      email: {
        text: 'Email*',
        placeholder: 'Email do contato'
      }
    }
  }
}

