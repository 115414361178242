import helpers from '@router/helpers';

const menuId = 'erro-permissao';

export default [
  helpers.criarRota(menuId, 'erropermissao', menuId, () =>
    import('@/views/system/PermissionErrorPage')
  ),

];
