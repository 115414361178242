export default {
  nome: 'Nome',
  tabela: {
    apelido:'Apelido',
    nome: 'Nome',
    cidade: 'Cidade',
    documento: 'Documento',
  },
  titulo_modal: 'Participantes',
};
