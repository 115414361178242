import { BasicoApi } from '@common/api';
const ClassificacaoFiscalService = {
  listar: function (parametros) {
    return BasicoApi.get()('/classificacaofiscal', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/classificacaofiscal/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/classificacaofiscal/${form.id}`, form);
    return BasicoApi.post()('/classificacaofiscal', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/classificacaofiscal/${id}`);
  },
};

export default ClassificacaoFiscalService;
