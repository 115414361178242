import helpers from '@router/helpers';
const menuId = 'mensagem-orcamento';

export default [
  helpers.criarRota(menuId, 'mensagem-orcamento', menuId, () =>
    import('@/views/modulos/cadastros/mensagem_orcamento')
  ),
  helpers.criarRota(
    menuId,
    'mensagem-orcamento-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/mensagem_orcamento/formulario')
  ),
  helpers.criarRota(
    menuId,
    'mensagem-orcamento-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/mensagem_orcamento/formulario'),
    true
  ),
];
