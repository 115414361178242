import { BasicoApi } from '@common/api';
const ClausulaContratualService = {
  listar: function (parametros) {
    return BasicoApi.get()('/clausulacontratual', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/clausulacontratual/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/clausulacontratual/${form.id}`, form);
    return BasicoApi.post()('/clausulacontratual', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/clausulacontratual/${id}`);
  },
  salvarOrdenacao: function (listaDados) {
    return BasicoApi.patch()('/prioridade/sequencia', {
      ids: listaDados.map((el) => el.id),
    });
  }
};

export default ClausulaContratualService;
