export default {
  titulos: {
    listagem: 'Categorias de Itens',
    novo: 'Nova Categoria de Item',
    editar: 'Editar Categoria de Item',
  },
  cadastro_sucesso: 'Categoria de Item salva com sucesso!',
  exclusao_sucesso: 'Categoria de Item excluída com sucesso!',
  tabela: {
    descricao: 'Descrição',
  },
  formulario: {
    descricao: 'Descrição',
    descricaoCompleta: 'Descrição Completa',
  },
  filtro: {
    descricao: 'Descrição',
  },
  erros: {
    id_invalido: 'O id do categoria de item é inválido',
  },
  validacoes: {
    formulario_invalido: 'Por favor verifique os campos',
  },
};
