export const TipoColunaConst = {
  TEXTO: 'Texto',
  REFERENCIA: 'Referencia',
  SMC: 'SMC',
  SMP: 'SMP',
  RESULTADO: 'Resultado',
  UNIDADE: 'Unidade',
  LOADING: 'Loading',
  TABELAAUXILIAR: 'TabelaAuxiliar',
};
