import { calculoCalibracao } from '@/config/axios';
//ToDo: remover importacao do axios e utilizar a referencia da api
const CalcularCalibracaoService = {
  buscar: function (id) {
    return calculoCalibracao.get()(`/relatorio/${id}/template`, {
      responseType: 'arraybuffer',
    });
  },
  calcularCalibracao: function (procedimentoEletronico) {
    const { id, colunas } = procedimentoEletronico;
    return calculoCalibracao.post()(`/calcularcalibracao/${id}`, { colunas });
  },
  getPdfReport: function (id) {
    return calculoCalibracao.post()(`/relatorio/${id}/report`, {
      responseType: 'blob',
    });
  },
};

export default CalcularCalibracaoService;
