import helpers from '@router/helpers';
const menuId = 'operacao';

export default [
  helpers.criarRota(menuId, 'operacao', menuId, () =>
    import('@/views/modulos/cadastros/operacao')
  ),
  helpers.criarRota(
    menuId,
    'operacao-analise-tecnica-aprovacao',
    `${menuId}/analise-tecnica-aprovacao/:idInstrumento?/:ordemServico?/:id`,
    () => import('@/views/modulos/cadastros/operacao/formularioAnaliseTecnica'),
    true
  ),
  helpers.criarRota(
    menuId,
    'operacao-analise-tecnica-aprovacao-garantia',
    `${menuId}/analise-tecnica-aprovacao-garantia/:id`,
    () =>
      import('@/views/modulos/cadastros/operacao/components/AprovarGarantia'),
    true
  ),
  helpers.criarRota(
    menuId,
    'operacao-analise-tecnica',
    `${menuId}/analise-tecnica/:idInstrumento?/:ordemServico?/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/operacao/components/WizardAnaliseTecnica'
      ),
    true
  ),
  helpers.criarRota(
    menuId,
    'operacao-analise-tecnica-em-lote',
    `${menuId}/analise-tecnica-em-lote/:ordemServico?`,
    () =>
      import(
        '@/views/modulos/cadastros/operacao/components/WizardAnaliseTecnicaEmLote'
      ),
    true
  ),
  helpers.criarRota(
    menuId,
    'operacao-manutencao-pecas-executadas',
    `${menuId}/manutencao-pecas-executadas/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/operacao/components/ManutencaoPecasExecutadas'
      ),
    true
  ),
  helpers.criarRota(
    menuId,
    'operacao-plano-inspecao',
    `${menuId}/plano-inspecao/:id?`,
    () => import('@/views/modulos/cadastros/operacao/components/PlanoInspecao'),
    true
  ),
  helpers.criarRota(
    menuId,
    'operacao-entrega',
    `${menuId}/entrega/:id?`,
    () => import('@/views/modulos/cadastros/operacao/entrega'),
    true
  ),
];
