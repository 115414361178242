import helpers from '@router/helpers';
const menuId = '';
const route = 'roteiro-executar-envio-material'
export default [
  helpers.criarRota(menuId, 'roteiro-executar-envio-material', route,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material')
  ),
  
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-checkin',
    `${route}/check-in`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/checkIn'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-entrega-recebimento',
    `${route}/entrega-recebimento`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/EntregaRecebimento'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-recebimento',
    `${route}/recebimento`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/EntregaRecebimento'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-sem-roteiro',
    `${route}/sem-roteiro`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/FinalizadoOuSemRoteiro'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-finalizado',
    `${route}/finalizado`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/FinalizadoOuSemRoteiro'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-finalizar',
    `${route}/finalizar/:roteiroId?`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-recebimento-sem-agendamento',
    `${route}/recebimento-sem-agendamento`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/RecebimentoSemAgendamento'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-checkout-por-reagendamento',
    `${route}/checkout-por-reagendamento`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/ReagendamentoOuCancelamento'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-checkout-por-cancelamento',
    `${route}/checkout-por-cancelamento`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/ReagendamentoOuCancelamento'),
    true
  ),
  helpers.criarRota(
    menuId,
    'roteiro-executar-envio-material-visualizar',
    `${route}/visualizar`,
    () => import('@/views/modulos/cadastros/roteiro_executar_envio_material/Visualizar'),
    true
  ),

];
