export default {
  titulos: {
    listagem: 'Agendamentos',
    novo: 'Novo Agendamento',
    editar: 'Editar Agendamento',
    visualizar: 'Visualizar Agendamento',
    programar: 'Programar Agendamento',
    confirmar: 'Confirmar Agendamento',
    executar: 'Executar Agendamento',
    registar_eventos: 'Registrar Eventos',
    analise_critica: 'Análise Crítica',
    iniciar_viagem: 'Iniciar Viagem',
    finalizar_viagem: 'Finalizar Viagem',
  },
  cadastro_sucesso: 'Agendamento salvo com sucesso',
  exclusao_sucesso: 'Agendamento excluído com sucesso',
  cancelamento_sucesso: 'Agendamento cancelado com sucesso',
  programado_sucesso: 'Agendamento programado com sucesso',
  confirmado_sucesso: 'Agendamento confirmado com sucesso',
  aprovado_sucesso: 'Aprovado com Sucesso',
  execucao_viagem_sucesso: 'Viagem executada com sucesso',
  execucao_fora_de_horario: 'Execução fora do horário',
  execucao_fora_de_horario_texto: 'O agendamento está programado para outro dia/horário, deseja continuar mesmo assim?',

  alterado_instrumentos_sucesso: 'Instrumentos alterados com sucesso',
  check_in_sucesso: 'Check-in realizado com sucesso',
  check_out_sucesso: 'Check-out realizado com sucesso',
  atendimento_iniciado_sucesso: 'Atendimento iniciado com sucesso',
  atendimento_finalizado_sucesso: 'Atendimento finalizado com sucesso',
  iniciar_atendimento_sucesso: 'Atendimento iniciado com sucesso',
  analise_critica_sucesso: 'Análise crítica realizada com sucesso',
  finalizar_atendimento_sucesso: 'Atendimento finalizado com sucesso',


  botoes: {
    salvar_instrumentos: 'Salvar Instrumentos',
    registar_eventos: 'Registrar Eventos',
    analise_critica: 'Análise Crítica',
  },
  tabela: {
    codigo: 'Código',
    data_inical: 'Data Inicial',
    data_final: 'Data Final',
    horario_inicial: 'Horário Inicial',
    horario_final: 'Horário Final',
    tipo_visita: 'Tipo de Visita',
    solicitante: 'Solicitante',
    cidade: 'Cidade',
    contratante: 'Contratante',
    contato_contratante: 'Contato do Contratante',
  },
  formulario: {
    abas: {
      dados: 'Dados do Agendamento',
      instrumentos: 'Instrumentos',
      encontrar_horario: 'Encontrar Horário',
      operacoes: 'Operações',
      resumo: 'Resumo',
      analise_critica: 'Análise Crítica',
    },
    dados: {
      contratante: 'Contratante',
      contato_contratante: 'Contato do Contratante',
      contato: 'Contato',
      solicitante: 'Solicitante',
      tipo_visita: 'Tipo de Visita',
      prioridade: 'Prioridade',
      data_inicial: 'Data Inicial',
      data_final: 'Data Final',
      horario_inicial: 'Horário Inicial',
      horario_final: 'Horário Final',
      sugestao_1: 'Sugestão 1',
      sugestao_2: 'Sugestão 2',
      sugestao_3: 'Sugestão 3',
      colaboradores: 'Colaboradores',
      instrumento_padrao: 'Instrumento Padrão',
      veiculo: 'Veículo',
      observacao: 'Observação',
      check_in: 'Check-in',
      atendimento: 'Atendimento',
      check_out: 'Check-out',
      dataHoraInicial: 'Data e Hora Inicial',
      dataHoraFinal: 'Data e Hora Final',
      mensagens: {
        contratante_participante_conta: 'O agendamento será apenas para bloqueio de agenda de Colaboradores, Instrumentos Padrões e/ou Veículos',
        insira_instrumentos: 'É necessário inserir ao menos um instrumento',
      },
      ordemServico: 'Ordem de Serviço',
      saida_kel: 'Saída K&L',
      chegada_kel: 'Chegada K&L',
      saida_cliente: 'Saída Cliente',
      chegada_cliente: 'Chegada Cliente',
      justificativa: 'Justificativa',

    }

  },

  historico: {
    Criado: 'Criado',
    Programado: 'Programado',
    Alterado: 'Alterado',
    Checkin: 'Check-in',
    Cancelado: 'Cancelado',
    Reagendado: 'Reagendado',
    Concluido: 'Concluído',
    SemAgendamento: 'Sem Agendamento',
    OrdemServicoCriada: 'Ordem de Serviço Criada',
    CheckOut: 'Check-out',
    AtendimentoIniciado: 'Atendimento Iniciado',
    AtendimentoFinalizado: 'Atendimento Finalizado',

    texto: {
      agendamento: 'Agendamento',
      por: 'por',
      participante: 'participante',
      no_cliente: 'no cliente',
    }
  },

  alertas: {
    cancelar_agendamento: 'Tem certeza que deseja cancelar o agendamento?',
    alerta_feriado: 'Alguma das datas sugeridas coincide com um final de semana ou feriado, o que implicará um aumento no valor do orçamento.Deseja continuar?',
    executar_agendamento_fora_periodo: 'O agendamento está programado para outro dia/horário, deseja continuar mesmo assim?',
    confirmar_aprovacao: 'Aprovar',
    confirmar_aprovacao_mensagem: 'Confirmar a aprovação?',
    confirmar_inicio_atendimento: 'Iniciar Atendimento',
    confirmar_inicio_atendimento_mensagem: 'Check in e Atendimento serão iniciados, confirmar? ',
    confirmar_analise_critica: 'Análise Crítica',
    confirmar_analise_critica_mensagem: 'Confirmar a Análise Crítica?',
    confirmar_checkout: 'Check-out',
    confirmar_checkout_mensagem: 'Confirma o check out para continuar o agendamento em outro dia?',
  },
  resumo: {
    titulo: {
      previsto_executado: 'Instrumentos previstos executados',
      nao_previsto_executado: 'Instrumentos não previstos executados',
      previsto_nao_realizado: 'Instrumentos previstos não realizados',
      horas_paradas: 'Horas paradas',
      assinatura_cliente: 'Assinatura do Cliente',
    },
    tabela: {
      previsto_executado: {
        data: 'Data',
        instrumento: 'Instrumento',
        tipo_instrumento: 'Tipo de Instrumento',
        manutencaoPecaCalibracao: 'Manutenção/Peça',
        codigo: 'Código',
        nome: 'Nome',
        quantidade: 'Quantidade',
      },
      nao_previsto_executado: {
        data: 'Data',
        instrumento: 'Instrumento',
        tipo_instrumento: 'Tipo de Instrumento',
        manutencaoPecaCalibracao: 'Manutenção/Peça',
        codigo: 'Código',
        nome: 'Nome',
        quantidade: 'Quantidade',
      },
      previsto_nao_realizado: {
        instrumento: 'Instrumento',
        tipo_instrumento: 'Tipo de Instrumento',
        manutencaoPecaCalibracao: 'Manutenção/Peça',
        codigo: 'Código',
        nome: 'Nome',
        quantidade: 'Quantidade',
        justificativa: 'Justificativa',
      },
      analise_critica: {
        instrumento: 'Instrumento',
        tipo_instrumento: 'Tipo de Instrumento',
        manutencaoPecaCalibracao: 'Manutenção/Peça',
        codigo: 'Código',
        nome: 'Nome',
        quantidade: 'Quantidade',
        justificativa: 'Justificativa',
        cancelaReagenda: 'Cancelar/Reagendar',
        cancelar: 'Cancelar',
        reagendar: 'Reagendar',
      },
      horas_paradas: {
        data: 'Data',
        numero_horas: 'Número de Horas',
        hora_inicial: 'Hora Inicial',
        hora_final: 'Hora Final',
      }

    },
    cliente: 'Cliente',
    data_hora_assinatura: 'Data e Hora da Assinatura',
  }
};
