import helpers from '@router/helpers';
const menuId = 'participantes';

export default [
  helpers.criarRota(menuId, 'participantes', menuId, () =>
    import('@/views/modulos/cadastros/participantes')
  ),
  helpers.criarRota(menuId, 'participantes-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/participantes/formulario')
  ),
  helpers.criarRota(
    menuId,
    'participantes-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/participantes/formulario'),
    true
  ),
];
