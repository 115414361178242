export default {
  roteiro: null,
  clientes: null,
  roteiroId:null,
  situacao:null,
  totalClientes:null,
  dataAgendamento: null,
  clienteDaVez: null,
  clienteDaVezIndex: null,
  envioMaterialDaVez: null,
  ultimoClienteCheckout: null,
};
