import { BasicoApi } from '@common/api';
const ModeloInstrumentoService = {
  listar: function (parametros, tiposInstrumentosIds) {
    return BasicoApi.get()(
      `/modeloinstrumento${
        tiposInstrumentosIds ? `?${tiposInstrumentosIds}` : ''
      }`,
      parametros
    );
  },
  buscar: function (id) {
    return BasicoApi.get()(`/modeloinstrumento/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/modeloinstrumento/${form.id}`, form);
    return BasicoApi.post()('/modeloinstrumento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/modeloinstrumento/${id}`);
  },
};

export default ModeloInstrumentoService;
