import helpers from '@router/helpers';
const menuId = 'tipo-documento';

export default [
  helpers.criarRota(menuId, 'tipo-documento', menuId, () =>
    import('@/views/modulos/cadastros/tipo_documento')
  ),
  helpers.criarRota(menuId, 'tipo-documento-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/tipo_documento/formulario')
  ),
  helpers.criarRota(
    menuId,
    'tipo-documento-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_documento/formulario'),
    true
  ),
];
