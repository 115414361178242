import { CalculosApi } from '@common/api';
const FormulaService = {
  listar: function (parametros) {
    return CalculosApi.get()('/formula', parametros);
  },
  buscar: function (id) {
    return CalculosApi.get()(`/formula/${id}`);
  },
  salvar: function (form) {
    if (form.id) return CalculosApi.put()(`/formula/${form.id}`, form);
    return CalculosApi.post()('/formula', form);
  },
  excluir: function (id) {
    return CalculosApi.delete()(`/formula/${id}`);
  },
  validar: function (parametros) {
    return CalculosApi.post()('/formula/validar', parametros);
  },
  duplicar: function (id, novoSimbolo) {
    if (!novoSimbolo) return CalculosApi.post()(`/formula/${id}/duplicar`, { novoSimbolo: null })
    return CalculosApi.post()(`/formula/${id}/duplicar`, novoSimbolo);
  },
  variaveis: function (id) {
    return CalculosApi.get()(`/formula/${id}/variaveis`);
  },
  buscarVariaveisCalculo: function (id) {
    return CalculosApi.get()(`/formula/${id}/variaveis-calculo`);
  },
};

export default FormulaService;
