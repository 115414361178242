export default {
  titulos: {
    listagem: 'Embalagens',
    novo: 'Nova Embalagem',
    editar: 'Editar Embalagem',
  },
  cadastro_sucesso: 'Embalagem salva com sucesso!',
  exclusao_sucesso: 'Embalagem excluída com sucesso!',
  tabela: {
    nome: 'Nome',
  },
  formulario: {
    nome: 'Nome',
  },
  filtro: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id da Embalagem é inválido',
  },
  validacoes: {
    formulario_invalido: 'Verifique os campos do formulário',
  },
};
