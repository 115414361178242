export class DropdownModelDoisParametros {
  constructor(lista, texto = 'nome', textoSecundario = '') {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.id;
        novoObj.text = el[texto];
        if (textoSecundario) novoObj[textoSecundario] = el[textoSecundario]
        return novoObj;
      });
    let novoObj = {
      value: lista.id,
      text: lista[texto],
    };
    if (textoSecundario) novoObj[textoSecundario] = lista[textoSecundario]

    return novoObj
  }
}
