export default {
  titulos: {
    listagem: 'Ocorrência Padrão',
    novo: 'Nova Ocorrência',
    editar: 'Editar Ocorrência',
  },
  cadastro_sucesso: 'Ocorrência salva com sucesso!',
  criterio_aprovacao: 'Critério de Aprovação',
  novo_criterio_aprovacao: 'Novo Critério de Aprovação',
  exclusao_sucesso: 'Ocorrência excluida com sucesso!',
  filtro: {
    nome: 'Nome',
    tipo_ocorrência: 'Cliente/Fornecedor',
    telefone: 'Telefone',
    email: 'Email',
    documento: 'Documento',
  },
  tabela: {
    nome: 'Nome',
    tipo_ocorrência: 'Cliente/Fornecedor',
    telefone: 'Telefone',
    email: 'Email',
    documento: 'Documento',
  },
  formulario: {
    abas: {
      dados_ocorrência_padrao: 'Dados da Ocorrência Padrão',
      setores: 'Setores',
    },
    ocorrencia: 'Ocorrência',
    acao_corretiva: 'Ação Corretiva',
    dias_coleta: 'Dias de Coleta',
    nome: 'Nome',
    apelido: 'Apelido',
    tipo_pessoa: 'Tipo',
    setores: {
      titulo_modal_novo: 'Setores',
      titulo_modal_editar: 'Setores',
      botoes: {
        novo: 'Selecionar Setores',
      },
      nome: 'Nome',
      tipo: 'Tipo',
      posicao: 'Posição',
      sigla: 'Sigla',
    },
  },
  erros: {
    id_invalido: 'O id do Ocorrência é invalido',
    setores_preencher: 'Cadastre um Setor',
  },
  validacoes: {
    formulario_invalido: 'Por favor, verifique os campos!',
  },
};
