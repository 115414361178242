<template>
  <div>
    <input-modal
      v-model="conteudo"
      :label="label"
      :placeholder="placeholder"
      :dense="dense"
      :regras="regras"
      :disabled="disabled"
      :multiplos="multiplos"
      :retornar-objeto="retornarObjeto"
      :obrigatorio="obrigatorio"
      @abrir-modal="abrirModal"
      @input="emitirInput"
    >
      <template #modal>
        <modal-padrao
          ref="modal-input"
          :max-width="880"
          :titulo="$t('componentes.input_campos_adicionais.titulo_modal')"
          :titulo-ok="$t('geral.botoes.confirmar')"
          @ok="aplicarSelecionados"
        >
          <p class="subtitle-2">
            {{ $t("componentes.input_campos_adicionais.informacoes") }}
          </p>

          <p class="subtitle-2">
            <strong>{{
              $t("componentes.input_campos_adicionais.importante.importante")
            }}</strong>
            {{ $t("componentes.input_campos_adicionais.importante.informacao") }}
          </p>
          <modal
            ref="modal"
            v-model="conteudoClonado"
            :multiplos="multiplos"
          />
        </modal-padrao>
      </template>
    </input-modal>
  </div>
</template>
<script>
import helpers from "@common/utils/helpers";
import { InputMixin } from "../../InputMixin";
import _ from "lodash";
import Modal from "./modal.vue";

export default {
  name: "InputCamposAdicionais",
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    tipoInstrumentos: {},

    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: [],
      conteudoClonado: [],
      selecionados: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit("input", this.conteudo);
    },
    textoOpcao: function (opcao) {
      if (!opcao) return "";
      return opcao.text || opcao;
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function () {
      this.conteudoClonado = _.cloneDeep(this.conteudo);
      this.$refs["modal-input"].abrirModal();
      setTimeout(() => {
        this.$refs.modal.listar(this.tipoInstrumentos);
      }, 200);
    },
    aplicarSelecionados: function () {
      this.$refs["modal-input"].fecharModal();
      this.conteudo = this.conteudoClonado
        .filter((el) => el.valorInput || el.valorInput === false)
        .map((el) => {
          if (el.tipoCampo == "Data")
            el.valorInput = helpers.formatarDataBr(this.valorPadrao);
          return el;
        });
    },
  },
};
</script>
