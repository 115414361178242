export default {
  roteiro: (state) => state.roteiro,
  clientes: (state) => state.clientes,
  roteiroId: (state) => state.roteiroId,
  situacao: (state) => state.situacao,
  totalClientes: (state) => state.totalClientes,
  dataAgendamento: (state) => state.dataAgendamento,
  clienteDaVez: (state) => state.clienteDaVez,
  clienteDaVezIndex: (state) => state.clienteDaVezIndex,
  envioMaterialDaVez: (state) => state.envioMaterialDaVez,
  ultimoClienteCheckout: (state) => state.ultimoClienteCheckout,
};
