import { AuthApi } from '@common/api';

const UsuarioService = {
  listar: function (parametros) {
    return AuthApi.get()('/usuarios', parametros);
  },
  buscar: function (id) {
    return AuthApi.get()(`/usuarios/${id}`);
  },
  salvar: function (form) {
    if (form.id) return AuthApi.put()(`/usuarios/${form.id}`, form.request);
    return AuthApi.post()('/usuarios', form.request);
  },
  excluir: function (id) {
    return AuthApi.delete()(`/usuarios/${id}`);
  },
  ativarInativar: function (id,ativo) {
    if(!ativo)
      return AuthApi.put()(`/usuarios/${id}/ativar`);
    return AuthApi.put()(`/usuarios/${id}/inativar`);
  },
  listarParticipantes: function () {
    return AuthApi.get()(`/usuarioLogado/participantes`);
  },
  alterarParticipante: function (form) {
    return AuthApi.post()(`/participanteLogado`, form);
  },
  alterarSenha: function (form, usuarioId) {
    return AuthApi.put()(`/usuarios/${usuarioId}/senha`, form);
  },
  alterarEnderecoIp: function(id, ipUsuario) {
    if(!ipUsuario) return;
    return AuthApi.put()(`/Usuarios/${id}/endereco-ip`, ipUsuario);
  }
};

export default UsuarioService;
