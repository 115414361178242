import { BasicoApi } from '@common/api';
import { EnumModel } from '@common/models/geral/EnumModel'
const EnumeradorService = {
  buscar: function (enumName) {
   return new Promise((resolve, reject) => {
     BasicoApi.get()(`/enumerador/${enumName}`).then(res => {
        resolve(new EnumModel(res.data, enumName))
    }).catch(() => {
      reject([])
    })

   })
  },
};

export default EnumeradorService;
