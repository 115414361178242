import { BasicoApi } from '@common/api';
const ContratoComentarioService = {
  listar: function (parametros) {
    return BasicoApi.get()('/contratocomentario', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/contratocomentario/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/contratocomentario/${form.id}`, form);
    return BasicoApi.post()('/contratocomentario', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/contratocomentario/${id}`);
  },
};

export default ContratoComentarioService;
