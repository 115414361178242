const validacaoMinimo = function (conteudo, minimo) {
  return conteudo?.toString().length >= minimo;
};
const validacaoMaximo = function (conteudo, maximo) {
  return conteudo?.toString().length <= maximo;
};

const regraValorAmplitude = function (conteudo) {
  if (!conteudo) return true;
  let intValue = conteudo.split('.')[0];
  let decimalValue = conteudo.split('.')[1];
  if (intValue.length > 10) {
    return this.$t('inputs.rules.maxBetweenDecimalValue', {
      char: 10,
      decimal: 9,
    });
  }

  if (decimalValue && decimalValue.length > 9) {
    return this.$t('inputs.rules.maxBetweenDecimalValue', {
      decimal: 9,
      char: 10,
    });
  }

  return true;
};

export default {
  validacaoMinimo,
  validacaoMaximo,
  regraValorAmplitude,
};
