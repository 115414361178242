import Vue from 'vue';
import App from './App.vue';
import '@/common/api/registerServiceWorker.js';
import router from './router';
import store from '@/store';

// Plugins
import i18n from '@/i18n/index';
import '@plugins/vue-meta';
import '@plugins/vue-the-mask';
import '@plugins/v-currency-field';
import '@plugins/v-mask';
import helpers from './common/utils/helpers';

import vuetify from '@/plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';

// Adding and Importing SCSS with Vuetify
import './scss/main.scss';

import VuetifyMoney from 'vuetify-money';
Vue.use(VuetifyMoney);

/** Mixins padrões */
import { AlertasMixin, MascarasMixin } from '@/common/mixins';
import { myMixins } from '@/common/mixins/myMixins';
Vue.mixin(AlertasMixin);
Vue.mixin(MascarasMixin);
Vue.mixin(myMixins); //todo remover

import PrimeVue from '@/plugins/prime-vue';
Vue.use(PrimeVue);
Vue.filter('formatarNumeroComPrecisaoPodeVazio', function (value) {
  return helpers.formatarNumeroComPrecisao(value, true);
});
Vue.filter('formatarNumeroComPrecisao', function (value) {
  return helpers.formatarNumeroComPrecisao(value);
});

/** Componentes padrões */
import {
  InputText,
  InputSelect,
  InputTextarea,
  InputDate,
  InputTime,
  InputRadio,
  InputCheckbox,
  InputModal,
  InputCombobox,
  InputMoney,
  InputHtmlEditor,
  InputComboBoxText,
  InputComboBoxTextOpcoes,
  InputFileDragNDrop,
  InputIde,
} from '@/components/inputs';
Vue.component('input-text', InputText);
Vue.component('input-select', InputSelect);
Vue.component('input-textarea', InputTextarea);
Vue.component('input-date', InputDate);
Vue.component('input-time', InputTime);
Vue.component('input-radio', InputRadio);
Vue.component('input-checkbox', InputCheckbox);
Vue.component('input-modal', InputModal);
Vue.component('input-combobox', InputCombobox);
Vue.component('input-money', InputMoney);
Vue.component('input-html-editor', InputHtmlEditor);
Vue.component('input-combobox-text', InputComboBoxText);
Vue.component('input-combobox-text-opcoes', InputComboBoxTextOpcoes);
Vue.component('input-file-drag-n-drop', InputFileDragNDrop);
Vue.component('input-ide', InputIde);

import {
  BotaoPadrao,
  DropdownPadrao,
  DropdownPadraoItem,
  DropdownPadraoConteudo,
} from '@/components/buttons';
Vue.component('botao-padrao', BotaoPadrao);
Vue.component('dropdown-padrao', DropdownPadrao);
Vue.component('dropdown-padrao-item', DropdownPadraoItem);
Vue.component('dropdown-padrao-conteudo', DropdownPadraoConteudo);

import { ModalPadrao } from '@/components/dialogs';
Vue.component('modal-padrao', ModalPadrao);

import { TabelaPadrao, TabelaPadraoPrimeVue } from '@/components/tabelas';
Vue.component('tabela-padrao', TabelaPadrao);
Vue.component('tabela-padrao-prime-vue', TabelaPadraoPrimeVue);

import { AccordionPadrao, AccordionTabela } from '@/components/cards';
Vue.component('accordion-padrao', AccordionPadrao);
Vue.component('accordion-tabela', AccordionTabela);

import { IconePadrao } from '@components/misc';
Vue.component('icone-padrao', IconePadrao);

Vue.config.productionTip = false;

import ColorPicker from 'primevue/colorpicker';
Vue.component('color-picker', ColorPicker);

import vueKanban from 'vue-kanban'


import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
Vue.use(VueGoodTablePlugin);
Vue.use(vueKanban);

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

Window.App = app;

if (module.hot) {
  module.hot.accept();
  window.addEventListener('message', () => {
    if ('production' == process.env.NODE_ENV) console.clear();
  });
}