export default {
  baseParticipanteContatosTable: {
    html: {
      title: 'Listando contato'
    },
    table: {
      tableName: 'Contatos',
      headers: {
        nome: 'Nome',
        setor: 'Setor',
        cargo: 'Cargo',
        telefone: 'Telefone',
        email: 'Email'
      },
      newItemButtonText: 'Novo Contato',
      slots: {
          loading: 'Carregando...',
          noData: 'Nenhum registro disponível'
      }
    }
  }
}
