import acreditacao from './acreditacao';
import areaAcreditacao from './areaAcreditacao';
import calibracao from './calibracao';
import calibracaoInterna from './calibracaoInterna'
import campoAdicional from './campoAdicional';
import cargoFuncao from './cargoFuncao';
import categoriaItem from './categoriaItem';
import categoriaRelatorio from './categoriaRelatorio';
import certificado from './certificado';
import certificadoTerceiro from './certificadoTerceiro';
import checklist from './checklist';
import classificacaoFiscal from './classificacaoFiscal';
import classificacaoInstrumento from './classificacaoInstrumento';
import clausulaContratual from './clausulaContratual';
import colaborador from './colaborador';
import condicaoPagamento from './condicaoPagamento';
import contrato from './contrato';
import conversorUnidade from './conversorUnidade';
import criterio_aceitacao from './criterio_aceitacao';
import definicaoEquipamentoMedicao from './definicaoEquipamentoMedicao';
import duvidaSolicitacaoParticipante from './duvidaSolicitacaoParticipante';
import embalagem from './embalagem';
import envioMaterial from './envioMaterial';
import erroPermissao from './erroPermissao';
import escopoServicos from './escopoServicos';
import feriado from './feriado';
import formula from './formula';
import grandezaFisica from './grandezaFisica';
import incertezas from './incertezas';
import instrumento from './instrumento';
import item from './item';
import kanban from './kanban';
import kanbanConfiguracao from './kanbanConfiguracao';
import localEstocagem from './localEstocagem';
import localExecucao from './localExecucao';
import marca from './marca';
import mensagemOrcamento from './mensagemOrcamento';
import modeloInstrumento from './modeloInstrumento';
import modeloRelatorio from './modeloRelatorio';
import motivoCancelamentoRoteiro from './motivoCancelamentoRoteiro';
import ocorrenciaPadrao from './ocorrenciaPadrao';
import operacao from './operacao';
import orcamentos from './orcamentos';
import ordemServico from './ordemServico';
import papel from './papel';
import parametros from './parametros';
import participantes from './participantes';
import prioridade from './prioridade';
import procedimentos from './procedimentos';
import procedimentosEletronicos from './procedimentos_eletronicos';
import registroInformativo from './registroInformativo';
import registroManutencaoTrocaPecas from './registroManutencaoTrocaPecas';
import registroOcorrencia from './registroOcorrencia';
import roteiroEnvioMaterial from './roteiroEnvioMaterial';
import roteiroExecutarEnvioMaterial from './roteiroExecutarEnvioMaterial'
import servicoCalibracao from './servicoCalibracao';
import servicoManutencao from './servicoManutencao';
import setor from './setor';
import solicitacaoCadastro from './solicitacaoCadastro';
import templateEmail from './templateEmail';
import tipoCalibracao from './tipoCalibracao';
import tipoComentario from './tipoComentario';
import tipoDocumento from './tipoDocumento';
import tipoEnvio from './tipoEnvio';
import tipoInstrumento from './tipoInstrumento';
import tipoItem from './tipoItem';
import tipoOcorrencia from './tipoOcorrencia';
import tipoSetores from './tipoSetores';
import tipoVisita from './tipoVisita';
import unidadeMedida from './unidadeMedida';
import usuario from './usuario';
import veiculo from './veiculo';
import verificacaoIntermediaria from './verificacaoIntermediaria';
import agendamento from './agendamento';
import programacao from './kanban_programacao';
import kanbanTipoInstrumento from './kanbanTipoInstrumento';
import kanbanEmExecucao from './kanbanEmExecucao';
import endpoints from './endpoints';
import requisitos from './requisitos';

export default [
  ...parametros,
  ...registroInformativo,
  ...registroOcorrencia,
  ...registroManutencaoTrocaPecas,
  ...definicaoEquipamentoMedicao,
  ...acreditacao,
  ...areaAcreditacao,
  ...calibracao,
  ...incertezas,
  ...categoriaItem,
  ...classificacaoFiscal,
  ...classificacaoInstrumento,
  ...embalagem,
  ...feriado,
  ...grandezaFisica,
  ...instrumento,
  ...item,
  ...localEstocagem,
  ...localExecucao,
  ...modeloInstrumento,
  ...participantes,
  ...orcamentos,
  ...prioridade,
  ...procedimentos,
  ...procedimentosEletronicos,
  ...setor,
  ...solicitacaoCadastro,
  ...tipoCalibracao,
  ...tipoEnvio,
  ...tipoInstrumento,
  ...tipoItem,
  ...tipoOcorrencia,
  ...tipoSetores,
  ...unidadeMedida,
  ...usuario,
  ...veiculo,
  ...operacao,
  ...servicoManutencao,
  ...servicoCalibracao,
  ...condicaoPagamento,
  ...mensagemOrcamento,
  ...marca,
  ...contrato,
  ...formula,
  ...tipoComentario,
  ...checklist,
  ...clausulaContratual,
  ...certificado,
  ...campoAdicional,
  ...categoriaRelatorio,
  ...modeloRelatorio,
  ...conversorUnidade,
  ...colaborador,
  ...cargoFuncao,
  ...tipoDocumento,
  ...criterio_aceitacao,
  ...ordemServico,
  ...escopoServicos,
  ...ocorrenciaPadrao,
  ...duvidaSolicitacaoParticipante,
  ...verificacaoIntermediaria,
  ...papel,
  ...envioMaterial,
  ...certificadoTerceiro,
  ...motivoCancelamentoRoteiro,
  ...roteiroEnvioMaterial,
  ...roteiroExecutarEnvioMaterial,
  ...calibracaoInterna,
  ...templateEmail,
  ...kanban,
  ...kanbanConfiguracao,
  ...agendamento,
  ...programacao,
  ...kanbanTipoInstrumento,
  ...kanbanEmExecucao,
  ...erroPermissao,
  ...tipoVisita,
  ...endpoints,
  ...requisitos
];
