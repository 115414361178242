import { CalculosApi } from '@common/api';
const CertificadoTerceiroService = {
  listar: function (parametros) {
    return CalculosApi.get()('/certificadoterceiro', parametros);
  },

  buscarCertificado: function (id) {
    return CalculosApi.get()(`/certificadoterceiro/${id}`);
  },

  salvar: function (form) {
    if (form.id)
      return CalculosApi.put()(
        `/certificadoterceiro/${form.id}`,
        form.dadosCertificado
      );
    return CalculosApi.post()('/certificadoterceiro', form.dadosCertificado);
  },
  atualizarLinhas: function (form) {
    return CalculosApi.put()(
      `/certificadoterceiro/${form.id}/linhas`,
      form.atualizarLinhas
    );
  },
  finalizar: function (id) {
    return CalculosApi.patch()(`/certificadoterceiro/${id}/finalizar`);
  },

  excluir: function (ids) {
    return CalculosApi.delete()(`/certificadoterceiro`, ids);
  },
  salvarAnexo: function (id, form) {
    return CalculosApi.post()(`/certificadoterceiro/${id}/anexos`, form);
  },
  excluirAnexos: function (id, ids) {
    return CalculosApi.delete()(`/certificadoterceiro/${id}/anexos`, ids);
  },
  listarAnexos: function (id, parametros) {
    return CalculosApi.get()(`/certificadoterceiro/${id}/anexos`, parametros);
  },
  voltarAndamento: function(id){
    return CalculosApi.patch()(`/certificado/${id}/voltar-em-andamento`);
  }
};

export default CertificadoTerceiroService;
