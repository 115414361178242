export default {
  titulos: {
    listagem: 'Usuários',
    novo: 'Novo Usuário',
    editar: 'Editar Usuário',
  },
  senha_alterada_sucesso: 'Senha alterada com sucesso!',
  cadastro_sucesso: 'Usuário salvo com sucesso!',
  cadastro_ip_sucesso:'Ip registrado com sucesso!',
  filtro: {
    nome: 'Nome',
    login: 'Login',
    email: 'Email',
    setor: 'Setor',
    papel: 'Papel',
    participante_conta: 'Participante Conta',
  },
  tabela: {
    nome: 'Nome',
    login: 'Login',
    email: 'Email',
    setor: 'Setor',
    papel: 'Papel',
    situacao: 'Situação',
    colaborador: 'Colaborador',
    contato: 'Contato',
    participantes: 'Participantes',
  },
  formulario: {
    nome: 'Nome',
    perfil: 'Perfil',
    papel: 'Papel',
    setor: 'Setor',
    participante: 'Participante*',
    login: 'Login',
    email: 'Email',
    confirmar_email: 'Confirmar Email',
    senha: 'Senha',
    confirmar_senha: 'Confirmar Senha',
    participantes_setor: 'Participantes e Setores',
    adicionar_linha: 'Adicionar Linha',
    associado_colaborador: 'Associado ao Colaborador',
    associado_contato: 'Associado ao Contato',
    assinatura: 'Assinatura',
  },
  erros: {
    id_invalido: 'O id do usuário é invalido',
  },
  tooltip_participantes:
    'São todos os participantes que o usuário pode acessar.',
  validacoes: {
    email: 'Email e Confirmar Email devem ser iguais',
    senha: 'Senha e Confirmar Senha devem ser iguais',
  },
  botoes: {
    alterar_senha: 'Alterar Senha',
  },
  inativado_sucesso: 'Usuário inativado com sucesso',
  ativado_sucesso: 'Usuário ativado com sucesso',
};
