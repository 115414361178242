import { CalculosApi } from '@common/api';
const VerificacaoIntermediariaService = {
  listar: function (parametros) {
    return CalculosApi.get()('/VerificacaoIntermediaria', parametros);
  },
  buscar: function (id) {
    return CalculosApi.get()(`/VerificacaoIntermediaria/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return CalculosApi.put()(
        `/VerificacaoIntermediaria/${form.id}`,
        form.request
      );
    return CalculosApi.post()('/VerificacaoIntermediaria', form.request);
  },
  excluir: function (ids) {
    return CalculosApi.delete()(`/VerificacaoIntermediaria`, ids);
  },
  finalizar: function (id) {
    return CalculosApi.patch()(`/VerificacaoIntermediaria/${id}/finalizar`);
  },
  aprovar: function (id) {
    return CalculosApi.patch()(`/VerificacaoIntermediaria/${id}/aprovar`);
  },
  reprovar: function (id) {
    return CalculosApi.patch()(`/VerificacaoIntermediaria/${id}/reprovar`);
  },
  salvarValores: function (verificacaoIntermediariaId, faixaId, form) {
    return CalculosApi.put()(
      `/VerificacaoIntermediaria/${verificacaoIntermediariaId}/faixas/${faixaId}/valores`,
      {
        linhas: form.map((el) => {
          return {
            linhaId: el.linhaId,
            instrumentoPadraoId: el.instrumentoPadrao
              ? el.instrumentoPadrao.id
              : null,
            primeiroCiclo: el.primeiroCiclo,
            segundoCiclo: el.segundoCiclo,
            terceiroCiclo: el.terceiroCiclo,
            criterio: el.criterio,
            descricao: el.descricao,
          };
        }),
      }
    );
  },
  salvarCamposAdicionais: function (verificacaoIntermediariaId, form) {
    return CalculosApi.put()(
      `/VerificacaoIntermediaria/${verificacaoIntermediariaId}/campos-adicionais`,
      {
        camposAdicionais: form.map((el) => {
          return {
            campoAdicionalId: el.value,
            valor: el.valorInput,
          };
        }),
      }
    );
  },
};

export default VerificacaoIntermediariaService;
