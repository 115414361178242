<template lang="">
  <div style="min-height: 200px">
    <tabela-padrao-prime-vue
      v-model="selecionados"
      :dados="tabela.dados"
      filtro-geral
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
      :filters="filters"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      :mostrar-acoes="false"
      :global-filters="['nome', 'email', 'login', 'papel.nome']"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
    />
  </div>
</template>
<script>
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService';
import { FilterMatchMode } from 'primevue/api';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    tipoInstrumentoId: { type: String, default: null },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_usuarios.tabela.nome'),
            sortable: false,
          },
          {
            value: 'email',
            text: this.$t('componentes.input_usuarios.tabela.email'),
            sortable: false,
          },
          {
            value: 'login',
            text: this.$t('componentes.input_usuarios.tabela.usuario'),
            sortable: true,
          },
          {
            value: 'papel.nome',
            text: this.$t('componentes.input_usuarios.tabela.papel'),
            sortable: true,
          },
        ],
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        const selecionados = Array.isArray(this.selecionados)
          ? this.selecionados
          : [this.selecionados];
        this.$emit('input', selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function () {
      if (!this.tipoInstrumentoId) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoInstrumentoService.buscarUsuariosExecutantes(this.tipoInstrumentoId)
        .then((res) => {
          this.tabela.dados = res.data;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
