import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumLidoNaoLido.Lido'),
    value: true,
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.EnumLidoNaoLido.NaoLido'),
    value: false,
    cor: 'primary-error-700',
  },
];
