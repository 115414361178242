export default {
  titulos: {
    listagem: 'Classificações de Instrumentos',
    novo: 'Nova Classificação',
    editar: 'Editar Classificação de Instrumentos',
  },
  cadastro_sucesso: 'Classificação de Instrumentos salva com sucesso!',
  exclusao_sucesso: 'Classificação de Instrumentos excluída com sucesso!',
  tabela: {
    classificacao: 'Classificação',
  },
  formulario: {
    descricao: 'Descrição',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido: 'É necessário validar o formulário antes de salvar o registro.'
  },
  filtro: {
    descricao: 'Descrição',
  },
};
