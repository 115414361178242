<template lang="">
  <div class="input-padrao" :class="emFiltro ? 'v-input--em-filtro' : ''">
    <span v-if="labelExterno" class="mb-3 d-block v-label--externo d-flex">
      {{ labelComputada }}
    </span>
    <v-textarea
      v-model.trim="conteudo"
      :label="labelEhDentro ? labelComputada : ''"
      :placeholder="placeholder"
      :dense="dense"
      ref="inputArea"
      :rules="regras"
      :disabled="disabled"
      :counter="contador"
      :clearable="btnLimpar"
      :clear-icon="iconeBtnLimpar"
      @blur="onBlur"
      @focus="onFocus"
      :rows="rows"
      outlined
      hide-details="auto"
      style="background-color: white"
    >
      <template v-for="slot in slots" v-slot:[`${slot}`]>
        <slot :name="slot" />
        <span :key="slot" />
      </template>
    </v-textarea>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';

export default {
  name: 'InputTextarea',
  mixins: [InputMixin],
  props: {
    contador: { type: Boolean, default: false },
    btnLimpar: { type: Boolean, default: false },
    iconeBtnLimpar: { type: String, default: 'mdi-close-circle' },
    rows: { type: [Number, String], default: 6 },
    /** Props de validação */
  },
  data() {
    return {
      conteudo: '',
    };
  },
  computed: {
    regras() {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      if (this.min) regras.push(this.validacaoMinimo);
      if (this.max) regras.push(this.validacaoMaximo);
      if (this.trim) regras.push(this.validacaoEspacoVazio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
    blur() {
      this.$refs.inputArea.blur();
    },
    focus() {
      this.$refs.inputArea.focus();
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
