import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.ativo_inativo.ativo'),
    value: true,
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.ativo_inativo.inativo'),
    value: false,
    cor: 'primary-error-700',
  },
];
