import { BasicoApi } from '@common/api';
const ProcedimentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/procedimento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/procedimento/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/procedimento/${form.id}`, form);
    return BasicoApi.post()('/procedimento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/procedimento/${id}`);
  },
};

export default ProcedimentoService;
