import helpers from '@router/helpers';

const menuId = 'orcamentos';

export default [
  helpers.criarRota(menuId, 'orcamentos', menuId, () =>
    import('@/views/modulos/cadastros/orcamentos')
  ),
  helpers.criarRota(menuId, 'orcamentos-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/orcamentos/formulario')
  ),
  helpers.criarRota(
    menuId,
    'orcamentos-aprovar',
    `${menuId}/aprovar/:id?`,
    () => import('@/views/modulos/cadastros/orcamentos/AprovarOrcamento'),
     true
  ),
  helpers.criarRota(
    menuId,
    'orcamentos-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/orcamentos/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'orcamentos-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/orcamentos/formularioVisualizar'),
    true
  ),
  helpers.criarRota(
    menuId,
    'orcamentos-visualizacao',
    `${menuId}/:id?/:visualizacao?`,
    () => import('@/views/modulos/cadastros/orcamentos/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'orcamentos-editar-cliente',
    `${menuId}/cliente/editar/:id`,
    () => import('@/views/modulos/cadastros/orcamentos/editar-cliente'),
    true
  ),
];
