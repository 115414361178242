export default {
  titulos: {
    listagem: 'Dúvidas/Solicitações de Clientes',
    novo: 'Nova Dúvida/Solicitação de Cliente',
    editar: 'Editar Dúvida/Solicitação de Cliente',
  },
  cadastro_sucesso: 'Dúvida/Solicitação salva com sucesso!',
  exclusao_sucesso: 'Dúvida/Solicitação excluida com sucesso!',
  status_sucesso: 'Status de Dúvida/Solicitação alterada com sucesso!',
  tabela: {
    data_abertura: 'Data de Abertura',
    data_conclusao: 'Data de Conclusão',
    categoria: 'Categoria',
    sub_categoria: 'Assunto',
    comentario: 'Comentário',
    situacao: 'Situação',
  },
  formulario: {
    tipo_duvida_solicitacao: 'Tipo de Dúvida/Solicitação',
    instrumentos: 'Instrumentos',
    assunto: ' Assunto',
    duvida_solicitacao: ' Dúvida/Solicitação',
    certificados: 'Certificados',
    ordens_servicos: 'Ordens de Serviços',
    servicos_campos: 'Serviços nas Instalações do Cliente',
    orcamentos: 'Orçamentos',
    contratos: 'Contratos',
    comentario: 'Comentário',
    anexos: 'Anexos',
    abas: {
      dados: 'Dados da Dúvida/Solicitação',
      comentario: 'Comentário',
      anexo: 'Anexo'
    }
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido: 'É necessário validar o formulário antes de salvar o registro.'
  },
  filtro: {
    data_abertura: 'Data de Abertura',
    data_conclusao: 'Data Conclusão',
    comentario: 'Comentário',
    status: 'Status',
    categoria: 'Categoria',
    sub_categoria: 'Assunto',
    ordenacao: 'Ordenação',
  },
  anexo: {
    anexar_arquivo: 'Anexar Arquivo',
    anexar_arquivos: 'Anexar Arquivo(s)',
    nome_arquivo: 'Nome'
  }
};
