import helpers from '@router/helpers';
const menuId = 'incertezas';

export default [
  helpers.criarRota(menuId, 'incertezas', menuId, () =>
    import('@/views/modulos/cadastros/incertezas/index')
  ),
  helpers.criarRota(menuId, 'incertezas-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/incertezas/formulario')
  ),
  helpers.criarRota(
    menuId,
    'incertezas-editar',
    `${menuId}/editar/:id?/:idRevisao?`,
    () => import('@/views/modulos/cadastros/incertezas/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'incertezas-revisoes',
    `${menuId}/revisoes/:id?`,
    () => import('@/views/modulos/cadastros/incertezas/revisoes'),
    true
  ),
  helpers.criarRota(
    menuId,
    'incertezas-revisoes-visualizar',
    `${menuId}/visualizar-revisao/:idRevisao?`,
    () => import('@/views/modulos/cadastros/incertezas/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'incertezas-revisoes-editar',
    `${menuId}/editar-revisao/:idRevisao?`,
    () => import('@/views/modulos/cadastros/incertezas/formulario'),
    true
  ),
];
