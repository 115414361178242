export default {
  titulos: {
    listagem: 'Checklists',
    novo: 'Novo Checklist',
    editar: 'Editar Checklist',
    servicos_para_analise_tecnica: 'Selecione os serviços de manutenção e peças que serão automaticamente adicionados na Análise Técnica quando o usuário marcar que o item não está ok'
  },
  toolTip: {
    classificacao_instrumento:
      'Ao alterar o Tipo de Instrumento, o campo de Classificação de Instrumento será resetado.',
    tipo_instrumento:
      'Ao alterar o Classificação de Instrumento, o campo de Tipo de Instrumento será resetado.',
  },
  cadastro_sucesso: 'Checklist salvo com sucesso!',
  exclusao_sucesso: 'Checklist excluído com sucesso!',
  tabela: {
    tipo_checklist: 'Tipo do Checklist',
    classificacao_instrumento: 'Classificação de Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
  },
  formulario: {
    abas: {
      dados_checklist: 'Dados do Checklist',
      itens: 'Itens',
    },

    dados_checklist: 'Dados do Checklist',
    itens: 'Itens',
    tipo_checklist: 'Tipo do Checklist',
    tipo_instrumento: 'Tipo de Instrumento',
    classificacao_instrumento: 'Classificação de Instrumento',
    codigo: 'Código',
    descricao: 'Descrição',
    acao_nao_ok: 'Ação não Ok',
    servicos: 'Serviços de Manutenção',
    pecas: 'Peças',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
    deve_ter_tipo_instrumento_ou_classificacao_instrumento:
      'É obrigatório inserir o Tipo de Instrumento ou a Classificação do Instrumento',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    tipo_checklist: 'Tipo do Checklist',
    classificacao_instrumento: 'Classificação de Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
  },
  botoes: {
    add_itens: 'Adicionar Itens',
  },
  itens: {
    tabela: {
      codigo: 'Código',
      descricao: 'Descrição',
      acao_nao_ok: 'Ação Não Ok',
      servicos: 'Serviços de Manutenção',
      pecas: 'Peças',
      sequencia: 'Sequência',
    },
    modal: {
      add_titulo: 'Adicionar Itens',
      editar_titulo: 'Editar Itens',
      itens: 'Itens',
      filtro: {
        codigo: 'Código',
        descricao: 'Descrição',
        acao_nao_ok: 'Ação não Ok',
      },
      tabelaServico: {
        titulo: 'Serviços de Manutenção',
        colunas: {
          tipo_servico: 'Tipo do serviço',
          codigo: 'Código',
          nome: 'Nome',
        },
      },
      tabelaPecas: {
        titulo: 'Peças',
        colunas: {
          codigo: 'Código',
          descricao: 'Descrição',
        },
      },
    },
  },
};
