import { CalculosApi } from '@common/api';
const TabelaService = {
  listar: function (parametros) {
    return CalculosApi.get()('/tabela', parametros);
  },
  listarPorInstrumento: function (parametros, tipoInstrumentoId) {
    return CalculosApi.get()(
      `/tabela?TipoInstrumentoId=${tipoInstrumentoId}`,
      parametros
    );
  }
};

export default TabelaService;
