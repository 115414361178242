<template lang="">
  <div>
    <div class="d-flex align-end">
      <input-text
        v-model="filtro.codigo"
        :label="$t('componentes.input_contrato.codigo')"
        class="flex-fill mr-2"
      />
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      :selecionar-apenas-um="!multiplos"
      @paginar="listar"
    />
  </div>
</template>
<script>
import ContratoService from "@common/services/cadastros/ContratoService";
import _ from "lodash";
import helpers from "@common/utils/helpers";
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      filtro: {
        nome: "",
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: "codigo",
            text: this.$t("componentes.input_contrato.tabela.codigo"),
            sortable: false,
          },
          {
            value: "dataInicio",
            text: this.$t("componentes.input_contrato.tabela.data_inicio"),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: "valorTotal",
            text: this.$t("componentes.input_contrato.tabela.valor"),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarMoeda(v, false);
              else return 'R$ 0,00';
            },
          },
          {
            value: "valorMensal",
            text: this.$t("componentes.input_contrato.tabela.valor_mensal"),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarMoeda(v, false);
              else return 'R$ 0,00';
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit("input", this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch("Layout/iniciarCarregamento");
      ContratoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch("Layout/terminarCarregamento");
        });
    },
  },
};
</script>
