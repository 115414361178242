import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.status_orcamento.em_avalicao'),
    value: 'EmAvaliacao',
  },
  {
    text: i18n.t('enums.status_orcamento.aprovados'),
    value: 'Aprovados',
  },
  {
    text: i18n.t('enums.status_orcamento.reprovados'),
    value: 'Reprovados',
  },
  {
    text: i18n.t('enums.status_orcamento.em_elaboracao'),
    value: 'EmElaboracao',
  },
  {
    text: i18n.t('enums.status_orcamento.em_negociacao'),
    value: 'EmNegociacao',
  },
  {
    text: i18n.t('enums.status_orcamento.vencidos'),
    value: 'Vencidos',
  },
  {
    text: i18n.t('enums.status_orcamento.aguardando_aprovacao'),
    value: 'AguardandoAprovacao',
  },
  {
    text: i18n.t('enums.status_orcamento.vencidos'),
    value: 'Vencidos',
  },
];
