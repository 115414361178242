<template lang="">
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo d-flex"
    >
      <span
        v-if="!tooltip"
        class="label"
      >
        {{ label }}
      </span>
      <v-tooltip
        v-else
        bottom
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ label }}</span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>

      <span
        v-if="obrigatorio && label"
        class="obrigatorio ml-1"
      > * </span>
    </span>
    <v-text-field
      :id="id"
      ref="input"
      v-model.trim="conteudo"
      v-mask="mascara"
      :label="labelEhDentro ? labelComputada : ''"
      :placeholder="placeholder"
      :dense="dense"
      :rules="regras"
      :type="type == 'password' ? (!show ? 'password' : 'text') : type"
      :min="0"
      :maxlength="max"
      :disabled="disabled"
      :readonly="readonly"
      :outlined="outlined"
      :flat="flat"
      :step="incremento"
      :solo="solo"
      :background-color="backgroundColor"
      :error="error"
      :error-messages="errorMessages"
      hide-details="auto"
      :color="color"
      :dark="dark"
      :append-icon="type != 'password' ? null : show ? '$eye' : '$eyeOff'"
      :loading="carregando"
      autocomplete="off"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.tab="onTab"
      @keydown.enter="onEnter"
      @keydown.esc="onEsc"
      @click:append="show = !show"
      @keydown="validarDigitacao"
    >
      <template
        v-for="slot in slots"
        v-slot:[`${slot}`]
      >
        <slot :name="slot" />
        <span :key="slot" />
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
import helpers from '@/common/utils/helpers';
import _ from 'lodash';
export default {
  name: 'InputText',
  mixins: [InputMixin],
  props: {
    type: { type: String, default: '' },
    tooltip: { type: String, default: '' },
    incremento: { type: Number, default: 1 },
    mascara: { type: [String, Boolean], default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: [Array, String], default: null },
    tabNoEnter: { type: Boolean, default: false },
    filtroGlobalFocus: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    /** Props de validação */
    email: { type: Boolean, default: false },
    obrigatorio: { type: Boolean, default: false },
    cnpj: { type: Boolean, default: false },
    selecionarTextoOnFocus: { type: Boolean, default: false },
    numeroMinimo: { type: [Number, String], default: null },
    numeroMaximo: { type: [Number, String], default: null },
    apenasNumeros: { type: Boolean, default: false },
    validarApenasNumeros: { type: Boolean, default: true },
    semlabel:{ type: Boolean, default: true }
  },
  data() {
    return {
      conteudo: '',
      show: false,
      carregando: false,
    };
  },
  computed: {
    regras() {
      let regras = _.cloneDeep(this.regrasPersonalizadas);
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      if (this.email) regras.push(this.validacaoEmail);
      if (this.apenasNumeros && this.validarApenasNumeros) regras.push(this.validacaoApenasNumeros);
      if (this.min && this.conteudo !== undefined)
        regras.push(this.validacaoMinimo);
      if (this.max && this.conteudo !== undefined)
        regras.push(this.validacaoMaximo);
      if (this.trim && this.conteudo !== undefined)
        regras.push(this.validacaoEspacoVazio);
      if (this.numeroMaximo != null) {
        let ctx = this;
        regras.push(function (v) {
          if (v == '') return true;
          return (
            !(parseInt(v) > ctx.numeroMaximo) ||
            ctx.$t('geral.validacao.numero_maximo', {
              tamanho: ctx.numeroMaximo,
            })
          );
        });
      }
      if (this.numeroMinimo != null) {
        let ctx = this;
        regras.push(function (v) {
          if (v == '') return true;
          return (
            !(parseInt(v) < ctx.numeroMinimo) ||
            ctx.$t('geral.validacao.numero_minimo', {
              tamanho: ctx.numeroMinimo,
            })
          );
        });
      }
      if (this.cnpj) regras.push(this.validacaoCNPJ);
      return regras;
    },
  },

  watch: {
    value: {
      handler(value) {
        if (this.apenasNumeros) value = helpers.converterNumeroParaTexto(value);
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      if (this.apenasNumeros)
        conteudo = helpers.converterTextoParaNumero(conteudo);
      this.$emit('input', conteudo);
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    validacaoEmail(conteudo) {
      if (!conteudo) return true;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(conteudo) || this.$t('inputs.rules.validEmail');
    },
    validacaoApenasNumeros(value) {
      if (!value) return true;
      const apenasNumeros = /^[0-9,]+$/;
      return (
        apenasNumeros.test(value) ||
        this.$t('inputs.rules.validarApenasNumeros')
      );
    },
    validarDigitacao(e) {
      let conteudoValidacao = `${this.conteudo || ''}`;
      if (this.type == 'number') {
        if (
          (e.key == '-' ? true : !isNaN(Number(e.key))) ||
          e.key == 'ArrowUp' ||
          e.key == 'ArrowDown'
        ) {
          let valor = e.key;
          switch (e.key) {
            case 'ArrowUp':
              conteudoValidacao++;
              if (conteudoValidacao < this.numeroMinimo)
                conteudoValidacao = `${this.numeroMinimo}`;
              break;
            case 'ArrowDown':
              conteudoValidacao--;
              if (conteudoValidacao > this.numeroMaximo)
                conteudoValidacao = `${this.numeroMaximo}`;
              break;
            default:
              if (
                this.numeroMinimo != null &&
                this.numeroMinimo >= 0 &&
                e.key == '-'
              ) {
                e.preventDefault();
                return;
              }
              conteudoValidacao = `${conteudoValidacao}${valor}`;
              if (
                isNaN(Number(conteudoValidacao.replace(',', '.'))) &&
                conteudoValidacao != '-'
              ) {
                e.preventDefault();
                return;
              }
          }
          if (
            (parseFloat(conteudoValidacao) < this.numeroMinimo &&
              this.numeroMinimo != null) ||
            (parseFloat(conteudoValidacao) > this.numeroMaximo &&
              this.numeroMaximo != null)
          )
            e.preventDefault();
        }
      }
    },
    validacaoCNPJ: function (cnpj) {
      if (!cnpj) return this.$t('inputs.rules.validCNPJ');

      cnpj = cnpj.toString().replace(/[^\d]+/g, '');

      if (cnpj === '') return this.$t('inputs.rules.validCNPJ');

      if (cnpj.length < 14) return this.$t('inputs.rules.validCNPJ');

      if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
      )
        return this.$t('inputs.rules.validCNPJ');

      let length = cnpj.length - 2;
      let numeros = cnpj.substring(0, length);
      let digitos = cnpj.substring(length);
      let soma = 0;
      let pos = length - 7;

      for (let i = length; i >= 1; i--) {
        soma += numeros.charAt(length - i) * pos--;
        if (pos < 2) pos = 9;
      }

      let result = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (result !== Number(digitos.charAt(0)))
        return this.$t('inputs.rules.validCNPJ');

      length = length + 1;
      numeros = cnpj.substring(0, length);
      soma = 0;
      pos = length - 7;

      for (let i = length; i >= 1; i--) {
        soma += numeros.charAt(length - i) * pos--;
        if (pos < 2) pos = 9;
      }

      result = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (result !== Number(digitos.charAt(1)))
        return this.$t('inputs.rules.validCNPJ');

      return true;
    },
    onBlur() {
      this.$emit('blur');
    },
    onTab(e) {
      if (this.preventTab) e.preventDefault();
      this.$emit(e.shiftKey ? 'shit-tab' : 'tab');
    },
    onEnter(e) {
      this.$emit('enter', e);
      if (this.tabNoEnter) this.$emit('tab');
    },
    onFocus(e) {
      if (this.selecionarTextoOnFocus) e.target.select();

      this.$emit('focus');
    },
    focusFiltroGlobal() {
      if (this.filtroGlobalFocus && this.$refs['input']) {
        this.$refs['input'].$el.focus();
      }
    },
    onEsc(e) {
      this.$emit('esc', e);
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    iniciarCarregamento() {
      this.carregando = true;
    },
    terminarCarregamento() {
      this.carregando = false;
    },
    alterarValor(valor) {
      this.conteudo = valor;
      this.$emit('input', this.conteudo);
    },
  },
};
</script>
<style>
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
