export default {
  titulos: {
    listagem: 'Campos Adicionais',
    novo: 'Novo Campo Adicional',
    editar: 'Editar Campo Adicional',
    select: 'Cadastro',
  },
  cadastro_sucesso: 'Campo Adicional salvo com sucesso!',
  exclusao_sucesso: 'Campo Adicional excluído com sucesso!',
  salvar_ordenacao_sucesso: 'Ordenação salva com sucesso',
  tabela: {
    nome: 'Nome',
    sequencia: 'Sequência',
    tipoCampo: 'Tipo do Campo',
    obrigatorio: 'Campo Obrigatório',
    quantidadeTipoInstrumentos: 'Qtde Tipos de Instrumentos',
  },
  formulario: {
    nome: 'Nome',
    cadastro: 'Cadastro',
    tipoCampo: 'Tipo do Campo',
    valorPadrao: 'Valor Padrão',
    itens: 'Itens',
    edicaoValor: 'Edição do Valor',
    campoObrigatorio: 'Campo Obrigatório*',
    tipoIntrumento: 'Tipos de Instrumentos',
    descricao: 'Descrição',
  },
  filtro: {
    nome: 'Nome',
    tipoCampo: 'Tipo do Campo',
    campoObrigatorio: 'Campo Obrigatório',
  },
  erros: {
    id_invalido: 'O id do Campo Adicional é inválido',
  },
  validacoes: {
    formulario_invalido: 'Por favor verifique os campos',
  },
};
