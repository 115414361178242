import { CalculosApi, BasicoApi } from '@common/api';

const IncertezasService = {
  listar: function (parametros) {
    return CalculosApi.get()('/Incerteza', parametros);
  },

  listarEnumEstruturaUtilizada: function () {
    return BasicoApi.get()('/Enumerador/EnumEstruturaUtilizada');
  },

  excluirIncerteza: function (id) {
    return CalculosApi.delete()(`/Incerteza/${id}`);
  },

  salvar: function (form) {
    if (form.id) return CalculosApi.put()(`/Incerteza/${form.id}`, form);

    return CalculosApi.post()('/Incerteza', form);
  },

  listarFormulas: function (parametros) {
    return CalculosApi.get()('/Formula', parametros);
  },

  salvarComponente: function (form, id) {
    if (form.id)
      return CalculosApi.put()(
        `/Incerteza/revisoes/componentes/${form.id}`,
        form
      );

    return CalculosApi.post()(`/Incerteza/${id}/revisoes/componentes`, form);
  },

  buscar: function (id) {
    return CalculosApi.get()(`/Incerteza/${id}`);
  },

  listarRevisoes: function (id) {
    return CalculosApi.get()(`/Incerteza/${id}/revisoes`);
  },

  buscarRevisao: function (id) {
    return CalculosApi.get()(`/Incerteza/revisoes/${id}`);
  },

  buscarComponente: function (id) {
    return CalculosApi.get()(`/Incerteza/revisoes/componentes/${id}`);
  },

  excluirItem: function (id) {
    return CalculosApi.delete()(`/Incerteza/revisoes/componentes/${id}`);
  },

  listarComponentes: function (id) {
    return CalculosApi.get()(`/Incerteza/revisoes/${id}/componentes`);
  },

  listarEnumOrigemContribuicao: function () {
    return BasicoApi.get()('/Enumerador/EnumOrigemContribuicao');
  },

  listarEnumDistribuicaoEstatistica: function () {
    return BasicoApi.get()('/Enumerador/EnumDistribuicaoEstatistica');
  },

  publicar: function (id) {
    return CalculosApi.put()(`/Incerteza/${id}/publicar`);
  },

  duplicarComponente: function (id) {
    return CalculosApi.post()(`/Incerteza/componente/duplicar/${id}`);
  },

  duplicar: function (id) {
    return CalculosApi.post()(`/Incerteza/${id}/duplicar`);
  },

  criarRevisao: function (id) {
    return CalculosApi.put()(`/Incerteza/${id}/revisar`);
  },

  excluirRevisao: function (id, revisaoId) {
    return CalculosApi.delete()(`/Incerteza/${id}/revisoes/${revisaoId}`);
  },

  salvarSequencia: function (id, payload) {
    return CalculosApi.patch()(`/Incerteza/revisoes/${id}/componentes/sequencia`, payload);
  }
};

export default IncertezasService;
