export default {
  titulos: {
    listagem: 'Contratos',
    novo: 'Novo Contrato',
    editar: 'Editar Contrato',
    reajuste_contrato: 'Reajuste no Contrato',
    visualizar: 'Visualizar Contrato',
  },
  criar_novo_aditivo: 'Criar Novo Aditivo',
  aditivo_parenteses: '(Aditivo)',
  cadastro_sucesso: 'Contrato salvo com sucesso!',
  ajuste_valor_sucesso: 'Reajuste de valor salvo com sucesso!',
  duplicar_renovar: 'Contrato Renovado com sucesso!',
  selecionar_tipo_contrato: 'Selecione o Tipo do Contrato',
  novo_item: 'Novo Item',
  atualizar_dados: 'Atualizar Dados',
  alterar_percentual_desconto: 'Alterar Percentual de Desconto',
  assinar_contrato: 'Assinar Contrato',
  associar_servicos_calibracao: 'Associar Serviços de Calibração',
  associar_servicos_manutencao: 'Associar Serviços de Manutenção',
  associar_pecas: 'Associar Peças',
  visualizar_comentarios: 'Visualizar Comentários',
  situacao: 'Situação',
  instrumento_validado: 'Instrumento Validado',
  instrumento_adicionado: 'Instrumento Adicionado',
  item_validado: 'Item Validado',
  item_removido: 'Item Removido',
  marque_os_itens_remover_contrato:
    'Marque os Itens que Deseja Remover do Contrato',
  adicionar_instrumento_contrato: 'Adicionar Instrumento(s) no Contrato',
  novo_comentario: 'Novo Comentário',
  selecionar_instrumento: 'Selecionar Instrumento',
  exclusao_sucesso: 'Contrato excluído com sucesso!',
  revisao_sucesso: 'Revisão do contrato criada com sucesso!',
  justificativa_sucesso: 'Justificativa criada com com sucesso!',
  exclusao_justificativa_sucesso:
    'Justificativa da exclusão feita com sucesso!',
  contrato_invalido: 'Contrato inválido',
  botoes: {
    editar_instrumento: 'Editar Instrumento',
    criterio_aceitacao: 'Critério de Aceitação',
  },
  tabela: {
    cliente: 'Cliente',
    codigo: 'Código',
    descricao: 'Descrição',
    valor: 'Valor',
    tipo_instrumento: 'Tipo de Instrumento',
    modelo_instrumento: 'Modelo de Instrumento',
    valor_com_desconto: 'Valor com Desconto',
    percentual_desc: 'Percentual de Desc.',
    data_iniescopo_contratocio: 'Data de Início',
    tipo: 'Tipo',
    situacao: 'Situação',
    data_inicio: 'Data de Início',
    data_envio: 'Data de Envio',
    valor_final: 'Valor Final',
    modal_add_revisao: {
      titulo: 'Atenção',
      mensagem:
        'Não é possível editar um contrato que já foi liberado para o cliente, deseja criar uma Revisão deste orçamento?',
      nova_revisao: 'Nova Revisão',
      excluir_revisao: 'Excluir Revisão',
      justificativa_nova_revisao:
        'Informe uma justificativa para criar uma nova revisão',
      justificativa_excluir_revisao:
        'Informe uma justificativa para excluir a revisão',
      justificativa: 'Justificativa',
    },
    modal_list_revisao: {
      titulo: 'Revisões do Contrato',
      tabela: {
        codigo: 'Código',
        data_emissao: 'Data de Emissão',
        usuario: 'Usuário',
        valor: 'Valor',
        valor_mensal: 'Valor Mensal',
        valor_final: 'Valor Final',
      },
    },
    valor_mensal: 'Valor Mensal',
    tag: 'Tag',
    codigo_servico: 'Código de Serviço',
    valor_total: 'Valor Total',
  },
  formulario: {
    abas: {
      dados_contrato: 'Dados do Contrato',
      escopo_contrato: 'Escopo do Contrato',
      escopo_calibracao: 'Escopo de Calibração',
      servicos_calibracao: 'Serviços de Calibração',
      servicos_manutencao: 'Serviços de Manutenção',
      pecas: 'Peças',
      escopo_manutencao: 'Escopo de Manutenção',
      escopo_peca: 'Escopo de Peça',
      clausula_contratual: 'Cláusula Contratual',
      cliente: 'Cliente',
      comentarios: 'Comentários',
    },
    servico_calibracao: 'Serviços de Calibração',
    cliente: 'Cliente',
    numero: 'Número',
    adicionar: 'Adicionar',
    descricao: 'Descrição',
    quantidade_total: 'Quantidade Total',
    sequencia: 'Sequência',
    tipo_instrumento: 'Tipo de Instrumento',
    faixa: 'Faixa',
    tipo_servico: 'Tipo de Serviço',
    nome: 'Nome',
    tipo: 'Tipo',
    laboratorio: 'Laboratório',
    instrumento: 'Instrumento',
    quantidade: 'Quantidade',
    valor: 'Valor',
    laboratorio_terceirizado: 'Laboratório Terceirizado',
    tipo_calibracao: 'Tipo de Calibração',
    freq_calibracao: 'Freq. de Calibração',
    local_execucao: 'Local de Execução',
    local: 'Local',
    itens_pontos: 'Itens/Pontos',
    extrapolar_qtd: 'Extrapolar Qtd',
    prazo_entrega: 'Prazo de Entrega',
    prazo: 'Prazo',
    codigo: 'Código',
    contato: 'Contato',
    data: 'Data',
    empresa: 'Empresa',
    nome_testemunha: 'Nome da Testemunha',
    cpf_testemunha: 'CPF da Testemunha',
    renovacao_contrato: 'Renovação do Contrato',
    situacao: 'Situação',
    justificativa: 'Justificativa',
    tipo_contrato: 'Tipo do Contrato',
    informe_data_assinar_contrato:
      'Informe uma data para assinar o contrato selecionado',
    informe_data_aprovar_contrato:
      'Informe uma data para aprovar o contrato selecionado',
    informe_data_reprovar_contrato:
      'Informe uma data para reprovar o contrato selecionado',
    informe_data_cancelar_contrato:
      'Informe uma data para cancelar o contrato selecionado',
    informe_data_encerrar_contrato:
      'Informe uma data para encerrar o contrato selecionado',
    data_inicio: 'Data de Início',
    data_renovacao: 'Data de Renovação',
    data_termino: 'Data de Término',
    data_assinatura: 'Data de Assinatura',
    parcelas: 'Parcelas',
    itensContrato: {
      botoes: {
        novoItemContrato: 'Novo Item de Contrato',
        adicionar_novo_instrumento: 'Adicionar Instrumento(s)',
      },
      steps: {
        instrumento: 'Instrumento',
        instrumentos_servico_calibracao:
          'Instrumentos e Serviços de Calibração',
        instrumentos: 'Instrumentos',
        servico_calibracao: 'Serviço de Calibração',
        servicos_calibracao: 'Serviços de Calibração',
        confirmacao: 'Confirmação',
        proximo: 'Próximo',
        confirmar: 'Confirmar',
        voltar: 'Voltar',
      },
      nome: 'Nome',
      telefone: 'Telefone',
      email: 'Email',
      setor: 'Setor',
      cargo: 'Cargo',
    },
    tipo_acrescimo: 'Tipo de Acréscimo',
    politica_preco_peca: 'Política Preço Peça',
    frequencia: 'Frequência',
    acrescimo: 'Acréscimo',
    aplicar_ajuste_para: 'Aplicar os Ajustes para',
    pontos_calibrar: 'Pontos a Calibrar',
    tipo_desconto: 'Tipo de Desconto',
    desconto: 'Desconto',
    valor_final: 'Valor Final',
    valor_final_rs: 'Valor Final (R$)',
    faxa: 'Faxa',
    porcentagem: 'Porcentagem',
    calibracao: 'Calibração',
    manutencao: 'Manutenção',
    valor_unitario: 'Valor Unitário',
    valor_total: 'Valor Total',
    valor_mensal: 'Valor Mensal (R$)',
    valor_itens: 'Valor dos Itens (R$)',
    pontos_calibracao: 'Pontos de Calibração',
    ultima_calibracao: 'Última Calibração',
    in_company: 'In Company',
    criterio_aceitacao: 'Critério de Aceitação',
    observacao: 'Observação',
    ajuste: 'Ajuste (%)',
    comentarios: {
      novo_comentario: 'Novo Comentário',
      usuario: 'Usuário',
      comentario: 'Comentário',
      data: 'Data',
      privado: 'Privado',
      encerrar: 'Encerrar',
      notificar: 'Notificar',
    },
    clausula_contratual: {
      associar_desassociar_clausula_contratual: 'Cláusulas Contratuais',
      sequencia: 'Sequência',
      nome: 'Nome',
    },
  },
  erros: {
    id_invalido: 'O id do contrato é inválido',
    selecione_um_servico_instrumento:
      'Selecione pelo menos um Serviço para cada Instrumento',
    preencha_formulario_selecione_instrumento:
      'Preencha o Formulário e selecione pelo menos um Instrumento',
    erro_buscar_datas_calibracao:
      'Erro ao calcular as próximas datas de calibração',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
    valor_entre_zero_cem: 'O valor deve estar entre 0 e 100',
    escopo_vazio: 'Precisa cadastrar um Escopo!',
    troca_politica_preco_peca_alerta:
      'Ao alterar a Política Preço Peça, o Escopo de Peças vai ser alterado',
    calibracoes_zerada: 'Há serviços sem calibração a realizar no período, próxima calibração do Instrumento está fora do intervalo, verifique o início e fim do Contrato',
  },
  filtro: {
    cliente: 'Cliente',
    codigo: 'Código',
    data_inicio: 'Data de Início',
    tipo: 'Tipo',
    situacao: 'Situação',
  },
  servico_peca: 'Serviços de Peças',
  modal_criterio_aceitacao: {
    titulo_modal: 'Critérios de Aceitação',
    faixa: ' Faixa',
    ponto: 'Ponto',
    criterio_aceitacao: 'Critério de Aceitação',
  },
};
