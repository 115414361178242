export default {
  filtro: {
    nome: 'Nome',
  },
  tabela: {
    nome: 'Nome',
    descricao: 'Descrição',
  },
  titulo_modal: 'Grandeza Física',
};
