export default {
  titulos: {
    listagem: 'Cargos e Funções',
    novo: 'Novo Cargo/Função',
    editar: 'Editar Cargo/Função',
  },
  cadastro_sucesso: 'Cargo/Função salvo/a com sucesso!',
  exclusao_sucesso: 'Cargo/Função excluído/a com sucesso!',
  tabela: {
    nome: 'Nome',
    cbo: 'CBO',
  },
  formulario: {
    nome: 'Nome',
    descricao: 'Descrição',
    cbo: 'CBO',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    nome: 'Nome',
    cbo: 'CBO',
  },
};
