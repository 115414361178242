import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.status_contrato.em_negociacao'),
    value: 'EmNegociacao',
  },
  {
    text: i18n.t('enums.status_contrato.aguardando_aprovacao'),
    value: 'AguardandoAprovacao',
  },
  {
    text: i18n.t('enums.status_contrato.pre_assinado'),
    value: 'PreAssinado',
  },
  {
    text: i18n.t('enums.status_contrato.assinado'),
    value: 'Assinado',
  },
  {
    text: i18n.t('enums.status_contrato.reprovado'),
    value: 'Reprovado',
  },
  {
    text: i18n.t('enums.status_contrato.cancelado'),
    value: 'Cancelado',
  },
  {
    text: i18n.t('enums.status_contrato.encerrado'),
    value: 'Encerrado',
  },
  {
    text: i18n.t('enums.status_contrato.aguardando_envio_cliente'),
    value: 'AguardandoEnvioParaCliente',
  },
  {
    text: i18n.t('enums.status_contrato.aguardando_validacao_kel'),
    value: 'AguardandoValidacaoKeL',
  },
];
