export default {
  titulos: {
    listagem: 'Locais de Estocagem',
    novo: 'Novo Local de Estocagem',
    editar: 'Editar Local de Estocagem',
  },
  cadastro_sucesso: 'Local de estocagem salvo com sucesso!',
  exclusao_sucesso: 'Local de estocagem excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    descricao: 'Descrição',
  },
  formulario: {
    nome: 'Nome',
    descricao: 'Descrição',
  },
  erros: {
    id_invalido: 'O id do local de estocagem é inválido',
  },
};
