export default {
  tipo_pessoa: {
    pessoa_juridica: 'Pessoa Jurídica',
    pessoa_fisica: 'Pessoa Física',
  },
  tipo_participantes: {
    cliente: 'Cliente',
    fornecedor: 'Fornecedor',
    ambos: 'Ambos',
  },
  status_orcamento: {
    em_avalicao: 'Em Avaliação',
    aprovados: 'Aprovados',
    reprovados: 'Reprovados',
    em_elaboracao: 'Em Elaboração',
    em_negociacao: 'Em Negociação',
    aguardando_aprovacao: 'Aguardando Aprovação',
    vencidos: 'Vencidos',
  },
  tipo_data: {
    data_emissao: 'Data de Emissão',
    data_vencimento: 'Data de Vencimento',
    data_envio: 'Data de Envio',
  },
  status_contrato: {
    em_negociacao: 'Em Negociação',
    aguardando_aprovacao: 'Aguardando Aprovação',
    pre_assinado: 'Pré Assinado',
    assinado: 'Assinado',
    reprovado: 'Reprovado',
    cancelado: 'Cancelado',
    encerrado: 'Encerrado',
    aguardando_envio_cliente: 'Aguardando Envio para o Cliente',
    aguardando_validacao_kel: 'Aguardando Validação K&L',
  },
  tipo_contrato: {
    contrato_aberto: 'Contrato Aberto',
    contrato_fechado: 'Contrato Fechado',
    cliente_especial: 'Cliente Especial',
  },
  tipo_desconto_acrescimo: {
    porcentagem: 'Porcentagem',
    percentual: 'Percentual',
    valor: 'Valor',
  },
  papel_fornecedor: {
    laboratorio: 'Laboratório',
    fabricante: 'Fabricante',
    transportadora: 'Transportadora',
  },
  tipo_desconto: {
    valor_real: 'Valor Real ($)',
    porcentagem: 'Porcentagem (%)',
    percentual: 'Percentual (%)',
  },
  forma_colicitacao_orcamento: {
    nao_informado: 'Não Informado',
    email: 'E-mail',
    telefone: 'Telefone',
    pessoalmente: 'Pessoalmente',
    marketing: 'Marketing',
    outro: 'Outro',
  },
  dias_envio_material: {
    segunda: 'Segunda-feira',
    terca: 'Terça-feira',
    quarta: 'Quarta-feira',
    quinta: 'Quinta-feira',
    sexta: 'Sexta-feira',
  },
  tipo_endereco: {
    sede: 'Sede',
    cobranca: 'Cobrança',
    local_entrega: 'Local de Entrega',
    local_retirada: 'Local de Retirada',
  },
  tipo_requisitos: {
    recebimento: 'Recebimento',
    analise_tecnica: 'Análise Técnica',
    calibracao: 'Calibração',
    ensaio: 'Ensaio',
    expedicao: 'Expedição',
    orcamento: 'Orçamento',
    notas_fiscais: 'Notas Fiscais',
  },
  boleano: {
    sim: 'Sim',
    nao: 'Não',
  },
  ativo_inativo: {
    ativo: 'Ativo',
    inativo: 'Inativo',
  },
  posicao_sequencia_setor: {
    inicial: 'Inicial',
    entre: 'Entre',
    final: 'Final',
  },
  tipo_procedimento_eletronico: {
    calibracao: 'Calibração',
    ensaio: 'Ensaio',
  },
  tipo_coluna: {
    texto: 'Texto',
    referencia: 'Referência',
    smc: 'SMC',
    smp: 'SMP',
    resultado: 'Resultado',
    unidade: 'Unidade',
    auxiliar: 'Tabela Auxiliar',
  },
  arredondamento: {
    nao_arredondar: 'Não Arredondar',
    arredondar_cima: 'Arredondar para Cima',
    arredondar_baixo: 'Arredondar para Baixo',
  },
  EnumTipoCriterioAprovacaoAutomatica: {
    PercentualEmRelacaoInstrumentoNovo:
      'Percentual em relação a um instrumento novo',
    PrecisaNumeroPedidoParaAprovacao:
      'Precisa do número do pedido para aprovação',
    PrecisaNumeroPedidoParaFaturamento:
      'Precisa do número do pedido para faturamento',
    PrecisaPedidoAnexadoParaAprovacao:
      'Precisa do pedido anexado para aprovação',
    PrecisaPedidoAnexadoParaFaturamento:
      'Precisa do pedido anexado para faturamento',
    SaldoConstanteEmContrato: 'Saldo constante em contrato',
    ServiçoCalibracaoExistenteNoContrato:
      'Serviço de Calibração existente no contrato',
    ServiçoManutencaoExistenteNoContrato:
      'Serviço de Manutenção existente no contrato',
    TudoAprovado: 'Tudo aprovado',
    ValorPeca: 'Valor da Peça',
    ValorServicoCalibracao: 'Valor do Serviço de Calibração',
    ValorServicoManutencao: 'Valor do Serviço de Manutenção',
    ValorTotalOrcamentosCada60DiasContarUltimoFechamento:
      'Valor Total de Orçamentos a cada 60 dias a contar do último fechamento',
    ValorTotalOrcamentosDesdeAssinaturaUltimoContrato:
      'Valor Total de Orçamentos desde a assinatura do último contrato',
    ValorTotalOrcamentosMes: 'Valor Total de Orçamentos no mês',
    ValorTotalOrcamento: 'Valor Total do Orçamento',
  },
  EnumConformidadeItemConforme: {
    Conforme: 'Conforme',
    NaoConforme: 'Não conforme',
    NaoAplica: 'Não se aplica',
  },
  EnumCurvaCorrecao: {
    Polinomio: 'Polinômio',
    Percentual: 'Percentual',
    Interpolacao: 'Interpolação',
    SemCorrecao: 'Sem correção',
  },
  EnumOperadorLogico: {
    E: 'E',
    Ou: 'Ou',
  },
  EnumTipoValor: {
    Logico: 'Lógico',
    Numerico: 'Numérico',
    Percentual: 'Percentual',
  },
  EnumOperadorRelacional: {
    NaoAplicavel: 'Não Aplicável',
    Diferente: 'Diferente',
    Igual: 'Igual',
    Maior: 'Maior',
    MaiorIgual: 'Maior ou Igual',
    Menor: 'Menor',
    MenorIgual: 'Menor ou Igual',
  },
  EnumMensagemClassificacao: {
    Administrativa: 'Administrativa',
    Tecnica: 'Técnica',
  },
  EnumTipoChecklist: {
    AnaliseTecnica: 'Análise Técnica',
    AssinaturaCertificado: 'Assinatura de Certificado',
  },
  EnumSituacaoCertificado: {
    EmAndamento: 'Em Andamento',
    NaoAvaliado: 'Não Avaliado',
    Assinado: 'Assinado',
    Cancelado: 'Cancelado',
    PendenteAssinatura: 'Pendente Assinatura',
  },
  EnumSituacaoAvaliacao: {
    NaoIniciada: 'Não Iniciada',
    Assinado: 'Assinado',
    NaoAvaliado: 'Não Avaliado',
    NaoValidado: 'Não Validado',
    PendenteDerivas: 'Pendente Derivas',
    PendenteAvaliacao: 'Pendente Avaliação',
    Aprovado: 'Aprovado',
    AprovadoComRessalvas: 'Aprovado com Ressalvas',
    Reprovado: 'Reprovado',
    ReprovadoComRessalvas: 'Reprovado com Ressalvas',
  },
  EnumSituacaoEnvioMaterial: {
    Pendente: 'Pendente',
    Programado: 'Programado',
    EmExecucao: 'Em Execução',
    Concluido: 'Concluído',
    Cancelado: 'Cancelado',
    Reagendado: 'Reagendado',
  },
  EnumSituacaoRoteiro: {
    Pendente: 'Pendente',
    Programado: 'Programado',
    EmExecucao: 'Em Execução',
    Concluido: 'Concluído',
    Cancelado: 'Cancelado',
  },
  EnumSituacaoOrdemServico: {
    AguardandoAnaliseTecnica: 'Aguardando Análise Técnica',
    AguardandoAprovacaoGarantia: 'Aguardando Aprovação de Garantia',
    AguardandoEnvioOrcamento: 'Aguardando Envio do Orçamento',
    AguardandoAprovacaoOrcamento: 'Aguardando Aprovação do Orçamento',
    AguardandoPeca: 'Aguardando Peça',
    AguardandoProgramacaoManutencao: 'Aguardando Programação (Manutenção)',
    ProgramacaoTerceiroDia: 'Programação do Terceiro Dia (Manutenção)',
    ProgramacaoSegundoDia: 'Programação do Segundo Dia (Manutenção)',
    ProgramacaoDia: 'Programação do Dia (Manutenção)',
    EmExecucaoManutencao: 'Em Manutenção',
    AguardandoProgramacaoCalibracao: 'Aguardando Programação (Calibração)',
    ProgramacaoCalibracaoTerceiroDia: 'Programação Terceiro Dia (Calibração)',
    ProgramacaoCalibracaoSegundoDia: 'Programação Segundo Dia (Calibração)',
    ProgramacaoCalibracaoDia: 'Programação Primeiro Dia (Calibração)',
    EmExecucaoCalibracao: 'Em Calibração',
    AguardandoAprovacaoCertificado: 'Aguardando Aprovação do Certificado',
    Calibrado: 'Calibrado',
    NaoCalibrado: 'Não Calibrado',
    Reprovado: 'Reprovado pelo Orçamento',
    //AguardandoPlanoInspecao: 'Aguardando Plano de Inspeção', // virou situação auxiliar
    EmExecucao: 'Em Execução',
    SemMovimento: 'Sem Movimento',
  },
  EnumTipoContrato: {
    ContratoAberto: 'Contrato Aberto',
    ContratoFechado: 'Contrato Fechado',
    ClienteEspecial: 'Cliente Especial',
    Aditivo: 'Aditivo',
  },
  EnumClassificacaoClausulaContratual: {
    ContratoAberto: 'Contrato Aberto',
    ContratoFechado: 'Contrato Fechado',
    ClienteEspecial: 'Cliente Especial',
    Aditivo: 'Aditivo',
  },
  EnumSituacaoContrato: {
    EmNegociacao: 'Em Negociação',
    AguardandoAprovacao: 'Aguardando Aprovação',
    PreAssinado: 'Pré Assinado',
    Assinado: 'Assinado',
    Reprovado: 'Reprovado',
    Cancelado: 'Cancelado',
    Encerrado: 'Encerrado',
    AguardandoEnvioParaCliente: 'Aguardando Envio para o Cliente',
    AguardandoValidacaoKeL: 'Aguardando Validação K&L',
  },
  EnumTipoAprovacaoAnaliseTecnica: {
    Contrato: 'Contrato',
    Garantia: 'Garantia',
    Orcamento: 'Orçamento',
    Automatica: 'Automática',
  },
  EnumTipoPonto: {
    ValorFixo: 'Valor Fixo',
    Percentual: 'Percentual',
  },
  EnumOrigemPonto: {
    Modelo: 'Modelo',
    Instrumento: 'Instrumento',
    TipoInstrumento: 'Tipo Instrumento',
  },
  EnumCampoAdicionalTipoCampo: {
    Numerico: 'Numérico',
    Texto: 'Texto',
    Data: 'Data',
    Angulo: 'Ângulo',
    Booleano: 'Booleano',
    ListaEscolhaUnica: 'Lista Escolha Única',
    ListaMultiplaEscolha: 'Lista Múltipla Escolha',
  },
  EnumCampoAdicionalEntidade: {
    Instrumentos: 'Instrumentos',
    Participantes: 'Participantes ',
    VerificacaoIntermediaria: 'Verificação Intermediária',
  },
  EnumCampoAdicionalEdicaoValor: {
    ModeloInstrumento: 'Modelo e Instrumento',
    SomenteModelo: 'Somente Modelo',
  },
  EnumContatoResponsabilidade: {
    Certificado: 'Certificado',
    NotaFiscal: 'Notas Fiscais',
    Orcamento: 'Orçamento',
    Contrato: 'Contrato',
  },
  EnumPerfilUsuario: {
    Colaborador: 'Colaborador',
    Usuario: 'Usuário',
  },
  EnumTipoArredondamentoServico: {
    NaoArredondar: 'Não Arredondar',
    ArredondarParaCima: 'Arredondar para Cima',
    ArredondarParaBaixo: 'Arredondar para Baixo',
  },
  EnumTipoArrendodamentoUnidadeMedida: {
    NaoArredondar: 'Não Arredondar',
    ZeroDecimais: '0 Decimais',
    UmDecimal: '1 Decimail',
    DoisDecimais: '2 Decimais',
    TresDecimais: '3 Decimais',
    QuatroDecimais: '4 Decimais',
    CincoDecimais: '5 Decimais',
    SeisDecimais: '6 Decimais',
    SeteDecimais: '7 Decimais',
    OitoDecimais: '8 Decimais',
    NoveDecimais: '9 Decimais',
  },
  EnumClassificacaoDocumento: {
    Empresarial: 'Empresarial',
    Pessoal: 'Pessoal',
  },
  EnumTipoPrazoAcordado: {
    Dias: 'Dias',
    DiasUteis: 'Dias Úteis',
  },
  EnumLocalManutencao: {
    Interna: 'Interna',
    Externa: 'Externa',
  },
  EnumTipoManutencao: {
    Preventiva: 'Preventiva',
    Corretiva: 'Corretiva',
    Preditiva: 'Preditiva',
  },
  EnumGeneroColaborador: {
    Masculino: 'Masculino',
    Feminino: 'Feminino',
  },
  instrumentotipomaterial: {
    Aco: 'Aço',
    Vidro: 'Vidro',
    Ceramica: 'Cerâmica',
    Aluminio: 'Alumínio',
    Plastico: 'Plástico',
  },
  EnumFormulaValidacaoCriterioValidacao: {
    SomenteErro: 'Somente Erro',
    SomenteIncerteza: 'Somente Incerteza',
    ErroIncertezaCombinado: 'Erro Incerteza Combinado',
    Formula: 'Fórmula',
  },
  EnumTipoReferencia: {
    ValorAbsoluto: 'Valor Absoluto',
    PorcentagemPontoLido: 'Porcentagem do Ponto Lido',
    PorcentagemFundoEscala: 'Porcentagem do Fundo de Escala',
    MenorQue: 'Menor Que',
    MaiorQue: 'Maior Que',
    EntreDoisValores: 'Entre Dois Valores',
  },
  EnumTipoCMC: {
    Formula: 'Fórmula',
    ValorFixo: 'Valor Fixo',
    Percentual: 'Percentual',
    Faixa: 'Faixa',
  },
  EnumTipoFeriado: {
    Fixo: 'Fixo',
    Movel: 'Móvel',
  },
  EnumNivelFeriado: {
    Federal: 'Federal',
    Estadual: 'Estadual',
    Municipal: 'Municipal',
    Nacional: 'Nacional',
  },
  EnumTipoCadastro: {
    UnidadeMedida: 'Unidade de Medida',
    TipoInstrumento: 'Tipo de Instrumento',
    ModeloInstrumento: 'Modelo de Instrumento',
    Outros: 'Outros',
  },
  EnumStatus: {
    Aberto: 'Aberto',
    Encerrado: 'Encerrado',
    Reprovado: 'Reprovado',
    EmAnalise: 'Em Análise',
  },
  EnumValidacaoCertificado: {
    SemValidacao: 'Sem validação',
    ClienteComCriterioCliente: 'Cliente com critério do cliente',
    ClienteComCriterioClienteOuGenerico:
      'Cliente com critério do cliente ou genérico',
    ContaComCriterioCliente: 'Conta com critério do cliente',
    ContaComCriterioClienteOuGenerico:
      'Conta com critério do cliente ou genérico',
  },
  EnumCatetegoriaDuvidaSolicitacaoParticipante: {
    Instrumentos: 'Instrumentos',
    Certificados: 'Certificados',
    OrdensServicos: 'Ordens de Serviços',
    ServicosInstalacoesCliente: 'Serviços nas Instalações do Cliente',
    Orcamento: 'Orçamento',
    Contrato: 'Contrato',
  },
  EnumSubCategoriaDuvidaSolicitacaoParticipanteInstrumento: {
    ManutencaoRealizada: 'Manutenção Realizada',
    FaltandoAcessorio: 'Faltando Acessório',
    FuncionamentoAnormalAvaria: 'Funcionamento Anormal / Avaria',
    Certificado: 'Certificado',
  },
  EnumSubCategoriaDuvidaSolicitacaoParticipanteCertificado: {
    InformacaoEquipamento: 'Informações de Equipamentos',
    Solicitante: 'Solicitante',
    Resultados: 'Resultados',
    CriterioAceitacao: 'Critérios de Aceitação',
  },
  EnumSubCategoriaDuvidaSolicitacaoParticipanteOrdemServico: {
    Prazo: 'Prazo',
    Embalagem: 'Embalagem',
    Coleta: 'Coleta',
    Fiscal: 'Fiscal',
    SeloCalibracao: 'Selo de Calibração',
  },
  EnumSubCategoriaDuvidaSolicitacaoParticipanteServicoCampo: {
    DataAgendamento: 'Data de Agendamento',
    RetornoSolicitacao: 'Retorno de Solicitação',
    DisponibilidadeCertificado: 'Disponibilidade de Certificado',
    LiberacaoAcesso: 'Liberação de Acesso',
    RetornoConclusaoServico: 'Retorno / Conclusão de Serviço',
    AtendimentoTecnico: 'Atendimento Técnico',
  },
  EnumSubCategoriaDuvidaSolicitacaoParticipanteOrcamento: {
    PrazoRetorno: 'Prazo de Retorno',
    Duvida: 'Dúvida',
  },
  EnumSubCategoriaDuvidaSolicitacaoParticipanteContrato: {
    SolicitacaoVisita: 'Solicitação de Visita',
    SolicitacaoTreinamento: 'Solicitação de Treinamento',
    RetornoPropostaAtualizazao: 'Retorno de Proposta / Atualização',
    AtualizacaoRequisito: 'Atualização de Requisito',
  },
  EnumStatusDuvidaSolicitacaoParticipante: {
    EmAberto: 'Em Aberto',
    EmAnalise: 'Em Análise',
    EmProcessoResolucao: 'Em Processo de Resolução',
    Resolvido: 'Resolvido',
    Concluido: 'Concluído',
  },
  EnumSituacaoVerificacao: {
    EmAndamento: 'Em andamento',
    NaoValidado: 'Não validado',
    Aprovada: 'Aprovada',
    Reprovada: 'Reprovada',
  },
  EnumModoExibicaoDescricaoContrato: {
    Nome: 'Nome',
    NomeMaisDescricao: 'Nome + Descrição',
  },
  EnumFonteIncerteza: {
    CaracteristicasObjetoMedido: 'Características do Objeto Medidora',
    InfluenciasExternas: 'Influências Externas',
    NaturezaMensurando: 'Natureza do Mensurando',
    PadraoReferencia: 'Padrão de Referência',
    ProcedimentoMedicao: 'Procedimentos de Medição',
    ResultadosObtidos: 'Resultados Obtidos',
  },
  EnumTagsContrato: {
    NomeCliente: 'Nome do Cliente',
    ValorContrato: 'Valor do Contrato',
    ValorContratoPorExtenso: 'Valor do Contrato Por Extenso',
    DataContrato: 'Data do Contrato',
  },
  EnumTipoProcedimento: {
    Calibracao: 'Calibração',
    Ensaio: 'Ensaio',
  },
  EnumInstrumentoPadraoValidacao: {
    SemCertificado: 'Sem Certificado',
    CertificadoVencido: 'Certificado Vencido',
    VerificacaoIntermediariaVencida: 'Verificacao Intermediária Vencida',
    Valido: 'Valido',
  },
  EnumMetodo: {
    GET: 'Listar',
    POST: 'Cadastrar',
    PUT: 'Editar',
    DELETE: 'Remover',
  },
  EnumTipoPermissao: {
    Desabilitado: 'Desabilitado',
    Anonimizado: 'Anonimizado',
  },
  EnumFuncionalidade: {
    CargosFuncao: 'Cargos e Funções',
    ClassificacaoInstrumento: 'Classificações de Instrumentos',
    AreaAcreditacao: 'Áreas de Acreditações',
    Colaborador: 'Colaboradores',
    CriterioAceitacao: 'Critérios de Aceitação',
    Feriado: 'Feriados',
    GrandezaFisica: 'Grandezas Físicas',
    Instrumento: 'Instrumentos',
    LocalEstocagem: 'Locais de Estocagem',
    LocalExecucao: 'Locais de Execução',
    Marca: 'Marcas',
    ModeloInstrumento: 'Modelos de Instrumento',
    Municipio: 'Município',
    OcorrenciaPadrao: 'Ocorrências Padrão',
    Participante: 'Participantes',
    Permissoes: 'Permissões',
    Procedimento: 'Procedimentos',
    Setor: 'Setores',
    TipoInstrumento: 'Tipos de Instrumento',
    TipoOcorrencia: 'Tipos de Ocorrência',
    TipoSetores: 'Tipos de Setores',
    UnidadeFederativa: 'Unidade Federativa',
    UnidadeMedida: 'Unidades de Medidas',
    Veiculo: 'Veículos',
    ViaCep: 'Via CEP',
    Escopo: 'Escopos de Serviços',
    Incerteza: 'Incertezas',
    ProcedimentoEletronico: 'Procedimentos Eletrônicos',
    Roteiro: 'Roteiros de Envio de Material',
    Conta: 'Parâmetros',
    EmailTemplate: 'Modelos de E-mails',
    Checklist: 'Checklists',
    ClassificacaoFiscal: 'Classificações Fiscais',
    CondicaoPagamento: 'Condições de Pagamentos',
    UnidadeMedidaConversao: 'Conversor de Unidades',
    DefinicaoEquipamentoMedicao: 'Definição de Equipamento para Medição',
    DuvidaSolicitacaoParticipante: 'Dúvidas/Solicitações de Clientes',
    Embalagem: 'Embalagens',
    Item: 'Itens de Estoque',
    Papel: 'Papéis',
    Permissao: 'Permissões',
    Prioridade: 'Prioridades',
    RegistroInformativo: 'Registros de Informativos',
    InstrumentoRegistroManutencaoTrocaPeca: 'Registros de Manutenção',
    ServicoCalibracao: 'Serviços de Calibração',
    ServicoManutencao: 'Serviços de Manutenção',
    SolicitacaoCadastro: 'Solicitações de Cadastros',
    TipoCalibracao: 'Tipos de Calibração',
    TipoComentarioOrcamento: 'Tipos de Comentários',
    TipoEnvio: 'Tipos de Envio',
    Mensagem: 'Mensagens de Orçamento',
    Orcamento: 'Orçamentos',
    ClausulaContratual: 'Cláusulas Contratuais',
    Contrato: 'Contratos',
    Acreditacao: 'Acreditações',
    Certificado: 'Certificados',
    CertificadoInterno: 'Certificados Internos',
    CertificadoTerceiro: 'Certificados de Terceiros',
    EscopoServico: 'Escopos de Serviços',
    Formula: 'Fórmulas',
    Incertezas: 'Incertezas',
    VerificacaoIntermediaria: 'Verificações Intermediárias',
    Operacao: 'Operações',
    OrdemServico: 'Ordens de Serviço',
    EnvioMaterial: 'Envios de Materiais',
    RoteiroMotivoCancelamento: 'Motivos de Cancelamento de Roteiro',
    RoteiroEnvioMaterial: 'Roteiros de Envio de Material',
    RelatorioCategoria: 'Categorias de Relatórios',
    Relatorio: 'Modelos de Relatórios',
    CampoAdicional: 'Campos Adicionais',
    Parametro: 'Parâmetros',
    Usuarios: 'Usuários',
    CategoriaItem: 'Categorias de Itens',
    TipoDocumento: 'Tipos de Documentos',
    TipoItem: 'Tipos de itens de Estoque',
    EntidadeElemento: 'Botão Teste',
    Agendamento: 'Agendamentos',
    ModeloRelatorio: 'Modelo de Relatório',
    Endpoint: 'Endpoints',
    Kanban: 'Kanban',
    Programacao: 'Programações',
    EmExecucao: 'Em Execução',
    CargoFuncao: 'Cargos e Funções',
    InstrumentoOcorrencia: 'Instrumentos Registros de Ocorrências',
    InstrumentoEmprestimo: 'Instrumentos Empréstimo para Outro Setor',
    InstrumentoMovimentacao: 'Instrumentos Movimentação para Outro Setor',
    TipoVisita: 'Tipos de Visita',
    Endpoints: 'Endpoints',
  },
  EnumFuncionalidadeAcao: {
    Listar: 'Listar',
    Inserir: 'Inserir',
    Editar: 'Editar',
    Deletar: 'Deletar',
    Desabilitado: 'Desabilitado',
    Anonimizado: 'Anonimizado',
    Excluir: 'Excluir',
    AjustarValores: 'Ajustar Valores',
    AlterarSenha: 'Alterar Senha',
    AlterarSituacao: 'Alterar Situação',
    AlterarTecnico: 'Alterar Técnico',
    AnaliseCritica: 'Analise Crítica',
    AnaliseTecnica: 'Analise Técnica',
    Aprovar: 'Aprovar',
    AprovarGarantia: 'Aprovar Garantia',
    AssociarUsuario: 'Associar Usuário',
    Ativar: 'Ativar',
    Ausencia: 'Ausência',
    AvaliarCertificado: 'Avaliar Certificado',
    Calibracao: 'Calibração',
    CancelarESubstituir: 'Cancelar e Substituir',
    Cancelar: 'Cancelar',
    Comentar: 'Comentar',
    Configurar: 'Configurar',
    Confirmar: 'Confirmar',
    CriarAditivo: 'Criar Aditivo',
    CriarCopiaParaCliente: 'Criar Cópia para Cliente',
    CriarRevisao: 'Criar Revisão',
    DefinirDerivas: 'Definir Derivas',
    Duplicar: 'Duplicar',
    EditarComoCliente: 'Editar como Cliente',
    Encerrar: 'Encerrar',
    EncerrarComentario: 'Encerrar Comentário',
    Entrega: 'Entrega',
    EnviarAoCliente: 'Enviar ao Cliente',
    ExcluirComentario: 'Excluir Comentário',
    Executar: 'Executar',
    GerarOrcamento: 'Gerar Orçamento',
    GerarPDF: 'Gerar PDF',
    GerarQrCode: 'Gerar QR Code',
    Imprimir: 'Imprimir',
    Liberar: 'Liberar',
    ListarComentario: 'Listar Comentário',
    Manutencao: 'Manutenção',
    MovimentarOutroSetor: 'Movimentar Outro Setor',
    MovimentarProximoSetor: 'Movimentar Próximo Setor',
    MovimentarSituacao: 'Movimentar Situação',
    OrdemServicoParcial: 'Ordem Serviço Parcial',
    Ordenar: 'Ordenar',
    PlanoInspecao: 'Plano Inspeçao',
    PreAssinar: 'Pré Assinar',
    Assinar: 'Assinar',
    Programar: 'Programar',
    Redefinir: 'Redefinir',
    RegistrarEvento: 'Registrar Evento',
    Renovar: 'Renovar',
    Reprovar: 'Reprovar',
    ResponderComentario: 'Responder Comentário',
    Visualizar: 'Visualizar',
    VisualizarHistorico: 'Visualizar Histórico',
    VisualizarRevisao: 'Visualizar Revisão',
  },
  EnumTipoEnvioMaterial: {
    Entrega: 'Entrega',
    Recebimento: 'Recebimento',
    RecebimentoSemAgendamento: 'Recebimento Sem Agendamento',
    ClienteLaboratorio: 'Do cliente para laboratório',
    LaboratorioCliente: 'Do laboratório para cliente',
    LaboratorioSemAgendamento: 'Do cliente para laboratório sem agendamento',
  },
  EnumEnvioMaterial: {
    Pendente: 'Pendente',
    Programado: 'Programado',
    EmExecucao: 'Em Execucao',
    Concluido: 'Concluido',
    Cancelado: 'Cancelado',
    Reagendado: 'Reagendado',
  },
  EnumIndicadorDeriva: {
    MaiorDasMedias: 'Maior das médias',
    MediaDasMedias: 'Médias das médias',
    DesvioPadraoDasMedias: 'Desvio padrão das médias',
    ValorFixo: 'Valor fixo',
    PorcentagemDoPonto: 'Porcentagem do ponto',
  },
  EnumGatilhoMovimentacao: {
    SemGatilho: 'Sem Gatilho',
    OrdemServicoCriada: 'Ordem de Serviço Criada',
    ManutencaoFinalizada: 'Manutenção Finalizada',
    CalibracaoFinalizada: 'Calibração Finalizada',
    PlanoInspecaoFinalizado: 'Plano de Inspeção Finalizado',
    FaturamentoFinalizado: 'Faturamento Finalizado',
    EntregaFinalizada: 'Entrega Finalizada',
  },
  EnumTipoRelatorio: {
    Sistema: 'Sistema',
    Usuario: 'Usuário',
    CertificadoPadrao: 'Certificado Padrão',
  },
  EnumUnidadeMedidaSistema: {
    Decimal: 'Decimal',
    Sexagesimal: 'Sexagesimal',
  },

  EnumTabelaReferencia: {
    DescricaoPontoCalibracao: 'Descrição do Ponto de Calibração',
    PontoCorrigidoPadrao: 'Ponto Corrigido do Padrão',
    SomatorioPontosPadrao: 'Somatório dos Pontos do Padrão',
    SomatorioPontosPadraoTorque: 'Somatório dos Pontos do Padrão(Torque)',
  },
  EnumTipoEmail: {
    OrdemServicoCriada: 'Ordem de Serviço Criada',
    OrcamentoParaAprovacao: 'Orçamento para Aprovação',
    OrcamentoAprovado: 'Orçamento Aprovado',
    ContratoParaAprovacao: 'Contrato para Aprovação',
    ContratoAprovado: 'Contrato Aprovado',
    CertificadoLiberado: 'Certificado Liberado',
  },

  EnumVariaveisTemplateEmail: {
    listaValores: 'Lista de Valores',
    nomeCliente: 'Nome Cliente',
    nomeContato: 'Nome Contato',
    codigoOrdemServico: 'Código OS',
    dataRecebimento: 'Data Recebimento',
    tipoEnvio: 'Tipo Envio',
    ordemServico: 'Ordem Serviço',
    id: 'Id',
    codigo: 'Código',
    cliente: 'Cliente',
    emissao: 'Emissão',
    valorTotal: 'Valor Total',
    dataInicio: 'Data Início',
    solicitante: 'Solicitante',
    contratante: 'Contratante',
    contato: 'Contato',
    notaFiscal: 'Nota Fiscal',
    localExecucao: 'Local Execução',
    modeloInstrumento: 'Modelo Instrumento',
    nomeResponsavel: 'Nome Responsável',
    contratanteNome: 'Contratante Nome',
    contratanteApelido: 'Contratante Apelido',
    solicitanteNome: 'Solicitante Nome',
    solicitanteApelido: 'Solicitante Apelido',
    participanteContaResponsavel: 'Participante Conta Responsável',
    contaResponsavelNome: 'Conta Responsável Nome',
    contaResponsavelApelido: 'Conta Responsável Apelido',
    instrumentos: {
      id: 'Instrumento Id',
      instrumentos: 'Instrumentos',
      codigo: 'Instrumento Código',
      tipoInstrumento: 'Instrumento Tipo Instrumento',
      marca: 'Instrumento Marca',
      modelo: 'Instrumento Modelo',
      numeroSerie: 'Instrumento Número de Série',
      numeroItem: 'Instrumento Número Item',
      modeloInstrumento: 'Instrumento Modelo Instrumento',
      codigoCertificado: 'Instrumento Código Certificado',
    },
  },

  EnumFormulaValidacao: {
    SomenteErro: 'Somente Erro',
    SomenteIncerteza: 'Somente Incerteza',
    ErroIncertezaCombinado: 'Erro Incerteza Combinado',
    Formula: 'Fórmula',
  },
  EnumCalibracaoNivel: {
    Calibracao: 'Calibração',
    Terceiro: 'Terceiro',
    Interna: 'Interna',
    VerificacaoIntermediaria: 'Verificação Intermediária',
  },
  EnumLidoNaoLido: {
    Lido: 'Lido',
    NaoLido: 'Não Lido',
  },

  EnumSituacaoAgendamento: {
    EmElaboração: { enum: 'Em Elaboração', icon: 'fileClockOutline' },
    AguardandoAnaliseTecnica: {
      enum: 'Aguardando Análise Técnica',
      icon: 'clipboardEditOutline',
    },
    AguardandoOrcamento: {
      enum: 'Aguardando Orçamento',
      icon: 'fileDocumentAlertOutline',
    },
    AguardandoAprovacaoOrcamento: {
      enum: 'Aguardando Aprovação Orçamento',
      icon: 'currencyUsd',
    },
    AguardandoProgramacao: {
      enum: 'Aguardando Programação',
      icon: 'clockOutline',
    },
    AguardandoConfirmacao: {
      enum: 'Aguardando Confirmação',
      icon: 'clipboardTextOutline',
    },
    Programado: { enum: 'Programado', icon: 'numeric1CircleOutline' },
    AguardandoAnaliseCritica: {
      enum: 'Aguardando Análise Crítica',
      icon: 'numeric3CircleOutline',
    },
    Concluido: { enum: 'Concluído', icon: 'checkDecagramOutline' },
    ReprovadoPeloOrcamento: {
      enum: 'Reprovado pelo Orçamento',
      icon: 'currencyUsdOff',
    },
    Cancelado: { enum: 'Cancelado', icon: 'closeCircleOutline' },
    EmExecucao: { enum: 'Em Execução', icon: 'numeric2CircleOutline' },
  },

  EnumTipoAprovacao: {
    Contrato: 'Contrato',
    Garantia: 'Garantia',
    Orcamento: 'Orçamento',
    Automatica: 'Automática',
  },

  EnumManutencaoPecaCalibracao: {
    Calibracao: 'Calibração',
    Manutencao: 'Manutenção',
    Peca: 'Peça',
  },

  EnumModulo: {
    CadastroBasico: 'Cadastro Básico',
    Calculo: 'Cálculo',
    Orcamento: 'Orçamento',
    Contrato: 'Contrato',
    OrdemServico: 'Ordem de Serviço',
    Expedicao: 'Expedição',
    Outros: 'Outros',
  },
  resposta_requisito: {
    sim: 'Sim',
    nao: 'Não',
    nao_aplica: 'Não se Aplica'
  },
};
