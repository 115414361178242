import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.dias_envio_material.segunda'),
    value: 'flagEnvioMaterialSegundaFeira',
  },
  {
    text: i18n.t('enums.dias_envio_material.terca'),
    value: 'flagEnvioMaterialTercaFeira',
  },
  {
    text: i18n.t('enums.dias_envio_material.quarta'),
    value: 'flagEnvioMaterialQuartaFeira',
  },
  {
    text: i18n.t('enums.dias_envio_material.quinta'),
    value: 'flagEnvioMaterialQuintaFeira',
  },
  {
    text: i18n.t('enums.dias_envio_material.sexta'),
    value: 'flagEnvioMaterialSextaFeira',
  },
];
