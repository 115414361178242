<template>
  <div>
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo"
    >
      <span v-if="!tooltip" class="label">{{ label }}</span>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ label }}</span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>

      <span v-if="obrigatorio && label" class="obrigatorio ml-1">*</span>
    </span>
    <div
      class="input-file-drag-n-drop"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div>
        <div class="text-center">
          <v-icon>$cloudArrowUp</v-icon>
        </div>
        <div class="text-center">
          {{ labelTexto }}
        </div>
        <div class="d-flex align-center mx-10 my-3">
          <v-divider />
          <span class="mx-4">ou</span>
          <v-divider />
        </div>
        <div class="text-center">
          <botao-padrao @click="abrirFileSystem">
            {{ labelBotao }}
          </botao-padrao>
        </div>
      </div>
      <input
        :id="id"
        :accept="accept"
        type="file"
        class="input-file"
        name=""
        :multiple="multiplos"
        @change="inputUpload"
      />
    </div>
    <v-list v-if="conteudo.length > 0" dense outlined class="mt-3">
      <v-list-item-group>
        <template v-for="(item, index) in conteudo">
          <v-list-item :key="index">
            <template v-slot:default>
              <v-list-item-content>
                <div class="d-flex align-center justify-space-between">
                  <span>{{ item.nome }}</span>
                  <div class="d-flex">
                    <botao-padrao text color="primary" @click="download(item)">
                      <v-icon class="mr-2"> $download </v-icon>
                      {{ $t('geral.botoes.download') }}
                    </botao-padrao>
                    <botao-padrao
                      text
                      class="mr-2"
                      color="red"
                      @click="remover(item)"
                    >
                      <v-icon class="mr-2"> $delete </v-icon>
                      {{ $t('geral.botoes.remover') }}
                    </botao-padrao>
                  </div>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-divider v-if="index < conteudo.length - 1" :key="index" />
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import BotaoPadrao from '../buttons/BotaoPadrao.vue';
import { InputMixin } from './InputMixin';
import helpers from '@common/utils/helpers';
import _ from 'lodash';
export default {
  name: 'InputFileDragNDrop',
  components: { BotaoPadrao },
  mixins: [InputMixin],
  props: {
    id: {
      type: String,
      default: () => {
        return `input-file-drag-n-drop-${helpers.gerarNumeroAleatorio()}`;
      },
    },
    accept: {
      type: String,
      default: '*',
    },
    tooltip: {
      type: String,
      default: '',
    },
    multiplos: {
      type: Boolean,
      default: true,
    },
    labelBotao: {
      type: String,
      default: function () {
        return this.$t('componentes.input_dragdrop.escolha_arquivo');
      },
    },
    labelTexto: {
      type: String,
      default: function () {
        return this.$t('componentes.input_dragdrop.arraste_arquivos');
      },
    },
    preventFileSystem: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      conteudo: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: function () {
      this.conteudo = this.value;
    },
  },
  mounted() {},
  methods: {
    abrirFileSystem() {
      if (this.preventFileSystem) {
        this.$emit('abrir-file-system');
        return;
      }
      document.getElementById(this.id).click();
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      event.preventDefault();
    },
    async drop(event) {
      event.preventDefault();
      let files = event.target.files || event.dataTransfer.files;
      this.conteudo = [];

      for (const arquivo of files) {
        let nomeArquivo = arquivo.name;
        let extensao = nomeArquivo.split('.').pop();

        await helpers.converterBase64(arquivo).then((data) => {
          this.conteudo.push({
            nome: nomeArquivo,
            extensao: extensao,
            base64: data,
          });
        });

        if (!this.multiplos) break;
      }

      if (this.preventFileSystem) {
        this.$emit('drop', _.cloneDeep(this.conteudo));
        this.conteudo = [];
        return;
      }

      this.$emit('input', this.conteudo);
    },
    inputUpload(event) {
      this.drop(event);
    },
    download: function (arquivo) {
      helpers.baixarBase64(arquivo.base64, arquivo.nome);
    },
    remover: function (arquivo) {
      this.conteudo.splice(this.conteudo.indexOf(arquivo), 1);
    },
  },
};
</script>
<style lang="scss">
.input-file-drag-n-drop {
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  padding: 10px;
  background: #f7f7f7;
  .v-icon__svg {
    height: 70px;
    width: 70px;
    fill: #9e9e9e;
  }
  .input-file {
    display: none;
  }
}
</style>
