<template lang="">
  <div class="input-padrao">
    <div
      v-if="label && divider"
      class="d-flex align-end input-label"
    >
      <span class="mr-2">{{ labelComputada }}</span>
      <v-divider />
    </div>
    <div
      v-if="label && !divider"
      class="d-flex align-end"
    >
      <span class="mr-2">{{ labelComputada }}</span>
      <v-tooltip
        v-if="tooltipHelp"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            color="primary"
            v-on="on"
          >
            $alertCircleOutline
          </v-icon>
        </template>
        <span>{{ tooltipHelp }}</span>
      </v-tooltip>
    </div>
    <v-radio-group
      v-model="conteudo"
      :dense="dense"
      :rules="regras"
      :disabled="disabled"
      outlined
      hide-details="auto"
      row
      :class="classGrupo"
    >
      <v-radio
        v-for="opcao in options"
        :key="opcao.value"
        :label="opcao.text"
        :value="opcao.value"
      >
        <template #label>
          <slot
            name="label"
            :opcao="opcao"
          >
            <div
              class="d-flex"
              style="font-size: 14px;"
            >
              {{ opcao.text }}
              <slot
                name="opcao"
                :opcao="opcao"
              />
            </div>
          </slot>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  name: 'InputRadio',
  mixins: [InputMixin],
  props: {
    type: { type: String, default: '' },
    divider: { type: Boolean, default: true },
    options: { type: Array, default: () => [], required: true },
    classGrupo: { type: String, default: 'mt-5' },
    tooltipHelp: { type: String, default: '' },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {},
};
</script>
