export default {
  titulos: {
    listagem: 'Cláusulas Contratuais',
    novo: 'Nova Cláusula Contratual',
    editar: 'Editar Cláusula Contratual',
  },
  cadastro_sucesso: 'Cláusula Contratual salva com sucesso!',
  exclusao_sucesso: 'Cláusula Contratual excluida com sucesso!',
  ordenacao_sucesso: 'Ordenação das cláusulas salva com sucesso!',
  tabela: {
    sequencia: 'Sequência',
    titulo: 'Título',
    classificacao: 'Classificação',
  },
  formulario: {
    sequencia: 'Sequência',
    titulo: 'Título',
    classificacao: 'Classificação',
    descricao: 'Descrição',
    menu: {
      nome_cliente: 'Nome do Cliente',
      valor_contrato: 'Valor do Contrato',
      valor_contrato_extenso: 'Valor do Contrato Por Extenso',
      data_contrato: 'Data do Contrato'
    }
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    titulo: 'Título',
    classificacao: 'Classificação',
  },

};
