import helpers from '@router/helpers';
const menuId = 'categoria-relatorio';

export default [
  helpers.criarRota(menuId, 'categoria-relatorio', menuId, () =>
    import('@/views/modulos/cadastros/categoria_relatorio')
  ),
  helpers.criarRota(
    menuId,
    'categoria-relatorio-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/categoria_relatorio/formulario')
  ),
  helpers.criarRota(
    menuId,
    'categoria-relatorio-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/categoria_relatorio/formulario'),
    true
  ),
];
