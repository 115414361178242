export default {
  titulos: {
    listagem: 'Feriados',
    novo: 'Novo Feriado',
    categoria: 'Categoria',
    editar: 'Editar',
  },

  validacao_dias: {
    validacao_dias_29: 'Não pode ter mais de 29 dias para este mês',
    validacao_dias_30: 'Não pode ter mais de 30 dias para este mês',
    validacao_dias_31: 'Não pode ter mais de 31 dias para este mês',
  },

  filtro: {
    unidade_federativa: 'Unidade Federativa',
    municipio: 'Município',
    dia: 'Dia',
    mes: 'Mês',
    ano: 'Ano',
  },

  form: {
    tipo: 'Tipo',
    nivel: 'Nível',
    unidade_federativa: 'Unidade Federativa',
    municipio: 'Município',
    dia: 'Dia',
    mes: 'Mês',
    ano: 'Ano',
    descricao: 'Descrição',
    descricao_feriado: 'Descrição do feriado',
  },

  exclusao_sucesso: 'Feriado(s) excluído(s) com sucesso',
  cadastro_sucesso: 'Feriado salvo com sucesso!',
  formulario_invalido:
    'É necessário preencher o formulário antes de salvar o registro.',

  listagem: {
    headers: {
      descricao: 'Descrição',
      nivel: 'Nível',
      unidade_federativa: 'Unidade Federativa',
      municipio: 'Município',
      tipo: 'Tipo',
      dia: 'Dia',
      mes: 'Mês',
      ano: 'Ano',
      data: 'Data',
    },
  },
};
