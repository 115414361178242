export default {
  titulos: {
    listagem: 'Grandezas Físicas',
    novo: 'Nova Grandeza Física',
    editar: 'Editar Grandeza Física',
  },
  cadastro_sucesso: 'Grandeza Física salvo com sucesso!',
  exclusao_sucesso: 'Grandeza Física excluído com sucesso!',
  tabela: {
    nome: 'Nome',
  },
  formulario: {
    nome: 'Nome',
    descricao: 'Descrição',
  },
  filtro: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id da Grandeza Física é inválido',
  },
  validacoes: {
    formulario_invalido: 'Verifique os campos do formulário',
  },
};
