import helpers from '@router/helpers';

const menuId = 'tipo-instrumento';

export default [
  helpers.criarRota(menuId, 'tipo-instrumento', menuId, () =>
    import('@/views/modulos/cadastros/tipo_instrumento')
  ),
  helpers.criarRota(
    menuId,
    'tipo-instrumento-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/tipo_instrumento/formulario'),
    false,
    false
  ),
  helpers.criarRota(
    menuId,
    'tipo-instrumento-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_instrumento/formulario'),
    true,
    false
  ),
  helpers.criarRota(
    menuId,
    'tipo-instrumento-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/tipo_instrumento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'tipo-instrumento-pontos',
    `${menuId}/pontos/:id?`,
    () =>
      import('@/views/modulos/cadastros/tipo_instrumento/pontosCalibracoes'),
    true,
    true
  ),
];
