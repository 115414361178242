import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumFuncionalidade.CargosFuncao'),
    value: 'CargoFuncao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ClassificacaoInstrumento'),
    value: 'ClassificacaoInstrumento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Colaborador'),
    value: 'Colaborador',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.CriterioAceitacao'),
    value: 'CriterioAceitacao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Feriado'),
    value: 'Feriado',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.GrandezaFisica'),
    value: 'GrandezaFisica',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Instrumento'),
    value: 'Instrumento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.LocalEstocagem'),
    value: 'LocalEstocagem',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.LocalExecucao'),
    value: 'LocalExecucao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Marca'),
    value: 'Marca',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ModeloInstrumento'),
    value: 'ModeloInstrumento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Municipio'),
    value: 'Municipio',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.OcorrenciaPadrao'),
    value: 'OcorrenciaPadrao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Participante'),
    value: 'Participante',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Permissoes'),
    value: 'Permissoes',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Procedimento'),
    value: 'Procedimento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Setor'),
    value: 'Setor',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoInstrumento'),
    value: 'TipoInstrumento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoOcorrencia'),
    value: 'TipoOcorrencia',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoSetores'),
    value: 'TipoSetores',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.UnidadeFederativa'),
    value: 'UnidadeFederativa',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.UnidadeMedida'),
    value: 'UnidadeMedida',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Veiculo'),
    value: 'Veiculo',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ViaCep'),
    value: 'ViaCep',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.CategoriaItem'),
    value: 'CategoriaItem',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Escopo'),
    value: 'Escopo',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Incerteza'),
    value: 'Incerteza',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ProcedimentoEletronico'),
    value: 'ProcedimentoEletronico',
  },  
  {
    text: i18n.t('enums.EnumFuncionalidade.EmailTemplate'),
    value: 'EmailTemplate',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Checklist'),
    value: 'Checklist',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ClassificacaoFiscal'),
    value: 'ClassificacaoFiscal',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.CondicaoPagamento'),
    value: 'CondicaoPagamento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.UnidadeMedidaConversao'),
    value: 'UnidadeMedidaConversao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.DefinicaoEquipamentoMedicao'),
    value: 'DefinicaoEquipamentoMedicao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.DuvidaSolicitacaoParticipante'),
    value: 'DuvidaSolicitacaoParticipante',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Embalagem'),
    value: 'Embalagem',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Item'),
    value: 'Item',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Papel'),
    value: 'Papel',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Permissao'),
    value: 'Permissao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Prioridade'),
    value: 'Prioridade',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.RegistroInformativo'),
    value: 'RegistroInformativo',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.InstrumentoRegistroManutencaoTrocaPeca'),
    value: 'InstrumentoRegistroManutencaoTrocaPeca',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ServicoCalibracao'),
    value: 'ServicoCalibracao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ServicoManutencao'),
    value: 'ServicoManutencao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.SolicitacaoCadastro'),
    value: 'SolicitacaoCadastro',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoCalibracao'),
    value: 'TipoCalibracao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoComentarioOrcamento'),
    value: 'TipoComentarioOrcamento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoEnvio'),
    value: 'TipoEnvio',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Mensagem'),
    value: 'Mensagem',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Orcamento'),
    value: 'Orcamento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.ClausulaContratual'),
    value: 'ClausulaContratual',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Contrato'),
    value: 'Contrato',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Acreditacao'),
    value: 'Acreditacao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Certificado'),
    value: 'Certificado',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.CertificadoInterno'),
    value: 'CertificadoInterno',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.CertificadoTerceiro'),
    value: 'CertificadoTerceiro',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Formula'),
    value: 'Formula',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.VerificacaoIntermediaria'),
    value: 'VerificacaoIntermediaria',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Operacao'),
    value: 'Operacao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.OrdemServico'),
    value: 'OrdemServico',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.EnvioMaterial'),
    value: 'EnvioMaterial',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.RoteiroMotivoCancelamento'),
    value: 'RoteiroMotivoCancelamento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.RoteiroEnvioMaterial'),
    value: 'RoteiroEnvioMaterial',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.RelatorioCategoria'),
    value: 'RelatorioCategoria',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Relatorio'),
    value: 'Relatorio',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.CampoAdicional'),
    value: 'CampoAdicional',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Usuarios'),
    value: 'Usuarios',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoDocumento'),
    value: 'TipoDocumento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoItem'),
    value: 'TipoItem',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Agendamento'),
    value: 'Agendamento',
  },  
  {
    text: i18n.t('enums.EnumFuncionalidade.Endpoint'),
    value: 'Endpoint',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.EmExecucao'),
    value: 'EmExecucao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidade.Kanban'),
    value: 'Kanban',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.Programacao'),
    value: 'Programacao',
  },  
  {
    text: i18n.t('enums.EnumFuncionalidade.AreaAcreditacao'),
    value: 'AreaAcreditacao',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.CargoFuncao'),
    value: 'CargoFuncao',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.Parametro'),
    value: 'Parametro',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.InstrumentoOcorrencia'),
    value: 'InstrumentoOcorrencia',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.InstrumentoEmprestimo'),
    value: 'InstrumentoEmprestimo',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.InstrumentoMovimentacao'),
    value: 'InstrumentoMovimentacao',
  }, 
  {
    text: i18n.t('enums.EnumFuncionalidade.TipoVisita'),
    value: 'TipoVisita',
  }, 

];

