<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="tooltip ? on : null" class="'pl-0'">
          <v-btn
            :depressed="depressed"
            :disabled="disabled"
            :outlined="outlined"
            :text="text"
            :color="color"
            :width="width"
            v-bind="attrs"
            :class="classes + retiraPaddingLeft"
            :loading="carregando"
            :style="btn100 ? 'width: 100% !important' : null"
            @click="onClick"
            @keyup.native="onKeyup"
          >
            <template v-for="slot in slots" v-slot:[`${slot}`]>
              <slot :name="slot" />
              <span :key="slot" />
            </template>
          </v-btn>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    tooltip: { type: String, default: null },
    depressed: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    botaoAdicionarInput: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
    retiraPaddingLeft: { type: String, default: '' },
    borderNone: { type: Boolean, default: false },
    alturaBotaoPequeno: { type: Boolean, default: false },
    semCapitalize: { type: Boolean, default: true },
    carregando: { type: Boolean, default: false },
    width: { type: [Number, String], default: 'auto' },
    btn100: { type: Boolean, default: false },
  },
  computed: {
    slots: function () {
      let slots = [];
      slots.push(...Object.keys(this.$slots));
      return slots;
    },
    classes: function () {
      let classes = [];

      if (this.botaoAdicionarInput) {
        classes.push('botao-padrao-adicionar-input');
      } else {
        classes.push('botao-padrao');
      }

      if (this.semCapitalize) {
        classes.push('sem-capitalize');
      }

      if (this.borderNone) {
        classes.push('border-none ');
      }

      if (this.alturaBotaoPequeno) {
        classes.push(' altura-botao-pequeno ');
      }

      return classes.join(' ');
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onKeyup(e) {
    if (e.keyCode === 113) {

      this.$emit('f2');
    }
  },
  },
};
</script>
<style lang="scss">
.botao-padrao {
  text-transform: capitalize;
  padding: 0 12px !important;
  min-width: 0px !important;
  border-radius: 8px !important;
  .v-icon__svg {
    height: 18px;
    width: 18px;
  }
}
.botao-padrao-adicionar-input {
  text-transform: capitalize;
  padding: 0 12px !important;
  min-width: 0px !important;
  height: 40px !important;
  border-radius: 0px 8px 8px 0px !important;
  .v-icon__svg {
    height: 18px;
    width: 18px;
  }
}

.botao-padrao-fill {
  .botao-padrao {
    width: 100% !important;
    .v-btn__content {
      justify-content: left;
    }
  }
}

.botao-padrao-sem-borda {
  .botao-padrao {
    border-radius: 0px !important;
  }
}

.sem-capitalize {
  text-transform: none;
}

.border-none {
  border: none !important;
}
.altura-botao-pequeno {
  height: 28px !important;
  width: 90px !important;
}
</style>
