import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_pessoa.pessoa_juridica'),
    value: 'PessoaJuridica',
  },
  {
    text: i18n.t('enums.tipo_pessoa.pessoa_fisica'),
    value: 'PessoaFisica',
  },
];
