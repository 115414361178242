import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumTipoAprovacao.Contrato'),
    value: 'Contrato',
  },
  {
    text: i18n.t('enums.EnumTipoAprovacao.Garantia'),
    value: 'Garantia',
  },
  {
    text: i18n.t('enums.EnumTipoAprovacao.Orcamento'),
    value: 'Orcamento',
  },
  {
    text: i18n.t('enums.EnumTipoAprovacao.Automatica'),
    value: 'Automatica',
  },
];
