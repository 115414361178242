export default {
  nome: 'Nome',
  tag: 'Tag',
  buscar: 'Buscar',
  tabela: {
    nome: 'Nome',
    area: 'Área de Acreditação',
    setor: 'Setor Responsável',
  },
  titulo_modal: 'Tipos de Instrumentos',
};
