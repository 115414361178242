<template>
  <v-dialog
    v-model="aberto"
    class="modal-padrao"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :content-class="`modal-padrao ${posicaoModal}`"
    :persistent="persistente"
    @click:outside="emitirClickFora"
    @input="emitirInput"
    @keydown="emitirKeyDown"
    @keyup.esc="fecharModal"
  >
    <v-card class="v-card__modal">
      <v-card-title
        class="flex-column"
        style="background-color: rgb(53, 80, 123); color: white"
      >
        <div
          :class="`w-100 ${estiloTitulo}`"
          :style="`height: ${subtitulo ? '50px' : '25px'};`"
        >
          <div :class="{ subtitulo: 'd-flex flex-column' }">
            <div>
              <slot name="titulo">
                <span>{{ titulo }}</span>
              </slot>
            </div>
            <span v-if="subtitulo" class="text-subtitle-2">
              {{ subtitulo }}</span
            >
          </div>
          <botao-padrao
            v-if="!persistente"
            text
            color="secondary"
            class="ml-auto"
            @click="fecharModal"
          >
            <v-icon style="color: white"> $close </v-icon>
          </botao-padrao>
        </div>
      </v-card-title>
      <div :class="`corpo-modal ${gerarScroll ? 'overflow-auto' : 'overflow-visible'}`">
        <slot />
      </div>
    </v-card>
    <v-footer class="footer">
      <div
        v-if="mostrarBotoesComAtualizar"
        :class="`w-100 d-flex align-center justify-space-between ${
          EhTelaPequena ? 'flex-column' : ''
        }`"
      >
        <slot name="botoes">
          <div>
            <botao-padrao
              color="primary"
              :class="{ 'mr-2': !EhTelaPequena }"
              :width="EhTelaPequena ? 240 : 'auto'"
              @click="emitirAtualizar"
            >
              <v-icon>$mdiRefresh</v-icon>
              {{ $t('geral.botoes.atualizar') }}
            </botao-padrao>
          </div>
          <div class="d-flex">
            <botao-padrao
              v-if="mostrarBotaoCancelar"
              outlined
              color="secondary"
              :class="{ 'mr-2': !EhTelaPequena }"
              :width="EhTelaPequena ? 240 : 'auto'"
              @click="emitirCancelar"
            >
              <v-icon>$mdiCancel</v-icon>
              {{ tituloCancelar }}
            </botao-padrao>
            <botao-padrao
              v-if="mostraBotaoSalvar"
              color="primary"
              :class="{ 'mt-2 mb-2': EhTelaPequena }"
              :width="EhTelaPequena ? 240 : 'auto'"
              :tooltip="tooltip"
              :disabled="okDesabilitado"
              @click="emitirOk"
            >
              <v-icon>$mdiContentSaveOutline</v-icon>
              {{ tituloOk }}
            </botao-padrao>
          </div>
          <slot name="botaoAdicional" />
        </slot>
      </div>
      <div
        v-if="mostrarBotoes"
        :class="`w-100 d-flex align-center justify-end ${
          EhTelaPequena ? 'flex-column' : ''
        }`"
      >
        <slot name="botoes">
          <botao-padrao
            v-if="mostrarBotaoCancelar"
            outlined
            color="secondary"
            :class="{ 'mr-2': !EhTelaPequena }"
            :width="EhTelaPequena ? 240 : 'auto'"
            @click="emitirCancelar"
          >
            <v-icon>$mdiCancel</v-icon>
            {{ tituloCancelar }}
          </botao-padrao>
          <botao-padrao
            v-if="mostraBotaoSalvar"
            color="primary"
            :class="{ 'mt-2 mb-2': EhTelaPequena }"
            :width="EhTelaPequena ? 240 : 'auto'"
            :tooltip="tooltip"
            :disabled="okDesabilitado"
            @click="emitirOk"
          >
            <v-icon v-if="iconeOk"> $mdiContentSaveOutline </v-icon>
            {{ tituloOk }}
          </botao-padrao>
          <slot name="botaoAdicional" />
        </slot>
      </div>
    </v-footer>
  </v-dialog>
</template>
<script>
export default {
  props: {
    gerarScroll: {type: Boolean, default: true },
    maxWidth: { type: [String, Number], default: 550 },
    maxHeight: { type: [String, Number], default: 550 },
    titulo: { type: String, default: 'Titulo' },
    tooltip: { type: String, default: '' },
    tituloCancelar: {
      type: String,
      default() {
        return this.$t('geral.botoes.cancelar_modal');
      },
    },
    tituloOk: {
      type: String,
      default() {
        return this.$t('geral.botoes.ok');
      },
    },
    subtitulo: { type: String, default: '' },
    direita: { type: Boolean, default: false },
    mostraBotaoSalvar: { type: Boolean, default: true },
    mostrarBotoes: { type: Boolean, default: true },
    mostrarBotoesComAtualizar: { type: Boolean, default: false },
    preventDefault: { type: Boolean, default: false },
    okDesabilitado: { type: Boolean, default: false },
    mostrarBotaoCancelar: { type: Boolean, default: true },
    persistente: { type: Boolean, default: false },
    iconeOk: { type: Boolean, default: true },
  },
  data() {
    return {
      aberto: false,
    };
  },
  computed: {
    posicaoModal() {
      if (this.direita) return 'v-dialog-right-position';
      return 'v-dialog-center-position';
    },
    estiloTitulo() {
      if (this.direita) return 'd-flex items-center justify-start';
      return 'd-flex items-center justify-space-between';
    },
    EhTelaPequena() {
      return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
    },
  },
  watch: {
    aberto: function (value) {
      if (!value) this.$emit('modal-fechado');
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyup);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyup);
  },
  methods: {
    abrirModal() {
      this.aberto = true;
    },
    fecharModal() {
      this.$emit('modalFechado', true);
      this.aberto = false;
    },
    emitirClickFora() {
      this.$emit('modalFechado', true);
      this.$emit('click-fora');
    },
    emitirCancelar(e) {
      this.$emit('cancelar', e);
      if (!this.preventDefault) this.fecharModal();
    },
    emitirAtualizar() {
      this.$emit('atualizar');
    },
    emitirOk() {
      this.$emit('ok');
    },
    emitirInput(e) {
      this.$emit('input', e);
    },
    emitirKeyDown(e) {
      this.$emit('keydown', e);
    },
  },
};
</script>

<style lang="scss">
.v-dialog.v-dialog-right-position {
  border-radius: 8px 0px 0px 8px;
}

.v-dialog.v-dialog-center-position {
  border-radius: 8px;
}

.v-card__modal {
  .v-card__title {
    padding: 12px 12px !important;

    .v-btn {
      height: fit-content;
      padding: 3px !important;
    }

    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .v-card__actions,
  .corpo-modal {
    padding: 0 12px;
    max-height: 70vh;
  }
}

.modal-padrao {
  .v-card__modal {
    display: flex;
    flex-direction: column;
  }
}

.modal-padrao .p-dropdown-items {
  height: 180px;
  position: fixed !important;
  overflow-y: auto !important;
}

.footer {
  padding-top: 12px;
  z-index: -1;
}
</style>
