import { AuthApi } from '@common/api';
const CampoAdicionalService = {
  listar: function (parametros) {
    return AuthApi.get()('/EmailTemplate', parametros);
  },
  buscar: function (tipoEmail) {
    return AuthApi.get()(`/EmailTemplate/${tipoEmail}`, null, false);
  },
  salvar: function (form) {
    if (form.tipoEmail) return AuthApi.put()(`/EmailTemplate/${form.tipoEmail}`, form.request);
  },
  buscarVariaveis: function (tipoEmail) {
    return AuthApi.get()(`/EmailTemplate/${tipoEmail}/variaveis`, null, false);
  },
  testarEmail: function (tipoEmail) {
    return AuthApi.post()(`/EmailTemplate/${tipoEmail}/envio-teste`);
  }

};

export default CampoAdicionalService;
