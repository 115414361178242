import { BasicoApi } from '@common/api';
const TipoDocumentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/tipodocumento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tipodocumento/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipodocumento/${form.id}`, form);
    return BasicoApi.post()('/tipodocumento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tipodocumento/${id}`);
  },
};

export default TipoDocumentoService;
