export default {
  mensagem_erro: 'Existem erros nas seguintes expressões',
  erros: {
    variavel_inexistente:
      'Variavel não existe ou o bloco da variavel está aberto',
    funcao_inexistente: 'Função não existe ou o bloco do comando está aberto',
    error: 'Bloco da expressão está aberto',
    desconhecido: 'Comando desconhecido',
    uso_invalido: 'Uso incorreto de vírgula/operador',
    expressao_invalida: 'Expressão inválida',
  },
};
