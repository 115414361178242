export default {
  titulos: {
    listagem: 'Itens de Estoque',
    novo: 'Novo Item',
    editar: 'Editar Item',
    visualizar_reserva: 'Visualizar Reservas',
  },
  cadastro_sucesso: 'Item salvo com sucesso!',
  exclusao_sucesso: 'Item excluído com sucesso!',
  acoes: {
    visualizar_reserva: 'Visualizar Reservas',
  },
  tabela: {
    codigo: 'Código',
    fabricante: 'Fabricante',
    descricao: 'Descrição',
    categoria: 'Categoria',
    modelos_instrumentos: 'Modelos de Instrumentos',
    tipos_instrumentos: 'Tipos de Instrumentos',
  },
  formulario: {
    abas: {
      dados_item: 'Dados do Item',
      tipo_modelo_instrumento: 'Tipos e Modelos de Instrumento',
    },
    codigo: 'Código',
    fabricante: 'Fabricante',
    descricao: 'Descrição',
    categoria: 'Categoria',
    classificacaoFiscal: 'Classificação Fiscal (NCM)',
    codigoEspecidicador: 'Código Especificador ST',
    unidade_medida: 'Unidade de Medida',
    tipo: 'Tipo',
    valor_compra: 'Valor de Compra (R$)',
    valor_frete: 'Valor do Frete (R$)',
    valor_venda: 'Valor de Venda (R$)',
    prazo_entrega: 'Prazo de Entrega',
    codigo_servico: 'Código de Serviço',
    alterar_resultados: 'Alterar Resultados',
    tempo_execucao: 'Horas de Execução Padrão',
    alquota_ICMS: 'Alíquota ICMS (%)',
    estoque_padrao: 'Estoque Padrão',
    descricao_completa: 'Descrição Completa',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
    inserir_instrumento: 'É necessário inserir um Tipo e Modelo Instrumento.',
  },
  filtro: {
    codigo: 'Código',
    fabricante: 'Fabricante',
    descricao: 'Descrição',
    categoria: 'Categoria',
  },
  tipo_modelo_instrumento: {
    sem_informacao: 'Sem informação',
  },
  tipoModeloInstrumentoModal: {
    title: 'Tipos e Modelos de Instrumento',
    steps: {
      one: {
        title: 'Tipos de Instrumento',
        table: {
          nome: 'Nome',
          areaAcreditacao: 'Área de Acreditação',
          responsavelSetor: 'Setor Responsável',
        },
      },
      two: {
        title: 'Modelos de Instrumento',
        table: {
          nome: 'Nome',
          tipoInstrumento: 'Tipo de Instrumento',
        },
      },
    },
    buttons: {
      cancel: 'Cancelar',
      previous: 'Voltar',
      confirm: 'Confirmar',
      next: 'Avançar',
    },
    inputs: {
      nome: {
        text: 'Nome',
        placeholder: 'Nome',
      },
    },
  },
  visualizar_reserva_chip: {
    codigo: 'Código',
    fabricante: 'Fabricante',
    descricao: 'Descrição'
  },
  visualizar_reserva_tabela: {
    ordem_servico: 'Ordem de Serviço',
    instrumento: 'Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
    setor: 'Setor',
    qtd_pecas: 'Quantidade de Peças',

  }
};
