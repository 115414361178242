export default {

  tabela: {
    nome: 'Nome',
    sede: 'Município Sede',
    apelido: 'Apelido',
    documento: 'Documento'
  },
  titulo_modal: 'Fabricantes',
};
