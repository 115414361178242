import spacetime from 'spacetime'

const config = {
  timezone: 'America/Sao_Paulo',
  formatterDate: '{date-pad}/{iso-month}/{year}',
  formatterTime: '{hour-24-pad}:{minute-pad}:{second-pad} {ampm}'
}

const AllowedTypes = [
  'day',
  'month',
  'year',
  'week',
  'quarter'
]

// Function that format date and hour
export const formatDatetime = (datetime, timezone, dateFormatter, hourFormatter) => {
  let currentDatetime = spacetime(datetime)
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (!currentDatetime.isValid()) return { msg: 'Invalid datetime' }

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  currentDatetime = currentDatetime.goto(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime
  }
}

// Function that returns today
export const datetimeNow = (timezone, dateFormatter, hourFormatter) => {
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  const currentDatetime = spacetime.now(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime
  }
}

// Function that returns today's date first time
export const datetimeToday = (timezone, dateFormatter, hourFormatter) => {
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  const currentDatetime = spacetime.today(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime
  }
}

// Function that returns today's date first time
export const datetimeYear = (datetime) => {
  return spacetime(datetime).year()
}

// Function that returns yesterday's date at the first time
export const datetimeTomorrow = (timezone, dateFormatter, hourFormatter) => {
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  const currentDatetime = spacetime.tomorrow(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime
  }
}

// Function that adds or subtracts (day, month, year, week, quarter) to the date
export const datetimeAddOrSubtract = (datetime, timezone, segment, type, value, dateFormatter, hourFormatter) => {
  let currentDatetime = spacetime(datetime)
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime
  let defaultValue = 1
  let defaultType = 'day'
  let defaultSegment = 'add'
  const AllowedSegments = [
    'add',
    'subtract'
  ]

  if (!currentDatetime.isValid()) return { msg: 'Invalid datetime' }

  if (timezone) defaultTimezone = timezone

  if (dateFormatter) defaultDateFormatter = dateFormatter

  if (hourFormatter) defaultHourFormatter = hourFormatter

  if (value) defaultValue = value

  if (type && AllowedTypes.includes(type)) defaultType = type

  if (segment && AllowedSegments.includes(segment)) defaultSegment = segment

  if (defaultSegment === 'add') {
    currentDatetime = currentDatetime.add(defaultValue, defaultType)
  } else {
    currentDatetime = currentDatetime.subtract(defaultValue, defaultType)
  }

  currentDatetime = currentDatetime.goto(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime
  }
}

// Function that adds or subtracts (day, month, year, week, quarter) to the date
export const datetimeAddAndSubtract = (date = { datetime: '', timezone: '', subtractType: '', subtractValue: '', addType: '', addValue: '', dateFormatter: '', hourFormatter: '' }) => {
  let currentDatetime = spacetime(date.datetime)
  let defaultTimezone = config.timezone
  let defaultDateFormatter = config.formatterDate
  let defaultHourFormatter = config.formatterTime
  let defaultSubtractValue = 1
  let defaultSubtractType = 'months'
  let defaultAddValue = 1
  let defaultAddType = 'day'

  if (!currentDatetime.isValid()) return { msg: 'Invalid datetime' }

  if (date.timezone) defaultTimezone = date.timezone

  if (date.dateFormatter) defaultDateFormatter = date.dateFormatter

  if (date.hourFormatter) defaultHourFormatter = date.hourFormatter

  if (date.subtractValue) defaultSubtractValue = date.subtractValue

  if (date.addValue) defaultAddValue = date.addValue

  if (date.subtractType && AllowedTypes.includes(date.subtractType)) defaultSubtractType = date.subtractType

  if (date.addType && AllowedTypes.includes(date.addType)) defaultAddType = date.addType

  currentDatetime = currentDatetime.subtract(defaultSubtractValue, defaultSubtractType).add(defaultAddValue, defaultAddType)

  currentDatetime = currentDatetime.goto(defaultTimezone)

  const currentDate = currentDatetime.format(defaultDateFormatter)
  const currentTime = currentDatetime.format(defaultHourFormatter)

  return {
    date: currentDate,
    time: currentTime
  }
}

// Function that generates a range of hours
export const generateHours = (startHour = 9, endHour = 18) => {
  const listHour = []

  for (let hour = startHour; hour < endHour; hour++) {
    const textHour = `0${hour}`.substr(-2)
    listHour.push(generateMinutesForHour(textHour))
  }

  return listHour.reduce(
    (previousValue, currentValue) => [...previousValue, ...currentValue],
    []
  )
}

function generateMinutesForHour (hour, { intervalBetweenMinutes = 30, maxMinute = 30 } = {}) {
  const listHourWithMinutes = []

  for (let minute = 0; minute <= maxMinute; minute += intervalBetweenMinutes) {
    const textMinute = `0${minute}`.substr(-2)
    listHourWithMinutes.push(`${hour}:${textMinute}`)
  }

  return listHourWithMinutes
}

// Function that returns the time of the date
export const datetimeHowMuchTime = (datetime, timezone) => {
  let today = spacetime.now()
  let currentDatetime = spacetime(datetime)
  let defaultTimezone = config.timezone

  if (!currentDatetime.isValid()) return { msg: 'Invalid datetime' }

  if (currentDatetime.isAfter(today)) return { msg: 'Invalid datetime' }

  if (timezone) defaultTimezone = timezone

  currentDatetime = currentDatetime.goto(defaultTimezone)
  today = today.goto(defaultTimezone)

  const diffDatetime = today.since(currentDatetime)

  return {
    diffDatetime: diffDatetime
  }
}

//
export const diffBetweenTwoTimes = (datetime) => {
  const now = spacetime.now()
  const before = spacetime(datetime)
  const since = now.since(before).rounded

  if (since !== 'now') {
    return formatToI18n(since)
  }
  return 'now'
}

export const formatToI18n = (datetime) => {
  const array = datetime.split(' ')

  const duration = array[0]
  const diff = array[1]
  const direction = array[2]

  const formattedDiff = diff.endsWith('s') ? diff : `${diff}s`

  return { duration, formattedDiff, direction }
}

// Funcao que formata a data para o componente BaseDatePicker somente
export const formateBaseDatePicker = (currentDate) => {
  let newFormatedValue = null

  if (currentDate) {
    newFormatedValue = spacetime(currentDate).format('numeric-uk')
  }

  return newFormatedValue
}

// Function that returns years list
export const yearList = (afterNow = 10, beforeNow = 0) => {
  const currentYear = datetimeYear()
  const yearStart = currentYear - afterNow
  const defaultList = []
  const nextYears = afterNow

  for (let i = 0; i <= nextYears; i++) {
    defaultList.push(yearStart + i)
  }

  if (beforeNow > 0) {
    for (let i = 0; i <= beforeNow; i++) {
      defaultList.push(currentYear + i)
    }
  }

  return defaultList
}
