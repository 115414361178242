import helpers from '@router/helpers';
const menuId = 'embalagem';

export default [
  helpers.criarRota(menuId, 'embalagem', menuId, () =>
    import('@/views/modulos/cadastros/embalagem')
  ),
  helpers.criarRota(menuId, 'embalagem-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/embalagem/formulario')
  ),
  helpers.criarRota(
    menuId,
    'embalagem-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/embalagem/formulario'),
    true
  ),
];
