import i18n from '@/i18n';
export default [
  {
    value: 'ValorAbsoluto',
    text: i18n.t('enums.EnumTipoReferencia.ValorAbsoluto'),
  },
  {
    value: i18n.t('enums.EnumTipoReferencia.PorcentagemPontoLido'),
    text: 'PorcentagemPontoLido',
  },
  {
    value: i18n.t('enums.EnumTipoReferencia.PorcentagemFundoEscala'),
    text: 'PorcentagemFundoEscala',
  },
];
