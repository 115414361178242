import { BasicoApi } from '@common/api';
const AnaliseTecnicaService = {
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/analiseTecnica/${form.id}`, form);
    return BasicoApi.post()('/analiseTecnica', form);
  },
  salvarEmLote: function (form, edicao) {
    if (!edicao) return BasicoApi.post()('/analiseTecnica/lote', form);
    else return BasicoApi.put()('/analiseTecnica/atualizar-lote', form);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/analiseTecnica/${id}`);
  },
  buscarAnaliseTecnicaEmLote: function (ordemServicoId) {
    return BasicoApi.get()(`/analiseTecnica/lote/${ordemServicoId}`);
  },
  buscarDadosViewModelAnaliseTecnicaEmLote: function (
    params,
    instrumentoString,
    tipoInstrumentoId
  ) {
    return BasicoApi.get()(
      `/analiseTecnica/view-model-lote/${params.ordemServicoId}?tipoInstrumentoId=${tipoInstrumentoId}${instrumentoString}`,
      params
    );
  },
  visualizar: function (id) {
    return BasicoApi.get()(`/analiseTecnica/visualizar/${id}`);
  },
  buscaPrazoEntregaTipoPrazo: function (params) {
    return BasicoApi.post()(`/analiseTecnica/prazo-entrega/`, params);
  },
  aprovarGarantia: function (id) {
    return BasicoApi.put()(`/analiseTecnica/${id}/aprovar-garantia`);
  },
  reprovarGarantia: function (id) {
    return BasicoApi.put()(`/analiseTecnica/${id}/reprovar-garantia`);
  },
};

export default AnaliseTecnicaService;
