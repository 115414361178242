import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAnaliseTecnica'),
    value: 1,
    name: 'AguardandoAnaliseTecnica',
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAprovacaoGarantia'),
    value: 2,
    name: 'AguardandoAprovacaoGarantia',
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoEnvioOrcamento'),
    value: 3,
    name: 'AguardandoEnvioOrcamento'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAprovacaoOrcamento'),
    value: 4,
    name: 'AguardandoAprovacaoOrcamento'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoPeca'),
    value: 5,
    name: 'AguardandoPeca'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoProgramacaoManutencao'),
    value: 6,
    name: 'AguardandoProgramacaoManutencao'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoTerceiroDia'),
    value: 7,
    name: 'ProgramacaoTerceiroDia'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoSegundoDia'),
    value: 8,
    name: 'ProgramacaoSegundoDia'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoDia'),
    value: 9,
    name: 'ProgramacaoDia'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.EmExecucaoManutencao'),
    value: 10,
    name: 'EmExecucaoManutencao'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoProgramacaoCalibracao'),
    value: 11,
    name: 'AguardandoProgramacaoCalibracao'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoCalibracaoTerceiroDia'),
    value: 12,
    name: 'ProgramacaoCalibracaoTerceiroDia'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoCalibracaoSegundoDia'),
    value: 13,
    name: 'ProgramacaoCalibracaoSegundoDia'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.ProgramacaoCalibracaoDia'),
    value: 14,
    name: 'ProgramacaoCalibracaoDia'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.EmExecucaoCalibracao'),
    value: 15,
    name: 'EmExecucaoCalibracao'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.AguardandoAprovacaoCertificado'),
    value: 16,
    name: 'AguardandoAprovacaoCertificado'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.Calibrado'),
    value: 17,
    name: 'Calibrado'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.NaoCalibrado'),
    value: 18,
    name: 'NaoCalibrado'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.Reprovado'),
    value: 19,
    name: 'Reprovado'
  },
  {
    text: i18n.t('enums.EnumSituacaoOrdemServico.SemMovimento'),
    value: 99,
    name: 'SemMovimento'
  },
];
