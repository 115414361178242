import i18n from '@/i18n';

export default [
  {
    text: '',
    value: 'NaoIniciada',
    cor: 'transparent',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.PendenteDerivas'),
    value: 'PendenteDerivas',
    cor: 'primary-warning-500',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.PendenteAvaliacao'),
    value: 'PendenteAvaliacao',
    cor: 'primary-warning-500',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.Aprovado'),
    value: 'Aprovado',
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.AprovadoComRessalvas'),
    value: 'AprovadoComRessalvas',
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.Reprovado'),
    value: 'Reprovado',
    cor: 'primary-error-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.ReprovadoComRessalvas'),
    value: 'ReprovadoComRessalvas',
    cor: 'primary-error-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoAvaliacao.NaoValidado'),
    value: 'NaoValidado',
    cor: '',
  },
];
