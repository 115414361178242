<template>
  <div>
    <span v-if="labelExterno && !semLabel" class="mb-3 d-block v-label--externo d-flex">
      <span v-if="!tooltip" class="label">{{ label }}</span>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ label }}</span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>

      <span v-if="obrigatorio && label" class="obrigatorio ml-1">*</span>
    </span>
    <div ref="input-div" :class="`input-ide ${erros.length > 0 || regraObrigatorio ? 'input-ide__error' : ''
      }`">
      <div class="input-ide__wrapper">
        <textarea ref="input" v-model="conteudo" class="input-ide__textarea" :class="disabled ? 'disabled' : ''"
          autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" rows="1" style="height: 32px"
          :disabled="disabled" @focus="focus" @blur="blur" @keydown="keyDown" @click="() => {
        this.sugestoesAberto = false;
        this.errosAberto = false;
      }
      " />
        <div class="input-ide__codigos">
          <span v-for="(tags, i) in codigosHtml" :key="`tag-${i}`" :class="`input-ide__${tags.tipo}`">{{ tags.expressao
            }}</span>
        </div>
        <div class="input-ide__icon__erro">
          <v-icon class="mr-2 mt-1" style="height: 14px; cursor: pointer" @click="abrirErros">
            $alertWarningtOutline
          </v-icon>
        </div>
      </div>
    </div>
    <div v-if="erros.length > 0 && errosAberto" class="input-ide__erros">
      <div class="input-ide__erros_wrapper">
        <span>{{ $t('componentes.input_ide.mensagem_erro') }}:</span>

        <div v-for="(erro, i) in erros" :key="`erro-${i}`">
          <div class="input-ide__mensagem_erro">
            {{
      $t(`componentes.input_ide.erros.${erro.tipo.replace('-', '_')}`)
    }}
          </div>
          <div class="input-ide__erros_expressao">
            {{ erro.expressao }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="regraObrigatorio" class="v-text-field__details mt-1 ml-3">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ this.$t('inputs.rules.required') }}
          </div>
        </div>
      </div>
    </div>
    <div :class="`input-select-ide ${!(sugestoesAberto && !errosAberto) ? 'sem-display' : ''
      }`">
      <v-select ref="selectMenu" v-model="conteudoSelect" :items="options" label="Selecione uma opção" dense flat solo
        no-data-text="" :menu-props="{ contentClass: 'menu-input-ide' }" @change="adicionarTexto2">
        <template #item="{ item }">
          <div class="w-100">
            <div v-if="!item.value.funcao">
              <v-icon style="width: 15px" class="ml-2 mr-1">
                $variable
              </v-icon>
              {{ item.text }}
            </div>
            <div v-if="item.value.funcao" class="input-ide__funcao">
              <div class="row input-ide select">
                <div class="col-12 col-md-2">
                  <div class="d-flex">
                    <v-icon style="width: 15px" class="ml-2 mr-1">
                      $function
                    </v-icon>
                    {{ item.text }}
                  </div>
                </div>
                <div class="col-12 col-md-5">
                  <div class="d-flex">
                    <v-chip small class="mr-2">
                      Fórmula:
                    </v-chip>
                    <div class="input-ide__wrapper flex-fill">
                      <div class="input-ide__codigos">
                        <span v-for="(tags, i) in formatarTagsHtml(
      tokenizeExpression(item.value.elemento.texto)
    )" :key="`tag-${i}`" :class="`input-ide__${tags.tipo}`">{{ tags.expressao }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-5">
                  <div class="d-flex">
                    <v-chip small class="mr-2">
                      Exemplo:
                    </v-chip>
                    <div class="input-ide__wrapper flex-fill">
                      <div class="input-ide__codigos">
                        <span v-for="(tags, i) in formatarTagsHtml(
      tokenizeExpression(item.value.elemento.exemplo)
    )" :key="`tag-${i}`" :class="`input-ide__${tags.tipo}`">{{ tags.expressao }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.value.funcao" style="font-size: 11px; padding-left: 10px; padding-bottom: 5px">
              {{ item.value.elemento.ajuda }}
            </div>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { InputMixin } from './InputMixin';
import { FormulaService } from '@common/services';
export default {
  mixins: [InputMixin],
  props: ['formulaMensurandoId'],
  data: function () {
    return {
      validado: false,
      errosAberto: false,
      sugestoesAberto: false,
      sugestoesFocus: false,
      sugestoesAbertoPor: '',
      ultimoTextoDigitado: '',
      ultimaPosicaoPonteiro: 0,
      conteudo: '',
      conteudoSelect: '',
      variaveis: [],
      funcoes: [
        {
          funcao: 'DATE',
          ajuda: 'Função para retornar data',
          texto: 'DATE({dia}, {mes}, {ano})',
          exemplo: 'DATE(1, 1, 2010)',
        },
        {
          funcao: 'DATEDIF',
          ajuda: 'Retorna a diferença entre duas datas em unidades específicas',
          texto: 'DATEDIF({data_inicial}, {data_final}, {unidade})',
          exemplo: 'DATEDIF(DATE(2021, 1, 1), DATE(2021, 12, 31), "y")',
        },
        {
          funcao: 'DATEVALUE',
          ajuda:
            'Converte uma representação de data em texto para um número serial de data',
          texto: 'DATEVALUE({texto_data})',
          exemplo: 'DATEVALUE("01/01/2022")',
        },
        {
          funcao: 'DAY',
          ajuda: 'Retorna o dia de uma data',
          texto: 'DAY({data})',
          exemplo: 'DAY(DATE(2022, 1, 1))',
        },
        {
          funcao: 'DAYS360',
          ajuda:
            'Retorna o número de dias entre duas datas com base no ano de 360 dias',
          texto: 'DAYS360({data_inicial}, {data_final}, {metodo})',
          exemplo: 'DAYS360(DATE(2022, 1, 1), DATE(2022, 12, 31), FALSE)',
        },
        {
          funcao: 'EDATE',
          ajuda:
            'Retorna a data que é um determinado número de meses antes ou depois de uma data especificada',
          texto: 'EDATE({data}, {meses})',
          exemplo: 'EDATE(DATE(2022, 1, 1), 6)',
        },
        {
          funcao: 'EOMONTH',
          ajuda:
            'Retorna a data do último dia do mês, antes ou depois de um número especificado de meses',
          texto: 'EOMONTH({data}, {meses})',
          exemplo: 'EOMONTH(DATE(2022, 1, 1), 6)',
        },
        {
          funcao: 'HOUR',
          ajuda: 'Retorna a hora de uma hora',
          texto: 'HOUR({hora})',
          exemplo: 'HOUR(TIME(9, 30, 0))',
        },
        {
          funcao: 'MINUTE',
          ajuda: 'Retorna o minuto de uma hora',
          texto: 'MINUTE({hora})',
          exemplo: 'MINUTE(TIME(9, 30, 0))',
        },
        {
          funcao: 'MONTH',
          ajuda: 'Retorna o mês de uma data',
          texto: 'MONTH({data})',
          exemplo: 'MONTH(DATE(2022, 1, 1))',
        },
        {
          funcao: 'NETWORKDAYS',
          ajuda: 'Retorna o número de dias úteis entre duas datas',
          texto: 'NETWORKDAYS({data_inicial}, {data_final}, {feriados})',
          exemplo: 'NETWORKDAYS(DATE(2022, 1, 1), DATE(2022, 1, 31), [])',
        },
        {
          funcao: 'NOW',
          ajuda: 'Retorna a data e hora atual',
          texto: 'NOW()',
          exemplo: 'NOW()',
        },
        {
          funcao: 'SECOND',
          ajuda: 'Retorna o segundo de uma hora',
          texto: 'SECOND({hora})',
          exemplo: 'SECOND(TIME(9, 30, 0))',
        },
        {
          funcao: 'TIME',
          ajuda:
            'Retorna um valor de hora dado os argumentos de hora, minuto e segundo',
          texto: 'TIME({hora}, {minuto}, {segundo})',
          exemplo: 'TIME(9, 30, 0)',
        },
        {
          funcao: 'TIMEVALUE',
          ajuda:
            'Converte uma representação de hora em texto para um número de série de hora',
          texto: 'TIMEVALUE({texto_hora})',
          exemplo: 'TIMEVALUE("9:30 AM")',
        },
        {
          funcao: 'TODAY',
          ajuda: 'Retorna a data atual',
          texto: 'TODAY()',
          exemplo: 'TODAY()',
        },
        {
          funcao: 'WEEKDAY',
          ajuda: 'Retorna o número do dia da semana de uma data',
          texto: 'WEEKDAY({data}, {tipo})',
          exemplo: 'WEEKDAY(DATE(2022, 1, 1), 2)',
        },
        {
          funcao: 'WEEKNUM',
          ajuda: 'Retorna o número da semana de uma data',
          texto: 'WEEKNUM({data}, {tipo})',
          exemplo: 'WEEKNUM(DATE(2022, 1, 1), 1)',
        },
        {
          funcao: 'WORKDAY',
          ajuda:
            'Retorna a data que é um número específico de dias úteis antes ou depois de uma data especificada',
          texto: 'WORKDAY({data}, {dias}, {feriados})',
          exemplo: 'WORKDAY(DATE(2022, 1, 1), 5, [])',
        },
        {
          funcao: 'YEAR',
          ajuda: 'Retorna o ano de uma data',
          texto: 'YEAR({data})',
          exemplo: 'YEAR(DATE(2022, 1, 1))',
        },
        {
          funcao: 'YEARFRAC',
          ajuda: 'Retorna a fração do ano representada por um número de dias',
          texto: 'YEARFRAC({data_inicial}, {data_final}, {método})',
          exemplo: 'YEARFRAC(DATE(2022, 1, 1), DATE(2022, 12, 31), 1)',
        },
        {
          funcao: 'PMT',
          ajuda:
            'Retorna o pagamento periódico para um investimento com pagamentos e taxas de juros constantes',
          texto: 'PMT({taxa}, {nper}, {pv}, {fv}, {tipo})',
          exemplo: 'PMT(0.05, 10, -1000, 0, 0)',
        },
        {
          funcao: 'ERROR.TYPE',
          ajuda:
            'Retorna um número correspondente a um determinado tipo de erro',
          texto: 'ERROR.TYPE({valor})',
          exemplo: 'ERROR.TYPE(#N/D)',
        },
        {
          funcao: 'ISBLANK',
          ajuda: 'Verifica se um valor é vazio',
          texto: 'ISBLANK({valor})',
          exemplo: 'ISBLANK({valor})',
        },
        {
          funcao: 'ISERR',
          ajuda: 'Verifica se um valor é um erro diferente de #N/D',
          texto: 'ISERR({valor})',
          exemplo: 'ISERR({valor})',
        },
        {
          funcao: 'ISERROR',
          ajuda: 'Verifica se um valor é um erro',
          texto: 'ISERROR({valor})',
          exemplo: 'ISERROR({valor})',
        },
        {
          funcao: 'ISEVEN',
          ajuda: 'Verifica se um número é par',
          texto: 'ISEVEN({valor})',
          exemplo: 'ISEVEN({valor})',
        },
        {
          funcao: 'ISLOGICAL',
          ajuda: 'Verifica se um valor é um valor lógico (VERDADEIRO ou FALSO)',
          texto: 'ISLOGICAL({valor})',
          exemplo: 'ISLOGICAL(TRUE)',
        },
        {
          funcao: 'ISNA',
          ajuda: 'Verifica se um valor é o erro #N/D',
          texto: 'ISNA({valor})',
          exemplo: 'ISNA(#N/D)',
        },
        {
          funcao: 'ISNONTEXT',
          ajuda: 'Verifica se um valor não é texto',
          texto: 'ISNONTEXT({valor})',
          exemplo: 'ISNONTEXT(123)',
        },
        {
          funcao: 'ISNUMBER',
          ajuda: 'Verifica se um valor é um número',
          texto: 'ISNUMBER({valor})',
          exemplo: 'ISNUMBER(10)',
        },
        {
          funcao: 'ISODD',
          ajuda: 'Verifica se um número é ímpar',
          texto: 'ISODD({valor})',
          exemplo: 'ISODD(5)',
        },
        {
          funcao: 'ISREF',
          ajuda: 'Verifica se um valor é uma referência',
          texto: 'ISREF({valor})',
          exemplo: 'ISREF(A1)',
        },
        {
          funcao: 'ISTEXT',
          ajuda: 'Verifica se um valor é texto',
          texto: 'ISTEXT({valor})',
          exemplo: 'ISTEXT("Hello")',
        },
        {
          funcao: 'N',
          ajuda: 'Converte um valor em um número',
          texto: 'N({valor})',
          exemplo: 'N("123")',
        },
        {
          funcao: 'NA',
          ajuda: 'Retorna o valor de erro #N/D',
          texto: 'NA()',
          exemplo: 'NA()',
        },
        {
          funcao: 'TYPE',
          ajuda: 'Retorna o tipo de valor',
          texto: 'TYPE({valor})',
          exemplo: 'TYPE(123)',
        },
        {
          funcao: 'AND',
          ajuda: 'Retorna TRUE se todos os argumentos forem verdadeiros',
          texto: 'AND({argumento1}, {argumento2}, ...{argumentoN})',
          exemplo: 'AND(TRUE, TRUE, FALSE)',
        },
        {
          funcao: 'TRUE',
          ajuda: 'Retorna o valor lógico VERDADEIRO',
          texto: 'TRUE()',
          exemplo: 'TRUE()',
        },
        {
          funcao: 'IF',
          ajuda:
            'Executa uma condição e retorna um valor se for verdadeiro ou outro valor se for falso',
          texto: 'IF({condicao}, {valor_se_verdadeiro}, {valor_se_falso})',
          exemplo: 'IF(A1 > 10, "Maior que 10", "Menor ou igual a 10")',
        },
        {
          funcao: 'IFERROR',
          ajuda:
            'Retorna um valor se não houver erro; caso contrário, retorna outro valor',
          texto: 'IFERROR({valor}, {valor_de_erro})',
          exemplo: 'IFERROR({valor}/{valor2}), "Erro")',
        },
        {
          funcao: 'NOT',
          ajuda: 'Inverte o valor de um argumento lógico',
          texto: 'NOT({valor})',
          exemplo: 'NOT(TRUE)',
        },
        {
          funcao: 'OR',
          ajuda: 'Retorna TRUE se pelo menos um dos argumentos for verdadeiro',
          texto: 'OR({argumento1}, {argumento2}, ...{argumentoN})',
          exemplo: 'OR(TRUE, FALSE, TRUE)',
        },
        {
          funcao: 'TRUE',
          ajuda: 'Retorna o valor lógico VERDADEIRO',
          texto: 'IF({Condição}, TRUE())',
          exemplo: 'IF(1=1 , TRUE())',
        },
        {
          funcao: 'COLUMN',
          ajuda: 'Retorna o número da coluna de uma referência',
          texto: 'COLUMN({referencia})',
          exemplo: 'COLUMN(A1)',
        },
        {
          funcao: 'COLUMNS',
          ajuda: 'Retorna o número de colunas em uma matriz ou intervalo',
          texto: 'COLUMNS({matriz||intervalo})',
          exemplo: 'COLUMNS({colunas})',
        },
        {
          funcao: 'HLOOKUP',
          ajuda:
            'Procura por um valor em uma linha específica de uma tabela e retorna um valor na mesma coluna, mas em uma linha diferente',
          texto:
            'HLOOKUP({valor_procurado}, {tabela}, {numero_linha}, {procurar_intervalo})',
          exemplo: 'HLOOKUP("Maçã", {tabela}, 3, FALSE)',
        },
        {
          funcao: 'INDEX',
          ajuda:
            'Retorna um valor de uma matriz ou intervalo, com base no número da linha e da coluna',
          texto: 'INDEX({matriz||intervalo}, {numero_linha}, {numero_coluna})',
          exemplo: 'INDEX({matriz||intervalo}, 2, 1)',
        },
        {
          funcao: 'MATCH',
          ajuda:
            'Procura por um valor em uma matriz ou intervalo e retorna a posição relativa desse valor',
          texto:
            'MATCH({valor_procurado}, {matriz||intervalo}, {tipo_de_combinacao})',
          exemplo: 'MATCH("Maçã", A1:A10, 0)',
        },
        {
          funcao: 'ROW',
          ajuda: 'Retorna o número da linha de uma referência',
          texto: 'ROW({referencia})',
          exemplo: 'ROW({referencia})',
        },
        {
          funcao: 'ROWS',
          ajuda: 'Retorna o número de linhas em uma matriz ou intervalo',
          texto: 'ROWS({matriz||intervalo})',
          exemplo: 'ROWS({matriz||intervalo})',
        },
        {
          funcao: 'VLOOKUP',
          ajuda:
            'Procura por um valor em uma coluna específica de uma tabela e retorna um valor na mesma linha, mas em uma coluna diferente',
          texto:
            'VLOOKUP({valor_procurado}, {tabela}, {numero_coluna}, {procurar_intervalo})',
          exemplo: 'VLOOKUP("Maçã", {tabela}, 3, FALSE)',
        },
        {
          funcao: 'ABS',
          ajuda: 'Retorna o valor absoluto de um número',
          texto: 'ABS({numero})',
          exemplo: 'ABS(-10)',
        },
        {
          funcao: 'ACOS',
          ajuda: 'Retorna o cosseno inverso de um número',
          texto: 'ACOS({numero})',
          exemplo: 'ACOS(0.5)',
        },
        {
          funcao: 'ACOSH',
          ajuda: 'Retorna o cosseno hiperbólico inverso de um número',
          texto: 'ACOSH({numero})',
          exemplo: 'ACOSH(1.5)',
        },
        {
          funcao: 'ASIN',
          ajuda: 'Retorna o seno inverso de um número',
          texto: 'ASIN({numero})',
          exemplo: 'ASIN(0.5)',
        },
        {
          funcao: 'ASINH',
          ajuda: 'Retorna o seno hiperbólico inverso de um número',
          texto: 'ASINH({numero})',
          exemplo: 'ASINH(1.5)',
        },
        {
          funcao: 'ATAN',
          ajuda: 'Retorna a tangente inversa de um número',
          texto: 'ATAN({numero})',
          exemplo: 'ATAN(0.5)',
        },
        {
          funcao: 'ATAN2',
          ajuda: 'Retorna a tangente inversa de dois números separados',
          texto: 'ATAN2({numero_y}, {numero_x})',
          exemplo: 'ATAN2(3, 4)',
        },
        {
          funcao: 'ATANH',
          ajuda: 'Retorna a tangente hiperbólica inversa de um número',
          texto: 'ATANH({numero})',
          exemplo: 'ATANH(0.5)',
        },
        {
          funcao: 'CEILING',
          ajuda: 'Arredonda para cima um número até o valor especificado',
          texto: 'CEILING({numero}, {multiplo})',
          exemplo: 'CEILING(10.5, 5)',
        },
        {
          funcao: 'COMBIN',
          ajuda:
            'Retorna o número de combinações de um determinado número de elementos selecionados de um conjunto',
          texto: 'COMBIN({n}, {k})',
          exemplo: 'COMBIN(5, 3)',
        },
        {
          funcao: 'COS',
          ajuda: 'Retorna o cosseno de um ângulo',
          texto: 'COS({angulo})',
          exemplo: 'COS(0.5)',
        },
        {
          funcao: 'COSH',
          ajuda: 'Retorna o cosseno hiperbólico de um número',
          texto: 'COSH({numero})',
          exemplo: 'COSH(1.5)',
        },
        {
          funcao: 'DEGREES',
          ajuda: 'Converte um ângulo em radianos para graus',
          texto: 'DEGREES({angulo})',
          exemplo: 'DEGREES(1.57)',
        },
        {
          funcao: 'EVEN',
          ajuda: 'Arredonda um número para o próximo número inteiro par',
          texto: 'EVEN({numero})',
          exemplo: 'EVEN(3.2)',
        },
        {
          funcao: 'EXP',
          ajuda: 'Retorna o valor de Euler elevado ao poder de um número',
          texto: 'EXP({numero})',
          exemplo: 'EXP(1)',
        },
        {
          funcao: 'FACT',
          ajuda: 'Retorna o fatorial de um número',
          texto: 'FACT({numero})',
          exemplo: 'FACT(5)',
        },
        {
          funcao: 'FACTDOUBLE',
          ajuda: 'Retorna o fatorial duplo de um número',
          texto: 'FACTDOUBLE({numero})',
          exemplo: 'FACTDOUBLE(6)',
        },
        {
          funcao: 'FLOOR',
          ajuda: 'Arredonda para baixo um número até o valor especificado',
          texto: 'FLOOR({numero}, {multiplo})',
          exemplo: 'FLOOR(10.5, 5)',
        },
        {
          funcao: 'GCD',
          ajuda: 'Retorna o maior divisor comum de um conjunto de valores',
          texto: 'GCD({valor1}, {valor2}, ...{valorN})',
          exemplo: 'GCD(24, 36)',
        },
        {
          funcao: 'INT',
          ajuda:
            'Arredonda um número para o maior número inteiro menor ou igual a ele',
          texto: 'INT({numero})',
          exemplo: 'INT(5.8)',
        },
        {
          funcao: 'LCM',
          ajuda: 'Retorna o menor múltiplo comum de um conjunto de valores',
          texto: 'LCM({valor1}, {valor2}, ...{valorN})',
          exemplo: 'LCM(2, 3, 4)',
        },
        {
          funcao: 'LN',
          ajuda: 'Retorna o logaritmo natural (base e) de um número',
          texto: 'LN({numero})',
          exemplo: 'LN(10)',
        },
        {
          funcao: 'LOG',
          ajuda: 'Retorna o logaritmo de um número para uma determinada base',
          texto: 'LOG({numero}, {base})',
          exemplo: 'LOG(100, 10)',
        },
        {
          funcao: 'LOG10',
          ajuda: 'Retorna o logaritmo de um número na base 10',
          texto: 'LOG10({numero})',
          exemplo: 'LOG10(100)',
        },
        {
          funcao: 'MDETERM',
          ajuda: 'Retorna o determinante de uma matriz',
          texto: 'MDETERM({matriz})',
          exemplo: 'MDETERM({matriz})',
        },
        {
          funcao: 'MINVERSE',
          ajuda: 'Retorna a matriz inversa de uma matriz',
          texto: 'MINVERSE({matriz})',
          exemplo: 'MINVERSE({matriz})',
        },
        {
          funcao: 'MMULT',
          ajuda: 'Retorna o produto de duas matrizes',
          texto: 'MMULT({matriz1}, {matriz2})',
          exemplo: 'MMULT({matriz1}, {matriz2})',
        },
        {
          funcao: 'MOD',
          ajuda: 'Retorna o resto da divisão de um número por outro',
          texto: 'MOD({dividendo}, {divisor})',
          exemplo: 'MOD(10, 3)',
        },
        {
          funcao: 'MROUND',
          ajuda:
            'Arredonda um número para o múltiplo mais próximo de um determinado valor',
          texto: 'MROUND({numero}, {multiplo})',
          exemplo: 'MROUND(10.5, 5)',
        },
        {
          funcao: 'MULTINOMIAL',
          ajuda: 'Retorna o multinomial de um conjunto de valores',
          texto: 'MULTINOMIAL({valor1}, {valor2}, ...{valorN})',
          exemplo: 'MULTINOMIAL(2, 3, 4)',
        },
        {
          funcao: 'ODD',
          ajuda: 'Arredonda um número para o próximo número inteiro ímpar',
          texto: 'ODD({numero})',
          exemplo: 'ODD(3.2)',
        },
        {
          funcao: 'PI',
          ajuda: 'Retorna o valor de Pi (3.14159265359)',
          texto: 'PI()',
          exemplo: 'PI()',
        },
        {
          funcao: 'POWER',
          ajuda: 'Retorna um número elevado a uma potência',
          texto: 'POWER({base}, {potencia})',
          exemplo: 'POWER(2, 3)',
        },
        {
          funcao: 'PRODUCT',
          ajuda: 'Multiplica valores e retorna o produto',
          texto: 'PRODUCT({valor1}, {valor2}, ...{valorN})',
          exemplo: 'PRODUCT(2, 3, 4)',
        },
        {
          funcao: 'QUOTIENT',
          ajuda: 'Retorna o quociente inteiro de uma divisão',
          texto: 'QUOTIENT({dividendo}, {divisor})',
          exemplo: 'QUOTIENT(10, 3)',
        },
        {
          funcao: 'RADIANS',
          ajuda: 'Converte um ângulo em graus para radianos',
          texto: 'RADIANS({angulo})',
          exemplo: 'RADIANS(90)',
        },
        {
          funcao: 'RAND',
          ajuda: 'Retorna um número aleatório entre 0 e 1',
          texto: 'RAND()',
          exemplo: 'RAND()',
        },
        {
          funcao: 'RANDBETWEEN',
          ajuda: 'Retorna um número aleatório entre dois valores fornecidos',
          texto: 'RANDBETWEEN({minimo}, {maximo})',
          exemplo: 'RANDBETWEEN(1, 10)',
        },
        {
          funcao: 'ROMAN',
          ajuda: 'Converte um número decimal em um número romano',
          texto: 'ROMAN({numero})',
          exemplo: 'ROMAN(5)',
        },
        {
          funcao: 'ROUND',
          ajuda:
            'Arredonda um número para um número especificado de casas decimais',
          texto: 'ROUND({numero}, {numero_de_casas})',
          exemplo: 'ROUND(10.567, 2)',
        },
        {
          funcao: 'ROUNDDOWN',
          ajuda:
            'Arredonda um número para baixo para um número específico de casas decimais',
          texto: 'ROUNDDOWN({numero}, {numero_de_casas})',
          exemplo: 'ROUNDDOWN(10.567, 2)',
        },
        {
          funcao: 'ROUNDUP',
          ajuda:
            'Arredonda um número para cima para um número específico de casas decimais',
          texto: 'ROUNDUP({numero}, {numero_de_casas})',
          exemplo: 'ROUNDUP(10.567, 2)',
        },
        {
          funcao: 'SERIESSUM',
          ajuda:
            'Retorna a soma de uma série infinita utilizando os termos da série',
          texto: 'SERIESSUM({x}, {n}, {a}, {b})',
          exemplo: 'SERIESSUM(0.5, 10, 1, 2)',
        },
        {
          funcao: 'SIGN',
          ajuda: 'Retorna o sinal de um número',
          texto: 'SIGN({numero})',
          exemplo: 'SIGN(10.5)',
        },
        {
          funcao: 'SIN',
          ajuda: 'Retorna o seno de um ângulo',
          texto: 'SIN({angulo})',
          exemplo: 'SIN(0.5)',
        },
        {
          funcao: 'SINH',
          ajuda: 'Retorna o seno hiperbólico de um número',
          texto: 'SINH({numero})',
          exemplo: 'SINH(1.5)',
        },
        {
          funcao: 'SQRT',
          ajuda: 'Retorna a raiz quadrada de um número',
          texto: 'SQRT({numero})',
          exemplo: 'SQRT(25)',
        },
        {
          funcao: 'SQRTPI',
          ajuda:
            'Retorna a raiz quadrada do produto de um número pelo valor de Pi',
          texto: 'SQRTPI({numero})',
          exemplo: 'SQRTPI(10)',
        },
        {
          funcao: 'SUBTOTAL',
          ajuda: 'Retorna um subtotal em uma lista ou banco de dados',
          texto: 'SUBTOTAL({numero_de_funcao}, {intervalo1}, ...{intervaloN})',
          exemplo: 'SUBTOTAL(9, {intervalo})',
        },
        {
          funcao: 'SUM',
          ajuda: 'Retorna a soma dos valores',
          texto: 'SUM({valor1}, {valor2}, ...{valorN})',
          exemplo: 'SUM(1,5,7)',
        },
        {
          funcao: 'SUMIF',
          ajuda:
            'Retorna a soma dos valores de um intervalo que atendem a um critério específico',
          texto: 'SUMIF({intervalo}, {criterio}, {intervalo_soma})',
          exemplo: 'SUMIF({intervalo}, ">5", {intervalo2})',
        },
        {
          funcao: 'SUMIFS',
          ajuda:
            'Retorna a soma dos valores de um intervalo que atendem a múltiplos critérios',
          texto:
            'SUMIFS({intervalo_soma}, {intervalo1}, {criterio1}, {intervalo2}, {criterio2}, ...)',
          exemplo: 'SUMIFS(A1:A10, B1:B10, ">5", C1:C10, "<10")',
        },
        {
          funcao: 'SUMPRODUCT',
          ajuda:
            'Retorna o produto escalar de matrizes após realizar operações de multiplicação',
          texto: 'SUMPRODUCT({matriz1}, {matriz2}, ...)',
          exemplo: 'SUMPRODUCT({matriz1}, {matriz2})',
        },
        {
          funcao: 'SUMSQ',
          ajuda: 'Retorna a soma dos quadrados dos valores',
          texto: 'SUMSQ({valor1}, {valor2}, ...)',
          exemplo: 'SUMSQ(2,5,6)',
        },
        {
          funcao: 'CLOSESTPOINT',
          ajuda: 'Retorna um valor do ponto mais próximo',
          texto: 'CLOSESTPOINT(ponto_procurado, [{pontos}], [{valores}])',
          exemplo: 'CLOSESTPOINT(10, [1,5,7.5,15], [0.01,0.02,0.03,0.04])'
        },
        {
          funcao: 'TAN',
          ajuda: 'Retorna a tangente de um ângulo',
          texto: 'TAN({angulo})',
          exemplo: 'TAN(0.5)',
        },
        {
          funcao: 'TANH',
          ajuda: 'Retorna a tangente hiperbólica de um número',
          texto: 'TANH({numero})',
          exemplo: 'TANH(1.5)',
        },
        {
          funcao: 'TRUNC',
          ajuda:
            'Trunca um número para o número especificado de casas decimais',
          texto: 'TRUNC({numero}, {numero_de_casas})',
          exemplo: 'TRUNC(10.567, 2)',
        },
        {
          funcao: 'AVERAGE',
          ajuda: 'Retorna a média dos valores',
          texto: 'AVERAGE({valor1}, {valor2}, ...{valorN})',
          exemplo: 'AVERAGE(1,2,3,4,5)',
        },
        {
          funcao: 'AVERAGEA',
          ajuda:
            'Retorna a média dos valores, incluindo texto e valores lógicos',
          texto: 'AVERAGEA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'AVERAGEA(1,2,3,4,5)',
        },
        {
          funcao: 'COUNT',
          ajuda: 'Retorna o número de valores em um intervalo',
          texto: 'COUNT({valor1}, {valor2}, ...{valorN})',
          exemplo: 'COUNT(1,2,3,4,5)',
        },
        {
          funcao: 'COUNTA',
          ajuda: 'Retorna o número de valores não vazios em um intervalo',
          texto: 'COUNTA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'COUNTA(1,2,3,4,5)',
        },
        {
          funcao: 'COUNTBLANK',
          ajuda: 'Retorna o número de células vazias em um intervalo',
          texto: 'COUNTBLANK({intervalo})',
          exemplo: 'COUNTBLANK({intervalo})',
        },
        {
          funcao: 'COUNTIF',
          ajuda:
            'Retorna o número de células em um intervalo que atendem a um critério específico',
          texto: 'COUNTIF({intervalo}, {criterio})',
          exemplo: 'COUNTIF({intervalo}, ">5")',
        },
        {
          funcao: 'COUNTIFS',
          ajuda:
            'Retorna o número de células em um intervalo que atendem a múltiplos critérios',
          texto:
            'COUNTIFS({intervalo1}, {criterio1}, {intervalo2}, {criterio2}, ...)',
          exemplo: 'COUNTIFS({intervalo1}, ">5", {intervalo2}, "<10")',
        },
        {
          funcao: 'DEVSQ',
          ajuda:
            'Retorna a soma dos quadrados das diferenças entre os valores e a média',
          texto: 'DEVSQ({valor1}, {valor2}, ...{valorN})',
          exemplo: 'DEVSQ(5,6)',
        },
        {
          funcao: 'FISHER',
          ajuda: 'Retorna a transformada Fisher de um número',
          texto: 'FISHER({numero})',
          exemplo: 'FISHER(0.5)',
        },
        {
          funcao: 'GEOMEAN',
          ajuda: 'Retorna a média geométrica dos valores',
          texto: 'GEOMEAN({valor1}, {valor2}, ...{valorN})',
          exemplo: 'GEOMEAN(1,8)',
        },
        {
          funcao: 'MAX',
          ajuda: 'Retorna o maior valor em um intervalo',
          texto: 'MAX({valor1}, {valor2}, ...{valorN})',
          exemplo: 'MAX(1,5,4)',
        },
        {
          funcao: 'MAXA',
          ajuda:
            'Retorna o maior valor em um intervalo, incluindo texto e valores lógicos',
          texto: 'MAXA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'MAXA(1,6,8,4)',
        },
        {
          funcao: 'MEDIAN',
          ajuda: 'Retorna a mediana dos valores',
          texto: 'MEDIAN({valor1}, {valor2}, ...{valorN})',
          exemplo: 'MEDIAN(1,8,7)',
        },
        {
          funcao: 'MIN',
          ajuda: 'Retorna o menor valor em um intervalo',
          texto: 'MIN({valor1}, {valor2}, ...{valorN})',
          exemplo: 'MIN(5,9,8)',
        },
        {
          funcao: 'MINA',
          ajuda:
            'Retorna o menor valor em um intervalo, incluindo texto e valores lógicos',
          texto: 'MINA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'MINA(4,8,7,9)',
        },
        {
          funcao: 'STDEV',
          ajuda:
            'Retorna o desvio padrão de uma população com base em uma amostra',
          texto: 'STDEV({valor1}, {valor2}, ...{valorN})',
          exemplo: 'STDEV(4,5,7,8)',
        },
        {
          funcao: 'STDEVA',
          ajuda:
            'Retorna o desvio padrão de uma população com base em uma amostra, incluindo texto e valores lógicos',
          texto: 'STDEVA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'STDEVA(5,4,8)',
        },
        {
          funcao: 'STDEVP',
          ajuda: 'Retorna o desvio padrão de uma população',
          texto: 'STDEVP({valor1}, {valor2}, ...{valorN})',
          exemplo: 'STDEVP(5,9,8)',
        },
        {
          funcao: 'STDEVPA',
          ajuda:
            'Retorna o desvio padrão de uma população, incluindo texto e valores lógicos',
          texto: 'STDEVPA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'STDEVPA(8,7,9)',
        },
        {
          funcao: 'VAR',
          ajuda: 'Retorna a variância de uma população com base em uma amostra',
          texto: 'VAR({valor1}, {valor2}, ...{valorN})',
          exemplo: 'VAR(8,7,4)',
        },
        {
          funcao: 'VARA',
          ajuda:
            'Retorna a variância de uma população com base em uma amostra, incluindo texto e valores lógicos',
          texto: 'VARA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'VARA(2,5,6)',
        },
        {
          funcao: 'VARP',
          ajuda: 'Retorna a variância de uma população',
          texto: 'VARP({valor1}, {valor2}, ...{valorN})',
          exemplo: 'VARP(8,4,6)',
        },
        {
          funcao: 'VARPA',
          ajuda:
            'Retorna a variância de uma população, incluindo texto e valores lógicos',
          texto: 'VARPA({valor1}, {valor2}, ...{valorN})',
          exemplo: 'VARPA(6,9,8)',
        },
        {
          funcao: 'ASC',
          ajuda: 'Retorna o código ASCII de um caractere',
          texto: 'ASC({caractere})',
          exemplo: 'ASC("A")',
        },
        {
          funcao: 'CHAR',
          ajuda: 'Retorna o caractere associado a um código ASCII',
          texto: 'CHAR({codigo})',
          exemplo: 'CHAR(65)',
        },
        {
          funcao: 'CLEAN',
          ajuda: 'Remove todos os caracteres não imprimíveis de um texto',
          texto: 'CLEAN({texto})',
          exemplo: 'CLEAN("Olá!")',
        },
        {
          funcao: 'CODE',
          ajuda: 'Retorna o código ASCII do primeiro caractere em um texto',
          texto: 'CODE({texto})',
          exemplo: 'CODE("A")',
        },
        {
          funcao: 'CONCATENATE',
          ajuda: 'Concatena vários textos em um único texto',
          texto: 'CONCATENATE({texto1}, {texto2}, ...{textoN})',
          exemplo: 'CONCATENATE("Olá", " ", "K&L")',
        },
        {
          funcao: 'DOLLAR',
          ajuda: 'Formata um número como texto com um símbolo de moeda',
          texto: 'DOLLAR({numero}, {casas_decimais})',
          exemplo: 'DOLLAR(1000, 2)',
        },
        {
          funcao: 'EXACT',
          uda: 'Verifica se dois textos são exatamente iguais',
          texto: 'EXACT({texto1}, {texto2})',
          exemplo: 'EXACT("K&L", "K&L")',
        },
        {
          funcao: 'FIND',
          ajuda: 'Localiza a posição de um texto dentro de outro texto',
          texto: 'FIND({texto_procurado}, {texto}, {posicao_inicial})',
          exemplo: 'FIND("K&L", "K&L - Laboratórios de metrologia", 1)',
        },
        {
          funcao: 'FIXED',
          ajuda:
            'Formata um número como texto com um número fixo de casas decimais',
          texto: 'FIXED({numero}, {casas_decimais}, {sem_notacao_cientifica})',
          exemplo: 'FIXED(123.456, 2, TRUE)',
        },
        {
          funcao: 'LEFT',
          ajuda:
            'Extrai uma determinada quantidade de caracteres do começo de um texto',
          texto: 'LEFT({texto}, {num_caracteres})',
          exemplo: 'LEFT("K&L", 1)',
        },
        {
          funcao: 'LEN',
          ajuda: 'Retorna o comprimento de um texto',
          texto: 'LEN({texto})',
          exemplo: 'LEN("K&L")',
        },
        {
          funcao: 'LOWER',
          ajuda: 'Converte um texto em minúsculas',
          texto: 'LOWER({texto})',
          exemplo: 'LOWER("K&L")',
        },
        {
          funcao: 'MID',
          ajuda:
            'Extrai uma determinada quantidade de caracteres de um texto a partir de uma posição inicial',
          texto: 'MID({texto}, {posicao_inicial}, {num_caracteres})',
          exemplo: 'MID("K&L", 1, 2)',
        },
        {
          funcao: 'PROPER',
          ajuda:
            'Converte um texto em título, com a primeira letra de cada palavra em maiúscula',
          texto: 'PROPER({texto})',
          exemplo: 'PROPER("Olá K&L")',
        },
        {
          funcao: 'REPLACE',
          ajuda: 'Substitui uma parte de um texto por outro texto',
          texto:
            'REPLACE({texto}, {posicao_inicial}, {num_caracteres}, {novo_texto})',
          exemplo: 'REPLACE("k&l - Laboratórios de metrologia", 0, 3, "K&L")',
        },
        {
          funcao: 'REPT',
          ajuda: 'Repete um determinado texto um número específico de vezes',
          texto: 'REPT({texto}, {num_repeticoes})',
          exemplo: 'REPT("abc", 3)',
        },
        {
          funcao: 'RIGHT',
          ajuda:
            'Extrai uma determinada quantidade de caracteres do final de um texto',
          texto: 'RIGHT({texto}, {num_caracteres})',
          exemplo: 'RIGHT("K&L - Laboratórios de metrologia", 3)',
        },
        {
          funcao: 'SEARCH',
          ajuda:
            'Localiza a posição de um texto dentro de outro texto (ignorando diferenças de maiúsculas e minúsculas)',
          texto: 'SEARCH({texto_procurado}, {texto}, {posicao_inicial})',
          exemplo: 'SEARCH("K&L", "K&L - Laboratórios de metrologia", 1)',
        },
        {
          funcao: 'SUBSTITUTE',
          ajuda:
            'Substitui todas as ocorrências de um texto por outro texto em uma cadeia de texto',
          texto:
            'SUBSTITUTE({texto}, {texto_antigo}, {texto_novo}, {num_ocorrencias})',
          exemplo:
            'SUBSTITUTE("K&L - Laboratório de metrologia", "Laboratório", "Laboratórios", 1)',
        },
        {
          funcao: 'T',
          ajuda: 'Retorna o argumento como texto',
          texto: 'T({valor})',
          exemplo: 'T({valor})',
        },
        {
          funcao: 'TEXT',
          ajuda: 'Formata um valor como texto usando um formato específico',
          texto: 'TEXT({valor}, {formato})',
          exemplo: 'TEXT({valor}, "00000")',
        },
        {
          funcao: 'TRIM',
          ajuda: 'Remove espaços em branco extras de um texto',
          texto: 'TRIM({texto})',
          exemplo: 'TRIM(" Olá K&L ")',
        },
        {
          funcao: 'UPPER',
          ajuda: 'Converte um texto em maiúsculas',
          texto: 'UPPER({texto})',
          exemplo: 'UPPER("Olá K&L")',
        },
        {
          funcao: 'VALUE',
          ajuda: 'Converte um texto em um número',
          texto: 'VALUE({texto})',
          exemplo: 'VALUE("123")',
        },
      ],
    };
  },
  computed: {
    regraObrigatorio: function () {
      return this.obrigatorio && this.conteudo.length == 0 && this.validado;
    },
    tokens: function () {
      return this.tokenizeExpression(this.conteudo);
    },
    codigosHtml: function () {
      return this.formatarTagsHtml(this.tokens);
    },
    variaveisFiltrada: function () {
      return this.variaveis
        .filter((el) => el.includes(this.ultimoTextoDigitado))
        .sort((a, b) => {
          return a.localeCompare(b, 'pt-BR', { sensitivity: 'base' });
        });
    },
    funcoesFiltrada: function () {
      return _.sortBy(
        this.funcoes.filter((el) =>
          el.funcao.includes(this.ultimoTextoDigitado)
        ),
        ['funcao']
      );
    },
    erros: function () {
      return this.retonarErros(this.tokens);
    },
    options: function () {
      return [
        ...this.variaveisFiltrada.map((el) => {
          return {
            value: {
              value: el,
              funcao: false,
            },
            text: el,
          };
        }),
        ...this.funcoesFiltrada.map((el) => {
          return {
            value: {
              value: el.funcao,
              elemento: el,
              funcao: true,
            },
            text: el.funcao,
          };
        }),
      ];
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value !== undefined && value !== null) this.conteudo = value;
        else this.conteudo = '';
        this.alterarTamanhoInput();
      },
      deep: true,
      immediate: true,
    },
    conteudo: function () {
      this.$emit('input', this.conteudo);
    },
    formulaMensurandoId: function () {
      this.buscarVariaveis();
    },
    sugestoesAberto: function () {
      if (this.sugestoesAberto) {
        this.$nextTick(() => {
          this.$refs.selectMenu.activateMenu();
        });
      } else {
        this.$refs.selectMenu.blur();
        this.sugestoesFocus = false;
      }
    },
  },
  mounted() {
    let ctx = this;
    window.addEventListener('resize', function (e) {
      if (e.target) return;
      ctx.alterarTamanhoInput();
    });

    window.addEventListener('click', function (e) {
      ctx.$refs.selectMenu?.blur();
      if (!e.target) {
        return;
      }
      try {
        if (!e.target?.className?.includes('input-ide')) {
          ctx.sugestoesAberto = false;
          ctx.errosAberto = false;
        }
      } catch {
        //eslint-disable-next-line
      }
    });
    this.buscarVariaveis();
  },
  methods: {
    validar: function () {
      this.validado = true;
    },
    desvalidar: function () {
      this.validado = false;
    },
    buscarVariaveis: function (removerVariavel) {
      this.variaveis = [];
      if (!this.formulaMensurandoId) return;
      FormulaService.buscarVariaveisCalculo(this.formulaMensurandoId).then(
        (res) => {
          let variaveis = JSON.parse(Buffer.from(res.data, 'base64').toString('utf-8'));
          if (removerVariavel) {
            if (removerVariavel in variaveis) {
              delete variaveis[removerVariavel]
            }
          }
          this.variaveis = this.formatarVariaveis(variaveis, '');
        }
      );
    },
    ehValido: function () {
      return this.erros.length == 0 && !this.regraObrigatorio;
    },
    abrirErros: function () {
      this.errosAberto = true;
    },
    retonarErros: function (tokens, pai = null) {
      let tags = [];
      let ctx = this;
      let tokenAnterior = null;
      tokens.forEach((token, i) => {

        let tokenClone = _.cloneDeep(token);
        delete tokenClone.filhos;
        if (
          tokenClone.tipo == 'error' ||
          tokenClone.tipo.includes('inexistente')
        ) {
          tokenAnterior = tokenClone;
          tags.push(tokenClone);
          return;
        }

        if (
          (tokenClone.tipo == 'variavel' || tokenClone.tipo == 'numerico') &&
          !(
            tokenAnterior == null ||
            tokenAnterior.tipo == 'operador' ||
            tokenAnterior.tipo == 'virgula'
          )
        ) {
          tokenClone.tipo = 'expressao-invalida';
          tags.push(tokenClone);
        }

        if (tokenClone.tipo == 'virgula' && (!pai || pai.tipo != 'funcao')) {
          tokenClone.tipo = 'uso-invalido';
          tags.push(tokenClone);
        }

        if (tokenClone.tipo == 'operador' || tokenClone.tipo == 'virgula') {
          let invalido = tokenAnterior == null || i == tokens.length - 1;
          if (
            tokenAnterior == null &&
            tokens[i].expressao == '-' &&
            i < tokens.length
          ) {
            const tipo = tokens[i + 1].tipo;
            invalido =
              tipo != 'numerico' && tipo != 'funcao' && tipo != 'expressao';
          }

          if (!invalido) {
            let j = i + 1;
            for (j = i + 1; j < tokens.length; j++) {
              if (tokens[j].tipo != 'espaco') break;
            }
            if (tokens.length > j) {
              invalido =
                (tokens[j].tipo == 'operador' ||
                  tokens[j].tipo == 'virgula' ||
                  tokens[j].tipo == 'espaco') &&
                tokens[j].expressao != '-';
            } else invalido = true;
          }

          if (invalido) {
            tokenClone.tipo = 'uso-invalido';
            tags.push(tokenClone);
          }
        }

        if (tokenClone.tipo != 'espaco') tokenAnterior = tokenClone;
        if (token.filhos)
          tags.push(...ctx.retonarErros(token.filhos, tokenClone));
      });

      return tags;
    },
    alterarTamanhoInput: function () {
      this.$nextTick(() => {
        const el = this.$refs['input'];
        if (el == null) return;
        el.style.height = 'auto';
        el.style.height = el.scrollHeight + 'px';
        setTimeout(() => {
          el.style.height = 'auto';
          el.style.height = el.scrollHeight + 'px';
        }, 0);
      });
    },
    adicionarTexto2: function (e) {
      this.adicionarTexto(e, e.funcao);
    },
    adicionarTexto: function (conteudo, funcao = false) {
      let startPos = this.ultimaPosicaoPonteiro,
        endPos = this.$refs.input.selectionEnd,
        cursorPos = startPos,
        conteudoTemporario = this.conteudo;

      let conteudoInserir = conteudo.value;
      if (funcao) conteudoInserir = conteudo.elemento.texto;
      // else {
      //   conteudoInserir = conteudoInserir.replace(this.ultimoTextoDigitado, '');
      // }

      this.conteudo =
        conteudoTemporario.substring(0, startPos) +
        conteudoInserir +
        conteudoTemporario.substring(endPos, conteudoTemporario.length);

      this.sugestoesAberto = false;
      setTimeout(() => {
        cursorPos += conteudoInserir.length;
        this.$refs.input.selectionStart = this.$refs.input.selectionEnd =
          cursorPos;
        this.$refs.input.focus();
      }, 10);
    },
    keyDown: function (e) {
      this.errosAberto = false;

      let ehfuncao = /^[A-Z]$/.test(e.key);
      if ((e.code == 'Space' && e.ctrlKey) || ehfuncao) {
        this.sugestoesAberto = true;
        this.sugestoesAbertoPor = 'Space';
        const selecaoInicial = this.$refs.input.selectionStart;
        this.ultimaPosicaoPonteiro = selecaoInicial;
        let expressao = '';
        for (let i = selecaoInicial - 1; i >= 0; i--) {
          const caractere = this.conteudo[i];
          if (' ,+-*/^='.includes(caractere)) break;

          this.ultimaPosicaoPonteiro = i;
          expressao = this.conteudo[i] + expressao;

          if (caractere == '{') break;
          if (this.conteudo[i] == '}') {
            expressao = '';
            break;
          }
        }


        this.ultimoTextoDigitado = expressao;
        if (expressao.includes('{')) this.sugestoesAbertoPor = 'BracketRight';
        // else this.ultimoTextoDigitado = '';
      }
      if (e.code == 'BracketRight') {
        this.sugestoesAberto = true;
        this.sugestoesAbertoPor = 'BracketRight';
        this.ultimoTextoDigitado = '{';
        this.ultimaPosicaoPonteiro = this.$refs.input.selectionStart;
      }

      if (!this.sugestoesAberto) this.ultimoTextoDigitado = '';
      else if (
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.code == 'Backspace' ||
        e.key == '.'
      ) {
        if (e.code == 'Backspace') {
          this.ultimoTextoDigitado = this.ultimoTextoDigitado.slice(0, -1);
          if (this.ultimoTextoDigitado.length == 0)
            this.sugestoesAberto = false;
        } else {
          this.ultimoTextoDigitado = this.ultimoTextoDigitado += e.key;
        }
      } else if (e.code == 'ArrowDown' && this.sugestoesAberto) {
        this.sugestoesFocus = true;
        this.$refs.selectMenu.focus();
        this.conteudoSelect = '';
      } else if (
        !(e.code == 'Space' && e.ctrlKey) &&
        e.code != 'BracketRight' &&
        !e.code.includes('Shift')
      ) {
        this.sugestoesAberto = false;
      }
    },
    focus: function () {
      this.$refs['input-div'].classList.add('input-ide__focus');
    },
    blur: function () {
      this.$refs['input-div'].classList.value =
        'input-ide ' +
        (this.erros.length > 0 || this.regraObrigatorio
          ? 'input-ide__error'
          : '');
      if (!this.sugestoesFocus) this.sugestoesAberto = false;
    },
    formatarTagsHtml: function (tokens) {
      let tags = [];
      let ctx = this;
      tokens.forEach((token) => {
        if (!token.filhos) {
          tags.push(token);
          return;
        }
        let tokenClone = _.cloneDeep(token);
        delete tokenClone.filhos;

        if (
          tokenClone.tipo == 'error' ||
          tokenClone.tipo.includes('inexistente')
        ) {
          tags.push(tokenClone);
          return;
        }

        let filhosJoin = `(${token.filhos.map((el) => el.expressao).join('')})`;
        tokenClone.expressao = `${tokenClone.expressao.replace(
          filhosJoin,
          ''
        )}()`;

        let tokenAntesParenteses = _.cloneDeep(tokenClone);
        let tokenDepoisParenteses = _.cloneDeep(tokenClone);

        tokenAntesParenteses.expressao = tokenClone.expressao.substring(
          0,
          tokenClone.expressao.indexOf('(') + 1
        );
        tokenDepoisParenteses.expressao = tokenClone.expressao = ')';

        tags.push(tokenAntesParenteses);
        tags.push(...ctx.formatarTagsHtml(token.filhos));
        tags.push(tokenDepoisParenteses);
      });

      return tags;
    },
    formatarVariaveis: function (objeto, prefixo = '') {
      let variaveis = [];
      let ctx = this;
      Object.entries(objeto).forEach(function (valor) {
        let campo = `${prefixo != '' ? `${prefixo}.` : ''}${valor[0]}`;
        if (typeof valor[1] !== 'object') variaveis.push(`{${campo}}`);
        if (typeof valor[1] === 'object' && !Array.isArray(valor[1]))
          variaveis.push(...ctx.formatarVariaveis(valor[1], campo));
        if (
          typeof valor[1] === 'object' &&
          Array.isArray(valor[1]) &&
          valor[1].length > 0
        ) {
          Object.entries(valor[1][0]).forEach(function (chave) {
            Object.entries(valor[1][0]).forEach(function (opcao) {
              variaveis.push(`{${campo}[(${chave[0]}=='')].${opcao[0]}}`);
            });
          });
        }
      });
      return variaveis;
    },
    tokenizeExpression: function (expression) {
      const tokens = [];
      let currentToken = '';

      const isNumeric = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      };

      const addToken = function (token, type) {
        if (tokens.length > 0 && tokens[tokens.length - 1].tipo == 'error') return;
        let tokenn = { expressao: token, tipo: type };
        tokens.push(tokenn);
        return tokenn;
      };

      const compararNumerico = function () {
        if (isNumeric(currentToken)) {
          addToken(currentToken, 'numerico');
        } else {
          addToken(currentToken, 'desconhecido');
        }
        currentToken = '';
      };

      for (let i = 0; i < expression.length; i++) {
        const char = expression[i];

        if (char === "'") {
          if (currentToken !== '') {
            compararNumerico();
          }
          let text = '';
          i++;
          while (i < expression.length && expression[i] !== "'") {
            text += expression[i];
            i++;
          }
          if (i < expression.length) {
            addToken(`'${text}'`, 'texto');
          } else {
            debugger;
            addToken(`'${text}`, 'error');
          }
        } else if (char === '{' || char === '[') {
          const closingChar = char === '{' ? '}' : ']';
          if (currentToken !== '') {
            compararNumerico();
          }
          let variable = '';
          i++;
          while (i < expression.length && expression[i] !== closingChar) {
            variable += expression[i];
            i++;
          }
          if (i < expression.length) {
            addToken(`${char}${variable}${closingChar}`, 'variavel');
            let expressaoVariavel = this.variaveis.find((el) => {
              return (
                el.replace(/\([^)]*\)/g, '') ==
                `${char}${variable.replace(/\([^)]*\)/g, '')}${closingChar}`
              );
            });
            if (!this.variaveis.includes(`${this.ensureBraces(variable)}`) && !expressaoVariavel) {
              tokens[tokens.length - 1].tipo = 'variavel-inexistente';
            }
          } else {
            addToken(`${char}${variable}`, 'error');
          }
        } else if (
          char === '+' ||
          char === '-' ||
          char === '*' ||
          char === '/' ||
          char === '^' ||
          char === ',' ||
          char === '=' ||
          char === ' '
        ) {
          if (currentToken !== '') {
            compararNumerico();
          }
          if (char === ',') addToken(char, 'virgula');
          else if (char === ' ') addToken(char, 'espaco');
          else addToken(char, 'operador');
        } else if (char === '(') {
          let t = null;
          if (currentToken !== '') {
            t = addToken(currentToken, 'funcao');
            currentToken = '';
          }
          let parenCount = 1;
          let subExpression = '';
          i++;
          while (parenCount > 0 && i < expression.length) {
            if (expression[i] === '(') {
              parenCount++;
            } else if (expression[i] === ')') {
              parenCount--;
            }
            if (parenCount > 0) {
              subExpression += expression[i];
            }
            i++;
          }
          i--;
          const subTokens = this.tokenizeExpression(subExpression);
          if (!t) {
            addToken(`(${subExpression})`, 'expressao');
          } else {
            t.expressao += `(${subExpression}${tokens[tokens.length - 1].tipo == 'error' ? '' : ')'}`;
            t.nomeFuncao = t.expressao.substring(0, t.expressao.indexOf('('));
            if (!this.funcoes.find((el) => el.funcao == t.nomeFuncao)) {
              t.tipo = 'funcao-inexistente';
            }
          }
          tokens[tokens.length - 1].filhos = subTokens;
        } else if (char === ' ') {
          continue;
        } else {
          currentToken += char;
        }
      }

      if (currentToken !== '') {
        compararNumerico();
      }

      return tokens;
    },
    ensureBraces(str) {
    if (!str.startsWith('{')) {
        str = '{' + str;
    }
    if (!str.endsWith('}')) {
        str = str + '}';
    }
    return str;
}
  },
};
</script>
<style lang="scss" scoped>
.input-ide {
  border: 1px solid #9e9e9e;
  border-radius: 8px;

  &:hover {
    border-color: #333;
  }

  &.input-ide__focus {
    border-color: #0d47a1;
    border-width: 2px;
  }

  .input-ide__icon__erro {
    display: none;
  }

  &.input-ide__error {
    border-color: #ff5252 !important;
    border-width: 2px;

    .input-ide__codigos {
      margin-right: 40px;
    }

    .input-ide__icon__erro {
      display: block;
    }

    .input-ide__icon__erro {
      display: block;
    }
  }

  .input-ide__wrapper {
    position: relative;
    display: flex;
    border-radius: 8px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 3px 0;

    .input-ide__textarea {
      width: 100%;
      resize: none;
      border-width: 0px;
      background-color: transparent;
      padding: 0.25rem 16px;
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        Liberation Mono, Courier New, monospace !important;
      color: transparent;
      outline: 2px solid transparent;
      outline-offset: 2px;
      caret-color: #000;

      &.disabled {
        background: #f9fafb;
      }
    }

    .input-ide__codigos {
      pointer-events: none;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      overflow: hidden;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      padding: 7px 16px;
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        Liberation Mono, Courier New, monospace !important;
    }

    .input-ide__funcao-inexistente,
    .input-ide__error,
    .input-ide__variavel-inexistente {
      color: red;
    }

    .input-ide__variavel {
      color: blueviolet;
    }

    .input-ide__operador {
      color: grey;
    }

    .input-ide__texto {
      color: orange;
    }

    .input-ide__numerico {
      color: green;
    }

    .input-ide__expressao {
      color: cyan;
    }

    .input-ide__funcao {
      color: blue;
    }
  }
}

.select {
  border: none !important;

  .input-ide__wrapper {
    background: transparent !important;
    padding: 0 !important;
  }

  .input-ide__codigos {
    padding: 0 !important;
  }
}

.input-ide__erros {
  position: relative;
  font-size: 14px;

  .input-ide__erros_wrapper {
    margin-top: 5px;
    position: absolute;
    width: 100%;
    z-index: 999;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    max-height: 208px;
    overflow: auto;
    padding: 10px;
    background: bisque;

    .input-ide__mensagem_erro {
      font-size: 13px;
      color: red;
      margin-top: 10px;
    }

    .input-ide__erros_expressao {
      background: #fff;
      padding: 10px;
      border-radius: 4px;
    }
  }
}

.input-ide__sugestoes {
  position: relative;
  font-size: 14px;

  .input-ide__sugestoes_wrapper {
    position: absolute;
    margin-top: 5px;
    width: 100%;
    z-index: 999;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    max-height: 208px;
    overflow: auto;

    .input-ide__variavel {
      background: #fff;
    }

    .input-ide__funcao {
      background: #e4efff;
    }

    ul {
      padding: 0;
    }

    li {
      list-style: none;
      border-bottom: 1px solid #c2c2c2;

      &:hover {
        background: #e4e4e4;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.input-select-ide {
  &.sem-display {
    display: none;

    .v-select__slot {
      display: none;
    }
  }
}

.menu-input-ide {

  .v-list-item,
  .v-list-item--dense {
    min-height: 28px !important;
  }
}
</style>
