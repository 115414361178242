import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumFormulaValidacao.SomenteErro'),
    value: 'SomenteErro',

  },
  {
    text: i18n.t('enums.EnumFormulaValidacao.SomenteIncerteza'),
    value: 'SomenteIncerteza',

  },
  {
    text: i18n.t('enums.EnumFormulaValidacao.ErroIncertezaCombinado'),
    value: 'ErroIncertezaCombinado',

  },
  {
    text: i18n.t('enums.EnumFormulaValidacao.Formula'),
    value: 'Formula ',

  },

];
