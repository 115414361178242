import { AuthApi } from '@common/api';
const ParametroService = {
  buscar: function () {
    return AuthApi.get()(`/conta/parametrogeral`);
  },
  salvar: function (form) {
    return AuthApi.put()(`/conta/parametrogeral`, form);
  },
};

export default ParametroService;
