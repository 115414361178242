<template lang="">
  <div class="input-padrao">
    <v-text-field
      :id="id"
      ref="input"
      v-model="valor"
      v-mask="mascaraInput"
      :dense="dense"
      :rules="regras"
      :disabled="disabled"
      :readonly="readonly"
      outlined
      hide-details="auto"
      :type="tipo"
      :loading="carregando"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.tab="onTab"
      @keydown.enter="onEnter"
      @keydown="validarDigitacao"
    >
      <template
        v-for="slot in slots"
        v-slot:[`${slot}`]
      >
        <slot :name="slot" />
        <span :key="slot" />
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
import _ from 'lodash';
import helpers from '@common/utils/helpers';
export default {
  name: 'InputText',
  mixins: [InputMixin],
  props: {
    sistema: { type: String },
    naoAplicarMascara: { type: Boolean, default: false },
    /** Props de validação */
    obrigatorio: { type: Boolean, default: false },
  },
  data() {
    return {
      conteudo: '',
      valor: '',
      show: false,
      carregando: false,
      mascaraInput: '',
      debouce: null,
    };
  },
  computed: {
    regras() {
      let regras = _.cloneDeep(this.regrasPersonalizadas);
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
    tipo: function () {
      return this.sistema == 'Sexagesimal' ? 'text' : 'number';
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
        this.valor = value;
        if (this.naoAplicarMascara) return;
        if (
          value !== null &&
          value !== undefined &&
          value !== '' &&
          this.sistema == 'Sexagesimal'
        ) 
          this.valor = helpers.decimalParaAngulo(value);
      },
      deep: true,
      immediate: true,
    },
    valor: function () {
      let regex1 = /^-?[0-9]{1,3} /.test(this.valor);
      let regex2 = /^-?[0-9]{1,3}° .*/.test(this.valor);
      if (regex1 || regex2) {
        this.debouce = _.debounce(function () {
          let indice = this.valor.indexOf(regex2 ? '°' : ' ');
          if (indice == -1) {
            this.mascaraInput = '';
            return;
          }

          if(this.valor.substring(0,1) == '-')
            this.mascaraInput = `-${'#'.repeat(indice - 1)}° ##' ##"`;
          else
            this.mascaraInput = `${'#'.repeat(indice)}° ##' ##"`;

        }, 100);
        this.debouce();
        return;
      }
      this.mascaraInput = '';
    },
  },
  methods: {
    validarDigitacao: function (e) {
      if (this.naoAplicarMascara) return;
      if (this.sistema == 'Sexagesimal') {
        var regex = /^[0-9 ,.]$/;
        if (!regex.test(e.key) && e.key != 'Backspace' && e.key != '-') e.preventDefault();
      }
    },
    onBlur() {
      this.conteudo = this.valor;
      if (!this.naoAplicarMascara) {
        if (
          this.valor !== undefined &&
          this.valor !== '' &&
          this.valor !== null &&
          this.sistema == 'Sexagesimal'
        ) {
          let regCompleto = /^-?[0-9]{1,3}° [0-9]{2}' [0-9]{2}"/.test(this.valor);
          let regSomenteEspaco = /^[0-9]{1,3} /.test(this.valor);
          let regComGrau = /^[0-9]{1,3}° .*/.test(this.valor);
          if ((regSomenteEspaco || regComGrau) && !regCompleto) this.valor = '';
          else {
            if (!regCompleto) {
              this.valor = helpers.decimalParaAngulo(
                this.valor > 360 ? 360 : this.valor
              );
            }
          }
          let decimal = helpers.anguloParaDecimal(this.valor);
          if (!isNaN(decimal))
            this.conteudo = helpers.anguloParaDecimal(this.valor);
          else this.conteudo = '';
        }
      }
      this.$emit('input', this.conteudo);
      this.$emit('blur');
    },
    onTab(e) {
      if (this.preventTab) e.preventDefault();
      this.$emit(e.shiftKey ? 'shit-tab' : 'tab');
    },
    onEnter(e) {
      e.preventDefault();
      this.$emit('enter');
      this.$emit('tab');
    },
    onFocus(e) {
      e.target.select();
      this.$emit('focus');
    },
    onEsc(e) {
      this.$emit('esc', e);
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    iniciarCarregamento() {
      this.carregando = true;
    },
    terminarCarregamento() {
      this.carregando = false;
    },
    alterarValor(valor) {
      this.conteudo = valor;
      this.$emit('input', this.conteudo);
    },
  },
};
</script>
