import { BaixoProxima, CimaProxima } from '@/assets/icons';
import {
  mdiAccountBox,
  mdiAccountBoxOutline,
  mdiAccountCardOutline,
  mdiAccountClockOutline,
  mdiAccountDetails,
  mdiAccountGroup,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiAlphaICircleOutline,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiBell,
  mdiBellBadge,
  mdiBellBadgeOutline,
  mdiBellOutline,
  mdiCalculatorVariantOutline,
  mdiCalendarBlank,
  mdiCameraRetakeOutline,
  mdiCancel,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiCheckDecagramOutline,
  mdiChevronDown,
  mdiChevronDownCircleOutline,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircleSmall,
  mdiClipboardEditOutline,
  mdiClipboardSearchOutline,
  mdiClipboardTextOutline,
  mdiClock,
  mdiClockOutline,
  mdiClose,
  mdiCloseBoxOutline,
  mdiCloseCircle,
  mdiCloseCircleOutline,
  mdiCloudArrowUp,
  mdiCloudDownloadOutline,
  mdiCog,
  mdiCogOutline,
  mdiComment,
  mdiCommentAccountOutline,
  mdiCommentQuestion,
  mdiContentCopy,
  mdiContentSaveMoveOutline,
  mdiContentSaveOutline,
  mdiCurrencyUsd,
  mdiCurrencyUsdOff,
  mdiDelete,
  mdiDotsVertical,
  mdiEmailFastOutline,
  mdiEmailOutline,
  mdiEye,
  mdiEyeOff,
  mdiEyeOutline,
  mdiFileArrowLeftRightOutline,
  mdiFileCancelOutline,
  mdiFileCheckOutline,
  mdiFileClockOutline,
  mdiFileDocument,
  mdiFileDocumentAlertOutline,
  mdiFileDocumentArrowRightOutline,
  mdiFileDocumentEditOutline,
  mdiFileDocumentRemoveOutline,
  mdiFileEyeOutline,
  mdiFileLockOutline,
  mdiFileSearchOutline,
  mdiFileUploadOutline,
  mdiFilterOutline,
  mdiFormatColumns,
  mdiFunction,
  mdiInformationOutline,
  mdiLayers,
  mdiLockOutline,
  mdiLogout,
  mdiMagnify,
  mdiMagnifyPlusOutline,
  mdiMenu,
  mdiMenuDown,
  mdiMenuLeft,
  mdiMenuRight,
  mdiMinus,
  mdiMinusBox,
  mdiNumeric1CircleOutline,
  mdiNumeric2CircleOutline,
  mdiNumeric3CircleOutline,
  mdiOcr,
  mdiOctagonOutline,
  mdiPaperclipPlus,
  mdiPencilPlus,
  mdiPlay,
  mdiPlayCircle,
  mdiPlayCircleOutline,
  mdiPlus,
  mdiPlusBox,
  mdiPlusCircleOutline,
  mdiPlusThick,
  mdiPower,
  mdiPublish,
  mdiQrcode,
  mdiRefresh,
  mdiReply,
  mdiRulerSquareCompass,
  mdiSegment,
  mdiStickerTextOutline,
  mdiStopCircle,
  mdiTimerEdit,
  mdiTrashCanOutline,
  mdiVariable,
  mdiWeight,
  mdiWhiteBalanceSunny,
  mdiWrenchClock,
  mdiWrenchCog,
  mdiPageNext,
  mdiPagePrevious,
  mdiFileCheck,
  mdiBank,
  mdiTruckDeliveryOutline,
  mdiTimerSand,
  mdiMinusCircleOutline,
  mdiCheckAll,
} from '@mdi/js';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';

const fadeTransition = createSimpleTransition('v-fade-transition');
Vue.component('v-fade-transition', fadeTransition);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#35507b',
        danger: '#D92D20',
        red: '#B42318',
        cinzaTabela: '#F2F4F7',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      email: mdiEmailOutline,
      formatColumns: mdiFormatColumns,
      lock: mdiLockOutline,
      search: mdiMagnify,
      sunny: mdiWhiteBalanceSunny,
      bell: mdiBell,
      bellBadge: mdiBellBadge,
      segment: mdiSegment,
      file: mdiFileDocument,
      accountBox: mdiAccountBox,
      layers: mdiLayers,
      dotCircle: mdiCircleSmall,
      close: mdiClose,
      mdiReply: mdiReply,
      alertWarningtOutline: mdiAlertOutline,
      successCircleOutline: mdiCheckCircleOutline,
      infoCircleOutline: mdiInformationOutline,
      closeCircleOutline: mdiCloseCircleOutline,
      closeCircle: mdiCloseCircle,
      dotsVertical: mdiDotsVertical,
      downCircleOutline: mdiChevronDownCircleOutline,
      filterOutline: mdiFilterOutline,
      closeBoxOutline: mdiCloseBoxOutline,
      minus: mdiMinus,
      plus: mdiPlus,
      minusBox: mdiMinusBox,
      plusBox: mdiPlusBox,
      rightArrow: mdiChevronRight,
      downArrow: mdiChevronDown,
      upArrow: mdiChevronUp,
      menu: mdiMenu,
      menuRight: mdiMenuRight,
      menuLeft: mdiMenuLeft,
      menuDown: mdiMenuDown,
      arrowUp: mdiArrowUp,
      arrowDown: mdiArrowDown,
      arrowRight: mdiArrowRight,
      arrowLeft: mdiArrowLeft,
      info: mdiAlphaICircleOutline,
      clipboardEditOutline: mdiClipboardEditOutline,
      clipboardTextOutline: mdiClipboardTextOutline,
      fileDocumentAlertOutline: mdiFileDocumentAlertOutline,
      currencyUsd: mdiCurrencyUsd,
      clockOutline: mdiClockOutline,
      numeric3CircleOutline: mdiNumeric3CircleOutline,
      numeric2CircleOutline: mdiNumeric2CircleOutline,
      numeric1CircleOutline: mdiNumeric1CircleOutline,
      plusCircleOutline: mdiPlusCircleOutline,
      stickerTextOutline: mdiStickerTextOutline,
      clipboardSearchOutline: mdiClipboardSearchOutline,
      checkDecagramOutline: mdiCheckDecagramOutline,
      alertCircleOutline: mdiAlertCircleOutline,
      currencyUsdOff: mdiCurrencyUsdOff,
      calendar: mdiCalendarBlank,
      mdiTimerEdit: mdiTimerEdit,
      fileUploadOutline: mdiFileUploadOutline,
      fileClockOutline: mdiFileClockOutline,
      fileCheckOutline: mdiFileCheckOutline,
      fileDocumentRemoveOutline: mdiFileDocumentRemoveOutline,
      fileDocumentArrowRight: mdiFileDocumentArrowRightOutline,
      accountClockOutline: mdiAccountClockOutline,
      fileDocumentEditOutline: mdiFileDocumentEditOutline,
      fileEyeOutline: mdiFileEyeOutline,
      fileCancelOutline: mdiFileCancelOutline,
      fileLockOutline: mdiFileLockOutline,
      fileArrowLeftRight: mdiFileArrowLeftRightOutline,
      wrenchClock: mdiWrenchClock,
      wrenchCog: mdiWrenchCog,
      cogOutline: mdiCogOutline,
      logout: mdiLogout,
      accountBoxOutline: mdiAccountBoxOutline,
      accountGroup: mdiAccountGroup,
      power: mdiPower,
      cog: mdiCog,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      bellBadgeOutline: mdiBellBadgeOutline,
      bellOutline: mdiBellOutline,
      commentAccountOutline: mdiCommentAccountOutline,
      comment: mdiComment,
      calculatorVariantOutline: mdiCalculatorVariantOutline,
      weight: mdiWeight,
      fileSearchOutline: mdiFileSearchOutline,
      rulerSquareCompass: mdiRulerSquareCompass,
      paperclipPlus: mdiPaperclipPlus,
      clock: mdiClock,
      cloudArrowUp: mdiCloudArrowUp,
      delete: mdiDelete,
      download: mdiCloudDownloadOutline,
      ocr: mdiOcr,
      stopCircle: mdiStopCircle,
      playCircle: mdiPlayCircle,
      play: mdiPlay,
      playCircleOutline: mdiPlayCircleOutline,
      eyeOutline: mdiEyeOutline,
      variable: mdiVariable,
      function: mdiFunction,
      mdiCheck: mdiCheck,
      mdiContentSaveOutline: mdiContentSaveOutline,
      mdiCheckCircleOutline: mdiCheckCircleOutline,
      mdiCancel: mdiCancel,
      mdiTrashCanOutline: mdiTrashCanOutline,
      mdiPlusThick: mdiPlusThick,
      mdiArrowLeft: mdiArrowLeft,
      mdiContentSaveMoveOutline: mdiContentSaveMoveOutline,
      octagonOutline: mdiOctagonOutline,
      mdiAccountDetails: mdiAccountDetails,
      mdiMagnifyPlusOutline: mdiMagnifyPlusOutline,
      mdiRefresh: mdiRefresh,
      clipBoardCopy: mdiContentCopy,
      mdiEmailFastOutline: mdiEmailFastOutline,
      cimaProxima: {
        component: CimaProxima,
      },
      baixoProxima: {
        component: BaixoProxima,
      },
      mdiPublish: mdiPublish,
      mdiQrcode: mdiQrcode,
      mdiCameraRetakeOutline: mdiCameraRetakeOutline,
      pencilPlus: mdiPencilPlus,
      mdiAccountCardOutline: mdiAccountCardOutline,
      mdiPageNext,
      mdiPagePrevious,
      commentQuestion: mdiCommentQuestion,
      mdiFileCheck,
      mdiBank,
      mdiTruckDeliveryOutline,
      mdiTimerSand,
      mdiMinusCircleOutline,
      mdiCheckAll,
    },
  },
});
