export default {
  titulos: {
    listagem: 'Critérios de Aceitação',
    novo: 'Novo Critério de Aceitação',
    editar: 'Editar Critério de Aceitação',
  },
  cadastro_sucesso: 'Critérios de Aceitação salvo com sucesso!',
  exclusao_sucesso: 'Critérios de Aceitação excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    cliente: 'Cliente',
    faixa: 'Faixa',
    setor: 'Setor',
    resolucao: 'Resolução',
    tipo_instrumento: 'Tipo de Instrumento',
    criterio_generico: 'Genérico',
    tabela_registro: 'Tabela de Registro',
    instrumento: 'Instrumento',
  },
  formulario: {
    nome: 'Nome',
    tipo_instrumento: 'Tipo de Instrumento',
    cliente: 'Cliente',
    setor: 'Setor dos Instrumentos',
    unidade_medida: 'Unidade de Medida',
    amplitude: 'Amplitude',
    amplitude_min: 'Mínima',
    amplitude_max: 'Máxima',
    resolucao: 'Resolução',
    tipo_referencia: 'Tipo de Referência',
    valor_referencia: 'Valor de Referência',
    formula_validacao: 'Fórmula de Validação',
    criterio_generico: 'Critério Genérico',
    instrumento: 'Instrumento',
    tabela_registro: 'Tabela de Registro',
    valor_referencia_final: 'Valor de Referência Final',
    formula: 'Fórmula',
    titulo_modal_formula : 'Fórmulas'
  },
  filtro: {
    nome: 'Nome',
    tipo_instrumento: 'Tipo de Instrumento',
    cliente: 'Cliente',
    resolucao: 'Resolução',
    faixa: 'Faixa',
  },
  erros: {
    id_invalido: 'O id da Critério de Aceitação é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
};
