<template lang="">
  <tabela-padrao-prime-vue
    ref="tabela"
    v-model="selecionados"
    class="mt-2"
    style="min-height: 300px"
    :colunas="tabela.colunas"
    :dados="tabela.dados"
    filtros-com-colunas
    :mostrar-acoes="false"
    :linha-hover="!multiplos"
    :quantidade-itens="tabela.quantidadeItens"
    filtro-global-focus
    :por-pagina="tabela.porPagina"
    :quantidade-paginas="tabela.quantidadePaginas"
    :pagina="tabela.paginaAtual"
    :selecionar-apenas-um="!multiplos"
    ajustar-altura-linha
    @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
    @paginar="listar"
    @filtrarGlobal="listar"
    @filtroGlobal="(v) => (filtroAplicado.filter = v)"
  />
</template>
  <script>
  import UnidadeMedidaService from '@/common/services/cadastros/UnidadeMedidaService';
  import GrandezaFisicaService from '@/common/services/cadastros/GrandezaFisicaService.js';
  
  export default {
    props: {
      multiplos: { type: Boolean, default: true },
      value: { type: Array, default: () => [] },
      buscaUnidadeMedidaGrandezaFisica: { type: Boolean, default: false },
      listarUnidadeMedidaPorGrandezasFisicasIds: {
        type: Boolean,
        default: false,
      },
      grandezasFisicasIds: { type: Array, default: () => [] },
      idGrandezaFisica: { type: String, default: '' },
    },
    data() {
      return {
        filtroAplicado: { filter: '' },
        selecionados: [],
        tabela: {
          dados: [],
          colunas: [
            {
              value: 'simbolo',
              text: this.$t('componentes.input_unidade_medida.tabela.simbolo'),
              sortable: true,
            },
            {
              value: 'nome',
              text: this.$t('componentes.input_unidade_medida.tabela.nome'),
              sortable: true,
            },
            {
              value: 'grandezaFisica.nome',
              text: this.$t('componentes.input_unidade_medida.tabela.grandeza'),
              sortable: true,
            },
          ],
          quantidadeItens: 0,
          quantidadePaginas: 0,
          paginaAtual: 1,
          porPagina: 10,
        },
      };
    },
    watch: {
      value: {
        handler() {
          this.selecionados = this.value;
        },
        immediate: true,
      },
      selecionados: {
        handler() {
          if (Array.isArray(this.selecionados)) {
            this.$emit('input', this.selecionados);
            return;
          }

        this.$emit('input', [this.selecionados]);
        },
      },
    },
    methods: {
      focusFiltroGlobal(){
            this.$refs.tabela?.focusFiltroGlobal(); 
      },
      listar(paginaAtual = this.tabela.paginaAtual, porPagina = this.tabela.porPagina) {
        let parametros = {
          page: paginaAtual,
          amountPerPage: porPagina,
          ...this.filtroAplicado,
        };
  
        let grandezasFisicasIdsLocal = [];
        this.$store.dispatch('Layout/iniciarCarregamento');
        if (this.listarUnidadeMedidaPorGrandezasFisicasIds) {
          if (this.grandezasFisicasIds) {
            if (Array.isArray(this.grandezasFisicasIds)) {
              grandezasFisicasIdsLocal = this.grandezasFisicasIds
                .map((i) => {
                  return `GrandezasFisicasIds=${i}`;
                })
                .join('&');
            } else {
              grandezasFisicasIdsLocal = `GrandezasFisicasIds=${this.grandezasFisicasIds}`;
            }
  
            UnidadeMedidaService.listarUnidadeMediaPorIdsGrandezaFisica(
              grandezasFisicasIdsLocal,
              parametros
            )
              .then((res) => {
                this.tabela.dados = res.data.items;
  
                this.tabela.quantidadeItens = res.data.totalItems;
                this.tabela.quantidadePaginas = res.data.totalPages;
                this.tabela.paginaAtual = paginaAtual;
                this.tabela.porPagina = porPagina;
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          }
        } else if (!this.buscaUnidadeMedidaGrandezaFisica) {
          UnidadeMedidaService.listar(parametros)
            .then((res) => {
              this.tabela.dados = res.data.items;
  
              this.tabela.quantidadeItens = res.data.totalItems;
              this.tabela.quantidadePaginas = res.data.totalPages;
              this.tabela.paginaAtual = paginaAtual;
              this.tabela.porPagina = porPagina;
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        } else {
          GrandezaFisicaService.buscarGrandezaFisicaUnidadeMedida(
            this.idGrandezaFisica,
            parametros
          )
            .then((res) => {
              this.tabela.dados = res.data.items;
  
              this.tabela.quantidadeItens = res.data.totalItems;
              this.tabela.quantidadePaginas = res.data.totalPages;
              this.tabela.paginaAtual = paginaAtual;
              this.tabela.porPagina = porPagina;
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        }
      },
    },
  };
  </script>
  