export default {
  clearStore ({ commit }, payload) {
    commit('CLEAR_STORE', payload)
  },
  changeBlockUi ({ commit }, payload) {
    commit('CHANGE_BLOCK_UI', payload)
  },
  setModuleFilters ({ commit }, payload) {
    commit('SET_MODULE_FILTERS', payload)
  },
  clearModuleFilter ({ commit }, payload) {
    commit('CLEAR_MODULE_FILTER', payload)
  },
  clearModuleFitlers ({ commit }, payload) {
    commit('CLEAR_MODULE_FILTERS', payload)
  },
  changeSpinner ({ commit }, payload) {
    commit('CHANGE_SPINNER', payload)
  },
  setAbaKanban ({ commit }, payload) {
    commit('SET_ABA_KANBAN', payload)
  }
}
