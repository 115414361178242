import helpers from "@router/helpers";
const menuId = "endpoints";
export default[
  helpers.criarRota(menuId, "endpoints", menuId, ()=>
  import('@/views/modulos/cadastros/endpoints')
  ),
  helpers.criarRota(menuId,
    'endpoints-novo',
    `${menuId}/novo`,
    ()=>  import('@/views/modulos/cadastros/endpoints/formulario'),
     false,
     false),

  helpers.criarRota(
    menuId,
   'endpoints-editar',
   `${menuId}/:id?`,
   ()=>  import('@/views/modulos/cadastros/endpoints/formulario'),
   true,
   false),
]
