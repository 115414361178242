export default {
  titulos: {
    listagem: 'Operações',
    novo: 'Nova Operação',
    analise_tecnica: 'Análise Técnica',
    analise_tecnica_em_lote: 'Análise Técnica em Lote',
    manutencao_peca: 'Manutenção/Peça',
    plano_inspecao: 'Plano de Inspeção',
    entrega: 'Entrega',
  },
  analise_tecnica: {
    salvo_com_sucesso: 'Análise Técnica salva com sucesso!',
    nao_existe_requisito: 'Não existe Requisitos para essa Análise Técnica',
  },
  cadastro_sucesso: 'Operação salva com sucesso!',
  entrega_sucesso: 'Entrega feita com sucesso!',
  plano_inspecao: 'Plano de Inspeção',
  movimentacao_sucesso: 'Movimentação feita com sucesso!',
  selecione_servicos_pecas: 'Selecione os Serviços/Peças que foram executados.',
  entrega_instrumento_sucesso: 'Entrega de instrumentos salva com sucesso!',
  exclusao_sucesso: 'Operação excluida com sucesso!',
  confirmacao_calibracao_nao_aprovada_sucesso:
    'Instrumento movimentado para o próximo setor',
  confirmacao_calibracao_nao_aprovada:
    'O instrumento não tem serviço de calibração, deseja movimentar para o próximo setor?',
  filtro: {
    codigo_instrumento: 'Instrumento',
    situacao: 'Situação',
    tipo_instrumento: 'Tipo de Instrumento',
    setor_id: 'Setor',
    ordem_servico: 'Ordem de Serviço',
    nome_cliente: 'Cliente',
    codigo: 'Código',
  },
  tabela: {
    numero_os: 'Número da OS',
    cliente: 'Cliente',
    certificado: 'Certificado',
    instrumento: 'Instrumento',
    aprovado: 'Aprovado',
    executado: 'Executado',
    tipo_aprovacao: 'Tipo de Aprovação',
    status: 'Situação',
    tipo_calibracao: 'Tipo de Calibração',
    local_execucao: 'Local de Execução',
    data_calibracao: 'Data de Calibração',
    usuario_tecnico_executante: 'Usuário Técnico Executante',
    data_assinatura: 'Data de Assinatura',
    usuario_signatario: 'Usuario Signatário',
    setores: 'Setores',
    contrato: 'Contrato',
    ultima_execucao: 'Última Execução',
    data_recebimento: 'Data de Recebimento',
    previsao_entrega: 'Previsão de Entrega',
    quantidade: 'Qtd Instrumentos',
    setor_anterior: 'Setor Anterior',
    codigo: 'Código',
    codigo_servico: 'Código do Serviço',
    nome: 'Nome',
    faixa: 'Faixa',
    faixa_instrumento: 'Faixa do Instrumento',
    faixa_servico: 'Faixa do Serviço',
    marca: 'Marca',
    modelo: 'Modelo',
    acreditacao: 'Acreditação',
    numero_serie: 'Número de Série',
    descricao: 'Descrição',
    servicos: 'Serviços',
    acao_nao_ok: 'Ação Não Ok',
    horas_execucao_padrao: 'Horas de Execução Padrão',
    quantidade_estoque: 'Qtde. em Estoque',
    quantidade_orcada: 'Qtde. Orçada',
    prazo_entrega: 'Prazo de Entrega',
    fabricante: 'Fabricante',
    minimo_pontos: 'Quantidade de Pontos',
    quantidade_pontos: 'Quantidade de Pontos',
    tempo_execucao: 'Horas de Execução Padrão',
    horas_execucao: 'Horas de Execução',
    horas_previstas: 'Horas Previstas',
    tipo_servico: 'Tipo do Serviço',
    codigo_contrato: 'Contrato',
    pecas: 'Peças',
    peca: 'Peça',
    manutencao: 'Manutenção',
    codigo_instrumento: 'Cód. do Instrumento',
    ordem_servico: 'Ordem de Serviço',
    recebimento: 'Recebimento',
    situacao: 'Situação',
    tipo_instrumento: 'Tipo de Instrumento',
    setor: 'Setor',
    ordem_servico_id: 'Cód. Ordem de Serviço',
    nome_cliente: 'Nome do Cliente',
    faixas: 'Faixas',
    manutencao_peca: 'Manutenção/Peça',
    qtd: 'Quantidade',
    quantidade_pecas: 'Quantidade de Peças',
  },
  steps: {
    dados_analise: 'Dados Análise Técnica',
    calibracao: 'Calibração',
    checklist: 'Checklist',
    manutencao: 'Manutenção',
    pecas: 'Peças',
    resumo: 'Resumo',
    instrumento: 'Instrumento',
    instrumentos: 'Instrumentos',
    tipo: 'Tipo',
    ordem_servico: 'Ordem de Serviço',
  },
  formulario: {
    abas: {
      requisitos: 'Requisitos',
      analise_tecnica: 'Análise Técnica',
      analise_tecnica_em_lote: 'Análise Técnica em Lote',
      analise_tecnica_anterior: 'Análise Técnica Anterior',
    },
    alerta_click_duplo:
      'Para desmarcar uma peça, basta clicar novamente no mesmo ponto.',
    selecione_pecas: 'Selecione peças ao clicar no ponto',
    vista_explodida: 'Vista Explodida',
    tipo_orcamento: 'Tipo de Orçamento',
    descricaoJustificativaSemConserto: 'Justificativa de Sem Conserto',
    solicitante: 'Solicitante',
    aprovacao_garantia: 'Aprovação da Garantia',
    usuario_aprovacao: 'Usuário da Aprovação',
    data_aprovacao: 'Data da Aprovação',
    data_recebimento_os: 'Data de Recebimento da OS',
    data_previsao_entrega_os: 'Data de Previsão de Entrega da OS',
    embalagem_recebimento: 'Embalagem do Recebimento',
    embalagem_entrega: 'Embalagem da Entrega',
    qtd_volumes: 'Qtd de Volumes',
    peso_kg: 'Peso(kg)',
    SemMovimento: 'Sem Movimento',
    Reprovado: 'Reprovado',
    telefone: 'Telefone',
    nome_pessoa_retirou: 'Nome da Pessoa que Retirou',
    NaoCalibrado: 'Não Calibrado',
    ComPendencia: 'Com Pendência',
    Calibrado: 'Calibrado',
    AguardandoPlanoInspecao: 'Aguardando Plano de Inspeção',
    AguardandoAprovacaoCertificado: 'Aguardando Aprovação de Certificado',
    ProgramacaoDia: 'Programação do Dia',
    ProgramacaoSegundoDia: 'Programação do Segundo Dia',
    ProgramacaoTerceiroDia: 'Programação do Terceiro Dia',
    AguardandoEnvioOrcamento: 'Aguardando Envio de Orçamento',
    AguardandoAprovacaoGarantia: 'Aguardando Aprovação Garantia',
    AguardandoAnaliseTecnica: 'Aguardando Análise Técnica',
    AguardandoProgramacao: 'Aguardando Programação',
    AguardandoAprovacaoOrcamento: 'Aguardando Aprovação Orçamento',
    EmExecucao: 'Em Execução',
    codigo: 'Código',
    dados_cadastro: 'Dados de Cadastro',
    novo_item_peca: 'Novo Item Peça',
    tipo_calibracao: 'Tipo de Calibração',
    pontos_itens: 'Pontos/Itens',
    pontos_calibracao_instrumento: 'Pontos  de Calibração do Instrumento',
    pontos_extras: 'Pontos Extras',
    novo_ponto_calibracao: 'Novo Ponto de Calibração',
    pontos_calibracao: 'Pontos de Calibração',
    criterios_aceitacao: 'Critérios de Aceitação',
    faixas: 'Faixas',
    servicos_manutencao: 'Serviços de Manutenção',
    selecione_servico_deseja_executar:
      'Selecione os serviços que deseja executar no instrumento.',
    selecione_pecas_deseja_substituir:
      'Selecione as peças que deseja substituir no instrumento.',
    titulo_modal_instrumentos: 'Instrumentos',
    titulo_modal_instrumentos_os: 'Instrumentos da OS',
    titulo_modal_servicos_calibracao: 'Serviços de Calibração',
    titulo_modal_servicos_manutencao: 'Serviços de Manutenção',
    titulo_modal_pecas: 'Peças',
    associar_desassociar_mensagens: 'Selecionar Mensagem',
    especificacao: 'Especificação',
    calibracao: 'Calibração',
    possui_calibracao: 'Possui Calibração?',
    ocorrencia: 'Ocorrência',
    fabricante: 'Fabricante',
    tipo_instrumento: 'Tipo de Instrumento',
    faixa: 'Faixa',
    laboratorio: 'Laboratório',
    valor: 'Valor',
    titulo_modal_responsavel: 'Responsável / Colaborador',
    nome: 'Nome',
    cliente: 'Cliente',
    cod_os: 'Ordem de Serviço',
    aos_cuidados_de: 'Aos Cuidados de',
    apelido: 'Apelido',
    fornecedor: 'Fornecedor',
    local_execucao: 'Local de Execução',
    tipo_prazo_entrega: 'Tipo Prazo de Entrega',
    local: 'Local',
    prazo: 'Prazo',
    qtd: 'Qtd',
    valor_unitario: 'Valor Unitário',
    valor_total: 'Valor Total',
    valor_aprovado: 'Valor Aprovado',
    adicionar: 'Adicionar',
    desconto: 'Desconto',
    frete: 'Frete',
    despesas: 'Despesas',
    condicao_pagamento_contrato: 'Condição de Pagto por Contrato',
    condicao_pagamento: 'Condição de Pagamento',
    nova_situacao: 'Nova Situação',
    situacao_atual: 'Situação Atual',
    setor_atual: 'Setor Atual',
    setor_anterior: 'Setor Anterior',
    proximo_setor: 'Próximo Setor',
    parcelas: 'Parcelas',
    pedido_compra: 'Pedido de Compra',
    como_solicitado: 'Como foi Solicitado',
    tipo_desconto: 'Tipo de Desconto',
    em_elaboracao: 'Em Elaboração',
    observacao: 'Observação',
    numero: 'Número',
    instrumento: 'Instrumento',
    ordem_servico: 'Ordem de Serviço',
    descricao: 'Descrição',
    responsavel: 'Responsável',
    data_emissao: 'Data de Emissão',
    justificativa_garantia: 'Justificativa de Garantia',
    garantia: 'Garantia',
    garantia_dias: 'Prazo de Garantia (dias)',
    aprovada: 'Aprovada',
    reprovada: 'Reprovada',
    validade_dias: 'Validade do Orçamento (dias)',
    tipo_pessoa: 'Tipo',
    documento: 'Documento',
    email: 'Email',
    site: 'Site',
    inscricao_estadual: 'Inscrição estadual',
    inscricao_municipal: 'Inscrição municipal',
    dia_coleta: 'Dia(s) de coleta',
    possivel_executar_concerto_instrumento: 'Possível Conserto',
    prazo_entrega: 'Prazo de Entrega',
    papel_fornecedor: 'Papel do fornecedor',
    enderecos: {
      botoes: {
        novo: 'Novo endereço',
      },

      tipo: 'Tipo',
      uf: 'UF',
      municipio: 'Municipio',
      logradouro: 'Logradouro',
      numero: 'Numero',
      complemento: 'Complemento',
      bairro: 'Bairro',
      cep: 'CEP',
    },
    itensOrcamento: {
      botoes: {
        novoItemOrcamento: 'Novo Item de Orçamento',
        visualizar_ultimos_precos_orcados:
          'Visualizar os Últimos Preços Orçados',
        ultimos_precos_orcados: 'Últimos Preços Orçados',
      },
      nome: 'Nome',
      telefone: 'Telefone',
      email: 'Email',
      setor: 'Setor',
      cargo: 'Cargo',
    },
    requisitos: {
      botoes: {
        novo: 'Novo requisito',
      },

      tipo_requisito: 'Tipo de requisito',
      descricao: 'Descrição',
    },
  },
  erros: {
    id_invalido: 'O id da operação é inválido',
    nao_encontrado_proximo_setor: 'Não foi encontrado o Próximo Setor',
    nao_encontrado_setor_anterior: 'Não foi encontrado o Setor Anterior',
    selecione_proximo_setor: 'Selecione o próximo Setor',
    selecione_instrumento: 'Selecione pelo menos um Instrumento',
    tipos_instrumentos_iguais: 'Os Tipos de Instrumento devem ser iguais',
    salvar_com_concerto_sem_servico_pecas:
      'Obrigatório ter algum serviço ou peça para salvar com Conserto',
    salvar_sem_concerto_perde_servico_pecas:
      'Todos os serviços e peças serão removidos porque o instrumento não tem concerto',
    selecione_servico_calibracao: 'Selecione um Serviço de Calibração',
    selecione_servico_calibracao_instrumento:
      'Selecione um Serviço de Calibração para cada Instrumento',
    instrumento_prazo_garantia:
      'Este instrumento possivelmente está no prazo de garantia',
    quantidade_orcada_nao_pode_zero:
      'Quantidade Orçada não pode ser Zero em uma Peça selecionada',
    horas_execuxao_nao_pode_zero:
      'Horas de Execução não pode ser Zero em um Serviço selecionado',
    reprovar_apenas_nao_ok:
      'Permitido reprovar apenas instrumentos selecionados Não Ok',
  },
  tooltips: {
    analise_tecnica:
      'Não é possível fazer a Análise Técnica do instrumento na situação atual',
    calibracao:
      'Não é possível fazer a Calibração do instrumento na situação atual',
    entrega_ordem_servico: 'Já existe um envio material para a OS',
    selecionar_acreditacao: 'Por favor selecione uma Acreditação',
  },
  botoes: {
    gerar_orcamento: 'Gerar Orçamento',
    editar_instrumento: 'Editar Instrumento',
    historico_instrumento: 'Histórico do Instrumento',
    analise_tecnica: 'Análise Técnica',
    analise_tecnica_em_lote: 'Análise Técnica em Lote',
    calibracao: 'Calibração',
    calibracao_terceiros: 'Calibração de Terceiro',
    certificado: 'Certificado',
    visualizar_certificado: 'Visualizar Certificado',
    aprovar_garantia: 'Aprovar Garantia',
    manutencao: 'Manutenção',
    manutencao_instrumento: 'Manutenção Instrumento',
    nao_possivel_executar: 'Não foi possível executar',
    permitir_execucao: 'Permitir execução',
    movimentar_proximo_setor: 'Movimentar para o Próximo Setor',
    movimentar_setor_anterior: 'Movimentar para o Setor Anterior',
    movimentar_outro_setor: 'Movimentar para outro Setor',
    plano_inspecao: 'Plano de Inspeção',
    etiquetas_certificados: 'Etiquetas dos Certificados',
    imprimir_etiqueta: 'Imprimir Etiquetas',
    pontos_calibracao:'Pontos de Calibração',
  },
  confirmacao_calibracao:
    'Já existe uma calibração deste instrumento em andamento. Deseja continuar?',
  confirmar_atualizacao_faixa: 'Faixas do instrumento atualizadas com sucesso.',
  finalizar_todos_selecionados:
    'Para finalizar, todos os serviços/peças permitidos devem estar selecionados.',
  nenhum_item_selecionado:
    'Não é possível Salvar/Finalizar sem nenhum item selecionado.',
  execucao_manutencao_peca_salvo_sucesso:
    'Execução de Serviços de Manutenção e Peças Salvo com Sucesso',
  plano_inspecao_salvo_sucesso: 'Plano de Inspeção Salvo com Sucesso',
  instrumentos_suplemento_devolvidos:
    'Os instrumentos que foram marcados como Não ok e todos os instrumentos do mesmo suplemento serão devolvidos para realizar ajustes no setor anterior. Confirma?',
  instrumentos_considerados_ok:
    'Todos os instrumentos serão considerados Ok. Deseja finalizar o plano de inspeção?',
  deseja_continuar: 'Deseja continuar?',
  confirmacao_sem_manutencao: {
    titulo: 'Sem Manutenção e Peça',
    mensagem:
      'Não há serviços de manutenção e peças disponíveis para o instrumento(s). Você deseja transferi-lo(s) para o próximo setor?',
  },

  avisos: {
    orcamento_existente: 'Já existe orçamento gerado, deseja editar?',
    orcamento_enviado:
      'Já existe orçamento com o cliente, deseja criar uma nova revisão?',
  },
};
