import { BasicoApi } from '@common/api';
const OrcamentoComentarioService = {
  listar: function (parametros) {
    return BasicoApi.get()('/orcamentocomentario', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/orcamentocomentario/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/orcamentocomentario/${form.id}`, form);
    return BasicoApi.post()('/orcamentocomentario', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/orcamentocomentario/${id}`);
  },
};

export default OrcamentoComentarioService;
