export default {
  CLEAR_STORE(state) {
    state.blockUi = false;
    state.filters = {};
  },
  CHANGE_BLOCK_UI(state, payload) {
    state.blockUi = payload;
  },
  SET_MODULE_FILTERS(state, payload) {
    const newObject = Object.fromEntries(
      Object.entries(payload).filter(
        (entry) =>
          entry[1] !== '' && entry[1] !== null && entry[1] !== undefined
      )
    );

    state.filters = newObject;
  },
  CLEAR_MODULE_FILTER(state, payload) {
    delete state.filters[payload];
  },
  CLEAR_MODULE_FILTERS(state) {
    state.filters = {};
  },
  CHANGE_SPINNER(state, { message, status }) {
    state.spinner = { message, status };
  },
  SET_ABA_KANBAN(state, payload) {
    state.abaKanban = payload;
  }
};
