export default {
  linha_selecionada: 'Linha(s) Selecionada(s)',
  limpar: 'Limpar',
  pesquisar: 'Pesquisar',
  primeira_pagina: 'Primeira Página',
  ultima_pagina: 'Última Página',
  proxima: 'Próxima',
  anterior: 'Anterior',
  linhas_por_pagina: 'Linhas por Página',
  de: 'de',
  pagina: 'Página',
  todas: 'Todas',
  nao_dados: 'Não há dados para serem exibidos',
};
