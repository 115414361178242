export default {
  nome: 'Nome',
  cidade: 'Cidade',
  documento: 'Documento',
  tabela: {
    apelido:'Apelido',
    nome: 'Nome',
    cidade: 'Cidade',
    documento: 'Documento'

  },
  titulo_modal: 'Laboratórios',
};
