import helpers from '@router/helpers';
const menuId = 'item';

export default [
  helpers.criarRota(menuId, 'item', menuId, () =>
    import('@/views/modulos/cadastros/item')
  ),
  helpers.criarRota(menuId, 'item-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/item/formulario')
  ),
  helpers.criarRota(
    menuId,
    'item-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/item/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'item-visualizar-reserva',
    `${menuId}/visualizar-reserva/:id?`,
    () => import('@/views/modulos/cadastros/item/visualizarReserva'),
    true
  ),
];
