import GeradorRelatorio from './GeradorRelatorio';
import InputAreaAcreditacao from './InputAreaAcreditacao';
import InputCamposAdicionais from './InputCamposAdicionais';
import InputCargo from './InputCargo';
import InputCertificado from './InputCertificado';
import InputClassificacaoFiscal from './InputClassificacaoFiscal';
import InputClassificacaoInstrumento from './InputClassificacaoInstrumento';
import InputCliente from './InputCliente';
import InputColaborador from './InputColaborador';
import InputContrato from './InputContrato';
import InputDragDrop from './InputDragDrop';
import InputEndpoints from './InputEndpoints';
import InputEscopoServico from './InputEscopoServico';
import InputFormula from './InputFormula';
import InputFormulaMensurando from './InputFormulaMensurando';
import InputFornecedor from './InputFornecedor';
import InputGrandezaFisica from './InputGrandezaFisica';
import InputIde from './InputIde';
import InputIncerteza from './InputIncerteza';
import InputInstrumento from './InputInstrumento';
import InputInstrumentoPadrao from './InputInstrumentoPadrao';
import InputLaboratorio from './InputLaboratorio';
import InputLocalExecucao from './InputLocalExecucao';
import InputMarca from './InputMarca';
import InputModeloInstrumento from './InputModeloInstrumento';
import InputOcorrencia from './InputOcorrencia';
import InputOcorrenciaInstrumento from './InputOcorrenciaInstrumento';
import InputOrcamento from './InputOrcamento';
import InputOrdemServico from './InputOrdemServico';
import InputParticipantes from './InputParticipantes';
import InputProcedimentoEletronico from './InputProcedimentoEletronico';
import InputServicoCampo from './InputServicoCampo';
import InputSetor from './InputSetor';
import InputTabelaRegistro from './InputTabelaRegistro';
import InputTipoInstrumento from './InputTipoInstrumento';
import InputUnidadeMedida from './InputUnidadeMedida';
import InputUsuarios from './InputUsuarios';
import InputVeiculos from './InputVeiculos';
import OCR from './OCR';
import InputCondicaoPagamento from './InputCondicaoPagamento';
import InputFabricante from './InputFabricante';
export default {
  cabecalho_pagina: {
    registros: 'nenhum registro | 1 registro | {count} registros',
  },
  input_area_acreditacao: InputAreaAcreditacao,
  input_tipo_instrumento: InputTipoInstrumento,
  input_contrato: InputContrato,
  input_escopo_servico: InputEscopoServico,
  input_participantes: InputParticipantes,
  input_unidade_medida: InputUnidadeMedida,
  input_instrumento: InputInstrumento,
  input_instrumento_padrao: InputInstrumentoPadrao,
  input_laboratorio: InputLaboratorio,
  input_usuarios: InputUsuarios,
  input_classificacao_instrumento: InputClassificacaoInstrumento,
  input_cliente: InputCliente,
  input_setor: InputSetor,
  input_incerteza: InputIncerteza,
  input_formula: InputFormula,
  input_formula_mensurando: InputFormulaMensurando,
  input_campos_adicionais: InputCamposAdicionais,
  input_local_execucao: InputLocalExecucao,
  input_cargo: InputCargo,
  input_colaborador: InputColaborador,
  input_dragdrop: InputDragDrop,
  input_endpoint: InputEndpoints,
  input_ocorrencia: InputOcorrencia,
  input_ordem_servico: InputOrdemServico,
  input_certificado: InputCertificado,
  input_servico_campo: InputServicoCampo,
  input_orcamento: InputOrcamento,
  input_grandeza_fisica: InputGrandezaFisica,
  gerador_relatorio: GeradorRelatorio,
  ocr: OCR,
  input_ide: InputIde,
  input_marca: InputMarca,
  input_modelo_instrumento: InputModeloInstrumento,
  input_tabela_registro: InputTabelaRegistro,
  input_procedimento_eletronico: InputProcedimentoEletronico,
  input_veiculos: InputVeiculos,
  input_ocorrencia_instrumento: InputOcorrenciaInstrumento,
  input_fornecedor: InputFornecedor,
  input_classificacao_fiscal: InputClassificacaoFiscal,
  input_condicao_pagamento: InputCondicaoPagamento,
  input_fabricante: InputFabricante,
};
