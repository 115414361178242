import { CalculosApi } from '@common/api';
import { TipoColunaConst } from '@const/TipoColunaConst';
const CalibracaoService = {
  salvar: function (form) {
    if (form.id)
      return CalculosApi.put()(`/Calibracao/${form.id}`, form.request);
    return CalculosApi.post()('/Calibracao', form.request);
  },
  buscar: function (instrumentoId, ordemServicoId) {
    return CalculosApi.get()(
      `/Calibracao/${ordemServicoId}/${instrumentoId}`,
      {},
      false
    );
  },
  buscarPorId: function (calibracaoId) {
    return CalculosApi.get()(`/Calibracao/${calibracaoId}`, {}, false);
  },
  buscarTabelas: function (id) {
    return CalculosApi.get()(`/Calibracao/${id}/tabelas`, {}, false);
  },
  salvarTabelas: function (id, selecionadas) {
    return CalculosApi.put()(
      `/Calibracao/${id}/tabelas`,
      selecionadas
    );
  },
  buscarListaTabelasCalibracao: function (calibracaoId) {
    return CalculosApi.get()(
      `/Calibracao/${calibracaoId}/tabelas/valores`,
      {},
      false
    );
  },
  buscarTabelaCalibracao: function (calibracaoId, tabelaId) {
    return CalculosApi.get()(
      `/Calibracao/${calibracaoId}/tabelas/${tabelaId}/valores`,
      {},
      false
    );
  },
  salvarValores: function (calibracaoId, tabelaId, form) {
    return CalculosApi.put()(
      `/Calibracao/${calibracaoId}/tabelas/${tabelaId}/valores`,
      form.request
    );
  },
  salvarMultiplasTabelasValores: function (calibracaoId, tabelas) {
    return CalculosApi.put()(`/Calibracao/${calibracaoId}/tabelas/valores`, {
      tabelas: tabelas.map((el) => {
        return el.request;
      }),
    });
  },
  limparInstrumentoPadrao: function (calibracaoId, tabelaId) {
    return CalculosApi.put()(
      `/Calibracao/${calibracaoId}/tabelas/${tabelaId}/limpar-instrumentos`
    );
  },
  finalizarCalibracao: function (id) {
    return CalculosApi.patch()(`/Calibracao/${id}/finalizar`);
  },
  validarPontos: function (calibracaoId, tabelaId) {
    return CalculosApi.get()(
      `/calibracao/${calibracaoId}/tabelas/${tabelaId}/validar`,
      {},
      false
    );
  },
  calcularLinha: function (
    calibracaoId,
    calibracaoLinhaId,
    linha,
    depurar = false
  ) {
    return CalculosApi.post()(
      `/Calibracao/${calibracaoId}/linha/${calibracaoLinhaId}/calcular`,
      {
        depurar: depurar,
        colunas: linha.valores
          .filter(
            (el) =>
              el.tipoColuna == TipoColunaConst.SMC ||
              el.tipoColuna == TipoColunaConst.SMP
          )
          .map((el) => {
            return {
              valor: el.valor,
              tipoColuna: el.tipoColuna,
              colunaId: el.tabelaColunaId,
            };
          }),
      }
    );
  },
  buscarHistorico: function (tabelaId, instrumentoId) {
    return CalculosApi.get()(
      `/Calibracao/tabelas/${tabelaId}/instrumentos/${instrumentoId}/valores`
    );
  },
  salvarAnexo: function (calibracaoId, form) {
    return CalculosApi.post()(`/Calibracao/${calibracaoId}/anexo`, form);
  },
  excluirAnexos: function (calibracaoId, ids) {
    return CalculosApi.delete()(`/Calibracao/${calibracaoId}/anexo`, ids);
  },
  listarAnexos: function (calibracaoId, parametros) {
    return CalculosApi.get()(`/Calibracao/${calibracaoId}/anexos`, parametros);
  },
  validarInstrumentos: function (calibracaoId, tabelaId) {
    return CalculosApi.post()(
      `/Calibracao/${calibracaoId}/tabelas/${tabelaId}/validar-instrumentos-padroes-vencidos`
    );
  },
  cache: function (calibracaoId) {
    return CalculosApi.post()(
      `/Calibracao/${calibracaoId}/cache`
    );
  },
};

export default CalibracaoService;
