import helpers from '@router/helpers';
const menuId = 'certificado-interno';


export default [
  helpers.criarRota(
    menuId,
    'certificado-interno',
    'certificado-interno',
    () => import('@/views/modulos/cadastros/certificado_interno'),
  ),
  helpers.criarRota(
    menuId,
    'calibracao-interna-novo',
    'calibracao-interna/novo',
    () => import('@/views/modulos/cadastros/certificado_interno/formulario'),
    true),
  helpers.criarRota(
    menuId,
    'calibracao-interna-editar',
    'calibracao-interna/:id?',
    () => import('@/views/modulos/cadastros/certificado_interno/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'calibracao-interna-visualizar',
    'calibracao-interna/visualizar/:id?',
    () => import('@/views/modulos/cadastros/certificado_interno/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'calibracao-interna-certificado',
    'calibracao-interna/certificado/:id?',
    () => import('@/views/modulos/cadastros/calibracao/components/Certificado'),
    true
  ),
];
