import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.arredondamento.nao_arredondar'),
    value: 1,
  },
  {
    text: i18n.t('enums.arredondamento.arredondar_cima'),
    value: 2,
  },
  {
    text: i18n.t('enums.arredondamento.arredondar_baixo'),
    value: 3,
  },
];
