<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      :colunas="colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      sem-acoes
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @paginar="listar"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
    />
  </div>
</template>
<script>
import IncertezasService from '@common/services/cadastros/IncertezasService';
import helpers from '@common/utils/helpers';
import _ from 'lodash';

export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    semRevisao: { type: Boolean, default: false },
    apenasPublicados: { type: Boolean, default: true },
    semEstaIncerteza: { type: String, default: '' },
    formula: { type: Object, default: () => {} },
    apenasComRevisao: { type: Boolean, default: false },
    nivelUnicoIncertezaAuxiliar: { type: Boolean, default: false },
    ignoreIncertezaId: { type: String, default: null },
  },

  data() {
    return {
      filtro: {
        codigo: '',
        revisao: '',
        nome: '',
        faixa: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },

  computed: {
    colunas: function () {
      let retorno = [
        {
          value: 'codigo',
          text: this.$t('componentes.input_incerteza.tabela.codigo'),
          sortable: false,
        },
      ];

      if (!this.semRevisao) {
        retorno.push({
          text: this.$t('componentes.input_incerteza.tabela.revisao'),
          value: 'revisao',
          sortable: false,
        });
      }

      retorno = [
        ...retorno,
        {
          text: this.$t('componentes.input_incerteza.tabela.nome'),
          value: 'nome',
          sortable: false,
        },
        {
          text: this.$t('componentes.input_incerteza.tabela.faixa'),
          value: 'faixa',
          sortable: false,
        },
      ];

      return retorno;
    },
  },

  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
      selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
  },

  mounted() {
    this.listar();
  },

  methods: {
    focusFiltroGlobal: function () {
      setTimeout(() => {
        this.$refs['tabela'].focusFiltroGlobal();
      }, 300);
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listar();
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },

    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        Page: paginaAtual,
        AmountPerPage: porPagina,
        ...this.filtroAplicado,
      };

      if (this.apenasPublicados) {
        parametros.publicada = true;
      }

      if (this.formula) {
        parametros.formulaId = this.formula.value;
      }

      if(this.ignoreIncertezaId) {
        parametros.ignoreIncertezaId = this.ignoreIncertezaId;
      }

      if(this.nivelUnicoIncertezaAuxiliar) {
        parametros.flagNivelUnicoIncertezaAuxiliar = this.nivelUnicoIncertezaAuxiliar;
      }


      this.$store.dispatch('Layout/iniciarCarregamento');
      IncertezasService.listar(parametros)
        .then((res) => {
          const dados = [];

          for (const dado of res.data.items) {
            dados.push({
              ...dado,
              faixa: helpers.formatarDescricao(`(${dado.amplitudeMinima} a ${dado.amplitudeMaxima}) ${dado.unidadeMedida.simbolo}`),
            });
          }

          this.tabela.dados = dados.filter(
            ({ id }) => id !== this.semEstaIncerteza
          );

          this.tabela.quantidadeItens = this.tabela.dados.length;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
