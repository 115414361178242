export default {
  titulos: {
    listagem: 'Veículos',
    novo: 'Novo Veículo',
    editar: 'Editar Veículo',
    visualizar:'Visualizar Veículo'
  },
  abas: {
    dados_veiculo: 'Dados do Veículo',
    historico: 'Histórico',
  },
  historico: {
    usuario: 'Usuário',
    dataSaida: 'Data de Saída',
    kmSaida: 'Km de Saída',
    checklistSaida: 'Checklist de Saída',
    dataChegada: 'Data de Chegada',
    kmChegada: 'Km de Chegada',
    checklistChegada: 'Checklist de Chegada',
    destino: 'Destino',
    observacao: 'Observação',
  },
  cadastro_sucesso: 'Veículo salvo com sucesso!',
  exclusao_sucesso: 'Veículo excluído com sucesso!',
  tabela: {
    modelo: 'Modelo',
    fabricante: 'Fabricante',
    placa: 'Placa',
    principalCondutorColaborador: 'Principal Condutor',
  },
  formulario: {
    modelo: 'Modelo',
    fabricante: 'Fabricante',
    placa: 'Placa',
    principalCondutorColaborador: 'Principal Condutor / Colaborador',
    cor: 'Cor',
    anoFabricacao: 'Ano de Fabricação',
    anoModelo: 'Ano do Modelo',
    renavam: 'Renavam',
    dataCompra: 'Data de Compra',
    valorCompra: 'Valor de Compra',
    dataVenda: 'Data de Venda',
    valorVenda: 'Valor de Venda',
    observacao: 'Observação',
  },
  validacoes: {
    formulario_invalido: 'Verifique o formulário',
  },
  erros: {
    id_invalido: 'O id do tipo de setor é inválido',
  },
};
