import cadastros from './cadastros';

export default [
  {
    path: 'cadastro',
    redirect: '/dashboard',
    component: () => import('@layouts/blank-layout'),
    children: [...cadastros],
  },
];
