import helpers from "@router/helpers";
const menuId = "tipo-visita";
export default[
  helpers.criarRota(menuId, "tipo-visita", menuId, ()=>
  import('@/views/modulos/cadastros/tipo_visita')
  ),
  helpers.criarRota(menuId,
    'tipo-visita-novo',
    `${menuId}/novo`,
    ()=>  import('@/views/modulos/cadastros/tipo_visita/formulario'),
     false,
     false),

  helpers.criarRota(
    menuId,
   'tipo-visita-editar',
   `${menuId}/:id?`,
   ()=>  import('@/views/modulos/cadastros/tipo_visita/formulario'),
   true,
   false),
]
