import { BasicoApi, CalculosApi } from '@common/api';
import helpers from '@common/utils/helpers';

const OrdemServicoService = {
  listar: function (parametros) {
    var queryString =  helpers.objectToQueryString(parametros);
    return BasicoApi.get()(`/ordemservico?${queryString}`);
  },
  gerarOrcamentoOrdemServico: function (id, revisar = false) {
    return BasicoApi.post()(`/ordemservico/${id}/orcamento?revisar=${revisar}`);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/ordemservico/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/ordemservico/${form.id}`, form);
    return BasicoApi.post()('/ordemservico', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/ordemservico/${id}`);
  },
  atualizarEmAndamento: function (ordemServicoId, instrumentoId) {
    return CalculosApi.patch()(
      `/ordemservico/${ordemServicoId}/instrumento/${instrumentoId}/emandamento`
    );
  },
  buscaDadosOrdemServicoInstrumento: function (instrumentoId, ordemServicoId) {
    return BasicoApi.get()(
      `/ordemservico/${ordemServicoId}/instrumento/${instrumentoId}/garantia`
    );
  },
  criarOrdemServicoParcial: function (id, form) {
    return BasicoApi.post()(`/ordemservico/${id}/os-parcial`, form);
  },
  atualizarDataPrevisaoEntregaParcial: function (id, form) {
    return BasicoApi.post()(`/ordemservico/${id}/prazo-entrega-os`, form);
  },
  alterarStatusOrdemServico: function (form) {
    return BasicoApi.post()(`/ordemservico/altera-status`, form);
  },
  reverterStatusOrdemServico: function (form) {
    return BasicoApi.post()(`/ordemservico/revert-status`, form);
  },
  buscarDadosEmailOSCriada: function (id) {
    return BasicoApi.get()(`/ordemservico/${id}/email-data`);
  },
  enviarEmailOSCriada: function (form) {
    return BasicoApi.post()(`/ordemservico/send-email`, form);
  },
  atualizarPreviewEmail: function (id, form) {
    return BasicoApi.post()(`/ordemservico/${id}/compile-email-html`, form);
  }
};

export default OrdemServicoService;
