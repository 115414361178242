import helpers from '@router/helpers';
const menuId = 'motivo-cancelamento-roteiro';

export default [
  helpers.criarRota(menuId, 'motivo-cancelamento-roteiro', menuId, () =>
    import('@/views/modulos/cadastros/roteiro_motivo_cancelamento')
  ),
  helpers.criarRota(menuId, 'motivo-cancelamento-roteiro-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/roteiro_motivo_cancelamento/formulario')
  ),
  helpers.criarRota(
    menuId,
    'motivo-cancelamento-roteiro-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/roteiro_motivo_cancelamento/formulario'),
    true
  ),
];
