import helpers from '@router/helpers';
const menuId = 'tipo-item';

export default [
  helpers.criarRota(menuId, 'tipo-item', menuId, () =>
    import('@/views/modulos/cadastros/tipo_item')
  ),
  helpers.criarRota(menuId, 'tipo-item-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/tipo_item/formulario')
  ),
  helpers.criarRota(
    menuId,
    'tipo-item-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_item/formulario'),
    true
  ),
];
