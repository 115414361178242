import { BasicoApi } from '@common/api';
const MensagemOrcamentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/mensagem', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/mensagem/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/mensagem/${form.id}`, form);
    return BasicoApi.post()('/mensagem', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/mensagem/${id}`);
  },
  salvarOrdenacao: function (lista) {
    return BasicoApi.put()(`/mensagem/atualiza-lista`, lista);
  },
};

export default MensagemOrcamentoService;
