export default {
  filtro: {
    codigo: 'Código',
    nome: 'Nome',
    quantidade_de_variaveis: 'Quantidade de Variáveis'
  },
  tabela: {
    codigo: 'Código',
    nome: 'Nome',
    quantidade_de_variaveis: 'Quantidade de Variáveis'
  },
  titulo_modal: 'Fórmulas do Mensurando',
};
