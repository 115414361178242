export default {
  titulos: {
    listagem: 'Registros de Manutenção / Troca de Peças',
    novo: 'Novo Registro de Manutenção / Troca de Peças',
    editar: 'Editar Registro de Manutenção / Troca de Peças',
  },
  dados: 'Dados',
  pecas: 'Peças',
  manutencao: 'Manutenção',
  cadastro_sucesso:
    'Registro de Manutenção / Troca de Peças salvo com sucesso!',
  exclusao_sucesso:
    'Registro de Manutenção / Troca de Peças excluído com sucesso!',
  tabela: {
    instrumento: 'Instrumento',
    fornecedor: 'Fornecedor',
    local_manutencao: 'Local de Manutenção',
    tipo_manutencao: 'Tipo de Manutenção',
    data_envio: 'Data de Envio',
  },
  formulario: {
    instrumento: 'Instrumento',
    local_manutencao: 'Local de Manutenção',
    fornecedor: 'Fornecedor',
    tipo_manutencao: 'Tipo de Manutenção',
    valor_manutencao: 'Valor da Manutenção',
    ordem_compra: 'Ordem de Compra',
    descricao_manutencao: 'Descrição da Manutenção',
    data_envio: 'Data de Envio',
    tipo_prazo_acordado: 'Tipo de Prazo',
    prazo_acordado: 'Prazo',
    data_prev_retorno: 'Data Prev. de Retorno',
    data_retorno: 'Data de Retorno',
    ocorrencia: 'Ocorrência',
    troca_pecas: 'Troca de Peças',
    descricao_peca: 'Descrição da Peça',
    valor_peca: 'Valor',
    ordem_compra_peca: 'Ordem de Compra',
    usuario: 'Usuário',
    obervacoes: 'Observações',
    participante: 'Fornecedor'
  },
  erros: {
    id_invalido: 'O id do Registro é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o Registro.',
  },
  filtro: {
    nome: 'Nome',
    classificacaoDocumento: 'Classificação',
  },
};
