import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.Numerico'),
    value: 'Numerico',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.Texto'),
    value: 'Texto',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.Data'),
    value: 'Data',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.Angulo'),
    value: 'Angulo',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.Booleano'),
    value: 'Booleano',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.ListaEscolhaUnica'),
    value: 'ListaEscolhaUnica',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalTipoCampo.ListaMultiplaEscolha'),
    value: 'ListaMultiplaEscolha',
  },
];
