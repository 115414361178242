export class DropDownIdNomeSimbolo {
  constructor(lista, texto = 'nome', simbolo = 'simbolo') {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.id;
        novoObj.text = el[texto];
        novoObj.simbolo = el[simbolo];
        novoObj.grandezaFisica = el?.grandezaFisica
        return novoObj;
      });
    return {
      value: lista.id,
      text: lista[texto],
      simbolo: lista[simbolo],
      grandezaFisica: lista?.grandezaFisica
    };
  }
}
