export default {
  titulos: {
    listagem: 'Modelos de Instrumentos',
    novo: 'Novo Modelo de Instrumento',
    editar: 'Editar Modelo de Instrumento',
    visualizar: 'Visualizar Modelo de Instrumento',
    modelo_instrumento: 'Modelo de Instrumento',
  },
  imagem:"Por favor, clique na imagem para adicionar peças",
  cadastro_sucesso: 'Modelo de Instrumento salvo com sucesso!',
  edicao_sucesso: 'Modelo de Instrumento editado com sucesso!',
  exclusao_sucesso: 'Modelo de Instrumento excluído com sucesso!',
  redirecionar_faixa: 'Editar Faixa no Tipo Instrumento',
  verifique_tipo_instrumento: 'Verifique ou Selecione um Tipo de Instrumento',
  confirmar_redirecionar_faixa: 'Você será redirecionado e perderá os dados não salvos, deseja continuar?',
  tabela: {
    nome: 'Nome',
    marca: 'Marca',
    faixas: 'Faixas',
    descricao: 'Descrição',
    tipo_de_instrumento: 'Tipo de Instrumento',
    pontos_de_calibracao: 'Pontos de Calibração',
    faixasTable: {
      descricao: 'Descrição',
      faixa: 'Faixa',
      resolucao: 'Resolução',
      valor: 'Valor',
      qtd_pontos_calibracao: 'Quantidade de Pontos',
      buscar: 'Buscar',
    },
    pontos: {
      valor: 'Valor',
      descricao: 'Descrição',
      tipoPonto: 'Tipo Ponto',
    },
  },
  formulario: {
    nome: 'Nome',
    tipo_de_instrumento: 'Tipo de Instrumento',
    marca: 'Marca',
    valor: 'Valor',
    descricao: 'Descrição',
    tabela_tipo_instrumento: {
      nome: 'Nome',
      area_de_acreditacao: 'Área de Acreditação',
      setor_responsavel: 'Setor Responsável',
    },
    abas: {
      dados_do_modelo_de_instrumento: 'Dados do Modelo de Instrumento',
      faixas: 'Faixas',
      campos_adicionais: 'Campos Adicionais',
      vista_explodida: 'Vista Explodida',
    },
    associar_desassociar_tipos_instrumentos: 'Faixas do Tipo de Instrumento',
    faixa: {
      nova_faixa: 'Nova Faixa',
      amplitude_minima: 'Amplitude Mínima',
      amplitude_maxima: 'Amplitude Máxima',
      resolucao: 'Resolução',
      unidade_de_medida: 'Unidade de Medida',
      valor: 'Valor',
    },
  },
  validacoes: {
    formulario_invalido: 'Por favor, revise os campos',
  },
  erros: {
    id_invalido: 'O id do Modelo de Instrumento é inválido',
    selecione_faixa: 'Por favor selecione uma Faixa',
    escolher_tipo_instrumento: 'É preciso inserir um Tipo Instrumento',
  },
  botoes: {
    associar_faixa: 'Selecionar Faixas',
    associar_desassociar: 'Associar / Dessasociar Setores',
    novo_ponto: 'Novo Ponto de Calibração',
    associar_desassociar_ponto: 'Associar / Dessasociar Pontos de Calibração',
    selecionar_pontos: 'Selecionar Pontos de Calibração',
  },
  campos_adicionais: {
    nome: 'Nome do Campo Adicional',
    valor_padrao: 'Valor no Modelo',
    valor_input: 'Valor Padrão',
    input_tipo_campo: 'Digite um valor caso existir',
    valor_verificacao_intermediarias: 'Valor na Verificação Intermediária'
  },
  filtro: {
    nome: 'Nome',
    tipo_instrumento: 'Tipo Instrumento',
    marca: 'Marca',
    descricao: 'Descrição'
  }
};
