<template>
  <modal-padrao
    v-if="modalAberto"
    ref="modal"
    :titulo="titulo"
    max-width="90%"
    :mostrar-botoes="false"
    @modal-fechado="destruirModal"
  >
    <gerador-relatorio
      ref="gerador"
      style="min-height: 1000px;"
      @houve-erro="destruirModal"
    />
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import GeradorRelatorio from './GeradorRelatorio.vue';

export default {
  components: {
    GeradorRelatorio,
  },
  props: {
    titulo: {
      type: String,
      default: 'Visualização de Relatório',
    },
  },
  data() {
    return {
      modalAberto: false
    }
  },
  methods: {
    destruirModal() {
      this.modalAberto = false;
    },
    abrirModal: async function (nomeRelatorio, params) {
      this.modalAberto = true;
      await this.$nextTick();

      const codigoRelatorio = helpers.CodigoRelatorioEnum.find(el => el.text == nomeRelatorio);
      if (!codigoRelatorio) { this.toastAlerta("Código do relatório não encontrado"); return }

      this.$refs['modal'].abrirModal();
      await this.$nextTick();
      this.$refs.gerador.inicializarVisualizadorPorCodigo(codigoRelatorio?.value, params);
    },
  },
};
</script>
