import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumOrigemPonto.Modelo'),
    value: 'Modelo',
  },
  {
    text: i18n.t('enums.EnumOrigemPonto.Instrumento'),
    value: 'Instrumento',
  },
  {
    text: i18n.t('enums.EnumOrigemPonto.TipoInstrumento'),
    value: 'TipoInstrumento',
  },
];
