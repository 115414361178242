import i18n from '@/i18n';
export class EnumModel {
  constructor(lista, nomeEnum) {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.value;
        novoObj.text = i18n.t(`enums.${nomeEnum}.${el.value}`);
        novoObj.seq = el.seq
        return novoObj;
      });
    return {
      value: lista.value,
      text: i18n.t(`${lista.value}`),
      seq: lista.seq,
    };
  }
}
