import helpers from '@router/helpers';
const menuId = 'ordem-servico';

export default [
  helpers.criarRota(menuId, 'ordem-servico', menuId, () =>
    import('@/views/modulos/cadastros/ordem_servico')
  ),
  helpers.criarRota(menuId, 'ordem-servico-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/ordem_servico/formulario')
  ),
  helpers.criarRota(
    menuId,
    'ordem-servico-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/ordem_servico/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'ordem-servico-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/ordem_servico/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'ordem-servico-parcial',
    `${menuId}/parcial/:id?`,
    () => import('@/views/modulos/cadastros/ordem_servico/ordemServicoParcial'),
    true
  ),
  helpers.criarRota(
    menuId,
    'ordem-servico-email',
    `${menuId}/email/:id?`,
    () => import('@/views/modulos/cadastros/ordem_servico/ordemSerivicoCriadaEmail'),
    true
  ),
  helpers.criarRota(
    menuId,
    'ordem-servico-movimentar-situacao',
    `${menuId}/movimentar-situacao/:id?`,
    () => import('@/views/modulos/cadastros/ordem_servico/ordemServicoMovimentarSituacao'),
    true
  ),
];
