import { BasicoApi } from '@common/api';
const TipoOcorrenciaService = {
  listar: function (parametros) {
    return BasicoApi.get()('/tipoocorrencia', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tipoocorrencia/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipoocorrencia/${form.id}`, form);
    return BasicoApi.post()('/tipoocorrencia', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tipoocorrencia/${id}`);
  },
};

export default TipoOcorrenciaService;
