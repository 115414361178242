import i18n from '@/i18n';
export default [
  {
    value: 'Somente Erro',
    text: i18n.t('enums.EnumFormulaValidacaoCriterioValidacao.SomenteErro'),
  },
  {
    value: i18n.t(
      'enums.EnumFormulaValidacaoCriterioValidacao.SomenteIncerteza'
    ),
    text: 'Somente Incerteza',
  },
  {
    value: i18n.t(
      'enums.EnumFormulaValidacaoCriterioValidacao.ErroIncertezaCombinado'
    ),
    text: 'Erro Incerteza Combinado',
  },
];
