import i18n from '@/i18n';
export default [
  {
    text: i18n.t('enums.tipo_endereco.sede'),
    value: 'Sede',
  },
  {
    text: i18n.t('enums.tipo_endereco.cobranca'),
    value: 'Cobranca',
  },
  {
    text: i18n.t('enums.tipo_endereco.local_entrega'),
    value: 'LocalEntrega',
  },
  {
    text: i18n.t('enums.tipo_endereco.local_retirada'),
    value: 'LocalRetirada',
  },
];
