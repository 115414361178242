export default {
  titulos: {
    listagem: 'Motivo de Cancelamento de Roteiro',
    novo: 'Novo Motivo de Cancelamento de Roteiro',
    editar: 'Editar Motivo de Cancelamento de Roteiro',
  },
  cadastro_sucesso: 'Motivo de Cancelamento de Roteiro salvo com sucesso!',
  exclusao_sucesso: 'Motivo de Cancelamento de Roteiro excluído com sucesso!',
  tabela: {
    nome: 'Nome',
  },
  formulario: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id do Motivo de Cancelamento de Roteiro é invalido',
  },
};
