import helpers from '@router/helpers';
const menuId = 'registro-ocorrencia';

export default [
  helpers.criarRota(menuId, 'registro-ocorrencia', menuId, () =>
    import('@/views/modulos/cadastros/registro_ocorrencia')
  ),
  helpers.criarRota(
    menuId,
    'registro-ocorrencia-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/registro_ocorrencia/formulario')
  ),
  helpers.criarRota(
    menuId,
    'registro-ocorrencia-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/registro_ocorrencia/formulario'),
    true
  ),
];
