import botoes from './botoes';
import tips from './tips';
import titulos from './titulos';
import inputs from './inputs';
import alertas from './alertas';
import erros from './erros';
import validacao from './validacao';
import vueGoodTable from './vueGoodTable';
import tabela from './tabela';
import regras from './regras';

export default {
  botoes,
  tips,
  titulos,
  inputs,
  alertas,
  erros,
  validacao,
  vueGoodTable,
  tabela,
  regras
};
