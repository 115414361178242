export default {
  titulos: {
    setor:"Setor",
    kanban:"Kanban",
    programacao_manual:"Programação Manual",
    listagem:"Kanban - Programação Manual",
  },
  mensagem_nao_ha_dados:"Não há colunas cadastradas para o setor informado",
  cadastro_sucesso: 'Coluna salva com sucesso!',
  exclusao_sucesso: 'Coluna de orçamento excluida com sucesso!',
  ordenacao_sucesso: 'Ordenação das colunas salva com sucesso!',
  tabela: {
    nome_coluna: 'Nome da Coluna',
    situacoes: 'Situações',
    quantidade_cartoes_por_linha: 'Quantidade de cartões por linha',
    tamanho_fonte: 'Tamanho da fonte',
    cor_coluna: 'Cor da coluna',
    cor_cartao: 'Cor do cartão',
    cor_fonte: 'Cor da fonte',
    cor_fundo_titulo: 'Cor do fundo do título',
    cor_fonte_titulo: 'Cor do texto do título',
    exibir_todos_setores: 'Exibir card de todos os setores',
  },
  erros: {
    id_invalido: 'O id da coluna é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  colunas:{

    data:"Data",
    num_horas_disponiveis:"Horas disponíveis",
    num_horas_alocadas:"Horas alocadas",

    AguardandoProgramacao:"Aguardando Programação",
    ProgramacaoTerceiroDia:"Programação do terceiro dia",
    ProgramacaoSegundoDia:"Programação do segundo dia",
    ProgramacaoDiaEmExecucao:"Programação do dia + Em execução",
  }
};
