import i18n from '@/i18n';
export default [
  {
    value: 'Sistema',
    text: i18n.t('enums.EnumTipoRelatorio.Sistema'),
  },
    {
        value: 'Usuario',
        text: i18n.t('enums.EnumTipoRelatorio.Usuario'),
    },
    {
        value: 'CertificadoPadrao',
        text: i18n.t('enums.EnumTipoRelatorio.CertificadoPadrao'),
    },
];