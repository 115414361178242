export default {
  codigo: 'Código',
  nome: 'Nome',
  qtdVariaveis: 'Quantidade de Variáveis',
  tabela: {
    codigo: 'Código',
    nome: 'Nome',
    qtdVariaveis: 'Quantidade de Variáveis',
  },
  titulo_modal: 'Associar Fórmula do Mensurado',
};
