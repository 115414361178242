import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumSituacaoVerificacao.EmAndamento'),
    value: 'EmAndamento',
    cor: '',
  },
  {
    text: i18n.t('enums.EnumSituacaoVerificacao.NaoValidado'),
    value: 'NaoValidado',
    cor: 'primary-warning-500',
  },
  {
    text: i18n.t('enums.EnumSituacaoVerificacao.Aprovada'),
    value: 'Aprovada',
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoVerificacao.Reprovada'),
    value: 'Reprovada',
    cor: 'primary-error-700',
  },
];
