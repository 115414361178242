<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      style="min-height: 300px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      filtro-global-focus
      @paginar="listar"
      @filtrarGlobal="listar"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
    />
  </div>
</template>
  <script>
  import EndpointsService from '@/common/services/cadastros/EndpointsService';
  import _ from 'lodash';
  export default {
    props: {
      multiplos: { type: Boolean, default: true },
      value: { type: Array, default: () => [] },
    },
    data() {
      return {
        filtro: {
          nome: '',
        },
        filtroAplicado: { filter: '' },
        selecionados: [],
        tabela: {
          dados: [],
          colunas: [
            {
              value: 'nome',
              text: this.$t('modulos.endpoints.sql.nome'),
              sortable: true,
            },
            {
              value: 'conteudo',
              text: this.$t('modulos.endpoints.sql.conteudo'),
              sortable: true,
            },
            {
              value: 'data_criacao',
              text: this.$t('modulos.endpoints.sql.data_criacao'),
              sortable: true,
            },
            {
              value: 'data_ultima_modificacao',
              text: this.$t('modulos.endpoints.sql.data_ultima_modificacao'),
              sortable: true,
            },
          ],
          quantidadeItens: 0,
          quantidadePaginas: 0,
          paginaAtual: 1,
          porPagina: 10,
        },
      };
    },
    watch: {
      value: {
        handler() {
          this.selecionados = this.value;
        },
        immediate: true,
      },
      selecionados: {
        handler() {
          if (Array.isArray(this.selecionados)) {
            this.$emit('input', this.selecionados);
            return;
          }
          this.$emit('input', [this.selecionados]);
        },
      },
    },
    mounted() {
      this.listar();
    },
    methods: {
      filtrar: function () {
        this.filtroAplicado = _.cloneDeep(this.filtro);
        this.listar();
      },
      MontarViewTags(tags) {
        return {
          value: tags.map((v) => {
            return { nome: v };
          }),
        };
      },
      listar: function (
        paginaAtual = this.tabela.paginaAtual,
        porPagina = this.tabela.porPagina
      ) {
        let parametros = {
          page: paginaAtual,
          amountPerPage: porPagina,
          sort: 'Nome',
          ...this.filtroAplicado,
        };
        this.$store.dispatch('Layout/iniciarCarregamento');
        EndpointsService.listarSql(parametros)
          .then((res) => {
            this.tabela.dados = res.data.items;
            this.tabela.quantidadeItens = res.data.totalItems;
            this.tabela.quantidadePaginas = res.data.totalPages;
            this.tabela.paginaAtual = paginaAtual;
            this.tabela.porPagina = porPagina;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      },
    },
  };
  </script>
  