import Vue from 'vue';
import VueI18n from 'vue-i18n';
import indexPtBr from './pt-BR/index';
// import indexEn from './en/index'
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = {
    'pt-BR': { ...indexPtBr },
  };

  const messages = {};

  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)/i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[locale];
    }
  });
  return messages;
}

const numberFormats = {
  'pt-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      currencyDisplay: 'symbol',
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  'ja-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol',
    },
  },
};

function getLocale() {
  const LSLocale = localStorage.getItem('locale');
  const validLocales = ['en', 'pt-BR'];
  const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'pt-BR';

  if (LSLocale) return LSLocale;

  let locale = '';

  const userLocale = navigator.languages[0] || navigator.language;

  if (userLocale && validLocales.includes(userLocale)) {
    locale = userLocale;
  } else {
    locale = defaultLocale;
  }

  return locale;
}

export default new VueI18n({
  legacy: false,
  globalInjection: true,
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  numberFormats,
});
