export default {
  excluir: {
    titulo: 'Excluir',
    mensagem: 'Deseja realmente excluir?',
  },
  salvar: {
    titulo: 'Salvar',
    mensagem: 'Deseja realmente salvar?',
  },
  cancelar: {
    titulo: 'Cancelar',
    mensagem: 'Deseja realmente cancelar?',
  },
  voltar: {
    titulo: 'Retornar',
    mensagem: 'Deseja realmente retornar?',
  },
  liberar_orcamento: {
    titulo: 'Liberar Orçamento',
    mensagem: 'Você tem certeza que deseja liberar o Orçamento {orcamento}?',
  },
  abrir_orcamento: {
    titulo: 'Visualizar Orçamento',
    mensagem: 'Orçamento gerado com sucesso, deseja editar o orçamento?',
  },
  confirmar_entrega: {
    titulo: 'Entrega',
    mensagem: 'Confirma a Entrega da Ordem de Serviço?',
  },
  confirmar_gerar_orcamento: {
    titulo: 'Gerar Orçamento',
    mensagem:
      'Deseja Gerar um Orçamento com base na Ordem de Serviço {codigo}?',
  },
  liberar_orcamento_lote: {
    titulo: 'Liberar Orçamento em Lote',
    mensagem:
      'Você tem certeza que deseja liberar os Orçamentos {codigos} em Lote?',
  },
  confirmar_email_lote: {
    titulo: 'Enviar por Email em Lote',
    mensagem:
      'Você tem certeza que deseja Enviar por Email em Lote os orçamentos {codigos}?',
  },
  liberar_contrato: {
    titulo: 'Liberar Contrato',
    mensagem: 'Você tem certeza que deseja liberar o Contrato?',
  },
  assinar_contrato: {
    titulo: 'Assinar Contrato',
    mensagem:
      'Você deseja Assinar o contrato {contrato}, com o valor final de R${valor}?',
  },
  reprovar_contrato: {
    titulo: 'Reprovar Contrato',
    mensagem:
      'Você deseja Reprovar o contrato {contrato}, com o valor final de R${valor}?',
  },
  cliente_participante: {
    titulo: 'Alteração Cliente',
    mensagem:
      'Ao alterar o Cliente vai perder os instrumentos cadastrados, deseja continuar?',
  },
  alteracao_checklist: {
    titulo: 'Alteração Tipo do Checklist',
    mensagem:
      'Ao alterar o Tipo do Checklist vai perder os itens cadastrados, deseja continuar?',
  },
  aprovar_orcamento: {
    titulo: 'Aprovar Orçamento',
    mensagem:
      'Você deseja aprovar o Orçamento {codigo}, com valor aprovado de {valor}?',
  },
  unicidade_instrumento: {
    titulo: 'Atenção',
    mensagem:
      'Instrumento já consta no contrato {codigo} e por essa razão não pode ser vinculado ao contrato atual.',
  },
  reprovar_orcamento: {
    titulo: 'Reprovar Orçamento',
    mensagem: 'Você tem certeza que deseja reprovar o orçamento selecionado?',
  },
  adicionar_automaticamente_servicos_pecas: {
    titulo: 'Adição Automática',
    mensagem:
      'Havendo compatibilidade de serviços e/ou peças, serão selecionados, deseja continuar?',
  },
  agrupar_instrumentos: {
    titulo: 'Instrumento',
    mensagem: 'Deseja inserir os instrumentos de forma agrupada?',
  },
  pre_assinar_contrato: {
    titulo: 'Pré Assinar Contrato',
    mensagem: 'Você tem certeza que deseja pré assinar o contrato selecionado?',
  },
  redefinir_orcamento: {
    titulo: 'Redefinir Orçamento',
    mensagem: 'Você tem certeza que deseja redefinir o orçamento selecionado?',
  },
  redefinir_contrato: {
    titulo: 'Redefinir Contrato',
    mensagem: 'Você tem certeza que deseja redefinir o contrato selecionado?',
  },
  criar_aditivo_contrato: {
    titulo: 'Criar Novo Aditivo',
    mensagem: 'Deseja criar um aditivo para o contrato {contrato}?',
  },
  criar_aditivo_contrato_ja_aditivo: {
    titulo: 'Criar Novo Aditivo',
    mensagem:
      'Deseja criar um aditivo para o contrato {contrato} baseado no conteúdo do contrato {contrato}.{sequencia}',
  },
  duplicar_orcamento: {
    titulo: 'Duplicar Orçamento',
    mensagem: 'Você tem certeza que deseja duplicar este orçamento?',
  },
  duplicar_contrato: {
    titulo: 'Renovar Contrato',
    mensagem: 'Você tem certeza que deseja renovar este contrato?',
  },
  analise_tecnica: {
    titulo_validacao_local: 'Alteração Local Execução',
    mensagem_validacao_local:
      'Ao alterar o Local de execução, os Serviços de Manutenção e Calibração devem ser escolhidos novamente',
  },
  analise_tecnica_tipo_calibracao: {
    titulo_validacao_tipo_calibracao: 'Alteração Tipo de Calibração',
    mensagem_validacao_tipo_calibracao:
      'Ao alterar o Tipo de Calibração, os Serviços de Calibração devem ser escolhidos novamente',
  },
  marcar_como_enviado: {
    titulo: 'Marcar Como Enviado',
    mensagem_selecionados:
      'Deseja marcar os itens selecionados como enviados ao cliente?',
    mensagem_selecionado: 'Deseja marcar o item como enviado ao cliente?',
    mensagem_selecione_orcamento:
      'Selecione algum orçamento para ser marcado como enviado',
  },
  alterar_participante_logado: {
    titulo: 'Alterar Participante',
    mensagem: 'Deseja continuar? Você será redirecionado para a página inicial',
  },
};
