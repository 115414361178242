export default {
  titulos: {
    listagem: 'Marcas',
    novo: 'Nova Marca',
    editar: 'Editar Marca',
  },
  cadastro_sucesso: 'Marca salva com sucesso!',
  exclusao_sucesso: 'Marca excluída com sucesso!',
  tabela: {
    nome: 'Nome',
  },
  formulario: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido: 'É necessário validar o formulário antes de salvar o registro.'
  },
  filtro: {
    nome: 'Nome',
  },
};
