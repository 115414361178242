import helpers from '@router/helpers';
const menuId = 'classificacao-fiscal';

export default [
  helpers.criarRota(
    menuId,
    'classificacao-fiscal',
    menuId,
    () => import('@/views/modulos/cadastros/classificacao_fiscal')
  ),
  helpers.criarRota(
    menuId,
    'classificacao-fiscal-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/classificacao_fiscal/formulario')
  ),
  helpers.criarRota(
    menuId,
    'classificacao-fiscal-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/classificacao_fiscal/formulario'),
    true
  ),
];
