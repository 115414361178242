export default {
  SETAR_TOAST(state, payload) {
    state.dadosToast = { visivel: true, ...payload };
  },
  SETAR_SPINNER(state, payload) {
    state.spinnerVisivel = payload[0];
    state.spinnerTexto = payload[1];
  },
  SETAR_TITULO(state, titulo) {
    state.tituloPagina = titulo;
  },
};
