<template>
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 16.5V5.5M7.5 5.5L14.25 12.25M7.5 5.5L0.75 12.25"
      stroke="#757575"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.25 8.25L7.5 1.5L0.75 8.25"
      stroke="#757575"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {

}
</script>
