// Here we import all enums modules

import selects from './selects';
import enums from '../../enums';

export default {
  enums: {
    ...selects, // Contains all global selects keys
    ...enums,
  },
};
