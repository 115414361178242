export default {
  titulos: {
    listagem: 'Mensagens de Orçamento',
    novo: 'Nova Mensagem de Orçamento',
    editar: 'Editar Mensagem de Orçamento',
  },
  cadastro_sucesso: 'Mensagem de orçamento salva com sucesso!',
  exclusao_sucesso: 'Mensagem de orçamento excluida com sucesso!',
  ordenacao_sucesso: 'Ordenação das mensagens salva com sucesso!',
  tabela: {
    titulo: 'Título',
    nome: 'Nome',
    classificacao: 'Classificação',
    sequencia: 'Sequência',
    descricao: 'Descrição',
    obrigatorio: 'Obrigatório',
    mais_informacoes: 'Mais Informações',
  },
  formulario: {
    sequencia: 'Sequência',
    obrigatorio: 'Obrigatório',
    titulo: 'Título',
    titulo_placeholder: 'Título',
    classificacao: 'Classificação',
    descricao: 'Descrição',
    descricao_placeholder: 'Descrição',
    motivo_reprova: 'Motivo da Reprova',
    tipo_comentario: 'Tipos de Comentário',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    titulo: 'Título',
    classificacao: 'Classificação',
  },
};
