export default {
  titulo: 'Parâmetros',
  cadastro_sucesso: 'Parâmetros salvos com sucesso!',
  parametros_gerais: 'Parâmetros Gerais',
  formulario: {
    conta: 'Conta',
    prazoEntregaPadrao: 'Prazo entrega Padrão',
    flagConsiderarDiasUteisPrazoEntrega: 'Considerar Dias Úteis',
    prazoGarantia: 'Prazo de Garantia',
    prazoOrcamentoContratoPadrao: 'Prazo Padrão p/ Orçamento e Contrato',
    prazoAgendamentosClientes: 'Prazo Agendamentos Clientes',
    prazoConfirmacaoAgendamento: 'Prazo Confirmação Agendamento',
    envio_usuario_logado: 'Enviar e-mails somente para o usuário logado',
    flagAtivaIntegracaoOmie: 'Integração com o OMIE ativa',
    appKey: 'App Key',
    appSecret: 'App Secret',
    email: 'Usuário',
    senha: 'Senha',
    endereco: 'Endereço',
    porta: 'Porta',
    restricaoAcessoColaboradoresIP: 'Restringir acesso de colaboradores por IP',
    restricaoAcessoColaboradoresFaixaIP: 'Restringir acesso de colaboradores por range de IP',
    ipsSelecionados:"IPs selecionados",
    horaMovimentacaoAutomatica:"Hora da movimentação automática",
    range_de_ips: "É range de IPs?",
    pastaArquivosSqlRelatorio: 'Pasta de arquivos SQL para relatórios',
  },
  abas: {
    email: 'E-mail',
    acesso:'Acesso'
  },
  erros: {
    conta_invalida: 'Conta inválida.',
  },
  validacoes: {
    valor_minimo_zero: 'Valor mínimo é zero',
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
    ip_invalido:"É necessário fornecer um endereço de IP válido.",
    ip_range_invalido: 'É necessário fornecer um range de endereço de IP válido e separados por - (hífen). Exemplo: 172.16.0.1-172.16.255.255'
  },
};
