<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    :flat="flat"
    :solo="solo"
    :outlined="outlined"
    :sem-label="semLabel"
    :background-color="backgroundColor"
    :prevent-acoes="preventAcoes"
    @abrir-modal="abrirModal"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="$t('componentes.input_ocorrencia.titulo_modal')"
        :titulo-ok="$t('geral.botoes.confirmar')"
        @ok="aplicarSelecionados"
      >
        <modal
          refs="modal"
          :key="componentKey"
          v-model="selecionados"
          :multiplos="multiplos"
          @fechar-modal-click-linha="aplicarSelecionados"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { InputMixin } from '../../InputMixin';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import Modal from './modal.vue';
import _ from 'lodash';
export default {
  name: 'InputSetor',
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    preventAcoes: { type: Boolean, default: false },
    /** Props de validação */
  },
  data: function () {
    return {
      componentKey: 0,
      conteudo: [],
      selecionados: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit('input', this.conteudo);
    },
    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      this.componentKey++;
      if (!this.conteudo) return false;
      let conteudoClonado = _.cloneDeep(this.conteudo);
      if (!this.multiplos) conteudoClonado = [conteudoClonado];
      this.selecionados = conteudoClonado.map((el) => {
        return {
          id: el.value,
          nome: el.text,
        };
      });
    },
    aplicarSelecionados: function () {
      let selecionados = new DropdownModel(this.selecionados);
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropdownModel(this.selecionados)[0];
      }
      this.conteudo = selecionados;
      this.fechaModal();
    },
    fechaModal() {
      this.$refs['modal-input'].fecharModal();
      this.selecionados = [];
    },
  },
};
</script>
