import helpers from '@router/helpers';

const menuId = 'certificado';

export default [
  helpers.criarRota(
    menuId,
    'certificado-definir-derivas',
    `${menuId}/definir-derivas/:calibracaoId?/instrumento/:instrumentoId?`,
    () => import('@/views/modulos/cadastros/certificados/definir_derivas'),
    true
  ),
  helpers.criarRota(menuId, 'certificado', menuId, () =>
    import('@/views/modulos/cadastros/certificados')
  ),
  helpers.criarRota(
    menuId,
    'certificado-avaliacao',
    `${menuId}/:calibracaoId?/avaliacao`,
    () => import('@/views/modulos/cadastros/certificados/avaliacao'),
    true
  ),
  helpers.criarRota(
    menuId,
    'instrumento-certificado-grafico-tendencia',
    `${menuId}/grafico-tendencia/:instrumentoId?/certificado/:certificadoId?`,
    () => import('@/views/modulos/cadastros/certificados/grafico_tendencia'),
    true
  ),
];
