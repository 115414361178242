export default [
    {
      text: 'ordem-servico',
      value: 'REL-001',
    },
    {
        text: 'registro-informativo',
        value: 'REL-002',
    },
    {
        text: 'contrato',
        value: 'REL-003',
    },
    {
        text: 'contrato-aditivo',
        value: 'REL-004',
    },
    {
        text: 'verificacao-intermediaria',
        value: 'REL-005',
    },
    {
        text: 'orcamento',
        value: 'REL-006',
    },
    {
        text: 'qrcode',
        value: 'REL-007',
    },
    {
        text: 'envio-material',
        value: 'REL-008',
    },
    {
        text: 'etiquetas',
        value: 'REL-009',
    }
  ];
  