<template>
  <div class="input-file" @mouseover="mostrarModal" @mouseleave="fecharModal">
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo d-flex"
    >
      <span class="label">{{ label }}</span>
    </span>
    <v-file-input
      :accept="accept"
      :rules="regras"
      :value="value"
      outlined
      block
      dense
      prepend-icon=""
      :disabled="disabled"
      :placeholder="placeholder"
      hide-details="auto"
      @click:clear="clear"
      @change="handleUpload"
      @clear="clearFile"
    >
      <template #prepend-inner>
        <v-icon>
          {{ $vuetify.icons.values.fileUploadOutline }}
        </v-icon>
        <v-divider vertical class="mx-2" />
      </template>
    </v-file-input>
    <template>
      <div v-if="imagemAmpliada" class="overlay-imagem-layout">
        <div class="fechar-download-imagem">
          <v-icon class="mr-2" size="32" color="#fff" @click="download">
            $download
          </v-icon>
          <v-icon size="32" color="#fff" @click="fecharImagemAmpliada">
            $closeCircleOutline
          </v-icon>
        </div>

        <div class="imagem-maximizada">
          <v-img :src="imagePreview" contain />
        </div>
      </div>
    </template>
    <div
      v-if="
        visualizador && mostrarImagemModal && !!imagePreview && !imagemAmpliada
      "
      class="modal-imagem-input"
      :style="visualizadorEmCima ? `bottom: 100%` : ''"
    >
      <div class="overlay--imagem">
        <v-icon size="64" color="#FFF" @click="abrirImagemAmpliada">
          $mdiMagnifyPlusOutline
        </v-icon>
      </div>
      <v-img :src="imagePreview" class="mt-2" contain />
    </div>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
import helpers from '../../common/utils/helpers';
export default {
  name: 'InputFile',
  mixins: [InputMixin],
  props: {
    accept: {
      type: String,
      default: 'image/*',
    },
    value: {
      type: File,
      default: null,
    },

    returns: {
      type: String,
      default: 'file',
      validator: (returns) => ['file', 'base64'].includes(returns),
    },
    image: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Adicionar Imagem',
    },
    visualizador: {
      type: Boolean,
      default: false,
    },
    visualizadorEmCima: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      file: null,
      conteudoAntigo: '',
      nomeImagem: null,
      imagePreview: '',
      mostrarImagemModal: false,
      imagemAmpliada: false,
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
    visualizarImagem: function () {
      return this.imagePreview;
    },
  },
  watch: {
    value: function (value) {
      if (value?.type == 'image/png') {
        this.objetoParaBase64ImagePreview(value);
      }
    },
  },
  mounted() {
    if (this.image) {
      if (this.image.indexOf('base64') > -1) {
        this.base64ParaObjetoFile(this.image);
      }
    }
  },
  methods: {
    clearFile() {
      this.$emit('clear');
    },
    handleUpload(file) {
      this.file = file;

      if (!file) {
        this.nomeImagem = '';
        this.$emit('nome-imagem', this.nomeImagem);
        this.$emit('handle-upload', null);
        return;
      }
      if (this.file) this.nomeImagem = this.file.name;

      if (this.returns === 'file') {
        this.nomeImagem = file?.name ? file.name : this.nomeImagem;
        this.$emit('handle-upload', this.file);
        this.$emit('nome-imagem', this.nomeImagem);
        return;
      }

      if (this.returns === 'base64') {
        this.nomeImagem = file?.name ? file.name : this.nomeImagem;

        const reader = new FileReader();

        reader.onload = (event) => {
          const base64 = event.target.result;

          this.imagePreview = base64;
          this.$emit('handle-upload', base64);
          this.$emit('nome-imagem', this.nomeImagem);
        };

        reader.readAsDataURL(file);
      }
    },
    base64ParaObjetoFile(dataUrl) {
      this.imagePreview = dataUrl;
      fetch(dataUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const type = dataUrl.split(',')[0].match(/:(.*?);/)[1];
          this.file = new File([blob], 'Imagem', { type });
        });
    },
    objetoParaBase64ImagePreview(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event.target.result;
        this.imagePreview = base64;
      };
      reader.readAsDataURL(file);
    },

    clear() {
      this.imagePreview = null;
      this.$emit('clear');
    },
    mostrarModal() {
      this.mostrarImagemModal = true;
    },
    fecharModal() {
      this.mostrarImagemModal = false;
    },
    abrirImagemAmpliada() {
      this.imagemAmpliada = true;
    },
    fecharImagemAmpliada() {
      this.imagemAmpliada = false;
    },
    download: function () {
      const nome = this.nomeImagem ? this.nomeImagem : this.value.name;
      helpers.baixarBase64(this.imagePreview, nome);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-imagem-input {
  position: absolute;
  width: 90%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px #ccc;
  z-index: 999;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
}

.overlay--imagem {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 9999;
  transition: all 0.3s linear;
  opacity: 0;

  &:hover {
    opacity: 0.3;
  }
}

.overlay-imagem-layout {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  height: 100vh !important;
  background-color: #000;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  transition: all 0.3s linear;
  .imagem-maximizada {
    width: 80%;
    height: 80%;
    max-width: 50vw;
    max-height: 50vh;
    transform: translateY(-15%);
  }

  .fechar-download-imagem {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.input-file {
  position: relative;
}
</style>
