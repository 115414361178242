export default {
  titulos: {
    listagem: 'Papéis',
    novo: 'Novo Papel',
    editar: 'Editar Papel',
  },
  cadastro_sucesso: 'Papel salvo com sucesso!',
  exclusao_sucesso: 'Papel excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    executa_calibracao: 'Executa Calibração',
    flagAtivo:"Ativo?",
    editar:{
      funcionalidade:"Funcionalidade",
      funcionalidadeAcao:'Ação',
      descricao:'Descricao'
    }
  },
  formulario: {
    nome: 'Nome',
    executa_calibracao: 'Executa Calibração',
    observacao: 'Observação',
    permissoes: 'Permissões',
    funcionalidade: 'Funcionalidade',
    selecionar_todos: 'Selecionar Todos',
    flagAtivo: 'Ativo?'
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    nome: 'Nome',
    executa_calibracao: 'Executa Calibração',
  },
};
