import helpers from '@router/helpers';
const menuId = 'tipo-envio';

export default [
  helpers.criarRota(menuId, 'tipo-envio', menuId, () =>
    import('@/views/modulos/cadastros/tipo_envio')
  ),
  helpers.criarRota(menuId, 'tipo-envio-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/tipo_envio/formulario')
  ),
  helpers.criarRota(
    menuId,
    'tipo-envio-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_envio/formulario'),
    true
  ),
];
