<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    @abrir-modal="abrirModal"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="$t('componentes.input_instrumento_padrao.titulo_modal')"
        :titulo-ok="$t('geral.botoes.confirmar')"
        :ok-desabilitado="!podeSalvar"
        @ok="aplicarSelecionados"
      >
        <modal
          ref="modal"
          v-model="selecionados"
          :instrumentos="instrumentos"
          :formula="formula"
          :multiplos="multiplos"
          :unidade-medida-id="unidadeMedidaId"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { InputMixin } from '../../InputMixin';
import Modal from './modal.vue';
import _ from 'lodash';
export default {
  name: 'InputInstrumento',
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    formula: { type: Object, default: () => {} },
    unidadeMedidaId : { type: String, default: null},
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: [],
      selecionados: [],
      instrumentos: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
    podeSalvar : function(){
      if(!this.selecionados.length) return false;
      return this.selecionados[0].faixaSelecionada != null
    }
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit('input', this.conteudo);
    },
    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      setTimeout(() => {
        this.$refs.modal.inicializarModal();
        this.$refs.modal.listarVariaveis(this.formula?.value);
      }, 500);

      if (!this.conteudo) return false;

      this.instrumentos = _.cloneDeep(this.conteudo);
    },
    aplicarSelecionados: function () {
      let form = this.$refs.modal.getForm();
      let instrumento = this.conteudo.find(
        (el) =>
          el.participanteId == form.participanteId &&
          el.formulaVariavel.value == form.formulaVariavel.value
      );
      if (!instrumento) this.conteudo.push(form);
      else {
        instrumento.text = form.text;
        instrumento.instrumentoPadrao = form.instrumentoPadrao;
      }

      this.$refs['modal-input'].fecharModal();
    },
  },
};
</script>
