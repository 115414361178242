// selects.js

export default {
  selects: {
    tipoEndereco: {
      sede: 'Sede',
      cobranca: 'Cobrança',
      localEntrega: 'Local de entrega',
      localRetirada: 'Local de retirada'
    },
    tipoParticipantes: {
      pessoaJuridica: 'Pessoa Jurídica',
      pessoaFisica: 'Pessoa Física'
    },
    boolean: {
      selecione: 'Selecione',
      ativo: 'Ativos',
      inativo: 'Inativo'
    },
    tipoRequisitos: {
      recebimento: 'Recebimento',
      analiseTecnica: 'Análise Técnica',
      calibracao: 'Calibração',
      ensaio: 'Ensaio',
      expedicao: 'Expedição',
      orcamento: 'Orçamento',
      notasFiscais: 'Notas Fiscais'
    },
    feriadoNivel: {
      federal: 'Federal',
      estadual: 'Estadual',
      municipio: 'Municipal'
    },
    feriadoTipo: {
      fixo: 'Fixo',
      movel: 'Móvel'
    },
    yesOrNot: {
      yes: 'Sim',
      not: 'Não'
    },
    solicitacoesCadastroTipoCadastro: {
      unidadeMedida: 'Unidade de medida',
      tipoInstrumento: 'Tipos de instrumento',
      modeloInstrumentos: 'Modelos de instrumento'
    },
    solicitacoesCadastroStatus: {
      aberto: 'Aberto',
      encecrrado: 'Encerrado',
      reprovado: 'Reprovado'
    },
    situacaoOrdemServico: {
      aguardandoAnaliseTecnica: 'Aguardando Análise Técnica',
      aguardandoAprovacaoGarantia: 'Aguardando Aprovação de Garantia',
      aguardandoEnvioOrcamento: 'Aguardando Envio do Orçamento',
      aguardandoAprovacaoOrcamento: 'Aguardando Aprovação do Orçamento',
      aguardandoProgramacao: 'Aguardando Programação',
      programacaoTerceiroDia: 'Programação do Terceiro Dia',
      programacaoSegundoDia: 'Programação do Segundo Dia',
      programacaoDia: 'Programação do Dia',
      emExecucao: 'Em Execução',
      aguardandoAprovacaoCertificado: 'Aguardando Aprovação do Certificado',
      aguardandoPlanoInspecao: 'Aguardando Plano de Inspeção',
      calibrado: 'Calibrado',
      comPendenciaAguardandoPeca: 'Com pendência – Aguardando Peça',
      naoCalibrado: 'Não calibrado (Reprovado / Sem Calibração)',
      reprovadoOrcamento: 'Reprovado pelo Orçamento',
    }
  }
}
