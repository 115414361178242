// inputs.js

export default {
  inputs: {
    rules: {
      required: 'O campo é obrigatório',
      charlimit: 'Limite máximo de {number} caracteres',
      minValue: 'O valor mínimo  é {number}',
      maxBetweenDecimalValue: 'Máximo de {char} inteiros com {decimal} decimais',
      validInputNumber: 'Coloque um valor valido',
      validEmail: 'E-mail deve ser válido',
      validarApenasNumeros: "Apenas números são permitidos",
      validCPF: 'CPF deve ser válido',
      validCNPJ: 'CNPJ deve ser válido',
      isInteger: 'O valor deve ser um número inteiro',
      amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima: 'Amplitude máxima não pode ser menor que amplitude miníma',
      amplitudeMinimaNaoPodeSerMaiorQueAmplitudeMaxima: 'Amplitude miníma não pode ser maior que amplitude máxima',
      unconfirmedEmail: 'O e-mail de confirmação não corresponde',
      unconfirmedPassword: 'A senha de confirmação não corresponde',
      minOneValueSelect: 'Selecione pelo menos um item',
      naoAceitaEspacoVazio: 'Não aceita espaço vazio',
      horario_inicio_maior_inicio_intervalo:'Horário de entrada não pode ser maior que o horário de  início do intervalo',
      horario_inicio_maior_termino_intervalo:'Horário de entrada não pode ser maior que o horário de  término do intervalo',
      horario_inicio_maior_saida:'Horário de entrada não pode ser maior que o horário de saída',

      horario_saida_menor_entrada:'Horário de saída não pode ser menor que o horário de entrada',
      horario_saida_menor_inicio_intervalo: 'Horário de saída não pode ser menor que o horário de início do intervalo',
      horario_saida_menor_termino_intervalo: 'Horário de saída não pode ser menor que o horário de término do intervalo',

      horario_inicio_intervalo_maior_termino_intervalo:'Horário de início do intervalo não pode ser maior que o horário de término do intervalo',
    }
  }
}
