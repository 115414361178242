export default {
  titulos: {
    listagem: 'Verificações Intermediárias',
    novo: 'Nova Verificação Intermediária',
    editar: 'Editar Verificação Intermediária',
    visualizar: 'Visualizar Verificação Intermediária',
    validar: 'Validar Verificação Intermediária',
    temperatura: 'Temperatura',
    umidade: 'Umidade Relativa',
    pressao: 'Pressão Atmosférica',
  },
  cadastro_sucesso: 'Verificação Intermediária salva com sucesso!',
  valores_sucesso: 'Valores salvos com sucesso!',
  exclusao_sucesso:
    'Verificação(ões) Intermediária(s) excluída(s) com sucesso!',
  aprovado_sucesso: 'Verificação Intermediária aprovada com sucesso!',
  reprovado_sucesso: 'Verificação Intermediária reprovada com sucesso!',
  finalizar_sucesso: 'Verificação Intermediária finalizada com sucesso!',
  campos_sucesso: 'Campos adicionais salvos com sucesso!',
  confirmar_finalizar: 'Deseja finalizar a verificação intermediária?',
  confirmar_voltar_edicao: 'A situação da Verificação Intermediária é {0}, deseja voltar para Edição?',
  confirmar_aprovar: 'Deseja aprovar a verificação intermediária?',
  confirmar_reprovar: 'Deseja reprovar a verificação intermediária?',
  tabela: {
    codigo_verificacao: 'Código',
    codigo_instrumento: 'Instrumento',
    tipo_instrumento: 'Tipo do Instrumento',
    cliente: 'Cliente',
    laboratorio: 'Laboratorio',
    data_verificacao: 'Data da Verificação',
    situacao_verificacao: 'Situação da Verificação',
    usuario_executante: 'Usuario Executante',
    usuario_aprovador: 'Usuario Aprovador',
  },
  formulario: {
    abas: {
      dados: 'Dados da Verificação',
      valores: 'Valores',
      faixas: 'Faixas',
      campos_adicionais: 'Campos Adicionais',
    },
    codigo: 'Código',
    instrumento: 'Instrumento',
    laboratorio: 'Laboratório',
    data: 'Data da Verificação',
    observacao: 'Observação',
    inicio_c: 'Início °C',
    meio_c: 'Meio °C',
    fim_c: 'Fim °C',
    inicio_porcentagem: 'Início %',
    meio_porcentagem: 'Meio %',
    fim_porcentagem: 'Fim %',
    inicio: 'Início',
    meio: 'Meio',
    fim: 'Fim',
    inicio_hpa: 'Início hPa',
    fim_hpa: 'Fim hPa',
  },
  validacoes: {
    formulario_invalido: 'Verifique o formulário',
  },
  erros: {
    id_invalido: 'O id do tipo de setor é inválido',
  },
  valores: {
    ponto: 'Ponto de Verificação',
    primeiroCiclo: '1º Ciclo',
    segundoCiclo: '2º Ciclo',
    terceiroCiclo: '3º Ciclo',
    media: 'Média',
    erro: 'Erro',
    criterio: 'Critério',
    situacao: 'Situação Avaliação',
    descricao: 'Descrição',
    titulos: {
      confirmar_instrumento_padrao: 'Editar Instrumentos Padrão',
      confirmar_limpar_instrumento_padrao: 'Limpar Instrumentos Padrão',
    },
    botoes: {
      limpar: 'Limpar SMP',
      editar: 'Editar SMP',
      tooltip: {
        conversor_unidades: 'Conversor de Unidades',
        instrumento: 'Instrumento',
        weight: 'SMP',
      },
    },
    instrumento_padrao: 'Instrumento Padrão',
    modal: {
      pontos: 'Ponto de Calibração',
      instrumento_atual: 'Instrumento Padrão Atual',
      novo_instrumento: 'Novo Instrumento Padrão',
      tabela: {
        codigo: 'Código',
        tipo_instrumento: 'Tipo Instrumento',
        setor: 'Setor',
      },
      tooltip:
        'É necessário escolher um ponto e um novo Instrumento Padrão para prosseguir.',
      mais_um_instrumento:
        'Existem diferentes instrumentos padrão associados para cada ponto',
    },
  },
  filtro: {
    codigo: 'Código',
    instrumento: 'Instrumento',
    tipo_instrumento: 'Tipo do Instrumento',
    cliente: 'Cliente',
    laboratorio: 'Laboratorio',
    data_verificacao: 'Data da Verificação',
    situacao_verificacao: 'Situação da Verificação',
  },
};
