import { BasicoApi } from '@common/api';
const TipoVisitaService = {
  listar: function (parametros, escoposIds = []) {
    let link = '/tipovisita';
    if (escoposIds.length > 0) link = `${link}?escoposIds=${escoposIds.join('&escoposIds=')}`
    return BasicoApi.get()(link, parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tipovisita/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipovisita/${form.id}`, form);
    return BasicoApi.post()('/tipovisita', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tipovisita/${id}`);
  },
};

export default TipoVisitaService;
