<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    :flat="flat"
    :solo="solo"
    :outlined="outlined"
    :sem-label="semLabel"
    :background-color="backgroundColor"
    :prevent-acoes="preventAcoes"
    @abrir-modal="abrirModalPrevent"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="tituloModal"
        :titulo-ok="$t('geral.botoes.confirmar')"
        @ok="aplicarSelecionados"
      >
        <modal
          ref="modal"
          v-model="selecionados"
          :mensurando="mensurando"
          :sem-filtro-quantidade-de-variaveis="semFiltroQuantidadeDeVariaveis"
          :multiplos="multiplos"
          :formula-mensurando-id="formulaMensurandoId"
          :incluir-formula-pai="incluirFormulaPai"
          :somente-mensurando="somenteMensurando"
          :disponivel-criterio-aceitacao="disponivelCriterioAceitacao"
          :flag-sistema="flagSistema"
          @opened="focusInput"
          @fechar-modal-click-linha="aplicarSelecionados"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>

<script>
import { InputMixin } from '../../InputMixin';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import Modal from './modal.vue';
import _ from 'lodash';

export default {
  name: 'InputUnidadeMedida',
  components: { Modal },
  mixins: [InputMixin],
  props: {
    semFiltroQuantidadeDeVariaveis: { type: Boolean, default: true },
    multiplos: { type: Boolean, default: true },
    mensurando: { type: Boolean, default: false },
    retornarObjeto: { type: Boolean, default: true },
    formulaMensurandoId: { type: String, default: null },
    preventAcoes: { type: Boolean, default: false },
    incluirFormulaPai: { type: Boolean, default: false },
    somenteMensurando: { type: Boolean, default: false },
    disponivelCriterioAceitacao: { type: Boolean, default: null },
    flagSistema: { type: Boolean, default: null },
    tituloModal: {
      type: String,
      default: function () {
        return this.$t('componentes.input_formula.titulo_modal');
      },
    },
  },
  data: function () {
    return {
      conteudo: [],
      selecionados: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit('input', this.conteudo);
    },

    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },

    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModalPrevent: function () {
      if (this.preventAcoes) {
        this.$emit('prevent-abrir-modal');
        return;
      }
      this.abrirModal();
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      setTimeout(() => {
        this.$refs.modal.listar();
        this.focusInput();
      }, 500);
      if (!this.conteudo) return false;

      let conteudoClonado = _.cloneDeep(this.conteudo);
      if (!this.multiplos) conteudoClonado = [conteudoClonado];

      this.selecionados = conteudoClonado.map((el) => {
        return {
          id: el.value,
          nome: el.text,
        };
      });
    },
    focusInput() {
      this.$nextTick(() => {
       this.$refs.modal.$refs.input.focus();
    });
  },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();

      let selecionados = new DropdownModel(this.selecionados, 'nome');

      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropdownModel(this.selecionados, 'nome')[0];
      }

      this.conteudo = selecionados;
    },
  },
};
</script>
