// BaseModal.js

export default {
  baseModal: {
    buttons: {
      save: 'Salvar',
      cancel: 'Cancelar',
      add: 'Adicionar',
      delete: 'Excluir',
      yes: 'Sim',
      no: 'Não'
    },
    types: {
      save: 'Salvar',
      cancel: 'Cancelar',
      delete: 'Excluir'
    },
    texts: {
      reallyWant: 'Deseja realmente'
    }
  }
}
