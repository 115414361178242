export default {
  titulos: {
    listagem: 'Modelos de Relatórios',
    novo: 'Novo Modelo de Relatório',
    editar: 'Editar Modelo de Relatório',
  },
  cadastro_sucesso: 'Modelo de Relatório salvo com sucesso!',
  exclusao_sucesso: 'Modelo de Relatório excluído com sucesso!',
  exclusao_validacao_tipo:
    'Não permitido excluir relatório do sistema ou certificado padrão',
  tabela: {
    codigo: 'Código',
    titulo: 'Título',
    categoria: 'Categoria',
    nome_parametro: 'Nome do Parâmetro',
    tipo_parametro: 'Tipo Parâmetro',
    obrigatorio: 'Parametro Obrigatório',
    sistema: 'Relatório do Sistema',
  },
  formulario: {
    codigo: 'Código',
    titulo: 'Título',
    categoria: 'Categoria',
    add_relatorio: 'Adicionar Relatório',
    endpoints: 'Endpoints',
    endpoints_placeholder: 'Endpoints (Separado por ";")',
    descricao: 'Descrição',
    parametros: 'Parametros do endpoint',
    texto_input_file: 'Arraste um Modelo de Relatório em JSON',
    botao_input_file: 'Abrir editor',
    sistema: 'Relatório do Sistema',
    tipoRelatorio: 'Tipo Relatório',
  },
  erros: {
    id_invalido: 'O id do relatório é invalido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    codigo: 'Código',
    titulo: 'Título',
    categoria: 'Categoria',
    titulo_modal_relatorio: 'Relatório',
    titulo_modal_parametros_relatorio: 'Parâmetros do relatório',
  },
  confirmar_fechar_editor: 'Deseja salvar alterações?',
};
