import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.posicao_sequencia_setor.inicial'),
    value: 'Inicial',
  },
  {
    text: i18n.t('enums.posicao_sequencia_setor.entre'),
    value: 'EntreInicialFinal',
  },
  {
    text: i18n.t('enums.posicao_sequencia_setor.final'),
    value: 'Final',
  },
];
