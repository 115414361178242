import { BasicoApi } from '@common/api';
const TipoCalibracaoService = {
  listar: function (parametros, escoposIds = []) {
    let link = '/tipocalibracao';
    if(escoposIds.length > 0) link = `${link}?escoposIds=${escoposIds.join('&escoposIds=')}`
    return BasicoApi.get()(link, parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tipocalibracao/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipocalibracao/${form.id}`, form);
    return BasicoApi.post()('/tipocalibracao', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tipocalibracao/${id}`);
  },
};

export default TipoCalibracaoService;
