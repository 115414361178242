<template lang="">
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon
        :class="classeIcone"
        :size="size"
        :color="cor"
        v-on="on"
        @click="onClick"
      >
        {{ icone }}
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'IconePadrao',
  props: {
    icone: { type: String, default: '' },
    classeIcone: { type: String, default: '' },
    tooltip: { type: String, default: '' },
    size: { type: String, default: '' },
    cor: { type: String, default: '' },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>
