export default {
  nome: 'Nome',
  tabela: {
    nome: 'Nome',
    cidade: 'Cidade',
    documento: 'Documento',
    setor: 'Setor',
    agendamento: 'Agendamento',
  },
  titulo_modal: 'Colaboradores',
};
