import { BasicoApi } from '@common/api';
const ContratoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/contrato', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/contrato/${id}`);
  },
  buscarServicoContrato: function (params) {
    return BasicoApi.get()(`/contrato/localizar-item`, params);
  },
  buscarDatasProximasCalibracoes: function (parametros) {
    return BasicoApi.get()(`/contrato/data-proxima-calibracao`, parametros);
  },
  buscarRevisoes: function (id) {
    return BasicoApi.get()(`/contrato/${id}/revisoes/`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/contrato/${form.id}`, form);
    return BasicoApi.post()('/contrato', form);
  },
  revisaoContrato: function (id) {
    return BasicoApi.post()(`/contrato/revisao/${id}`);
  },
  validacaoUnicidadeInstrumento: function (params) {
    return BasicoApi.get()(`/contrato/validar-unicidade-instrumento`, params);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/contrato/${id}`);
  },
  alteraStatus: function (params) {
    return BasicoApi.put()(`/contrato/alterar-status`, params);
  },
  duplicarContrato: function (id, params) {
    return BasicoApi.put()(`/contrato/renovar-duplicar/${id}`, params);
  },
  atualizarContratoCliente: function (id, params) {
    return BasicoApi.put()(`/contrato/${id}/atualizar-pelo-cliente`, params);
  },
  criarNovoAditivoContrato: function (id) {
    return BasicoApi.post()(`/contrato/aditivo/${id}`);
  },
};

export default ContratoService;
