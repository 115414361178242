import { BasicoApi } from '@common/api';
const CampoAdicionalService = {
  listar: function (parametros, tiposInstrumentosIds) {
    return BasicoApi.get()(
      `/campoadicional${
        tiposInstrumentosIds ? `?${tiposInstrumentosIds}` : ''
      }`,
      parametros
    );
  },
  buscar: function (id) {
    return BasicoApi.get()(`/campoadicional/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/campoadicional/${form.id}`, form);
    return BasicoApi.post()('/campoadicional', form);
  },
  excluir: function (ids) {
    return BasicoApi.delete()(`/campoadicional`, ids);
  },
  salvarOrdenacao: function (listaDados) {
    return BasicoApi.patch()('/campoadicional/sequencia', {
      ids: listaDados.map((el) => el.id),
    });
  },
};

export default CampoAdicionalService;
