import helpers from '@router/helpers';
const menuId = 'agendamento';

export default [
  helpers.criarRota(menuId, 'agendamento', menuId, () =>
    import('@/views/modulos/cadastros/agendamento')
  ),
  helpers.criarRota(menuId, 'agendamento-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/agendamento/formulario')
  ),
  helpers.criarRota(
    menuId,
    'agendamento-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-programar',
    `${menuId}/programar/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-confirmar',
    `${menuId}/confirmar/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-executar',
    `${menuId}/executar/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-registro-eventos',
    `${menuId}/registro-eventos/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-iniciar-viagem',
    `${menuId}/iniciar-viagem/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/components/ExecutarViagem'),
    true
  ),
  helpers.criarRota(
    menuId,
    'agendamento-finalizar-viagem',
    `${menuId}/finalizar-viagem/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/components/ExecutarViagem'),
    true,
  ),
  helpers.criarRota(
    menuId,
    'agendamento-analise-critica',
    `${menuId}/analise-critica/:id?`,
    () => import('@/views/modulos/cadastros/agendamento/formulario'),
    true
  ),
];
