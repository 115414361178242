import { DropdownModel } from '@common/models/geral/DropdownModel';

export default {
  ALTERAR_AUTENTICACAO(state, payload) {
    let {
      tokenData: {
        token,
        refreshToken,
        usuario,
        participanteLogadoId,
        refreshTokenValidity,
        isParticipanteLogadoConta,
      },
      encodedData,

    } = payload;
    state.token = token;
    state.refreshToken = refreshToken;
    usuario.participantes = new DropdownModel(usuario.participantes);
    state.usuario = usuario;
    state.participanteSelecionado = participanteLogadoId;
    state.refreshTokenValidity = refreshTokenValidity;
    state.participanteLogadoConta = isParticipanteLogadoConta;
    state.encodedData = encodedData;
    state.colaborador = null;
  },
  ALTERAR_COLABORADOR(state, payload) {
    state.colaborador = payload.colaborador;
  },
  ALTERAR_REFRESH_TOKEN(state, payload) {
    let { token, refreshToken, refreshTokenValidity } = payload;
    state.token = token;
    state.refreshToken = refreshToken;
    state.refreshTokenValidity = refreshTokenValidity;
  },
  LIMPAR_AUTENTICACAO(state) {
    state.token = null;
    state.refreshToken = null;
    state.usuario = null;
    state.refreshTokenValidity = null;
    state.buscandoRefreshToken = null;
    state.participanteSelecionado = null;
    state.participanteLogadoConta = null;
    state.encodedData = null;
    state.colaborador = null;
  },
  ALTERAR_PARTICIPANTE_SELECIONADO(state, payload) {
    state.participanteSelecionado = payload;
  },
  ALTERAR_BEARER(state, payload) {
    let {
      tokenData: {
        token,
        refreshToken,
        usuario,
        participanteLogadoId,
        refreshTokenValidity,
        isParticipanteLogadoConta,
      },
      encodedData,
      colaborador
    } = payload;
    state.token = token;
    state.refreshToken = refreshToken;
    usuario.participantes = new DropdownModel(usuario.participantes);
    state.usuario = usuario;
    state.participanteSelecionado = participanteLogadoId;
    state.refreshTokenValidity = refreshTokenValidity;
    state.participanteLogadoConta = isParticipanteLogadoConta;
    state.encodedData = encodedData;
    state.colaborador = colaborador;
  },
  ALTERAR_VALIDACAO_GET_REFRESH(state, payload) {
    state.buscandoRefreshToken = payload;
  },
};
