<template lang="">
  <div>
    <div class="d-flex align-end mb-5">
      <input-text
        v-model="filtro.descricao"
        :label="$t('modulos.classificacao_instrumento.formulario.descricao')"
        class="flex-fill mr-6"
      />
      <botao-padrao
        outlined
        color="secondary"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <v-treeview
      v-model="selecionados"
      :items="tabela.dados"
      :item-key="tabela.dados.id"
      :item-text="'descricao'"
      open-on-click
      selectable
      return-object
      :selection-type="'independent'"
      @input="limparSelecionados"
    />
  </div>
</template>
<script>
import ClassificacaoInstrumentoService from '@common/services/cadastros/ClassificacaoInstrumentoService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'descricao',
            text: this.$t(
              'componentes.input_classificacao_instrumento.tabela.descricao'
            ),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    limparSelecionados(){
      if(this.multiplos) return
      setTimeout(() => {
        this.selecionados = [this.selecionados[this.selecionados.length - 1]]
      },100)
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10, papelFornecedor = 1) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        papelFornecedor: papelFornecedor,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ClassificacaoInstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
          this.selecionados = [...this.selecionados]
          this.$forceUpdate()
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
