import { BasicoApi } from '@common/api';
const ColaboradorAusenciaService = {
  listar: function (parametros) {
    return BasicoApi.get()('/colaboradorAusencia', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/colaboradorAusencia/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/colaboradorAusencia/${form.id}`, form);
    return BasicoApi.post()('/colaboradorAusencia', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/colaboradorAusencia/${id}`);
  },
};

export default ColaboradorAusenciaService;
