import helpers from '@router/helpers';
const menuId = 'classificacao-instrumento';

export default [
  helpers.criarRota(
    menuId,
    'classificacao-instrumento',
    menuId,
    () => import('@/views/modulos/cadastros/classificacao_instrumento')
  ),
  helpers.criarRota(
    menuId,
    'classificacao-instrumento-novo',
    `${menuId}/novo/:paiId?`,
    () =>import('@/views/modulos/cadastros/classificacao_instrumento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'classificacao-instrumento-editar',
    `${menuId}/:id?`,
    () =>
      import('@/views/modulos/cadastros/classificacao_instrumento/formulario'),
    true
  ),
];
