import helpers from '@router/helpers';
const menuId = 'kanban';

export default [
  helpers.criarRota(menuId, 'kanban-configuracao', 'kanban-configuracao/:setorId', () =>
    import('@/views/modulos/cadastros/kanban_configuracao')
  ),

  helpers.criarRota(
    menuId,
    'kanban-coluna-novo',
    'kanban-coluna/novo/:id',
    () => import('@/views/modulos/cadastros/kanban_configuracao/components/FormularioColuna')
  ),

  helpers.criarRota(
    menuId,
    'kanban-cartao-novo',
    'kanban-cartao/novo/:id',
    () => import('@/views/modulos/cadastros/kanban_configuracao/components/FormularioCartao')
  ),

  helpers.criarRota(
    menuId,
    'kanban-coluna-editar',
    'kanban-coluna/:id?/:setorId',
    () => import('@/views/modulos/cadastros/kanban_configuracao/components/FormularioColuna'),
    true
  ),

  helpers.criarRota(
    menuId,
    'kanban-cartao-editar',
    'kanban-cartao/:id?/:setorId',
    () => import('@/views/modulos/cadastros/kanban_configuracao/components/FormularioCartao'),
    true
  ),
];
