<!-- eslint-disable -->
<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @paginar="listar"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="(v) => filtroAplicado.filter = v"
    />
  </div>
</template>
<script>
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import helpers from '@/common/utils/helpers';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    listarAtualizacao: { type: Number, default: 1 },
    value: { type: [Array, Object], default: () => [] },
  },
  data() {
    return {
      filtro: {
        nome: '',
        cidade: '',
        documento: '',
      },
      helpers: helpers,
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'apelido',
            text: this.$t('componentes.input_cliente.tabela.apelido'),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t('componentes.input_cliente.tabela.nome'),
            sortable: false,
          },
          {
            value: 'municipioSede.nome',
            text: this.$t('componentes.input_cliente.tabela.cidade'),
            sortable: false,
          },
          {
            value: 'documento',
            text: this.$t('componentes.input_cliente.tabela.documento'),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDocumento(v);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      itensPorpagina: 10,
      passo: 0,
      paginaAtual: 1,
      numeroItens: 1,
    };
  },
  watch: {
    paginaAtual() {
      this.enviaDadosPaginacao();
    },
    itensPorpagina() {
      this.enviaDadosPaginacao();
    },
    listarAtualizacao() {
      this.listar();
    },
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    enviaDadosPaginacao: _.debounce(function () {
      this.passo = this.paginaAtual / this.itensPorpagina + 1;
      this.listar(this.passo, this.itensPorpagina);
    }, 300),
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: _.debounce(function (paginaAtual = 1, porPagina = 10) {
      const flagParticipanteConta =
        this.$store.getters['Autenticacao/participanteLogadoConta'];
      let parametros = {
        page: paginaAtual,
        sort: 'Nome',
        amountPerPage: porPagina,
        flagParticipanteConta: flagParticipanteConta ? '' : false,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    }, 400),
    filtrarGlobal() {
        this.listar();
      },
  },
};
</script>
<style scoped>
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

:deep(.p-datatable-header) {
  padding-bottom: 46px !important;
  display: flex;
  justify-content: end;
}

.divHeader {
  display: flex;
  justify-content: space-between;
  z-index: 10;
  position: fixed;
}

:deep(.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td) {
  padding: 0.001rem 0.5rem !important;
}
:deep(.p-radiobutton .p-radiobutton-box) {
  margin-top: 2px;
  width: 16px !important;
  height: 16px !important;
}
:deep(.p-paginator .p-paginator-page-input .p-inputtext) {
  max-width: 4.7rem;
  height: 37px;
  padding-top: 12px;
}
:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  position: fixed;
  overflow-y: scroll;
  height: 150px;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
