<template>
  <div>
    <v-expansion-panels v-model="conteudoComputado" flat :readonly="naoRetrair">
      <v-expansion-panel
        v-model="conteudoComputado"
        class="accordion-padrao"
        :class="naoRetrair ? 'nao-retrair' : ''"
      >
        <v-expansion-panel-header
          :hide-actions="naoRetrair"
          :style="alterarCor ? 'color: #0d47a1' : ''"
        >
          <div class="d-flex align-end">
            <slot name="header" />
          </div>

          <template v-slot:actions>
            <v-icon class="ml-4" :color="alterarCor ? '#0d47a1' : ''">
              $downCircleOutline
            </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <slot />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    naoRetrair: {
      type: Boolean,
      default: false,
    },
    alterarCor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      conteudo: 0,
    };
  },
  computed: {
    conteudoComputado: {
      set: function (valor) {
        this.conteudo = valor;
      },
      get: function () {
        if (this.naoRetrair) return 0;
        return this.conteudo;
      },
    },
  },
};
</script>
<style lang="scss">
.accordion-padrao {
  .v-expansion-panel-header {
    padding-left: 0;
    padding-right: 0;
    min-height: 48px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
.v-expansion-panel {
  background: transparent !important;
}
</style>
