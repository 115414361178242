import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumEnvioMaterial.Pendente'),
    value: 'Pendente',
    icon: 'fileClockOutline',
    color: 'warning',
  },
  {
    text: i18n.t('enums.EnumEnvioMaterial.Programado'),
    value: 'Programado',
    icon: 'fileCheckOutline',
  },
  {
    text: i18n.t('enums.EnumEnvioMaterial.EmExecucao'),
    value: 'EmExecucao',
    icon: 'fileDocumentEditOutline',
  },
  {
    text: i18n.t('enums.EnumEnvioMaterial.Concluido'),
    value: 'Concluido',
    icon: 'fileCheckOutline',
    color: 'success'
  },
  {
    text: i18n.t('enums.EnumEnvioMaterial.Cancelado'),
    value: 'Cancelado',
    icon: 'fileCancelOutline',
    color: 'danger',
  },
  {
    text: i18n.t('enums.EnumEnvioMaterial.Reagendado'),
    value: 'Reagendado',
    icon: 'accountClockOutline',
  },
];
