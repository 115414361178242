<template>
  <div>
    <div>
      <div id="viewer" />
    </div>
  </div>
</template>
<script>
import StimulsoftViewer from 'stimulsoft-reports-js/Scripts/stimulsoft.viewer';
import Stimulsoft from 'stimulsoft-reports-js/Scripts/stimulsoft.reports';
export default {
  data() {
    return {
      viewer: null,
      dataSource: null,
    };
  },
  mounted: function () {},
  methods: {
    ehStringJson(str) {
      try {
        var json = JSON.parse(str);
        return typeof json === 'string';
      } catch (e) {
        return false;
      }
    },
    montarComponente: function (arquivoJson, marcaDagua) {
      Stimulsoft.Stimulsoft.Base.StiLicense.Key = `${process.env.VUE_APP_STIMULSOFT_LICENSE_KEY}`;

      let options = new StimulsoftViewer.StiViewerOptions();
      options.toolbar.visible = true;
      options.toolbar.showSaveButton = false;
      options.toolbar.showOpenButton = false;
      options.toolbar.showAboutButton = false;
      options.toolbar.showFullScreenButton = false;
      options.toolbar.viewMode = 1;
      options.appearance.fullScreenMode = false;

      this.viewer = new Stimulsoft.StiViewer(options, 'StiViewer', false);
      let report = new Stimulsoft.Stimulsoft.Report.StiReport();

      if (arquivoJson) {
        let ehStringJson = this.ehStringJson(arquivoJson);
        let arq = arquivoJson;
        if (ehStringJson) arq = JSON.parse(arquivoJson);
        report.load(arq);
      }
      this.viewer.report = report;

      if (marcaDagua) {
        report.pages.list.forEach((pagina) => {
          pagina.watermark.enabled = true;
          pagina.watermark.angle = 0;
          pagina.watermark.font.size = 50;
          pagina.watermark.text = marcaDagua;
        });
      }

      let dataSet = new Stimulsoft.Stimulsoft.System.Data.DataSet('KeL');
      dataSet.readJson(this.dataSource);
      report.regData(dataSet.dataSetName, '', dataSet);
      report.dictionary.synchronize();

      this.viewer.renderHtml('viewer');
    },
    carregarArquivo: function (arquivoJson) {
      let report = new Stimulsoft.Stimulsoft.Report.StiReport();
      report.load(JSON.parse(arquivoJson));
      this.viewer.report = report;
    },
    carregarDataSource: function (dataSource) {
      this.dataSource = dataSource;
    },
    exportarPDF: function () {
      let ctx = this;
      this.viewer.report.renderAsync(function () {
        ctx.viewer.report.exportDocumentAsync(function (data) {
          Stimulsoft.Stimulsoft.System.StiObject.saveAs(
            data,
            'Report.pdf',
            'application/pdf'
          );
        }, Stimulsoft.Stimulsoft.Report.StiExportFormat.Pdf);
      });
    },
  },
};
</script>
