<template lang="">
  <div class="input-padrao">
    <div v-if="titulo" class="d-flex align-end input-label">
      <span class="mr-2">{{ titulo }}</span>
      <v-divider />
    </div>
    <v-checkbox
      v-if="options === null"
      v-model="conteudo"
      :label="label"
      :value="valor"
      dense="dense"
      :rules="regras"
      :disabled="disabled"
      outlined
      hide-details="auto"
    />
    <div v-if="options !== null" :class="enterClass">
      <v-checkbox
        v-for="opcao in options"
        :key="opcao.value"
        v-model="conteudo"
        :label="opcao.text"
        :value="opcao.value"
        dense="dense"
        :rules="regras"
        :disabled="disabled"
        outlined
        hide-details="auto"
      />
    </div>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  name: 'InputRadio',
  mixins: [InputMixin],
  props: {
    value: {},
    valor: {},
    options: { type: Array, default: null },
    titulo: { type: String, default: null },
    enterClass: { type: String, default: 'null' },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    handleClick() {
      this.$emit('change');
    },
  },
};
</script>
