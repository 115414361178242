import helpers from '@router/helpers';
const menuId = 'unidade-medida';

export default [
  helpers.criarRota(menuId, 'unidade-medida', menuId, () =>
    import('@/views/modulos/cadastros/unidade_medida')
  ),
  helpers.criarRota(
    menuId,
    'unidade-medida-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/unidade_medida/formulario'),
    false,
    false
  ),
  helpers.criarRota(
    menuId,
    'unidade-medida-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/unidade_medida/formulario'),
    true,
    false
  ),
];
