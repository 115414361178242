export default {
  titulos: {
    listagem: 'Envios de Materiais',
    novo: 'Novo Envio de Material',
    editar: 'Editar Envio de Material',
    visualizar: 'Visualizar Envio de Material - Cód:',
    aprovar: 'Aprovar Envio Material - Cód:',
    qrCodes: 'QR Codes',
    imprimir: 'Imprimir'
  },
  aguardando_aprovacao: 'Aguardando aprovação',
  cadastro_sucesso: 'Envio de Material {nome} salvo com sucesso!',
  exclusao_sucesso: 'Envio de Material excluído com sucesso!',
  gerar_qr_code_sucesso: 'QR Code gerado com sucesso!',
  aprovacao_informacao: 'Dados informados pelo Contratante',
  aprovacao_solicitante: 'Solicitante pertence ao Contratante, por favor selecione ou crie um novo Participante',
  aprovacao_criar_solicitante: 'Aprovação: Clique para criar um novo Participante com base no Solicitante do Contratante',
  aprovacao_criar_instrumento: 'Aprovação: Clique para criar um novo Instrumento',
  aprovacao_instrumentos_invalidos: 'A quantidade dos instrumentos do Solicitante Contratante deve ser mesma do Solicitante',
  aviso_qrcode_quantidade_volumes: 'Existem QR Codes gerados para esse envio de material, alterar a quantidade de volumes irá excluir os QR Codes. Deseja continuar?',
  tabela: {
    codigo:'Código',
    data_solicitacao: 'Data da Solicitação',
    tipo_coleta: 'Tipo de Coleta',
    ordem_servico: 'Ordem de Serviço',
    cliente: 'Cliente',
    data_agendamento: 'Data de Agendamento',
    tipo_envio: 'Tipo de Envio',
    situacao: 'Situação',
    sentido: 'Logística',
    logistica: 'Logística',
    solicitante: 'Solicitante',
    contratante: 'Contratante',
  },
  formulario: {
    codigo:'Código',
    data_solicitacao: 'Data da Solicitação',
    tipo_coleta: 'Logística',
    logistica: 'Logística',
    ordem_servico: 'Ordem de Serviço',
    solicitante: 'Solicitante',
    solicitante_contratante: 'Solicitante Contratante',
    nota_fiscal: 'Nota Fiscal',
    contratante: 'Contratante',
    cliente: 'Cliente',
    data_agendamento: 'Data de Agendamento',
    tipo_envio: 'Tipo de Envio',
    nome_transportadora: 'Nome da Transportadora',
    observacao: 'Observação',
    qtd_volumes: 'Qtd Volumes',
    quantidade_volumes: 'Quantidade de Volumes',
    peso_estimado: 'Peso Estimado(Kg)',
    contato_contratante: 'Contato Contratante',
    contato_atendimento: 'Contato Atendimento',
    contato_solicitante: 'Contato',
    situacao: 'Situação',
    novo_contato: 'Novo Contato Solicitante',
    abas: {
      dados: 'Dados do Envio',
      instrumentos: 'Instrumentos',
    }
  },
  erros: {
    id_invalido: 'O id do envio de material é inválido',
    cliente_sem_dias_envio: 'O contratante não possui dias de envio cadastrados. Entre em contato com o Administrador',
  },
  validacoes: {
    formulario_invalido: 'É necessário associar um instrumento antes de salvar o registro.'
  },
  filtro: {
    codigo: 'Código',
    data_solicitacao: 'Data da Solicitação',
    tipo_coleta: 'Tipo de Coleta',
    tipo_envio: 'Tipo de Envio',
    contratante: 'Contratante',
    data_agendamento: 'Data de Agendamento',
    ordem_servico: 'Ordem de Serviço',
    situacao:'Situação',
    logistica: 'Logística'
  },
  instrumentos: {
    tabela: {
      codigo: 'Código',
      codigo_aprovacao: 'Código de Aprovação',
      tipo_instrumento: 'Tipo de Instrumento',
      numero_serie: 'Número de Série',
      qtd_faixas: 'Qtd de Faixas',
      modelo_instrumento: 'Modelo do Instrumento',
      marca: 'Marca',
    },
    botoes:{
      novo_instrumento: 'Novo Instrumento',
      selecionar_instrumentos: 'Selecionar Instrumentos',
    },
  },
  historico:{
    Criado: 'Criado',
    Programado : 'Programado',
    Alterado: 'Alterado',
    Checkin: 'Check-In',
    Cancelado: 'Cancelado',
    Reagendado: 'Reagendado',
    Concluido: 'Concluído',
    SemAgendamento: 'Criado Recebimento sem Agendamento',
    OrdemServicoCriada: 'Ordem de Serviço Criada',
    CheckinDesfeito: 'Check-In Desfeito',
    texto:{
      envio: 'Envio',
      por: 'por',
      agendamento: 'Agendamento',
      participante: 'Participante',
      no_cliente: 'no cliente por',
    }
  }
};
