export default {
  titulos: {
    listagem: 'Tipos de Documentos',
    novo: 'Novo Tipo de Documento',
    editar: 'Editar Tipo de Documento',
  },
  cadastro_sucesso: 'Tipo de Documento salvo com sucesso!',
  exclusao_sucesso: 'Tipo de Documento excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    classificacaoDocumento: 'Classificação de Documento',
    descricao: 'Descrição',
    validade: 'Validade(dias)',
    anexo: 'Anexo',
  },
  formulario: {
    nome: 'Nome',
    classificacaoDocumento: 'Classificação',
    classificacao_documento: 'Classificação de Documento',
    descricao: 'Descrição',
    validade: 'Validade',
    validade_dias: 'Validade(dias)',
    nomeArquivo: 'Nome do Arquivo',
    anexo: 'Anexo',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    nome: 'Nome',
    classificacaoDocumento: 'Classificação',
  },
};
