import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumModulo.CadastroBasico'),
    value: 'CadastroBasico',
  },
  {
    text: i18n.t('enums.EnumModulo.Calculo'),
    value: 'Calculo',
  },
  {
    text: i18n.t('enums.EnumModulo.Orcamento'),
    value: 'Orcamento',
  },
  {
    text: i18n.t('enums.EnumModulo.Contrato'),
    value: 'Contrato',
  },
  {
    text: i18n.t('enums.EnumModulo.OrdemServico'),
    value: 'OrdemServico',
  },
  {
    text: i18n.t('enums.EnumModulo.Expedicao'),
    value: 'Expedicao',
  },
  {
    text: i18n.t('enums.EnumModulo.Outros'),
    value: 'Outros',
  },
];
