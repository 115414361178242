import { BasicoApi } from '@common/api';
const OperacaoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/operacao', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/operacao/${id}`);
  },
  buscaManutencaoPecasOrdemServico: function (parametros) {
    return BasicoApi.get()(
      `/operacao/${parametros.ordemServicoId}/manutencoes-pecas`,
      parametros
    );
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/operacao/${form.id}`, form);
    return BasicoApi.post()('/operacao', form);
  },
  salvarExecucaoManutencaoPeca: function (form) {
    return BasicoApi.post()('/operacao/finalizar-manutencoes-pecas', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/operacao/${id}`);
  },
  buscaMovimentacoesSetor: function (params) {
    return BasicoApi.get()(`/operacao/movimentacoes-setor`, params);
  },
  movimentarSetor: function (params) {
    return BasicoApi.post()(`/operacao/movimentacao-setor-situacao`, params);
  },
  entregaSimplificada: function (id) {
    return BasicoApi.post()(`/operacao/${id}/expedicao-entrega-simplificada`);
  },
  buscaDadosPlanoInspecao: function (id) {
    return BasicoApi.get()(`/operacao/${id}/obter-plano-inspecao`);
  },
  finalizarPlanoInspecao: function (params) {
    return BasicoApi.post()(
      `/operacao/${params.orcamentoId}/plano-inspecao`,
      params
    );
  },
  reprovarPlanoInspecao(ordemServicoId, form) {
    return BasicoApi.put()(
      `/operacao/${ordemServicoId}/reprovar-plano-inspecao`,
      form
    );
  },
  salvarEntregaOrdemServico(ordemServicoId, form) {
    return BasicoApi.put()(
      `/operacao/${ordemServicoId}/entrega-instrumento-cliente`,
      form
    );
  },
  instrumentoSemCalibração(ordemServicoId, instrumentoId) {
    return BasicoApi.put()(
      `/operacao/${ordemServicoId}/operacao-sem-calibracao`,
       instrumentoId 
    );
  },
};

export default OperacaoService;
