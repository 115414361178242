import _ from 'lodash'
export default {
  ADICIONAR_FILTRO(state, payload) {
    state.filtros[payload?.rota] = _.cloneDeep(payload?.dados);
  },
  REMOVER_FILTRO(state, payload) {
    delete state.filtros[payload?.rota]
  },
  REMOVER_FILTROS(state) {
    state.filtros = {};
  }
};
