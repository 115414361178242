import helpers from '@router/helpers';
const menuId = 'clausula-contratual';

export default [
  helpers.criarRota(menuId, 'clausula-contratual', menuId, () =>
    import('@/views/modulos/cadastros/clausula_contratual')
  ),
  helpers.criarRota(
    menuId,
    'clausula-contratual-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/clausula_contratual/formulario')
  ),
  helpers.criarRota(
    menuId,
    'clausula-contratual-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/clausula_contratual/formulario'),
    true
  ),
];
