export default {
  filtro: 'Filtrar',
  atencao: 'Atenção',
  a: 'à',
  ola: 'Olá',
  ativo: 'Ativo',
  inativo: 'Inativo',
  sim: 'Sim',
  nao: 'Não',
  obrigatorio: 'Obrigatório',
};
