export default {
  titulo: 'Ocorrências',
  ocorrencia: 'Ocorrência Padrão',
  colaborador: 'Colaborador Responsável',
  tabela: {
    data: 'Data',
    ocorrencia: 'Ocorrência Padrão',
    colaborador: 'Colaborador Responsável',
    observacao: 'Observação',
  },
};
