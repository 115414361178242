<template lang="">
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo d-flex"
    >
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio && !semLabel"
        class="obrigatorio ml-1"
      >*</span>
    </span>
    <v-combobox
      v-model="conteudo"
      :append-icon="appendIcon"
      :items="options"
      :label="labelEhDentro ? labelComputada : ''"
      :placeholder="placeholder"
      :dense="dense"
      :item-value="itemValue"
      :rules="regras"
      :disabled="disabled"
      chips
      :multiple="multiplo"
      :background-color="backgroundColor"
      style="background-color: white"
      :return-object="retornarObjeto"
      clearable
      outlined
      :hide-details="hideDetails"
      :error="error"
      :error-messages="errorMessages"
      :menu-props="{ offsetY: false }"
      @keydown="onKeydown"
    >
      <template
        v-for="slot in slots"
        v-slot:[`${slot}`]
      >
        <slot :name="slot" />
        <span :key="slot" />
      </template>
      <template v-slot:selection="{ item, index}">
        <v-chip
          close
          label
          outlined
          close-icon="$closeBoxOutline"
          @click:close="removerOpcao(index)"
        >
          <span v-if="retornarObjeto">{{ item.text }}</span>
          <span v-else>{{ item }}</span>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { InputMixin } from './InputMixin';

export default {
  name: 'InputComboboxText',
  mixins: [InputMixin],
  props: {
    options: { type: Array, default: () => [], required: false },
    retornarObjeto: { type: Boolean, default: false },
    itemValue: { type: String, default: 'value' },
    placeholder: { type: String, default: 'Selecione' },
    multiplo: { type: Boolean, default: true },
    somenteComboBox: { type: Boolean, default: false },
    appendIcon: { type: String, default: '' },
    semLabel: { type: Boolean, default: false },
    desabilitarVirgula: { type: Boolean, default: false },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
      error: false,
      errorMessages: ''
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;

      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      else if (regras.includes(this.validacaoObrigatorio)) {
        regras.splice(regras.indexOf(this.validacaoObrigatorio), 1);
      }
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    removerOpcao: function (indice) {
      if (this.disabled) return;
      if (this.multiplo) {
        this.conteudo.splice(indice, 1);
        return;
      }
      this.conteudo = null;
    },
    semInput: function (e) {
      if (!this.somenteComboBox) return;
      e.preventDefault();
    },
    onKeydown(event) {
      if (this.desabilitarVirgula && event.key === ',') {
        event.preventDefault();
      }
    },
  }
}
</script>
