export default {
  titulos: {
    listagem: 'Incertezas',
    novo: 'Nova Incerteza',
    editar: 'Editar Incerteza',
    revisao: 'Revisão',
    visualizar_revisao_incertezas: 'Visualizar Revisões - Incerteza ',
  },
  confirmar_mudanca_aba: {
    titulo: 'Atenção',
    mensagem:
      'Há dados não salvos neste formulário, deseja salvar as alterações?',
  },
  duplicar_componente_sucesso: 'Xi %{xi} criado com sucesso',
  duplicar_sucesso: 'Incerteza %{INC} criada com sucesso',
  publicar_sucesso: 'Incerteza publicada com sucesso',
  incerteza_excluida_com_sucesso: 'Incerteza excluída com sucesso',
  excluir_componente_sucesso: 'Componente excluído com sucesso',
  publicar: {
    titulo: 'Publicar',
    mensagem: 'Deseja realmente publicar a incerteza?',
  },
  tabela: {
    codigo: 'Código',
    revisao: 'Revisão',
    nome: 'Nome',
    faixa: 'Faixa',
    revisao_publ: 'Revisão Publicada',
    publicada_em: 'Publicada em',
  },
  revisoes: {
    tabela: {
      revisao: 'Revisão',
      criado_em: 'Criado em',
      usuario_de_criacao: 'Usuário de Criação',
      publicada_em: 'Publicada em',
      usuario_de_publicacao: 'Usuário de publicação',
      justificativa: 'Justificativa',
    },
  },
  filtro: {
    codigo: 'Código da Incerteza',
    nome: 'Nome',
    faixa: 'Faixa',
  },
  revisao_excluida_sucesso: 'Revisão excluída com sucesso',
  indicadores: {
    incerteza_combinada: 'Incerteza Combinada',
    veff: 'Veff',
    fator_k: 'Fator K',
    incerteza_expandida: 'Incerteza Expandida',
    incerteza_expandida_com_arredondamento:
      'Incerteza Expandida com Arredondamento',
  },
  componentes_listagem: {
    incerteza_combinada: 'Incerteza Combinada',
    veff: 'Veff',
    fator_k: 'Fator K',
    incerteza_expandida: 'Incerteza Expandida',
    incerteza_expandida_com_arredondamento:
      'Incerteza Expandida com Arredondamento',
  },
  formulario: {
    salvar_sequencia: 'Sequência salva com sucesso!',
    salvar_incerteza: 'Incerteza salva com sucesso!',
    salvar_componente: 'Componente salvo com sucesso!',
    abas: {
      dados_incerteza: 'Dados da Incerteza',
      componentes: 'Componentes',
      componentes_bloqueada:
        'É necessário salvar os dados da incerteza antes de adicionar componentes.',
      grafico_bloqueado:
        'É necessário salvar os dados da incerteza antes de visualizar gráfico.',
      grafico: 'Gráfico de Contribuições',
      diagrama: 'Diagrama de Ishikawa',
      diagrama_bloqueado:
        'É necessário salvar os dados da incerteza antes de visualizar o diagrama.',
    },
    dados_incerteza: {
      nome: 'Nome',
      estrutura_utilizada: 'Estrutura Utilizada',
      unidade_medida: 'Unidade de Medida',
      amplitude: 'Amplitude',
      a: 'a',
      formula_mensurando: 'Fórmula do Mensurando',
      descricao_padrao: 'Descrição Padrão',
      descricao_melhor_artefato: 'Descrição Melhor Artefato',
      adicionar_imagem: 'Adicionar Imagem',
      selecione: 'Selecione',
      justificativa: 'Justificativa',
    },
    componentes: {
      codigo_incerteza: 'Cód. Incerteza: %{codigo}',
      fonte_incerteza: 'Fonte da Incerteza',
      descricao: 'Descrição',
      incerteza_padrao: 'Incerteza Padrão',
      tipoA: 'Tipo A',
      xi: 'Xi',
      origem_da_contribuicao: 'Origem da Contribuição',
      formula_da_contribuicao: 'Fórmula da Contribuição',
      unidade_da_contribuicao: 'Unidade da Contribuição',
      incerteza_auxiliar: 'Incerteza Auxiliar',
      distribuicao_estatistica: 'Distribuição Estatística',
      formula_de: 'Fórmula DE',
      unidade_ci: 'Unidade CI',
      formula_ci: 'Fórmula CI',
      metodo_numerico_ci: 'Método Numérico CI',
      unidade_da_incerteza_padrao: 'Unidade da Incerteza Padrão',
      variavel: 'Variável',
      grau_liberdade: 'Grau de Liberdade',
      error: {
        regraDE: {
          obrigatorio: 'Campo DE é obrigatório',
          zero: 'Campo DE não pode ser zero',
        },
        regra_graus_liberdade: 'O Grau de Liberdade deve ser maior que 0',
        regra_graus_liberdade_max: 'O Grau de Liberdade máximo: 1000',
      },
      valores_de_teste: {
        titulo: 'Valores de Teste',
        contribuicao: 'Contribuição',
        de: 'DE',
        ci: 'CI',
        graus_liberdade: 'Graus de Liberdade',
        incerteza_padrao: 'Incerteza Padrão',
      },
      novo_componente: 'Novo Componente da Incerteza',
      editar_componente: 'Editar Componente da Incerteza',
      visualizar_componente: 'Visualizar Componente da Incerteza',
    },
  },
  grafico_de_contribuicoes: {
    tabela: {
      descricao: 'Descrição',
      incerteza_padrao: 'Incerteza Padrão',
      percentual: 'Percentual',
    },
    indicadores: {
      incerteza_combinada: 'Incerteza Combinada',
      veff: 'Veff',
      fator_k: 'Fator K',
      incerteza_expandida: 'Incerteza Expandida',
      incerteza_expandida_com_arredondamento:
        'Incerteza Expandida com Arredondamento',
    },
  },
  alertas: {
    justificativa: "Justificativa não pode ser vazia ao Publicar",
    incerteza:
      'A revisão selecionada já está publicada. Deseja realmente criar uma nova Revisão e editar esta incerteza?',
  },
};
