import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_participantes.cliente'),
    value: 1,
  },
  {
    text: i18n.t('enums.tipo_participantes.fornecedor'),
    value: 2,
  },
  {
    text: i18n.t('enums.tipo_participantes.ambos'),
    value: 3,
  },
];
