export default {
  tooltip_formula:
    'É necessário salvar o registro antes de adicionar a formula',
  titulos: {
    listagem: 'Fórmulas',
    novo: 'Nova Fórmula',
    editar: 'Editar Fórmula',
    visualizar: 'Visualizar Fórmula',
  },
  cadastro_sucesso: 'Fórmula salva com sucesso!',
  exclusao_sucesso: 'Fórmula excluída com sucesso!',
  duplicada_sucesso: 'Fórmula Duplicada com sucesso!',
  botoes: {
    nova_formula_filha: 'Nova Fórmula Filha',
    visualizar_formula_filha: 'Visualizar Fórmulas Filhas',
  },
  tabela: {
    codigo: 'Código',
    simbolo: 'Símbolo',
    nome: 'Nome',
    mensurando: 'Mensurando',
    qtdVariaveis: 'Qtd de Variáveis',
    formulaMensurando: 'Fórmula do Mensurando',
  },
  formulario: {
    codigo: 'Código',
    nome: 'Nome',
    descricao: 'Descrição',
    mensurando: 'Mensurando*',
    imagem: 'Adicionar imagem',
    componentes_incerteza_obrigatorio: 'Depende dos Componentes da Incerteza*',
    componentes_incerteza: 'Depende dos Componentes da Incerteza',
    disponivel_criterio_aceitacao: 'Disponível em Critérios de Aceitação*',
    simbolo: 'Símbolo',
    unidadeMedida: 'Unidade de Medida',
    formula: 'Fórmula',
    formulaMensurando: 'Fórmula do Mensurando',
    criada_sistema: 'Criada pelo Sistema',
    variaveis: {
      titulo: 'Variáveis',
      tabela: {
        simbolo: 'Símbolo*',
        unidadeMedida: 'Unidade de Medida',
        formulaCorrecao: 'Fórmula de Correção',
        valorTeste: 'Valor de Teste',
        descricao: 'Descrição',
      },
    },
  },
  filtro: {
    codigo: 'Código',
    nome: 'Nome',
    mensurando: 'Mensurando',
    formulaMensurando: 'Fórmula do Mensurando',
  },
  modal: {
    titulo: 'Validar Fórmula',
    resultado: 'Resultado da fórmula',
    erro: 'Erro',
  },
  erros: {
    id_invalido: 'O id da fórmula é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário preencher todos os campos da nova fórmula e suas variáveis',
    formula_nao_validada: 'É necessário validar a formula antes de salvar',
  },
  modal_duplicar: {
    titulo: 'Duplicar Fórmula',
    simboloNaoPodeSerIgual:
      'O símbolo não pode ser igual ao da fórmula original',
    formulario: {
      novo_simbolo: 'Novo Símbolo',
    },
  },
};
