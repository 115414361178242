<template lang="">
  <div>
    <tabela-padrao-prime-vue
      v-model="selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      scroll-height="290px"
      :filtros-com-colunas="true"
      filtro-global-focus
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :selecionar-apenas-um="!multiplos"
      :mostrar-acoes="false"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @filtrarGlobal="listar"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @paginar="listar"
    />
  </div>
</template>
<script>
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import _ from 'lodash';
import helpers from '@common/utils/helpers';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: [Array, Object], default: () => [] },
    participanteConta: { type: Boolean, default: false },
    flagFornecedor: { type: [Number, String], default: null},
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'apelido',
            text: this.$t('componentes.input_participantes.tabela.apelido'),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t('componentes.input_participantes.tabela.nome'),
            sortable: false,
          },
          {
            value: 'municipioSede.nome',
            text: this.$t('componentes.input_participantes.tabela.cidade'),
            sortable: false,
          },
          {
            value: 'documento',
            text: this.$t('componentes.input_participantes.tabela.documento'),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDocumento(v);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
  },
  mounted() {
    this.listar();
    //this.focusInput('opened');
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: 'Nome',
      };

      if(this.flagFornecedor !== null) parametros.tipoClienteFornecedor = this.flagFornecedor;
      if (this.participanteConta) {
        parametros.FlagParticipanteConta = true;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      const metodoService = this.participanteConta
        ? 'listarParticipantesConta'
        : 'listar';
      ParticipanteService[metodoService](parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
<style scoped>
:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  position: fixed;
  overflow-y: scroll;
  height: 150px;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
