import helpers from '@router/helpers';
const menuId = 'instrumento';

export default [
  helpers.criarRota(menuId, 'instrumento', menuId, () =>
    import('@/views/modulos/cadastros/instrumento')
  ),
  helpers.criarRota(menuId, 'instrumento-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/instrumento/formulario')
  ),
  helpers.criarRota(
    menuId,
    'instrumento-editar',
    `${menuId}/:id?/:retorno?`,
    () => import('@/views/modulos/cadastros/instrumento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'instrumento-pontos',
    `${menuId}/pontos/instrumentos/:id?`,
    () => import('@/views/modulos/cadastros/instrumento/pontosCalibracoes'),
    true
  ),
  helpers.criarRota(
    menuId,
    'instrumento-certificados',
    `${menuId}/certificados/:id?`,
    () => import('@/views/modulos/cadastros/instrumento/certificados'),
    true
  ),
  helpers.criarRota(
    menuId,
    'instrumento-historico',
    `historico/${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/instrumento/instrumentoHistorico'),
    true
  ),
  // Retirar certificado/:certificadoId receber agora só o instrumentoId
    helpers.criarRota(
    menuId,
    'instrumento-grafico-tendencia',
    `${menuId}/grafico-tendencia/:instrumentoId?/certificado/:certificadoId?`,
    () => import('@/views/modulos/cadastros/certificados/grafico_tendencia'),
    true
  ),
];
