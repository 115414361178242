import i18n from '@/i18n';

export default [
  { text: i18n.t('geral.inputs.meses.janeiro'), value: 1 },
  { text: i18n.t('geral.inputs.meses.fevereiro'), value: 2 },
  { text: i18n.t('geral.inputs.meses.marco'), value: 3 },
  { text: i18n.t('geral.inputs.meses.abril'), value: 4 },
  { text: i18n.t('geral.inputs.meses.maio'), value: 5 },
  { text: i18n.t('geral.inputs.meses.junho'), value: 6 },
  { text: i18n.t('geral.inputs.meses.julho'), value: 7 },
  { text: i18n.t('geral.inputs.meses.agosto'), value: 8 },
  { text: i18n.t('geral.inputs.meses.setembro'), value: 9 },
  { text: i18n.t('geral.inputs.meses.outubro'), value: 10 },
  { text: i18n.t('geral.inputs.meses.novembro'), value: 11 },
  { text: i18n.t('geral.inputs.meses.dezembro'), value: 12 },
];
