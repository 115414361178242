import { BasicoApi } from '@common/api';
import _ from 'lodash';
import helpers from '@common/utils/helpers';
const ParticipanteService = {
  listar: function (parametros) {
    return BasicoApi.get()('/participante', parametros);
  },
  listarContatosCliente: function (id, params = []) {
    return BasicoApi.get()(
      `/participante/${id}/contatos/resposabilidades`,
      params
    );
  },
  buscar: function (id) {
    return BasicoApi.get()(`/participante/${id}`);
  },
  buscaParticipantesContatosPorId: function (id) {
    return BasicoApi.get()(`/participante/${id}/contatos`);
  },
  buscaRequisitosParticipante: function (id, tipoRequsitos) {
    return BasicoApi.get()(`/participante/${id}/requisitos?${tipoRequsitos}`);
  },
  salvar: function (form) {
    let formFormatado = _.cloneDeep(form);
    formFormatado.documento = helpers.removerMascaraDocumento(form.documento);
    formFormatado.enderecos = formFormatado.enderecos.map((el) => {
      el.cep = helpers.removerMascaraCEP(el.cep);
      return el;
    });
    if (form.id)
      return BasicoApi.put()(`/participante/${form.id}`, formFormatado);
    return BasicoApi.post()('/participante', formFormatado);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/participante/${id}`);
  },
  listarParticipanteFornecedorLaboratorio: function (params) {
    return BasicoApi.get()('/participante/fornecedor-laboratorio', params)
  },
  salvarContato:function(participanteId,form){
    return BasicoApi.post()(`/participante/${participanteId}/contato`, form)
  },
  listarParticipantesConta: function (params) {
    return BasicoApi.get()('/participante/participante-conta', params)
  },
  salvarRequisitosTexto(form) {
    return BasicoApi.post()(`/participante/${form.id}/requisitos-texto`, form)
  }
};

export default ParticipanteService;
