export default {
  titulos: {
    listagem: 'Tipos de Itens de Estoque',
    novo: 'Novo Tipo de Item',
    editar: 'Editar Tipo de Item',
  },

  listagem: {
    headers: {
      codigo: 'Código',
      descricao: 'Descrição',
      peca: 'Peça',
    },
  },

  exclusao_sucesso: 'Tipo(s) de item excluído(s) com sucesso',
  exclusao_erro:
    'Ocorreu um erro durante a exclusão do(s) tipo(s) de item, por favor refaça a operação',
  cadastro_sucesso: 'Tipo de item criado com sucesso',
  formulario_invalido: 'Verifique os campos do formulário',

  formulario: {
    codigo: 'Código',
    descricao: 'Descrição',
    peca: 'Peça',
  },

  filtro: {
    codigo: 'Código',
    descricao: 'Descrição',
    peca: 'Peça'
  },
};
