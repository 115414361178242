export default {
  nome: 'Nome',
  email: 'Email',
  setor: 'Setor',
  tabela: {
    nome: 'Nome',
    email: 'Email',
    setor: 'Setor',
  },
  titulo_modal: {
    usuarioSignatario: 'Usuários Signatários',
    usuarioTecnicoResponsavel:
      'Usuários Técnicos Executantes',
  },
};
