export default {
  titulos: {
    listagem: 'Escopos de Serviços',
    novo: 'Novo Escopo de Serviço',
    editar: 'Editar Escopo de Serviço',
  },
  faixa_cruzando: 'Já existe uma faixa que atende a amplitude {faixa}',
  cadastro_sucesso: 'Escopo de Serviço salvo com sucesso!',
  exclusao_sucesso: 'Escopo de Serviço excluído com sucesso!',
  tabela: {
    codigo: 'Código',
    acreditacao: 'Acreditação',
    locaisExecucao: 'Locais Execução',
    qtdTipoCalibracao: 'Tipos de Calibração',
    qtdFaixas: 'Faixas',
    nome: 'Nome',
  },
  formulario: {
    abas: {
      dados_escopo: 'Dados do Escopo de Serviço',
      faixas: 'Faixas',
    },
    dados_escopo: {
      codigo: 'Código',
      acreditacao: 'Acreditação',
      nome: 'Nome',
      tipo_calibracao: 'Tipo de Calibração',
      local_execucao: 'Local de Execução',
    },
    faixas: {
      faixa: 'Faixa',
      amplitudeMin: 'Amplitude Mínima',
      amplitudeMax: 'Amplitude Máxima',
      unidadeMedida: 'Unidade de Medida',
      cmc: 'Tipo CMC',
      unidadeCmc: 'Unidade CMC',
      adicionar_faixa: 'Nova Faixa',
      valor_cmc_inicial: 'Valor CMC Inicial',
      valor_cmc_final: 'Valor CMC Final',
      formula_cmc: 'Fórmula CMC',
      formulario: {
        novo: 'Nova Faixa do Escopo do Serviço',
        editar: 'Editar Faixa do Escopo do Serviço',
        descricao: 'Descrição',
        amplitude: 'Amplitude',
        amplitude_min: 'Mínima',
        amplitude_max: 'Máxima',
        unidade_medida: 'Unidade de Medida',
        tipo_cmc: 'Tipo CMC',
        unidade_cmc: 'Unidade CMC',
        valor_cmc_inicial: 'Valor CMC Inicial',
        valor_cmc_final: 'Valor CMC Final',
        formula_cmc: 'Fórmula CMC',
      },
      modal: {
        unidade_cmc: 'Unidades de Medida CMC',
      },
    },
  },
  filtro: {
    nome: 'Nome',
    codigo: 'Código',
    acreditacao: 'Acreditação',
    localExecucao: 'Local de Execução',
  },
  erros: {
    id_invalido: 'O id da Embalagem é inválido',
  },
  validacoes: {
    formulario_invalido: 'Verifique os campos do formulário',
    faixa_invalido: 'É preciso adicionar uma faixa',
  },
};
