export default {
  clearErrorStore(context, payload) {
    context.commit('CLEAR_ERROR_STORE', payload);
  },
  removeErrorStore(context, payload) {
    context.commit('REMOVE_ERROR_STORE', payload);
  },
  changeError(context, payload) {
    context.commit('CHANGE_ERROR', payload);
  },
};
