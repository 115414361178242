export default {
  nome: 'Nome do Usuário',
  nome_placeholder: 'Digite o nome do usuário que deseja buscar',
  tabela: {
    nome: 'Nome',
    email: 'E-mail',
    usuario: 'Usuario',
    papel: 'Papel',
    login: 'Login'
  },
  titulo_modal: 'Usuários',
};
