export default {
  titulos: {
    listagem: 'Setores',
    novo: 'Novo Setor',
    editar: 'Editar Setor',
  },
  cadastro_sucesso: 'Setor salvo com sucesso!',
  exclusao_sucesso: 'Setor excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    tipoSetor: 'Tipo',
    sigla: 'Sigla',
    executa_manutencao: 'Executa Manutenção',
    executa_calibracao: 'Executa Calibração',
  },
  formulario: {
    nome: 'Nome',
    tipoSetorId: 'Tipo de Setor',
    localEstocagemId: 'Local Estocagem',
    sigla: 'Sigla',
    observacao: 'Observação',
    executa_manutencao: 'Executa Manutenção',
    executa_calibracao: 'Executa Calibração',
    temperatura: {
      titulo: 'Temperatura',
      valor: 'Temperatura °C',
      variacao: 'Variação da Temp °C',
    },
    umidadeRelativa: {
      titulo: 'Umidade Relativa',
      valor: 'Valor % ur',
      variacao: 'Variação da Umidade % ur',
    },
    pressaoAtm: {
      titulo: 'Pressão Atmosférica',
      valor: 'Valor',
      valor_hpa: 'Valor hPa',
      variacao: 'Variação da Pressão',
      variacao_hpa: 'Variação da Pressão hPa',
    },
  },
  erros: {
    id_invalido: 'O id do setor é inválido',
  },
  validacoes: {
    formulario_invalido: 'O Formulario deve ser preenchido corretamente !',
  },
};
