<template>
  <div>
    <v-tooltip right content-class="tooltip-right">
      <template v-slot:activator="{ on }">
        <div v-on="tooltip ? on : null">
          <v-list-item-content :disabled="disabled" class="d-flex">
            <slot />
          </v-list-item-content>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-divider />
  </div>
</template>
<script>
export default {
  props: {
    tooltip: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
};
</script>
