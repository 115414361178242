export default {
  mostrarToast({ commit }, payload) {
    commit('SETAR_TOAST', payload);
  },
  fecharToast({ commit }) {
    commit('SETAR_TOAST', { visivel: false });
  },
  iniciarCarregamento({ commit }, texto = '') {
    commit('SETAR_SPINNER', [true, texto]);
  },
  terminarCarregamento({ commit }) {
    commit('SETAR_SPINNER', [false, '']);
  },
  alterarTituloPagina({ commit }, titulo) {
    commit('SETAR_TITULO', titulo);
  },
};
