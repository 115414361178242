export default {
  titulos: {
    listagem: 'Procedimentos',
    novo: 'Novo Procedimento',
    editar: 'Editar Procedimento',
  },
  cadastro_sucesso: 'Procedimento salvo com sucesso!',
  exclusao_sucesso: 'Procedimento excluído com sucesso!',
  tabela: {
    codigo: 'Código',
    titulo: 'Titulo',
  },
  formulario: {
    codigo: 'Código',
    titulo: 'Titulo',
    descricao: 'Descrição',
  },
  erros: {
    id_invalido: 'O id do Procedimento é invalido',
  },
};
