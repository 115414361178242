export class DropdownProcedimento {
  constructor(lista, textoUm = 'codigo', textoDois = 'titulo') {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.id;
        novoObj.text = `${el[textoUm]} - ${el[textoDois]}`;
        return novoObj;
      });
    return {
      value: lista.id,
      text: `${lista[textoUm]} - ${lista[textoDois]}`,
    };
  }
}
