import helpers from '@router/helpers';
const menuId = 'definicao-equipamento-medicao';

export default [
  helpers.criarRota(
    menuId,
    'definicao-equipamento-medicao',
    menuId,
    () => import('@/views/modulos/cadastros/definicao_equipamento_medicao')
  ),
];
