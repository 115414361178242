import helpers from '@router/helpers';
const menuId = 'template-email';

export default [
  helpers.criarRota(menuId, 'modelo-email', menuId, () =>
    import('@/views/modulos/cadastros/template_email')
  ),

  helpers.criarRota(
    menuId,
    'modelo-email-editar',
    `${menuId}/:tipoEmail?`,
    () => import('@/views/modulos/cadastros/template_email/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'modelo-email-editar-visualizar',
    `${menuId}/visualizar/:tipoEmail?`,
    () => import('@/views/modulos/cadastros/template_email/formulario'),
    true
  ),
];
