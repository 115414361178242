export default {
  titulos: {
    listagem: 'Modelos de E-mails',
    editar: 'Editar Modelo de E-mail',
  },
  copiado: 'Variável: {variavel} copiada para a área de transferência',
  copiado_falha: 'Falha ao copiar para a área de transferência',
  cadastro_sucesso: 'Modelo de Email salvo com sucesso!',
  teste_envio_sucesso: 'E-mail teste enviado com sucesso!',
  tabela: {
    tipo_email: 'Tipo de E-mail',
    nome_exibicao: 'Nome',
    assunto: 'Assunto',
    retorno_usuario: 'Retornar para e-mail de quem envia',
  },
  formulario: {
    tipo_email: 'Tipo de E-mail',
    nome_exibicao: 'Nome',
    assunto: 'Assunto',
    retorno_usuario: 'Retornar para e-mail de quem envia',
    corpo_email: 'Corpo do E-mail',
  },
  botoes: {
    teste_envio: 'Enviar E-mail de Teste',
  },
  erros:{
    tipo_nao_existe: 'Tipo de E-mail não existe',
  }

};
