import helpers from '@router/helpers';
const menuId = 'contrato';

export default [
  helpers.criarRota(menuId, 'contrato', menuId, () =>
    import('@/views/modulos/cadastros/contrato')
  ),
  helpers.criarRota(menuId, 'contrato-novo',`${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/contrato/formulario')
  ),
  helpers.criarRota(menuId, 'contrato-assinar', `${menuId}/assinar/:id?`, () =>
    import('@/views/modulos/cadastros/contrato/assinar-contrato')
  ),
  helpers.criarRota(
    menuId,
    'contrato-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/contrato/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'contrato-editar-cliente',
    `${menuId}/cliente/:id?`,
    () => import('@/views/modulos/cadastros/contrato/editar-cliente'),
    true
  ),
];
