export default {
  titulos: {
    listagem: 'Endpoints',
    novo: 'Novo Endpoint',
    editar: 'Editar Endpoint',
  },
  cadastro_sucesso: 'Endpoint salvo com sucesso!',
  exclusao_sucesso: 'Endpoint excluído com sucesso!',
  tabela: {
    url:'URL',
    nome: 'Nome',
    simbolo: 'Descrição',
    jsonModelo: 'Modelo JSON',
    verboHTTP: 'Verbo HTTP',
    moduloSistema: 'Módulo do Sistema',
    api: 'API',
    endpointsParametros:{
      nome: 'Nome',
      tipoParametro: 'Tipo de Parâmetro',
      tipoPassagemParametro: 'Tipo de Passagem de Parâmetro',
      obrigatorio: 'Obrigatório',
      simbolo: 'Símbolo',
    }
  },
  formulario: {
    url:'URL',
    nome: 'Nome',
    simbolo: 'Símbolo',
    jsonModelo: 'Modelo JSON',
    verboHTTP: 'Verbo HTTP',
    nome_arquivo: 'Nome do Arquivo (SQL)',
    moduloSistema: 'Módulo do Sistema',
    api: 'API',
    endpointsParametros:{
      novo_parametro: 'Novo Parâmetro',
      nome: 'Nome',
      tipoParametro: 'Tipo de Parâmetro',
      tipoPassagemParametro: 'Tipo de Passagem de Parâmetro',
      obrigatorio: 'Obrigatório ?',
      simbolo: 'Símbolo',
    }
  },
  sql: {
    sqls: 'SQLs',
    nome: 'Nome',
    conteudo: 'Conteúdo',
    data_criacao: 'Data de Criação',
    data_ultima_modificacao: 'Data de Última Modificação',
  },
  abas: {
    dados_endpoint: 'Dados Endpoint',
    parametros: 'Parametros',
  },
  filtro: {
    nome: 'Nome',
    url: 'Url',

  },
  erros:{
    id_invalido: 'O id do endpoint é inválido',
  },
};
