export default {
  titulos: {
    listagem: 'Certificados de Terceiros',
    novo: 'Novo Certificado de Terceiro',
    editar: 'Editar Certificado de Terceiro',
    visualizar: 'Visualizar Certificado',
  },
  salvar_pontos_sucesso: 'Pontos de calibração salvos com sucesso!',
  cadastro_sucesso: 'Certificado de Terceiro salvo com sucesso!',
  exclusao_sucesso: 'Certificado de Terceiro excluído com sucesso!',
  finalizar_sucesso: 'Certificado finalizado com sucesso!',
  botoes: {
    definir_derivas: 'Definir Derivas',
    avaliar_certificado: 'Avaliar Certificado',
  },
  tabela: {
    codigo: 'Código',
    codigo_instrumento: 'Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
    cliente: 'Cliente',
    laboratorio: 'Laboratório',
    situacao: 'Situação',
    situacao_certificado: 'Situação Certificado',
    situacao_avaliacao: 'Situação Avaliação',
    calibradoEm: 'Calibrado Em',
    usuarioExecutante: 'Usuário Executante',
    liberarClientes: 'Liberado para Cliente',
    ordem_servico: 'Ordem de Serviço',
  },
  formulario: {
    valores_auxiliar: 'Valores auxiliares',
    abas: {
      dados_certificado: 'Dados do Certificado',
      valores: 'Valores',
      anexo: 'Anexo',
    },
    dados_certificado: {
      codigo: 'Código do Certificado',
      cliente: 'Cliente',
      instrumento: 'Instrumento',
      laboratorio: 'Laboratório',
      data_calibracao: 'Data da Calibração',
      obs: 'Observação',
      ordem_servico: 'Ordem de Serviço',
    },
    valores: {
      tabela: {
        ponto: 'Pontos de Calibração',
        erro: 'Erro',
        incerteza: 'Incerteza',
        veff: 'Veff',
        fatorK: 'Fator K',
        descricao: 'Descrição',
        valor_aux: '{valor}º Valor Auxiliar',
        adicionar: 'Adicionar Coluna',
      },
    },
    anexos: {
      titulo_modal: 'Anexar arquivo',
      formulario: {
        nome_arquivo: 'Nome arquivo',
        descricao: 'Descrição',
      },
      botoes: {
        anexar_arquivo: 'Anexar arquivo',
      },
      anexo_sucesso: 'Arquivo anexado com sucesso',
      exclusao_sucesso: 'Anexo excluído com sucesso',
    },
  },
  popup: {
    mensagem:
      'Este certificado já foi finalizado, você tem certeza que deseja voltar para o processo de edição?',
  },
  validacao: {
    veff: 'Veff não pode ser maior que 1000',
    nao_pode_decimal: 'Veff não pode ser decimal',
    veff_1000_decimal: 'Veff não pode ser maior que 1000 ou ser decimal'
  },
  linhas_modal: {
    titulo: 'Linhas sem valor',
    mensagem_finalizar:
      'Certificado de terceiro não pode ser finalizado, pois as linhas abaixo estão sem valor. Corrija os valores antes de salvar o Certificado de Terceiro.',
    mensagem_salvar:
      'Certificado de terceiro não pode ser salvo, pois as linhas abaixo estão sem valor. Corrija os valores antes de salvar o Certificado de Terceiro.',
  },
};
