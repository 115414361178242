import { BasicoApi } from '@common/api';
const MarcaService = {
  listar: function (parametros) {
    return BasicoApi.get()('/marca', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/marca/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/marca/${form.id}`, form);
    return BasicoApi.post()('/marca', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/marca/${id}`);
  },
};

export default MarcaService;
