export default {
  filtro: {
    codigo: 'Código',
    revisao: 'Revisão',
    nome: 'Nome',
    faixa: 'Faixa',
  },
  tabela: {
    codigo: 'Código',
    revisao: 'Revisão',
    nome: 'Nome',
    faixa: 'Faixa',
  },
  titulo_modal: 'Incerteza Auxiliar',
};
