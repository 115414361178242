export default {
  titulos: {
    listagem: 'Tipos de Ocorrências',
    novo: 'Novo Tipo de Ocorrência',
    editar: 'Editar Tipo de Ocorrência',
  },
  cadastro_sucesso: 'Tipo de ocorrência salvo com sucesso!',
  exclusao_sucesso: 'Tipo de ocorrência excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    ocorrencia: 'Ocorrência',
  },
  formulario: {
    nome: 'Nome',
    ocorrencia: 'Ocorrência',
  },
  filtro: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id do tipo de ocorrência é inválido',
  },
  validacoes: {
    formulario_invalido: 'Por favor, verifique os campos!',
  },
};
