export default {
  sistema: {
    nome_projeto: 'Laboratórios de Metrologia',
    minha_conta: 'Minha conta',
    sair: 'Logout',
    filtro: 'Digite sua pesquisa',
    modulos: 'Modulos',
    configuracoes: 'Configurações',
    home: 'Home',
    operacional: 'Operacional',
  },
  cadastro: {
    movimentacao: 'Movimentação',
    tabela_preco: 'Tabela de Preço',
    configuracoes: 'Configurações',
    raiz: 'Cadastros',
    calculos: 'Cálculos',
    gerais: 'Gerais',
    participante: 'Participantes',
    tipo_envio: 'Tipos de Envio',
    contrato: 'Contrato',
    contratos: 'Contratos',
    procedimento: 'Procedimentos',
    mensagem_orcamento: 'Mensagens de Orçamento',
    registro_informativo: 'Registros de Informativos',
    orcamentos: 'Orçamentos',
    orcamentosContratos: 'Orçamentos / Contratos',
    registro_ocorrencia: 'Registro de Ocorrências',
    orcamento: 'Orçamentos',
    prioridade: 'Prioridades',
    tipo_setor: 'Tipos de Setores',
    local_estocagem: 'Locais de Estocagem',
    calibracao: 'Calibrações',
    setor: 'Setores',
    area_acreditacao: 'Áreas de Acreditações',
    grandeza_fisica: 'Grandezas Físicas',
    unidade_medida: 'Unidades de Medidas',
    marca: 'Marcas',
    modelo_instrumento: 'Modelos de Instrumentos',
    tipo_instrumento: 'Tipos de Instrumentos',
    tipo_ocorrencia: 'Tipos de Ocorrências',
    classificacao_fiscal: 'Classificações Fiscais',
    local_execucao: 'Locais de Execução',
    embalagem: 'Embalagens',
    feriado: 'Feriados',
    instrumento: 'Instrumentos',
    tipo_calibracao: 'Tipos de Calibração',
    tipo_visita:"Tipos de Visita",
    categoria_item: 'Categorias de Itens',
    tipo_item: 'Tipos de Itens de Estoque',
    classificacao_instrumento: 'Classificações de Instrumentos',
    item: 'Itens de Estoque',
    solicitacao_cadastro: 'Solicitações de Cadastros',
    veiculo: 'Veículos',
    usuario: 'Usuários',
    ordem_servico: 'Ordens de Serviço',
    acreditacao: ' Acreditações',
    escopo_servico: 'Escopo de Serviços',
    escopos_servicos: 'Escopos de Serviços',
    parametro: 'Parâmetros',
    procedimento_eletronico: 'Procedimentos Eletrônicos',
    operacoes: 'Operações',
    servico_manutencao: 'Serviços de Manutenção',
    servico_calibracao: 'Serviços de Calibração',
    condicao_pagamento: 'Condição de Pagamento',
    condicoes_pagamento: 'Condições de Pagamento',
    ocorrencias_padroes: 'Ocorrências Padrão',
    ocorrencia_padrao: 'Ocorrência Padrão',
    formula: 'Fórmulas',
    incertezas: 'Incertezas',
    checklist: 'Checklist',
    clausula_contratual: 'Cláusula Contratual',
    clausulas_contratuais: 'Cláusulas Contratuais',
    certificado: 'Certificados',
    tipo_comentario: 'Tipos de Comentários',
    categoria_relatorio: 'Categoria de Relatório',
    categorias_relatorios: 'Categorias de Relatórios',
    modelo_relatorio: 'Modelo de Relatório',
    relatorios: 'Relatórios',
    campo_adicional: 'Campos Adicionais',
    conversor_unidade: 'Conversor de Unidades',
    definicao_equipamento_medicao: 'Definição de Equipamento para Medição',
    cargo_funcao: 'Cargos e Funções',
    tipo_documento: 'Tipos de Documentos',
    criterio_aceitacao: 'Critérios de Aceitação',
    colaborador: 'Colaborador',
    colaboradores: 'Colaboradores',
    registro_manutencao_troca_pecas: 'Registro de Manutenção/Troca de Peças',
    registros_manutencao_troca_pecas: 'Registros de Manutenção/Troca de Peças',
    verificacao_intermediaria: 'Verificação Intermediária',
    verificacoes_intermediarias: 'Verificações Intermediárias',
    duvida_solicitacao_participante: 'Dúvida/Solicitação do Cliente',
    papel: 'Papéis',
    envio_material: 'Envios de Materiais',
    certificado_terceiro: 'Certificados de Terceiros',
    roteiro_motivo_cancelamento: 'Motivo de Cancelamento de Roteiro',
    roteiro_envio_material: 'Roteiro de Envio de Material',
    certificado_interno: 'Certificados Internos',
    modelo_email: 'Modelos de E-mails',
    kanban_titulo: 'Kanban',
    agendamento: 'Agendamento',
    kanban_programacoes: 'Programações',
    em_execucao:"Em Execução",
    endpoints: 'Endpoints',
    requisitos: 'Requisitos',
  },
};
