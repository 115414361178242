<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      scroll-height="290px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @paginar="listar"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
    />
  </div>
</template>
<script>
import MarcaService from '@common/services/cadastros/MarcaService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    modeloId: { type: String, default: '' },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_marca.tabela.nome'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    limparFiltro() {
      this.value = [];
      this.filtro = { nome: ''};
      this.filtroAplicado = _.cloneDeep(this.filtro);
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      if (this.modeloId) {
        parametros.modeloId = this.modeloId;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      MarcaService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    filtrarGlobal() {
      this.listar();
    },
  },
};
</script>
