// TheDashboardDrawer.js

export default {
  TheDashboardDrawer: {
    drawer: {
      brand: {
        title: 'Laboratórios de Metrologia'
      },
      modules: {
        subHeader: 'MÓDULOS'
      }
    },
    bar: {
      inputs: {
        search: {
          placeholder: 'Digite sua pesquisa'
        }
      },
      profle: {
        greetingsTitle: 'Olá'
      }
    }
  }
}
