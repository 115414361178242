import helpers from '@router/helpers';
const menuId = 'campo-adicional';

export default [
  helpers.criarRota(menuId, 'campo-adicional', menuId, () =>
    import('@/views/modulos/cadastros/campo_adicional')
  ),
  helpers.criarRota(
    menuId,
    'campo-adicional-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/campo_adicional/formulario')
  ),
  helpers.criarRota(
    menuId,
    'campo-adicional-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/campo_adicional/formulario'),
    true
  ),
];
