import helpers from '@router/helpers';
const menuId = 'setor';

export default [
  helpers.criarRota(menuId, 'setor', menuId, () =>
    import('@/views/modulos/cadastros/setor')
  ),
  helpers.criarRota(menuId, 'setor-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/setor/formulario')
  ),
  helpers.criarRota(
    menuId,
    'setor-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/setor/formulario'),
    true
  ),
];
