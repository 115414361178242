<template lang="">
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno"
      class="mb-3 d-block v-label--externo d-flex"
    >
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio"
        class="obrigatorio ml-1"
      >*</span>
    </span>

    <v-menu
      ref="menu"
      v-model="menu"
      max-width="290px"
      min-width="290px"
      offset-y
      :close-on-content-click="false"
      :nudge-right="nudge"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="conteudo"
          v-mask="mask"
          :label="labelEhDentro ? labelComputada : ''"
          :placeholder="placeholder"
          :dense="dense"
          :rules="regras"
          :disabled="disabled"
          outlined
          readonly
          hide-details="auto"
          :append-icon="disabled ? '' : '$clockOutline'"
          v-bind="attrs"
          @click:append-left="menu = true"
          v-on="on"
        />
      </template>
      <v-time-picker
        v-model="hora"
        locale="pt-BR"
        :type="type"
        @click:hour="closePicker"
      />
    </v-menu>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  name: 'InputTime',
  mixins: [InputMixin],
  props: {
    mask: { type: String, default: '##:##' },
    type: { type: String, default: 'time' },
    nudge: { type: String, default: '' },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
      menu: false,
      hora: null,
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
    hora() {
      this.conteudo = this.hora;
    },
  },
  methods: {
    closePicker: function(v){
      v = v < 10 ? '0'+v : v;
      this.hora = v+":00";
      this.menu = false
    },
  },
};
</script>
