import { BasicoApi } from '@common/api';
const ConversorUnidadeService = {
  listar: function (parametros) {
    return BasicoApi.get()('/conversao-valor-lista', parametros);
  },
  salvar: function (dados) {
    return BasicoApi.post()(`${dados.unidadeMedidaDeId}/unidade-medida-conversoes`, dados);
  },
};

export default ConversorUnidadeService;
