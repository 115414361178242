export default {
  titulos: {
    listagem: 'Tipos de Setores',
    novo: 'Novo Tipo de Setor',
    editar: 'Editar Tipo de Setor',
  },
  cadastro_sucesso: 'Tipo de setor salvo com sucesso!',
  exclusao_sucesso: 'Tipo de setor excluído com sucesso!',
  tabela: {
    descricao: 'Descrição',
    posicao: 'Posição',
  },
  formulario: {
    descricao: 'Descrição',
    posicao: 'Posição',
    nome: 'Nome',
  },
  validacoes: {
    formulario_invalido: 'Preencher Formulário',
  },
  erros: {
    id_invalido: 'O id do tipo de setor é inválido',
  },
};
