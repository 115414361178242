import helpers from '@router/helpers';
const menuId = 'categoria-item';

export default [
  helpers.criarRota(menuId, 'categoria-item', menuId, () =>
    import('@/views/modulos/cadastros/categoria_item')
  ),
  helpers.criarRota(menuId, 'categoria-item-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/categoria_item/formulario')
  ),
  helpers.criarRota(
    menuId,
    'categoria-item-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/categoria_item/formulario'),
    true
  ),
];
