import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_procedimento_eletronico.calibracao'),
    value: 1,
  },
  {
    text: i18n.t('enums.tipo_procedimento_eletronico.ensaio'),
    value: 2,
  },
];
