<template>
  <v-app>
    <!-- todo remover -->
    <BaseSpinner
      id="global-spinner"
      :visible="spinnerStatus"

      size="100"
    />

    <BaseAlert
      :show="alertMessage"
      :message="alertMessage"
      :type="alertType"
      :dismissible="isDismissibleAlert"
      :auto-close="alertIsAutoClose"
      @close-alert="closeAlert"
    />


    <spinner id="global-spinner-atual" />
    <toast ref="toast" />
    <router-view />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  metaInfo() {
    return {
      title: this.tituloPagina,
      htmlAttrs: {
        lang: "pt-BR",
      },
    };
  },
  components : {
    Spinner: () => import("@components/layout/Spinner"),
    Toast: () => import("@components/layout/Toast"),
    BaseSpinner: () => import("@components/fragments/BaseSpinner"),
    BaseAlert: () => import("@components/fragments/BaseAlert"),

  },
  /** remover data e mapgetters */
  data() {
    return {
      isDismissibleAlert: true,
    };
  },
  computed: {
    tituloPagina : function(){
      return `${this.$store.state.Layout.tituloPagina} | ${process.env.VUE_APP_NAME}`;
    },
    /** todo remover */
    ...mapGetters("ModuleErrors", ["errorsList"]),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler(to) {
        if (to.name === "LoginPage") if(this.$refs["toast"]) this.$refs["toast"].fecharToast();
        /** todo remover */
        if (to.name === "LoginPage" && this.errorsList.length)
          this.closeAlert();
      },
    },
    /** todo remover */
    errorsList(error) {
      if (error.length) {
        const currentError = error[0];

        this.setBlockUi(true);

        this.setAlert(currentError);
      }
    },
  },
  mounted() {
    this.$store.dispatch("Layout/terminarCarregamento");
    this.$store.dispatch("Filtros/removerFiltros");
  },
  /** todo remover metodos */
  methods: {
    ...mapActions("ModuleErrors", ["clearErrorStore"]),
    setAlert(error) {
      this.alertMessage = error.message;
      this.isDismissibleAlert = error.dismissible ?? true;
      this.alertType = error.type ?? "error";
      this.alertIsAutoClose = error.autoClose ?? true;
    },
  },
};
</script>
