import { BasicoApi } from '@common/api';
const ItemService = {
  listar: function (parametros) {
    return BasicoApi.get()('/item', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/item/${id}`);
  },
  buscarPecas: function (parametros, stringInstrumento) {
    return BasicoApi.get()(`/item${stringInstrumento}`, parametros);
  },
  buscarPecasGlobal: function (parametros, stringInstrumento) {
    return BasicoApi.get()(
      `/item/busca-global${stringInstrumento}`,
      parametros
    );
  },
  buscarPecasGlobalVistaExplodida: function (parametros, stringInstrumento) {
    return BasicoApi.get()(
      `/item/busca-global-vista-explodida${stringInstrumento}`,
      parametros
    );
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/item/${form.id}`, form);
    return BasicoApi.post()('/item', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/item/${id}`);
  },
  buscarReservaPecas: function (id) {
    return BasicoApi.get()(`/item/${id}/reservas`);
  }
};

export default ItemService;
