import { BasicoApi } from '@common/api';
const CriterioAprovacaoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/clienteCriterioAprovacaoOrcamento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/clienteCriterioAprovacaoOrcamento/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/clienteCriterioAprovacaoOrcamento`, form);
    return BasicoApi.post()('/clienteCriterioAprovacaoOrcamento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/clienteCriterioAprovacaoOrcamento/${id}`);
  },
};

export default CriterioAprovacaoService;
