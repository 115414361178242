import helpers from '@router/helpers';
const menuId = 'tipo-setores';

export default [
  helpers.criarRota(menuId, 'tipo-setores', menuId, () =>
    import('@/views/modulos/cadastros/tipo_setor')
  ),
  helpers.criarRota(menuId, 'tipo-setores-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/tipo_setor/formulario')
  ),
  helpers.criarRota(
    menuId,
    'tipo-setores-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_setor/formulario'),
    true
  ),
];
