<template lang="">
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span class="mb-3 d-block v-label--externo d-flex">
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio"
        class="obrigatorio ml-1"
      >*</span>
    </span>
    <v-combobox
      v-model="conteudo"
      append-icon="$downArrow"
      :items="options"
      :label="labelEhDentro ? labelComputada : ''"
      :placeholder="placeholder"
      :dense="dense"
      :rules="regras"
      :disabled="disabled"
      chips
      multiple
      :background-color="backgroundColor"
      style="background-color: white"
      :return-object="retornarObjeto"
      clearable
      outlined
      hide-details="auto"
      @change="onChangeCombobox"
    >
      <template
        v-for="slot in slots"
        v-slot:[`${slot}`]
      >
        <slot :name="slot" />
        <span :key="slot" />
      </template>
    </v-combobox>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';

export default {
  name: 'InputCombobox',
  mixins: [InputMixin],
  props: {
    options: { type: Array, default: () => [], required: false },
    retornarObjeto: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Selecione' },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    onChangeCombobox(items) {
      this.conteudo = items.filter((item) => this.options.includes(item));
    },
  },
};
</script>
