<template>
  <div
    class="tabela-padrao-prime-vue"
    :class="{
      'tabela-padrao-com-acoes': mostrarAcoes,
      'tabela-padrao-com-seletor': mostrarSeletor,
    }"
  >
    <span
      v-if="mostrarQtdRegistros"
      class="quantidade"
    >
      {{ $tc('componentes.cabecalho_pagina.registros', quantidadeItens, {
        count: quantidadeItens,
      }) }}
    </span>
    <data-table
      class="p-datatable-sm tabela-prime-vue-inherit"
      :class="{
        'editable-cells-table': editarColunas,
        'ajustar-altura-linha': ajustarAlturaLinha,
        'ajustar-dropdown-acima': ajustarDropdownAcima,
      }"
      :data-key="dataKey"
      :value="dados"
      :row-class="rowClass"
      :paginator="paginacaoEmMemoria"
      :rows="porPagina"
      :scroll-height="scrollHeight"
      :total-records="quantidadeItens"
      :lazy="lazy"
      :filter-display="null"
      :selection.sync="selecionados"
      :row-hover="linhaHover"
      :reorderable-columns="colunasReordenaveis"
      :edit-mode="editarColunas ? 'cell' : ''"
      :responsive-layout="layoutResponsivo"
      :breakpoint="breakpoint"
      :show-gridlines="mostrarLinhasGrade"
      :auto-layout="layoutAutomatico"
      :resizable-columns="colunasAjustaveis"
      :filters.sync="filters"
      current-page-report-template="{currentPage} de {totalPages}"
      :rows-per-page-options="paginacaoEmMemoria ? [5, 10, 20, 50, 100] : null"
      paginator-template="JumpToPageInput FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
      :global-filter-fields="globalFilters"
      :row-group-mode="rowGroupMode"
      :group-rows-by="groupRowsBy"
      :sort-mode="sortMode"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :expandable-row-groups="expandableRowGroups"
      :expanded-row-groups.sync="expandedRowGroupLocal"
      @update:sortOrder="atualizarSentidoOrdenacao"
      @update:sortField="atualizarOrdenacaoCampo"
      @row-select="emitirEventoLinhaSelecionada"
      @row-select-all="eventoSelectAll"
      @row-click="selecionarLinhaComDuploClique"
    >
      <template
        v-if="mostraHeader"
        #header
      >
        <slot name="header" />
        <div
          v-if="filtroGeral"
          class="divHeader"
        >
          <span
            v-if="filtroGeral"
            class="p-input-icon-left ml-100 mr-2"
          >
            <i class="pi pi-search" />
            <InputText
              ref="filtro-global-colunas"
              v-model="filters['global'].value"
              class="p-inputtext-sm"
              placeholder="Filtrar"
              autofocus
              @input="filtroGlobal"
            />
          </span>
          <Dropdown
            v-if="mostrarSelectFiltro"
            v-model="selectModel"
            :options="opcoesSelect"
            option-label="name"
            :placeholder="placeholderSelectFiltro"
            :filter="true"
            :show-clear="true"
            class="mr-2 p-inputtext-sm dropdown-header"
            @input="selecionaOpcaoSelectFiltrar"
          />
          <botao-padrao
            outlined
            tooltip="Colunas"
            @click="abreModalDefineColunas"
          >
            <v-icon>$formatColumns</v-icon>
          </botao-padrao>
          <div />
        </div>
        <div
          v-if="filtrosComColunas"
          class="d-flex justify-end"
        >
          <span class="p-input-icon-left ml-100 mr-2">
            <i class="pi pi-search" />
            <InputText
              ref="filtro-global-colunas"
              v-model="valueFiltroGlobal"
              class="p-inputtext-sm"
              placeholder="Filtrar"
              @keydown.enter="filtrarGlobal"
              @input="filtroGlobal"
            />
          </span>
          <botao-padrao
            outlined
            tooltip="Colunas"
            @click="abreModalDefineColunas"
          >
            <v-icon>$formatColumns</v-icon>
          </botao-padrao>
        </div>
      </template>

      <column
        v-if="mostrarSeletor"
        class-name="column-sm"
        :selection-mode="selecionarApenasUm ? 'single' : 'multiple'"
      />

      <column
        v-if="mostrarSeletorCheckBox"
        field="seletor"
        :styles="{ width: '3%' }"
      >
        <template #body="slotProps">
          <slot
            :slotProps="slotProps"
            name="seletor"
          />
        </template>
      </column>

      <column
        v-if="mostrarAcoes"
        field="acoes"
        :styles="{ width: '3%' }"
      >
        <template #body="slotProps">
          <slot
            :slotProps="slotProps"
            name="acoes"
          />
        </template>
      </column>

      <template
        v-if="expandableRowGroups"
        #groupheader="slotProps"
      >
        <slot
          name="agrupadorLinhas"
          :slotProps="slotProps"
        />
      </template>

      <div
        ref="grupoColunas"
        :key="colunasKey"
      >
        <column
          v-for="(col, index) of colunasSelecionadas"
          :key="col.field + '_' + index"
          :field="col.field"
          :header="col.header"
          :sortable="col.sortable"
          :tipo="col.tipo ?? 'string'"
          :body-class="selecionarApenasUm ? 'hoverCelulas' : ''"
        >
          <template
            #body="slotProps"
            :style="col.style"
          >
            <slot
              :slotProps="slotProps"
              :name="col.field"
            >
              {{ formatarValorColuna(col, slotProps.data) }}
            </slot>
          </template>

          <template
            v-if="editarColunas"
            #editor="slotProps"
          >
            <InputText
              :value="obterValorCampo(slotProps.data, slotProps.column.field)"
              autofocus
              @input="atualizarValorCampo($event, slotProps)"
            />
          </template>
        </column>
      </div>

      <template #empty>
        <span>{{ $t('geral.tabela.nenhum_registro_foi_encontrado') }}</span>
      </template>
    </data-table>
    <Paginator
      v-if="!semPaginacao"
      :first.sync="paginaAtual"
      :rows.sync="itensPorpagina"
      row-per-page-dropdown
      :rows-per-page-options="[10, 25, 50, 100, 500 ]"
      :total-records="quantidadeItens"
      current-page-report-template="{currentPage} de {totalPages}"
      template="JumpToPageInput FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
      class="fixed-bottom dropdown-paginacao-acima"
    />
    <DefineColunasModalPrimeVue
      ref="modal-define-colunas"
      :colunas="colunas"
      :selecionadas="novasColunas"
      @envia-colunas-selecionadas="refazColunasTabela"
    />
  </div>
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import DefineColunasModalPrimeVue from './DefineColunasModalPrimeVue';
// import { EventTypes, useEmitterBus } from '@plugins/emitter-bus.js'
import _ from 'lodash';
import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaPadraoPrimeVue',
  components: { DefineColunasModalPrimeVue },
  props: {
    inputFocus: { type: Boolean, default: false },
    colunas: { type: Array, default: () => [] },
    globalFilters: { type: Array, default: () => [] },
    dados: { type: [Array, Object], default: null },
    porPagina: { type: Number, default: 10 },
    quantidadeItens: { type: Number, default: 0 },
    selecionarApenasUm: { type: Boolean, default: false },
    mostrarAcoes: { type: Boolean, default: true },
    mostrarSeletor: { type: Boolean, default: true },
    mostrarSeletorCheckBox: { type: Boolean, default: false },
    groupBy: { type: String, default: null },
    rowClass: { type: Function, default: null },
    validarAoSelecionar: { type: Function, default: () => true },
    selecionarColunas: { type: Boolean, default: true },
    mostrarSelectFiltro: { type: Boolean, default: false },
    placeholderSelectFiltro: { type: String, default: 'Setores' },
    opcoesSelect: { type: Array, default: () => [] },
    selecionarAutomaticoSelect: { type: Boolean, default: false },
    editarColunas: { type: Boolean, default: false },
    quantidadePaginas: { type: Number, default: 0 },
    semPaginacao: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
    filtroGeral: { type: Boolean, default: false },
    mostraHeader: { type: Boolean, default: true },
    linhaHover: { type: Boolean, default: false },
    colunasReordenaveis: { type: Boolean, default: true },
    layoutResponsivo: { type: String, default: 'stack' },
    valorPadraoSelect: { type: Object, default: () => {} },
    breakpoint: { type: String, default: '960px' },
    mostrarLinhasGrade: { type: Boolean, default: true },
    layoutAutomatico: { type: Boolean, default: true },
    colunasAjustaveis: { type: Boolean, default: true },
    value: { type: [Array, Object], default: () => [] },
    filters: { type: Object, default: () => {} },
    filtrosComColunas: { type: Boolean, default: false },
    pagina: { type: Number, default: 1 },
    scrollHeight: { type: String, default: null },
    ajustarAlturaLinha: { type: Boolean, default: false },
    filtroGlobalFocus: { type: Boolean, default: false },
    paginacaoEmMemoria: { type: Boolean, default: false },
    rowGroupMode: { type: String, default: null },
    groupRowsBy: { type: [String, Array, () => {}], default: null },
    sortMode: { type: String, default: 'single' },
    sortField: { type: [String, () => {}], default: null },
    sortOrder: { type: Number, default: null },
    expandableRowGroups: { type: Boolean, default: false },
    expandedRowGroups: { type: Array, default: () => [] },
    dataKey: { type: String, default: 'id' },
    ajustarDropdownAcima: { type: Boolean, default: true },
    mostrarQtdRegistros: { type: Boolean, default: false}
  },
  data() {
    return {
      selecionados: [],
      novasColunas: [],
      colunasSelecionadas: [],
      colunasKey: 0,
      paginaAtual: this.pagina * 10 - 1 || 1,
      passo: 0,
      itensPorpagina: this.porPagina ?? 10,
      selectModel: this.verificarValorInicialSelectModel(),
      valueFiltroGlobal: null,
      expandedRowGroupLocal: null,
      campo: '',
      ordem: 1
      // emitterGlobal: useEmitterBus(),
    };
  },
  watch: {
    expandedRowGroups: {
      handler() {
        if (this.expandableRowGroups)
          this.expandedRowGroupLocal = _.cloneDeep(this.expandedRowGroups);
      },
      immediate: true,
    },
    porPagina: {
      handler() {
        this.itensPorpagina = this.porPagina;
      },
      immediate: true,
    },
    selecionados() {
      this.$emit('input', this.selecionados);
    },
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
      deep: true,
    },
    paginaAtual() {
      this.enviaDadosPaginacao();
    },
    itensPorpagina() {
      this.enviaDadosPaginacao();
    },
    valorPadraoSelect() {
      this.selectModel = this.valorPadraoSelect;
    },
    inputFocus: {
      handler() {
        this.$refs['filtro-global-colunas'].$el.focus();
      },
    },
  },
  mounted() {
    this.valueFiltroGlobal = this.buscarStateFiltros()?.filtroAplicado?.filter ?? '';
    this.colunas?.forEach((coluna) => {
      coluna.field = coluna.value;
      coluna.header = coluna.text;
    });
    this.colunasSelecionadas = this.colunas;
    this.focusFiltroGlobal();
    // this.registrarEventGlobal();
  },
  methods: {
    // registrarEventGlobal(){
    //   this.emitterGlobal.listen(EventTypes.FiltroGrid, () => {
    //     console.log("filter")
    //   })
    // },
    filtrarGlobal(){
      if(!this.valueFiltroGlobal && this.paginaAtual != 0) return this.paginaAtual = 0;
      this.$emit('filtrarGlobal')
    },
    limparValueFiltroGlobal() {
      this.valueFiltroGlobal = '';
    },
    verificarValorInicialSelectModel() {
      const valorInicial = this.opcoesSelect?.length
        ? this.opcoesSelect[0]
        : null;
      this.selecionarAutomaticoSelect &&
        valorInicial &&
        this.$emit('filtroSelect', valorInicial);
      return this.selecionarAutomaticoSelect ? valorInicial : null;
    },
    atualizarPaginaAtual() {
      this.paginaAtual = 0;
    },
    focusFiltroGlobal() {
     this.$nextTick(() => {
        if (this.filtroGlobalFocus && this.filtrosComColunas)
          this.$refs['filtro-global-colunas'].$el.focus();
      });
    },
    enviaDadosPaginacao: async function () {
      await this.$nextTick();
      this.passo = this.paginaAtual / this.itensPorpagina + 1;

      this.$emit('paginar', this.passo, this.itensPorpagina);
    },
    selecionarLinhaComDuploClique: async function (event) {
      if (!this.mostrarSeletor) return;
      if (event.originalEvent.detail !== 2) return;

      if (this.selecionarApenasUm) {
        this.selecionados = [event.data];
        await this.$nextTick();
        await this.$nextTick();
        this.$emit('fechar-modal-click-linha');
      } else {
        const index = this.selecionados.findIndex(
          (item) => item.id === event.data.id
        );

        if (index !== -1) {
          this.selecionados.splice(index, 1);
        } else {
          this.selecionados.push(event.data);
        }
      }

      // this.$emit('fecharModal');
    },
    obterValorCampo: get,
    emitirEventoLinhaSelecionada(item) {
      this.$emit('linha-selecionada', item);
    },
    selecionaOpcaoSelectFiltrar() {
      this.$emit('filtroSelect', this.selectModel);
    },
    abreModalDefineColunas() {
      this.$refs['modal-define-colunas'].abrirModal();
    },
    refazColunasTabela(colunas) {
      if (colunas.length) {
        this.novasColunas = [];
        this.colunas.forEach((col) =>
          colunas.forEach((novaColuna) => {
            if (col.header == novaColuna.coluna) {
              this.novasColunas.push(col);
            }
          })
        );
        this.aoSelecionarColuna(this.novasColunas);
      }
    },
    aoSelecionarColuna(value) {
      this.colunasSelecionadas = this.colunas.filter((col) =>
        value.includes(col)
      );
      ++this.colunasKey;
    },
    paginar(event) {
      this.$emit('paginar', event.page, event.rows);
    },
    formatarValorColuna(coluna, data) {
      const valor = this.obterValorCampo(data, coluna.field);
      return Object.hasOwn(coluna, 'formatter')
        ? coluna.formatter(valor)
        : valor;
    },
    atualizarValorCampo(value, slotProps) {
      set(this.dados[slotProps.index], slotProps.column.field, value);
    },
    filtrar() {
      this.$emit('filtrar', this.filtroAplicado);
    },
    filtroGlobal(valor) {
      this.$emit('filtroGlobal', valor);
    },
    eventoSelectAll: async function (event) {
      const selecionados = _.cloneDeep(this.selecionados).concat(
        _.cloneDeep(event.data)
      );
      await this.$nextTick();
      this.selecionados = _.uniqBy(selecionados, 'id');
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    atualizarSentidoOrdenacao(event) {
      this.ordem = event == 1 ? '' : '-';
      this.novaOrdenacao()
    },
    atualizarOrdenacaoCampo(event) {
      this.campo = event;
    },
    novaOrdenacao() {
      let campoOrdenado = "";
      if (this.campo.includes('.')) campoOrdenado = helpers.formatarColunasComPonto(this.campo);
      else campoOrdenado = helpers.primeiraLetraMaiuscula(this.campo);
      this.colunaOrdenada = this.ordem + campoOrdenado;
      this.$emit('nova-ordenacao', this.colunaOrdenada);
    }
  },
};
</script>

<style lang="scss" scoped>
.tabela-padrao-com-acoes td:nth-child(2),
.tabela-padrao-com-acoes th:nth-child(2) {
  width: 0;
}

.tabela-padrao-com-seletor td:nth-child(1),
.tabela-padrao-com-seletor th:nth-child(1) {
  width: 0;
}

.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

:deep(.ajustar-altura-linha td) {
  height: 30px !important;
}

:deep(.p-datatable-header) {
  padding-bottom: 18px !important;
  display: flex;
  justify-content: end;
}

.divHeader {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

:deep(.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td) {
  padding: 0.001rem 0.5rem !important;
}

:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

:deep(.p-radiobutton .p-radiobutton-box) {
  margin-top: 2px;
  width: 16px !important;
  height: 16px !important;
}

.dropdown-header {
  width: 190px;
  font-family: 'Work Sans', sans-serif !important;

  :deep(.p-dropdown-items) {
    position: unset !important;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif !important;
  }

  :deep(.p-dropdown-label) {
    font-family: 'Work Sans', sans-serif !important;
  }
}

:deep(.p-paginator .p-paginator-page-input .p-inputtext) {
  max-width: 4.7rem;
  height: 37px;
  padding-top: 12px;
}

:deep(.hoverCelulas:hover) {
  cursor: pointer;
}

.p-dropdown-items-wrapper {
  z-index: 10000;
}

.tabela-padrao-prime-vue .fixed-bottom {
  bottom: 0;
  position: sticky;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.corpo-modal {
  padding-bottom: 0 !important;
}

:deep(.ajustar-dropdown-acima .p-dropdown-panel) {
  top: -180px !important;
}

:deep(.ajustar-dropdown-acima .p-dropdown-items) {
  position: relative !important;
}

:deep(.dropdown-paginacao-acima .p-dropdown-panel) {
  z-index: 1019 !important;
  transform-origin: center top !important;
  top: -195px !important;
  left: 0px !important;
}

</style>
