import helpers from '@router/helpers';
const menuId = 'marca';

export default [
  helpers.criarRota(menuId, 'marca', menuId, () =>
    import('@/views/modulos/cadastros/marca')
  ),
  helpers.criarRota(menuId, 'marca-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/marca/formulario')
  ),
  helpers.criarRota(
    menuId,
    'marca-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/marca/formulario'),
    true
  ),
];
