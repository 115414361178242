import { BasicoApi } from '@common/api';
const ClassificacaoInstrumentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/classificacaoinstrumento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/classificacaoinstrumento/${id}`);
  },
  buscarInstrumentoChildren: function (id) {
    return BasicoApi.get()(`/classificacaoinstrumento/${id}/children`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(`/classificacaoinstrumento/${form.id}`, form);
    return BasicoApi.post()('/classificacaoinstrumento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/classificacaoinstrumento/${id}`);
  },
};

export default ClassificacaoInstrumentoService;
