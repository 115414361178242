<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    :flat="flat"
    :solo="solo"
    :outlined="outlined"
    :sem-label="semLabel"
    :background-color="backgroundColor"
    @abrir-modal="abrirModal"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="$t('componentes.input_setor.titulo_modal')"
        :titulo-ok="$t('geral.botoes.confirmar')"
        :participante-id="participanteId"
        @ok="aplicarSelecionados"
      >
        <modal
          ref="modal"
          v-model="selecionados"
          :multiplos="multiplos"
          :ocultar-setor="ocultarSetor"
          :participante-id="participanteId"
          :flagParticipanteComCalibracaoOuManutencao="flagParticipanteComCalibracaoOuManutencao"
          @fechar-modal-click-linha="aplicarSelecionados"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { InputMixin } from '../../InputMixin';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import Modal from './modal.vue';
import _ from 'lodash';
export default {
  name: 'InputSetor',
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    value: { type: [Array, Object], default: null },
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    ocultarSetor: { type: String, default: '' },
    participanteId: { type: String, default: '' },
    flagParticipanteComCalibracaoOuManutencao: { type: Boolean, default: false },

    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: [],
      selecionados: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      else if (regras.includes(this.validacaoObrigatorio)) {
        regras.splice(regras.indexOf(this.validacaoObrigatorio), 1);
      }
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit('input', this.conteudo);
    },
    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      this.$nextTick(() => {
        this.$refs['modal'].limparFiltro();
        this.$refs['modal'].listar();
        this.$refs['modal'].focusFiltroGlobal();
      });
      if (!this.conteudo) return false;

      let conteudoClonado = _.cloneDeep(this.conteudo);
      if (!this.multiplos) conteudoClonado = [conteudoClonado];

      this.selecionados = conteudoClonado.map((el) => {
        return {
          id: el.value,
          nome: el.text,
        };
      });
    },
    aplicarSelecionados: function () {
      let selecionados = new DropdownModel(this.selecionados);
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropdownModel(this.selecionados)[0];
      }
      this.conteudo = selecionados;
      this.$emit("emiterEvento",this.conteudo);
      this.fechaModal();
    },
    fechaModal() {
      this.$refs['modal-input'].fecharModal();
      this.selecionados = [];
    },
  },
};
</script>
