export default {
  criarRota(menuId, name, path, component, props = {}, acessoCliente = true) {
    return {
      meta: { menuId: menuId, acessoCliente: acessoCliente },
      name: name,
      path: path,
      component: component,
      props: props,
    };
  },
};
