import { BasicoApi } from '@common/api';
const DuvidaSolicitacaoParticipanteService = {
  listar: function (parametros) {
    return BasicoApi.get()('/duvidasolicitacaoparticipante', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/duvidasolicitacaoparticipante/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/duvidasolicitacaoparticipante/${form.id}`, form);
    return BasicoApi.post()('/duvidasolicitacaoparticipante', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/duvidasolicitacaoparticipante/${id}`);
  },
  alterarStatus: function (status) {
    return BasicoApi.put()(`/duvidasolicitacaoparticipante/alterar-status`, status);
  },
};

export default DuvidaSolicitacaoParticipanteService;
