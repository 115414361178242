import helpers from '@router/helpers';
const menuId = 'envio-material';

export default [
  helpers.criarRota(menuId, 'envio-material', menuId, () =>
    import('@/views/modulos/cadastros/envio_material')
  ),
  helpers.criarRota(menuId, 'envio-material-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/envio_material/formulario')
  ),
  helpers.criarRota(
    menuId,
    'envio-material-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/envio_material/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'envio-material-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/envio_material/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'envio-material-ler-qrcode',
    `${menuId}/:id?/qrcode/:qrcodeId?`,
    () => import('@/views/modulos/cadastros/envio_material/ler-qrcode'),
    true
  ),
];
