export default {
  titulos: {
    listagem: 'Configuração do Kanban',
    adicionar_coluna: 'Nova Coluna',
    adicionar_cartao: 'Nova Regra de Cartão',
    nova_coluna: 'Nova Coluna Kanban',
    editar_coluna: 'Editar Coluna Kanban',
    novo_cartao: 'Nova Configuração de Cartao Kanban',
    editar_cartao: 'Editar Configuração de Cartão Kanban',
    setor:"Setor",
    kanban:"Kanban",
    atualizado_em:"Atualizado em",
    coluna:"Colunas",
    cartao:"Cartões",
  },
  cadastro_sucesso: 'Coluna salva com sucesso!',
  exclusao_sucesso: 'Coluna de orçamento excluida com sucesso!',
  ordenacao_sucesso: 'Ordenação das colunas salva com sucesso!',
  cadastro_sucesso_cartao: 'Regra de cartão salva com sucesso!',
  exclusao_sucesso_cartao: 'Regra de cartão excluida com sucesso!',
  ordenacao_sucesso_cartao: 'Ordenação das regras de cartões salva com sucesso!',
  tabela: {
    nome_coluna: 'Nome da Coluna',
    situacoes: 'Situações',
    situacao: 'Situação',
    quantidade_cartoes_por_linha: 'Limite de cartões por linha',
    quantidade_cartoes_por_coluna: 'Limite de cartões por coluna',
    tamanho_fonte: 'Tamanho da fonte',
    cor_coluna: 'Cor da coluna',
    cor_cartao: 'Cor do cartão',
    cor_fonte: 'Cor da fonte',
    cor_fonte_cartao: 'Cor da fonte do cartão',
    exibir_todos_setores: 'Exibir OS de todos os setores',
    sequencia: 'Sequência',
    campo: 'Campo',
    operador: 'Operador',
    valor: 'Valor',
    data_entrega: 'Dias faltantes para a data de entrega',
  },
  formulario: {
    nome_coluna: 'Nome da Coluna',
    nome_coluna_placeholder: 'Nome da coluna',
    situacoes: 'Situações',
    situacao: 'Situação',
    data_entrega: 'Dias faltantes para a data de entrega',
    prioridade: 'Prioridade',
    quantidade_cartoes_por_linha: 'Limite de cartões por linha',
    quantidade_cartoes_por_coluna: 'Limite de cartões por coluna',
    tamanho_fonte: 'Tamanho da fonte',
    cor_coluna: 'Cor da coluna',
    cor_cartao: 'Cor do cartão',
    cor_fonte: 'Cor da fonte',
    cor_fonte_cartao: 'Cor da fonte do cartão',
    exibir_todos_setores: 'Exibir OS de todos os setores',
    adicionar_regra_cor_situacao: 'Adicionar nova regra de cores por situação',
    adicionar_regra_cor_prioridade: 'Adicionar nova regra de cores por prioridade',
    adicionar_regra_cor_data: 'Adicionar nova regra de cores por data',
    obrigatorio: 'Obrigatório',
    titulo: 'Título',
    classificacao: 'Classificação',
    descricao: 'Descrição',
    descricao_placeholder: 'Descrição',
    motivo_reprova: 'Motivo da Reprova',
    tipo_comentario: 'Tipos de Comentário',
    campo: 'Campo',
    operador: 'Operador',
    valor: 'Valor',
  },
  erros: {
    id_invalido: 'O id da coluna é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
};
