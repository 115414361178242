import { AuthApi, BasicoApi } from '@common/api';

const UtilsService = {
  buscarCep: function (cep) {
    cep = `${cep}`.replace(/\.|-/g, '');
    return BasicoApi.get()(`/viacep/${cep}`);
  },
  buscarMunicipios: function (parametros) {
    return BasicoApi.get()('/municipio', parametros);
  },
  buscarTodosMunicipios: function (unidadeFederativaId) {
    return BasicoApi.get()(`/unidadefederativa/${unidadeFederativaId}/municipios`);
  },
  buscarMunicipiosPorUf: function (unidadeFederativaId) {
    return BasicoApi.get()(`/municipio`, { unidadeFederativaId });
  },
  buscarUnidadeFederativa: function () {
    return BasicoApi.get()('/unidadefederativa');
  },
  listarColaboradoresResponsaveis: function (parametros) {
    return BasicoApi.get()('/colaborador', parametros);
  },
  buscaColaboradorResponsavel: function (id) {
    return BasicoApi.get()(`/colaborador/${id}`);
  },
  buscaLocalExecucao: function () {
    return BasicoApi.get()(`/localexecucao`);
  },
  buscaCondicaoPagamento: function () {
    return BasicoApi.get()(`/condicaopagamento`);
  },
  listarTipoServicos: function (
    parametros,
    tiposervico,
    stringInstrumento = ''
  ) {
    return BasicoApi.get()(
      `/servico/tiposervico/${tiposervico}?${stringInstrumento}`,
      parametros
    );
  },
  buscarDadosPadrao: function (parametros) {
    return AuthApi.get()(`/conta/parametrogeral`, parametros);
  },
  buscarCNPJ: function (cnpj){
   return BasicoApi.get()(`/cnpj/${cnpj}`)
  }
};

export default UtilsService;
