import Validacoes from '@common/utils/validacoes';
import helpers from '@common/utils/helpers';
export const InputMixin = {
  props: {
    id: {
      default: () => {
        return `${helpers.gerarNumeroAleatorio()}`;
      },
    },
    label: { type: String, default: '' },
    placeholder: {
      type: String,
      default: function () {
        return this.label;
      },
    },
    dense: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    emFiltro: { type: Boolean, default: false },
    labelDentro: { type: Boolean, default: false },
    semLabel: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    flat: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    backgroundColor: { type: String, default: '' },
    color: { type: String, default: '' },
    value: {},
    preventTab: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
    /** Props de validação */
    obrigatorio: { type: Boolean, default: false },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    trim: { type: Boolean, default: false },
    tooltip: { type: String, default: '' },
    regrasPersonalizadas: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    /** Validações */
    validacaoObrigatorio: function (conteudo) {
      if (conteudo === 0) conteudo = conteudo.toString();

      if (Array.isArray(conteudo))
        return conteudo.length > 0 || this.$t('inputs.rules.required');

      return !!conteudo || this.$t('inputs.rules.required');
    },
    validacaoMinimo(conteudo) {
      return (
        Validacoes.validacaoMinimo(conteudo, this.min) ||
        this.$t('inputs.rules.minValue', { number: this.min })
      );
    },
    validacaoMaximo(conteudo) {
      if (!conteudo) return true;
      return (
        Validacoes.validacaoMaximo(conteudo, this.max) ||
        this.$t('inputs.rules.charlimit', { number: this.max })
      );
    },
    validacaoEspacoVazio(conteudo) {
      if (conteudo?.length != 0)
        return (
          conteudo?.trim().length > 0 ||
          this.$t('inputs.rules.naoAceitaEspacoVazio')
        );
    },
  },
  computed: {
    ehDense: function () {
      if (this.emFiltro) return false;
      return this.dense;
    },
    labelEhDentro: function () {
      if (this.emFiltro) return false;
      return this.labelDentro;
    },
    labelExterno: function () {
      if (this.emFiltro) return true;
      return !this.labelDentro;
    },
    slots: function () {
      let slots = [];
      slots.push(...Object.keys(this.$slots));
      slots.push(...Object.keys(this.$scopedSlots));
      return slots;
    },
    labelComputada: function () {
      if (!this.label) return '';
      if (this.obrigatorio) return `${this.label} *`;
      return this.label;
    },
  },
};
