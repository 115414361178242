import axios from 'axios';
import store from '@store';
import route from '@router';
axios.defaults.headers.post['Content-Type'] = 'application/json-patch+json';

let api = null;

const setarToken = function (config) {
  const token = store.getters['Autenticacao/token'];
  if (token) config.headers['Authorization'] = token;

  return config;
};

const logoutTokenExpirado = function () {
  route.push({
    name: 'login',
    params: { redirect: route?.app?.$route?.path },
  });
  setTimeout(() => {
    Window.App.toastAlerta(Window.App.$t(`geral.erros.expiredToken`));
  }, 150);
  setTimeout(() => {
    store.dispatch('Autenticacao/limparAutenticacao');
  }, 1000);
};

const configurarAxios = function (url) {
  api = axios.create({
    baseURL: url,
    headers: { 'Content-Type': 'application/json-patch+json' },
  });
  api.interceptors.request.use(async (config) => {
    return setarToken(config);
  });

  api.interceptors.response.use(
    (config) => {
      return setarToken(config);
    },
    (error) => {
      const token = store.getters['Autenticacao/token'];
      if (token && error.response.status == 401) logoutTokenExpirado();
      return Promise.reject(error);
    }
  );
};

const mensagemErro = function (mostrarErro, erro) {
  if (!mostrarErro) return;
  if (erro.response.status == 401) return;
  let mensagem = 'erroGenerico';
  if (erro?.response?.data.errors !== undefined)
    mensagem = erro.response.data.errors[0];
  Window.App.toastErro(Window.App.$t(`geral.erros.${mensagem}`));
};

const get = function (link, parametros, mostrarErro = true) {
  let qs = new URLSearchParams();
  if (parametros) qs = new URLSearchParams({ ...parametros });

  return new Promise((resolve, reject) => {
    api
      .get(link, {
        params: qs,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        mensagemErro(mostrarErro, err);
        reject(err);
      });
  });
};

const post = function (link, form, mostrarErro = true) {
  return new Promise((resolve, reject) => {
    api
      .post(link, form)
      .then((res) => resolve(res))
      .catch((err) => {
        mensagemErro(mostrarErro, err);
        reject(err);
      });
  });
};

const put = function (link, form, mostrarErro = true) {
  return new Promise((resolve, reject) => {
    api
      .put(link, form)
      .then((res) => resolve(res))
      .catch((err) => {
        mensagemErro(mostrarErro, err);
        reject(err);
      });
  });
};

const deletar = function (link, form = {}, mostrarErro = true) {
  return new Promise((resolve, reject) => {
    api
      .delete(link, { data: form })
      .then((res) => resolve(res))
      .catch((err) => {
        mensagemErro(mostrarErro, err);
        reject(err);
      });
  });
};

const patch = function (link, form, mostrarErro = true) {
  return new Promise((resolve, reject) => {
    api
      .patch(link, form)
      .then((res) => resolve(res))
      .catch((err) => {
        mensagemErro(mostrarErro, err);
        reject(err);
      });
  });
};

class BaseApi {
  static get = function () {
    return this.config(get);
  };
  static post = function () {
    return this.config(post);
  };
  static put = function () {
    return this.config(put);
  };
  static delete = function () {
    return this.config(deletar);
  };
  static patch = function () {
    return this.config(patch);
  };
}

export class AuthApi extends BaseApi {
  static config(metodo) {
    configurarAxios(process.env.VUE_APP_AUTH_API);
    return metodo;
  }
}
export class BasicoApi extends BaseApi {
  static config(metodo) {
    configurarAxios(process.env.VUE_APP_API);
    return metodo;
  }
}
export class CalculosApi extends BaseApi {
  static config(metodo) {
    configurarAxios(process.env.VUE_APP_CALCULO_CALIBRACAO_API);
    return metodo;
  }
}
