export default {
  adicionarFiltro({ commit }, payload) {
    commit('ADICIONAR_FILTRO', payload);
  },
  removerFiltro({ commit }, payload) {
    commit('REMOVER_FILTRO', payload);
  },
  removerFiltros({ commit }) {
    commit('REMOVER_FILTROS');
  }
};
