import { BasicoApi } from '@common/api';

const TipoItemService = {
  listar: function (parametros) {
    return BasicoApi.get()('/tipoitem', parametros);
  },

  buscar: function (id) {
    return BasicoApi.get()(`/tipoitem/${id}`);
  },

  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipoitem/${form.id}`, form);
    return BasicoApi.post()('/tipoitem', form);
  },

  excluir: function (id) {
    return BasicoApi.delete()(`/tipoitem/${id}`);
  },
};

export default TipoItemService;
