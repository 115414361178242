export default {
  titulos: {
    listagem: 'Serviços de Manutenção',
    novo: 'Novo Serviço de Manutenção',
    editar: 'Editar Serviços de Manutenção',
  },
  cadastro_sucesso: 'Serviços de manutenção salvo com sucesso!',
  exclusao_sucesso: 'Serviços de manutenção excluído com sucesso!',
  aviso_alterar_unidade_medida_amplitude: 'Se confirmar, as informações de Tipos e Modelos precisarão ser selecionadas novamente.',
  alteracao_na_faixa: 'Alteração na Faixa',
  filtro: {
    apelido:"Apelido",
    nome: 'Nome',
    codigo: 'Código',
  },
  tabela: {
    apelido:'Apelido',
    codigo: 'Código',
    nome: 'Nome',
    quantidade_tipo: 'Qtd tipo de Instrumento',
    quantidade_modelo: 'Qtd Modelo de Instrumento',
    valor_servico: 'Valor do Serviço',
    local_execucao: 'Local de Execução',
    faixa: 'Faixa',
  },
  formulario: {
    abas: {
      dados_servico: 'Dados do Serviço',
      tipo_modelo_instrumento: 'Tipos e Modelos de Instrumento',
    },
    codigo: 'Código',
    codigo_placeholder: 'Código',
    nome: 'Nome',
    nome_placeholder: 'Nome',
    descricao: 'Descrição',
    descricao_placeholder: 'Descrição',
    local_execucao: 'Local de Execução',
    laboratorio: 'Laboratório',
    minimo_pontos: 'Quantidade de Pontos',
    unidade_medida: 'Unidade de Medida',
    amplitude: 'Amplitude',
    amplitude_min: 'Mínima',
    amplitude_max: 'Máxima',
    valor_servico: 'Valor do Serviço',
    valor_servico_placeholder: 'Valor do Serviço',
    valor_ponto: 'Valor por Ponto',
    valor_ponto_placeholder: 'Valor por Ponto',
    valor_hora: 'Valor por Hora',
    valor_hora_placeholder: 'Valor por Hora',
    tempo_execucao: 'Horas de Execução Padrão',
    desconto: 'Desconto Liberado %',
    desconto_placeholder: 'Desconto Liberado',
    tipo_exibicao: 'Modo de Exibição',
    a: 'a',
    tipo_modelo_instrumento: {
      botoes: {
        novo: 'Tipos e Modelos de Instrumento',
      },
      tabela: {
        modelo_instrumento: 'Modelo de Instrumento',
        tipo_instrumento: 'Tipo de Instrumento',
      },
    },
    erros: {
      codigo_existente: 'Código já utilizado',
      amplitude_maxima: 'Amplitude máxima deve ser maior que amplitude mínima',
      unidade_medida: 'Prencha unidade de medida',
    },
  },
  erros: {
    id_invalido: 'O id do tipo de envio é inválido',
  },
  validacoes: {
    formulario_invalido: 'Preencha o formulário para liberar o botão',
    tipoModelo_invalido:
      'Adicione ao menos um tipo e modelo de instrumento para salvar',
  },
  botoes: {
    duplicar_servico: 'Duplicar Serviço',
    duplicar: 'Duplicar',
    atualizar_preco: 'Atualizar Preço',
  },
  modal_duplicar: {
    titulo: 'Duplicar Serviço',
    input: {
      codigo: 'Código',
    },
  },
  modal_atualizar_preco: {
    titulo: 'Atualizar Preço',
    inputs: {
      percentual_atualização: 'Percentual de Atualização',
      arredondamento: 'Arredondamento',
      valores_atualizar: 'Quais Valores Atualizar?',
      valor_serviço: 'Valor do Serviço',
      valor_ponto: 'Valor por Ponto',
      valor_hora: 'Valor por Hora',
    },
  },
};
