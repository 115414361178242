import helpers from '@router/helpers';
const menuId = 'modelo-instrumento';

export default [
  helpers.criarRota(menuId, 'modelo-instrumento', menuId, () =>
    import('@/views/modulos/cadastros/modelo_instrumento')
  ),
  helpers.criarRota(
    menuId,
    'modelo-instrumento-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/modelo_instrumento/formulario'),
    false,
    false
  ),
  helpers.criarRota(
    menuId,
    'modelo-instrumento-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/modelo_instrumento/formulario'),
    true,
    false
  ),
  helpers.criarRota(
    menuId,
    'modelo-instrumento-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/modelo_instrumento/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'modelo-instrumento-pontos',
    `${menuId}/pontos/:id?`,
    () =>
      import('@/views/modulos/cadastros/modelo_instrumento/pontosCalibracoes'),
    true,
    false
  ),
];
