export default {
  titulos: {
    listagem: 'Acreditações',
    novo: 'Nova Acreditação',
    editar: 'Editar Acreditação',
  },
  cadastro_sucesso: 'Acreditação salva com sucesso!',
  exclusao_sucesso: 'Acreditação excluída com sucesso!',
  tabela: {
    codigo: 'Código',
    nomeParticipanteConta: 'Participante',
  },
  formulario: {
    codigo: 'Código',
    nomeParticipanteConta: 'Participante',
    imagem_cabecalho: 'Imagem do Cabeçalho de Certificados',
    imagem_rodape: 'Imagem do Rodapé de Certificados'
  },
  filtro: {
    codigo: 'Código',
    nomeParticipanteConta: 'Participante',
  },
  erros: {
    id_invalido: 'O id do acreditação é inválido',
  },
};
