import helpers from '@router/helpers';
const menuId = 'registro-manutencao-troca-pecas';

export default [
  helpers.criarRota(
    menuId,
    'registro-manutencao-troca-pecas',
    menuId,
    () => import('@/views/modulos/cadastros/registro_manutencao_troca_pecas')
  ),
  helpers.criarRota(
    menuId,
    'registro-manutencao-troca-pecas-instrumento',
    `${menuId}/:id?/instrumento/:codigo?`,
    () => import('@/views/modulos/cadastros/registro_manutencao_troca_pecas')
  ),
  helpers.criarRota(
    menuId,
    'registro-manutencao-troca-pecas-novo-instrumento',
    `${menuId}/novo/:instrumentoId?/instrumento/:codigo?`,
    () =>
      import(
        '@/views/modulos/cadastros/registro_manutencao_troca_pecas/formulario'
      )
  ),
  helpers.criarRota(
    menuId,
    'registro-manutencao-troca-pecas-novo',
    `${menuId}/novo`,
    () =>
      import(
        '@/views/modulos/cadastros/registro_manutencao_troca_pecas/formulario'
      )
  ),
  helpers.criarRota(
    menuId,
    'registro-manutencao-troca-pecas-editar',
    `${menuId}/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/registro_manutencao_troca_pecas/formulario'
      ),
    true
  ),
];
