import helpers from '@router/helpers';
const menuId = 'tipo-calibracao';

export default [
  helpers.criarRota(menuId, 'tipo-calibracao', menuId, () =>
    import('@/views/modulos/cadastros/tipo_calibracao')
  ),
  helpers.criarRota(
    menuId,
    'tipo-calibracao-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/tipo_calibracao/formulario'),
    false,
    false
  ),
  helpers.criarRota(
    menuId,
    'tipo-calibracao-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_calibracao/formulario'),
    true,
    false
  ),
];
