export default {
  titulos: {
    listagem: 'Áreas de Acreditações',
    novo: 'Nova Área de Acreditação',
    editar: 'Editar Área de Acreditação',
  },
  cadastro_sucesso: 'Área de acreditação salva com sucesso!',
  exclusao_sucesso: 'Área de acreditação excluída com sucesso!',
  tabela: {
    nome: 'Nome',
    descricao: 'Descrição',
  },
  formulario: {
    nome: 'Nome',
    descricao: 'Descrição',
    usuarioSignatario: 'Usuários Signatários',
    usuarioTecnicoResponsavel: 'Usuários Técnicos Executantes',
  },
  erros: {
    id_invalido: 'O id da área de acreditação é inválido',
  },
  validacoes: {
    formulario_invalido: 'O formulario deve ser preenchido corretamente!',
  },
};
