export default {
  titulos: {
    listagem: 'Registros de Informativos',
    novo: 'Novo Registro de Informativo',
    editar: 'Editar Registro Informativo',
    impressao: 'Registro Informativo',
  },
  cadastro_sucesso: 'Registro salvo com sucesso!',
  criterio_aprovacao: 'Critério de Aprovação',
  novo_criterio_aprovacao: 'Novo Critério de Aprovação',
  exclusao_sucesso: 'Registro excluído com sucesso!',
  filtro: {
    codigo: 'Código',
    data_emissao: 'Data de Emissão',
    data_ocorrencia: 'Data de Ocorrência',
    nome: 'Nome',
    nome_ocorrencia: 'Nome da Ocorrência Padrão',
    participante: 'Participante',
    ordem_servico: 'Ordem de Serviço',
    usuario_responsavel: 'Usuário Responsável',
  },
  tabela: {
    codigo: 'Código',
    data_emissao: 'Data de Emissão',
    data_ocorrencia: 'Data de Ocorrência',
    nome: 'Nome',
    participante: 'Participante',
    ordem_servico: 'Ordem de Serviço',
    usuario_responsavel: 'Usuário Responsável',
    registro_ocorrencia: 'Registro de Ocorrência',
  },
  formulario: {
    abas: {
      dados_registro_informativo: 'Dados do Registro',
      setores: 'Setores',
      instrumentos: 'Instrumentos',
    },
    codigo: 'Código',
    sequencia: 'Sequência',
    data_emissao: 'Data de Emissão',
    data_ocorrencia: 'Data de Ocorrência',
    ocorrencia_padrao: 'Ocorrência Padrão',
    ocorrencia: 'Ocorrência',
    texto: 'Texto',
    nome: 'Nome',
    participante: 'Participante',
    ordem_servico: 'Ordem de Serviço',
    usuario_responsavel: 'Usuário Responsável',
    registro: 'Registro',
    acao_corretiva: 'Ação Corretiva',
    dias_coleta: 'Dias de Coleta',
    apelido: 'Apelido',
    tipo_pessoa: 'Tipo',
    setores: {
      titulo_modal_novo: 'Setores',
      titulo_modal_editar: 'Setores',
      botoes: {
        novo: 'Selecionar Setores',
      },
      nome: 'Nome',
      tipo: 'Tipo',
      posicao: 'Posição',
      sigla: 'Sigla',
    },
  },
  erros: {
    id_invalido: 'O id do Registro é invalido',
    setores_preencher: 'Cadastre um Setor',
    setores_selecione: 'Selecione um Setor',
    instrumentos_selecione: 'Selecione um Instrumento',
    instrumentos_preencher: 'Cadastre um Instrumento',
    data_ocorrencia_maior_data_emissao:
      'Data de Ocorrência não pode ser maior que a Data de Emissão',
  },
  validacoes: {
    formulario_invalido: 'Por favor, verifique os campos!',
  },
};
