export default {
  titulos: {
    listagem: 'Colaboradores',
    novo: 'Novo Colaborador',
    editar: 'Editar Colaborador',
    ausencia: 'Ausências do Colaborador',
  },
  botoes: {
    ausencia: 'Ausências',
    nova_ausencia: 'Nova Ausência do Colaborador',
  },
  cadastro_sucesso: 'Colaborador salva com sucesso!',
  cadastro_ausencia_sucesso: 'Ausência salva com sucesso!',
  exclusao_sucesso: 'Colaborador excluida com sucesso!',
  exclusao_ausencia_sucesso: 'Ausência excluida com sucesso!',
  tabela: {
    nome: 'Nome',
    setor: 'Setor',
    cpf: 'CPF',
    data_nascimento: 'Data Nascimento',
    email_corporativo: 'Email Corporativo',
    assinatura: 'Assinatura',
    usuario_sistema: 'Usuário do Sistema',
    data_inicial: 'Data Inicial',
    data_final: 'Data Final',
    possuiAtestado: 'Tem Atestado',
    motivo: 'Motivo',
    tipo_documento: 'Tipo de Documento',
    valor_documento: 'Valor',
    validade_documento:"Validade"
  },
  formulario: {
    nome: 'Nome',
    cpf: 'CPF',
    data_nascimento: 'Data Nascimento',
    genero: 'Gênero',
    email_corporativo: 'Email Corporativo',
    cargo: 'Cargo',
    setor: 'Setor',
    assinatura: 'Assinatura',
    horario_inicio: 'Horário Início do Trabalho',
    horario_termino: 'Horário Término do Trabalho',
    horario_inicio_intervalo: 'Horário Início do Intervalo',
    horario_termino_intervalo: 'Horário Término do Intervalo',
    usuario_sistema: 'Usuário do Sistema',
    data_inicial: 'Data Inicial',
    data_final: 'Data Final',
    possuiAtestado: 'Tem Atestado',
    motivo: 'Motivo',
    observacao: 'Observações',
    documento_nome:'Nome do Documento',
    documento_tipo:'Tipo de Documento',
    documento_valor: 'Valor',
    documento_validade: 'Validade',
    documento_anexo:"Anexar Documento",
    restringir_por_ip:'Restringir por IP ?',
    restringir_acesso_horario_trabalho:"Restrigir acesso a apenas o horário de trabalho?",


    },
  erros: {
    id_invalido: 'O id do procedimento é inválido',

  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
    permitido_png: 'Permitido somente imagens no formato PNG',
  },
  filtro: {
    nome: 'Nome',
    cpf: 'CPF',
    data_nascimento: 'Data Nascimento',
  },
  abas: {
    dados_colaborador: 'Dados Colaborador',
    usuario_sistema: 'Usuário Sistema',
    documentos_colaborador: 'Documentos',
  },
};
