import { BasicoApi } from '@common/api';
const EmailService = {
  listarEmailsOrcamentos: function (parametros) {
    return BasicoApi.get()('/emails/orcamentos', parametros);
  },
  listarEmailsContratos: function (parametros) {
    return BasicoApi.get()('/emails/contratos', parametros);
  },
  reenviarEmail: function (id, parametros) {
    return BasicoApi.put()(`/emails/reenviar/${id}`, parametros);
  },
};

export default EmailService;
