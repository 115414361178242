<template>
  <div
    :class="{ comScrollBar: maisDeUmItem, semScrollbBar: !maisDeUmItem }"
    :style="customStyle"
  >
    <div
      v-for="item in item.value"
      :key="item.id"
    >
      <v-chip small>
        <span>{{ item.nome }}</span>
      </v-chip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: [],
    customStyle: {
      type: String,
      default: "",
    },
  },
  computed: {
    maisDeUmItem: function () {
      return this.item.value.length > 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.semScrollbBar {
  display: flex;
  padding: 6px 0;
}
.comScrollBar {
  display: flex;
  padding: 6px 0 2px 0;
  gap: 0.25rem;
  max-width: 175px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      background-color: darkgray;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
    }
  }
}
</style>
