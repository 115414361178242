export default {
  titulos: {
    listagem: 'Definição de Equipamento para Medição',
  },
  tabela: {
    codigo_tag: 'Código Tag',
    tipo_instrumento: 'Tipo do Instrumento',
  },
  formulario: {
    grandeza_fisica: 'Grandeza Física',
    unidade_medida: 'Unidade de Medida',
    valor_nominal_medicao: 'Valor Nominal da Medição',
    tolerancia_medicao: 'Tolerância da Medição',
  },
  modal: {
    // titulo: 'Fatores de Conversão',
    // unidade_medida: 'Unidade de Medida',
    // prefixo: 'De:',
    // nome: 'Para',
    // simbolo: 'Símbolo',
    // fator: 'Fator',
  },
  // filtro: {
  //   nome: 'Nome',
  //   simbolo: 'Símbolo',
  //   grandezaFisica: 'Grandeza Física',
  // },
  validacoes: {
    formulario_invalido: 'Preencher Formulário',
  },
  erros: {
    id_invalido: 'O id da unidade de medida é inválido',
  },
};
