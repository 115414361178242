<template lang="">
  <div>
    <div class="d-flex align-end">
      <div class="row">
        <input-text
          v-model="filtro.codigo"
          :label="$t('componentes.input_certificado.tabela.codigo')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.tipo"
          :label="$t('componentes.input_certificado.tabela.tipo')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.situacaoAvaliacao"
          :label="$t('componentes.input_certificado.tabela.situacao')"
          class="col-12 col-md-4"
        />
      </div>
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      :selecionar-apenas-um="!multiplos"
      :sem-paginacao="!graficoTendencia"
    />
  </div>
</template>
<script>
import CertificadoService from '@common/services/cadastros/CertificadoService';
import helpers from '@common/utils/helpers';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    instrumentoId: { type: String, default: null },
    situacao: { type: String, default: null },
    graficoTendencia: { type: Boolean, default: false },
  },
  data() {
    return {
      filtro: {
        codigo: '',
        tipo: '',
        situacaoAvaliacao: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('componentes.input_certificado.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'nivel',
            text: this.$t('componentes.input_certificado.tabela.tipo'),
            formatter: (nivel) => {
              switch (nivel) {
                case 'Terceiro':
                  return this.$t(
                    'componentes.input_certificado.tabela.certificado_terceiro'
                  );
                case 'Interna':
                  return this.$t(
                    'componentes.input_certificado.tabela.certificado_interno'
                  );

                default:
                  return this.$t(
                    'componentes.input_certificado.tabela.certificado'
                  );
              }
            },
            sortable: false,
          },
          {
            value: 'dataCalibracao',
            text: this.$t('componentes.input_certificado.tabela.data'),
            formatter: (v) => {
              return helpers.formatarDataBr(v);
            },
            sortable: false,
          },
          {
            value: 'situacaoAvaliacao',
            text: this.$t('componentes.input_certificado.tabela.situacao'),
            formatter: (v) => {
              return helpers.SituacaoAvaliacaoEnum.find((el) => el.value == v)
                ?.text;
            },
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },

  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      const niveisArray = ['Calibracao', 'Terceiro', 'Interna'];

      let niveis = niveisArray
        .map((nivel) => {
          return `Niveis=${nivel}`;
        })
        .join('&');

      if (this.graficoTendencia) {
        if (this.instrumentoId) parametros.instrumentoId = this.instrumentoId;
        if (this.situacao) parametros.situacao = this.situacao;
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.listar(parametros, niveis)
          .then((res) => {
            this.tabela.dados = res.data.items;

            // this.tabela.dados.map((item) => {
            //   if (item.codigo == this.selecionados.map((el) => el.codigo)[0])
            //     return this.selecionados.push(item);
            // });

            this.tabela.quantidadeItens = res.data.totalItems;
            this.tabela.quantidadePaginas = res.data.totalPages;
            this.tabela.paginaAtual = paginaAtual;
            this.tabela.porPagina = porPagina;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      } else {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.buscarUltimosTresEAtualCeritifcados(
          this.instrumentoId,
          parametros
        )
          .then((res) => {
            this.tabela.dados = res.data;

            // this.tabela.dados.map((item) => {
            //   if (item.codigo == this.selecionados.map((el) => el.codigo)[0])
            //     return this.selecionados.push(item);
            // });

            this.tabela.quantidadeItens = res.data.length;
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
  },
};
</script>
