import helpers from '@router/helpers';
const menuId = 'usuario';

export default [
  helpers.criarRota(menuId, 'usuario', menuId, () =>
    import('@/views/modulos/cadastros/usuario')
  ),
  helpers.criarRota(menuId, 'usuario-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/usuario/formulario')
  ),
  helpers.criarRota(
    menuId,
    'usuario-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/usuario/formulario'),
    true
  ),
];
