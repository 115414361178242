import { BasicoApi } from '@common/api';
const TipoComentarioOrcamentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/tipocomentarioorcamento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tipocomentarioorcamento/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipocomentarioorcamento/${form.id}`, form);
    return BasicoApi.post()('/tipocomentarioorcamento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tipocomentarioorcamento/${id}`);
  },
};

export default TipoComentarioOrcamentoService;
