import helpers from '@router/helpers';
const menuId = 'verificacao-intermediaria';

export default [
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria',
    menuId,
    () => import('@/views/modulos/cadastros/verificacoes_intermediarias')
  ),
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria-instrumento',
    `${menuId}/:id?/instrumento/:codigo?`,
    () => import('@/views/modulos/cadastros/verificacoes_intermediarias')
  ),
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria-novo-instrumento',
    `${menuId}/novo/:instrumentoId?/instrumento/:codigo?`,
    () =>
    import('@/views/modulos/cadastros/verificacoes_intermediarias/formulario')
  ),
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria-novo',
    `${menuId}/novo`,
    () =>
      import('@/views/modulos/cadastros/verificacoes_intermediarias/formulario')
  ),
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria-editar',
    `${menuId}/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/verificacoes_intermediarias/formulario'
      ),
    true
  ),
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria-visualizar',
    `${menuId}/visualizar/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/verificacoes_intermediarias/formulario'
      ),
    true
  ),
  helpers.criarRota(
    menuId,
    'verificacao-intermediaria-validar',
    `${menuId}/validar/:id?`,
    () =>
      import(
        '@/views/modulos/cadastros/verificacoes_intermediarias/formulario'
      ),
    true
  ),
];
