import acreditacao from './acreditacao';
import area_acreditacao from './area_acreditacao';
import calibracao from './calibracao';
import categoria_item from './categoria_item';
import classificacao_fiscal from './classificacao_fiscal';
import participantes from './participantes';
import operacao from './operacao';
import orcamentos from './orcamentos';
import tipo_envio from './tipo_envio';
import prioridades from './prioridades';
import tipo_setor from './tipo_setor';
import tipo_item from './tipo_item';
import feriado from './feriado';
import tipo_ocorrencia from './tipo_ocorrencia';
import procedimento from './procedimento';
import procedimentos_eletronicos from './procedimentos_eletronicos';
import servico_manutencao from './servico_manutencao';
import servico_calibracao from './servico_calibracao';
import incertezas from './incertezas';
import condicao_pagamento from './condicao_pagamento';
import local_estocagem from './local_estocagem';
import local_execucao from './local_execucao';
import mensagem_orcamento from './mensagem_orcamento';
import marca from './marca';
import contrato from './contrato';
import tipo_comentario from './tipo_comentario';
import checklist from './checklist';
import setor from './setor';
import formula from './formula';
import clausula_contratual from './clausula_contratual';
import certificados from './certificados';
import tipo_instrumento from './tipo_instrumento';
import modelo_instrumento from './modelo_instrumento';
import categoria_relatorio from './categoria_relatorio';
import modelo_relatorio from './modelo_relatorio';
import campo_adicional from './campo_adicional';
import instrumento from './instrumento';
import unidade_medida from './unidade_medida';
import conversor_unidade from './conversor_unidade';
import comentario from './comentario';
import usuarios from './usuarios';
import colaborador from './colaborador';
import cargo_funcao from './cargo_funcao';
import tipo_documento from './tipo_documento';
import parametros from './parametros';
import criterio_aceitacao from './criterio_aceitacao';
import tipo_calibracao from './tipo_calibracao';
import ordem_servico from './ordem_servico';
import item from './item';
import veiculo from './veiculo';
import grandeza_fisica from './grandeza_fisica';
import embalagem from './embalagem';
import classificacao_instrumento from './classificacao_instrumento';
import escopo_servico from './escopo_servico';
import ocorrencia_padrao from './ocorrencia_padrao';
import registro_informativo from './registro_informativo';
import solicitacao_cadastro from './solicitacao_cadastro';
import registro_ocorrencia from './registro_ocorrencia';
import duvida_solicitacao_participante from './duvida_solicitacao_participante';
import registro_manutencao_troca_pecas from './registro_manutencao_troca_pecas';
import verificacao_intermediaria from './verificacao_intermediaria';
import definicao_equipamento_medicao from './definicao_equipamento_medicao';
import papel from './papel';
import envio_material from './envio_material';
import certificado_terceiro from './certificado_terceiro';
import roteiro_motivo_cancelamento from './roteiro_motivo_cancelamento'
import roteiro_envio_material from './roteiro_envio_material';
import roteiro_execucao_envio_material from './roteiro_execucao_envio_material'
import certificado_interno from './certificado_interno';
import kanban_configuracao from './kanban_configuracao'
import kanban from './kanban'
import agendamento from './agendamento';
import kanban_programacao_manual from './kanban_programacao_manual'
import kanban_em_execucao from './kanban_em_execucao'
import tipo_visita from './tipo_visita'
import template_email from './template_email'
import kanban_tipo_instrumento from './kanban_tipo_instrumento'
import endpoints from './endpoints';
import requisitos from './requisitos';

export default {
  parametros,
  instrumento,
  acreditacao,
  area_acreditacao,
  registro_manutencao_troca_pecas,
  definicao_equipamento_medicao,
  calibracao,
  categoria_item,
  classificacao_fiscal,
  ocorrencia_padrao,
  registro_informativo,
  registro_ocorrencia,
  participantes,
  operacao,
  orcamentos,
  tipo_envio,
  prioridades,
  tipo_setor,
  tipo_item,
  tipo_ocorrencia,
  procedimento,
  procedimentos_eletronicos,
  servico_manutencao,
  servico_calibracao,
  incertezas,
  feriado,
  condicao_pagamento,
  local_estocagem,
  local_execucao,
  mensagem_orcamento,
  marca,
  contrato,
  tipo_comentario,
  checklist,
  setor,
  formula,
  clausula_contratual,
  certificados,
  tipo_instrumento,
  modelo_instrumento,
  categoria_relatorio,
  modelo_relatorio,
  campo_adicional,
  unidade_medida,
  conversor_unidade,
  comentario,
  usuarios,
  colaborador,
  cargo_funcao,
  tipo_documento,
  criterio_aceitacao,
  tipo_calibracao,
  ordem_servico,
  item,
  veiculo,
  grandeza_fisica,
  embalagem,
  classificacao_instrumento,
  escopo_servico,
  solicitacao_cadastro,
  duvida_solicitacao_participante,
  verificacao_intermediaria,
  papel,
  envio_material,
  certificado_terceiro,
  roteiro_motivo_cancelamento,
  roteiro_envio_material,
  roteiro_execucao_envio_material,
  certificado_interno,
  template_email,
  kanban_configuracao,
  kanban,
  agendamento,
  kanban_programacao_manual,
  kanban_em_execucao,
  kanban_tipo_instrumento,
  tipo_visita,
  endpoints,
  requisitos
};
