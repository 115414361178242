export default {
  titulos: {
    listagem: 'Condições de Pagamentos',
    novo: 'Nova Condição de Pagamento',
    editar: 'Editar Condição de Pagamento',
  },
  cadastro_sucesso: 'Condição de Pagamento salva com sucesso!',
  exclusao_sucesso: 'Condição de pagamento excluída com sucesso!',
  tabela: {
    nome: 'Nome',
    condicao: 'Condição',
  },
  formulario: {
    nome: 'Nome',
    nome_placeholder:'Nome',
    condicao: 'Condição',
    condicao_placeholder: 'Condição',
    descricao: 'Descrição',
    descricao_placeholder: 'Descrição'
  },
  erros: {
    id_invalido: 'O id da Condição de Pagamento é invalido',
  },
  validacoes: {
    formulario_invalido: 'É necessário validar o formulário antes de salvar o registro.'
  },
  filtro: {
    nome: 'Nome',
    condicao: 'Condição',
  }
};
