import helpers from '@router/helpers';
const menuId = 'certificado-terceiro';

export default [
  helpers.criarRota(
    menuId,
    'certificado-terceiro',
    menuId,
    () => import('@/views/modulos/cadastros/certificado_terceiro')
  ),
  helpers.criarRota(
    menuId,
    'certificado-terceiro-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/certificado_terceiro/formulario')
  ),
  helpers.criarRota(
    menuId,
    'certificado-terceiro-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/certificado_terceiro/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'certificado-terceiro-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/certificado_terceiro/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'certificado-terceiro-definir-derivas',
    `${menuId}/definir-derivas/:calibracaoId?/instrumento/:instrumentoId?`,
    () => import('@/views/modulos/cadastros/certificados/definir_derivas'),
    true
  ),
];
