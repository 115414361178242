export default {
  titulos: {
    listagem: 'Certificados Internos',
    visualizar: 'Visualizar Calibração Interna',
    visualizar_certificado: 'Visualizar Calibração Interna',
    novo: 'Novo Certificado Interno',
    editar: 'Editar Calibração Interna',
    pagina: 'Calibração Interna',
  },


  tabela: {
    codigo: 'Código',
    codigo_instrumento: 'Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
    cliente: 'Cliente',
    tipo_calibracao: 'Tipo de Calibração',
    situacao: 'Situação',
    situacao_certificado: 'Situação Certificado',
    situacao_avaliacao: 'Situação Avaliação',
    ordem_servico: 'Ordem de Serviço',
    data_calibracao: 'Calibrado Em',
    usuario_signatario: 'Usuário Signatário',
    liberarClientes: 'Liberado para Cliente',
  },
  formulario: {
    abas: {
      dados: 'Dados',
      tabelas_calibracao: 'Tabelas de Calibração',
      valores: 'Valores',
      anexos: 'Anexos',
      requisitos: 'Requisitos'
    }

  },

  confirmar_voltar_edicao: "Este certificado já foi finalizado, você tem certeza que deseja voltar para o processo de edição?",
  confirmar_assinar:
    'Você tem certeza que deseja assinar o certificado selecionado?',
  confirmar_cancelar:
    'Você tem certeza que deseja cancelar a calibração selecionada?',
  cancelar_substituir: 'Você tem certeza que deseja cancelar e substituir o certificado selecionado?',
  assinado_sucesso: 'Certificado assinado com sucesso',
  cancelado_sucesso: 'Calibração cancelada com sucesso',
  tooltip: 'Seu perfil não permite executar essa ação.',
  tooltip_checklist: 'Há checklist não ok',
  checklist: {
    checklist: 'Checklist',
    opcoes: {
      ok: 'Ok',
      naoOk: 'Não Ok',
    },
  },
  requisitos: {
    requisitos_participante_calibracao:
      'Requisitos do Participante - Calibração',
    sem_requisitos: 'Não há requisitos para este tipo de calibração',
  },
  botoes: {
    certificado: 'Certificado',
    assinar_certificado: 'Assinar Certificado',
    cancelar_certificado: 'Cancelar Certificado',
    cancelar_substituir: 'Cancelar e Substituir Certificado',
    definir_derivas: 'Definir Derivas',
    avaliar: 'Avaliar Certificado',
    visualizar_calibracao: 'Visualizar Calibração',
    visualizar_certificado: 'Visualizar Certificado',
  },
  filtro: {
    codigo: 'Código',
    codigo_instrumento: 'Cód. do Instrumento',
    nome_tipo_instrumento: 'Tipo de Instrumento',
    nome_cliente: 'Cliente',
    tipo_calibracao: 'Tipo de Calibracao',
    situacao: 'Situação Certificado',
    calibrado_em: 'Calibrado Em',
    situacao_avaliacao: 'Situação Avaliação',
  },
  erros: {
    id_invalido: 'O id da prioridade é inválido',
  },
  definir_derivas: {
    alerta_faixas:
      'Existem faixas que estão sem a definição de derivas. É necessário salvar todas as faixas.',
    alerta_conformidades:
      "Para prosseguir é necessário que não haja itens 'Não Conformes.'",
    titulo: 'Definir Derivas',
    filtro: {
      grafico: 'Gráfico',
      faixa_instrumento: 'Faixa do Instrumento',
      curva_correcao: 'Curva de Correção',
      grau_polinomio: 'Grau do Polinômio',
      percentual_leituras: 'Percentual das Leituras',
    },
    salvar_sucesso: 'Derivas salvas com sucesso!',
    conformidades_sucesso: 'Conformidades salvas com sucesso!',
    indicadores_sucesso: 'Indicador salvo com sucesso!',
    finalizar_sucesso: 'Definição das derivas finalizada com sucesso!',
    confirmar_finalizar: 'Deseja finalizar a definição de derivas?',
    curva: 'Curva de correção',
    conformidade: 'Conformidade',
    indicador: 'Indicador',
    indicadores: {
      certificados: 'Certificados',
      instrumento: 'Instrumento',
      faixa: 'Faixa',
      indicadorDeriva: 'Indicador da Deriva',
      valorDeriva: 'Valor da Deriva',
      desvioPadrao: 'Desvio Padrão do Resíduo da Curva',
      maxResiduo: 'Máximo Resíduo da Curva',
      mediaResiduo: 'Média do Resíduo da Curva',
      valor: 'Valor da Deriva'
    },
    opcoes: {
      graficos: {
        erro: 'Erro',
        erro_incerteza: 'Erro + Incerteza',
        incerteza: 'Incerteza',
      },
      curva_correcao: {
        polinomio: 'Polinômio',
        percentual: 'Percentual',
        interpolacao: 'Interpolação',
        sem_correcao: 'Sem correção',
      },
      conformes: {
        conforme: 'Conforme',
        nao_conforme: 'Não conforme',
        nao_se_aplica: 'Não se aplica',
      },
    },
    formula_curva: 'Fórmula da Curva',
    tabela_certificado: {
      ultimo_certificado: 'Último Certificado {nome}',
      certificado_atual: 'Certificado Atual {nome}',
      erro: 'Erro',
      erro_corrigido: 'Erro Corrigido',
      deriva: 'Deriva',
    },
    tabela_pontos: {
      ponto: 'Ponto',
      nome: 'Nome',
    },
    grafico: {
      erro: 'Erro',
      tendencia: 'Erro corrigido',
      incerteza: 'Incerteza',
    },
    modal_conformidades: {
      titulo: 'Atenção',
      descricao:
        'Antes de salvar é necessário informar a Conformidade dos itens.',
      itens: 'Itens',
      conforme: 'Conforme',
      selecione: 'Selecione',
    },
  },
  avaliacao: {
    titulo: {
      texto: 'Avaliar Resultados',
      instrumento: 'Instrumento: {instrumento}',
      certificado: 'Certificado: {certificado}',
    },
    sucesso: 'Avaliação realizada com sucesso',
    revalidar_sucesso: 'Critérios revalidados com sucesso',
    situacao_avaliacao: 'Situação Avaliação do Certificado',
    confirmar_avaliacao: 'Confirmar Avaliação',
    confirmar_avaliacao_informativo:
      'Informe uma justificativa para confirmar a Avaliação',
    confirmar_avaliacao_justificativa: 'Justificativa',
    ocorrencia: 'Ocorrências',
    inativar_instrumento: 'Inativar Instrumento',
    revalidar_criterio: 'Revalidar Critérios',
    tooltip_salvar:
      'Tabela(as) sem critérios de aceitação: {tabelasSemCriterio}',
    tabela: {
      ponto_calibracao: 'Ponto de Calibração',
      nome: 'Nome',
      erro: 'Erro',
      incerteza: 'Incerteza',
      erro_incerteza: 'Erro + Incerteza',
      criterio: 'Critério',
      formula_validacao: 'Fórmula de Validação',
      situacao_avaliacao: 'Situação Avaliação',
      formula: 'Fórmula',
    },
  },
  grafico_tendencia: {
    titulo: 'Gráfico de Tendências',
    instrumento: 'Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
    tipo_grafico: 'Tipo de Gráfico',
    faixa_instrumento: 'Faixa do Instrumento',
    certificados: 'Certificado(s)',
    tabela_calibracao: 'Tabela de Calibração',
  },

  valores: {
    calculando: 'Realizando calculos da linha',
    titulos: {
      confirmar_instrumento_padrao: 'Editar Instrumentos Padrão',
      confirmar_limpar_instrumento_padrao: 'Limpar Instrumentos Padrão',
      variavel: 'Variável',
      valor: 'Valor',
      depuracao_modal: 'Depuração de Valores do Cálculo',
    },
    pontos_invalidos:
      'Existem pontos de calibração inválidos para o instrumento padrão associado aos mesmos',
    ponto_invalido:
      'O ponto de calibração é invalido para os instrumentos padrões associados',
    cabecalho: 'Cabeçalho',
    ponto_calibracao: 'Ponto de Calibração',
    sentido: 'Sentido',
    variavel: 'Variável',
    cadastro_sucesso: 'Valores salvos com sucesso',
    finalizado_sucesso: 'Calibração finalizada com sucesso',
    alertas: {
      confirmar_salvar_ir_proxima:
        'Deseja salvar e ir para a próxima tabela de calibração?',
      confirmar_salvar: 'Deseja salvar tabela de calibração?',
      confirmar_finalizar: 'Deseja finalizar a calibração?',
      checkbox_modal: 'Visualizar Certificado',
      confirmar_instrumento_padrao:
        'Todos os dados preenchidos na tabela serão apagados caso um novo Instrumento Padrão for definido, deseja continuar ?',
      confirmar_limpar_instrumento_padrao:
        'Deseja limpar os instrumentos padrões e retornar para os configurados originalmente na tabela de registro?',
      limpar_instrumento_sucesso:
        'Instrumentos padrões redefinidos com sucesso!',
      confirmar_limpar_smp:
        'Todos os dados de SMP preenchidos nesta tabela serão apagados, deseja continuar?',
      confirmar_limpar_smc:
        'Todos os dados de SMC preenchidos nesta tabela serão apagados, deseja continuar?',
      mensagem_instrumentos:
        'Os instrumentos abaixos estão invalidos e foram removidos da caibração:<br>',
      confirmar_proxima_tabela: 'Ir para próxima tabela?',
      confirmar_sair_sem_salvar_calibracao:
        'Existem tabelas de calibração não salvas. Deseja realmente sair sem salva-las?',
    },
  }
};
