<template>
  <div>
    <v-tooltip right content-class="tooltip-right">
      <template v-slot:activator="{ on }">
        <div v-on="tooltip ? on : null">
          <v-list-item :disabled="disabled" class="d-flex" @click="emitirClick">
            <slot />
          </v-list-item>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-divider v-if="divider" />
  </div>
</template>
<script>
export default {
  props: {
    tooltip: { default: '' },
    disabled: { type: Boolean, default: false },
    divider: { type: Boolean, default: true },
  },
  methods: {
    emitirClick: function () {
      this.$emit('click');
    },
  },
};
</script>
