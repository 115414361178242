import helpers from '@router/helpers';
const menuId = 'area-acreditacao';

export default [
  helpers.criarRota(menuId, 'area-acreditacao', menuId, () =>
    import('@/views/modulos/cadastros/area_acreditacao')
  ),
  helpers.criarRota(
    menuId,
    'area-acreditacao-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/area_acreditacao/formulario')
  ),
  helpers.criarRota(
    menuId,
    'area-acreditacao-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/area_acreditacao/formulario'),
    true
  ),
];
