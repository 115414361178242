import helpers from '@router/helpers';
const menuId = 'tipo-comentario';

export default [
  helpers.criarRota(menuId, 'tipo-comentario', menuId, () =>
    import('@/views/modulos/cadastros/tipo_comentario')
  ),
  helpers.criarRota(
    menuId,
    'tipo-comentario-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/tipo_comentario/formulario')
  ),
  helpers.criarRota(
    menuId,
    'tipo-comentario-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/tipo_comentario/formulario'),
    true
  ),
];
