import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumCampoAdicionalEdicaoValor.ModeloInstrumento'),
    value: 'ModeloInstrumento',
  },
  {
    text: i18n.t('enums.EnumCampoAdicionalEdicaoValor.SomenteModelo'),
    value: 'SomenteModelo',
  },
];
