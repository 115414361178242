export default {
  baseOperacaoTable: {
    html: {
      title: 'Listando contato'
    },
    table: {
      tableName: 'Operações',
      mainHeaders: {
        codigo: 'Número da OS',
        cliente: 'Cliente',
        status: 'Status',
        quantidade: 'Qtd Itens'
      },
      headers: {
        codigoInstrumento: 'Cod. do Instrumento',
        situacao: 'Situação',
        tipoInstrumento: 'Tipo de Instrumento',
        setor: 'Setor',
        faixas: 'Faixas'
      },
      slots: {
        loading: 'Carregando...',
        noData: 'Nenhum registro disponível'
      }
    }
  }
}
