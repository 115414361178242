<template lang="">
  <div>
    <div class="d-flex flex-column">
      <form class="row">
        <input-select
          v-model="participanteId"
          class="col-12 col-md-6"
          :options="opcoes.participantes"
          :label="$t('componentes.input_instrumento_padrao.participante')"
        />
        <input-radio
          v-model="formulaVariavelId"
          class="col-12"
          :label="$t('componentes.input_instrumento_padrao.variavel')"
          :options="opcoes.variaveis"
        />
        <accordion-padrao
          v-if="!!formulaVariavelId && !!participanteId"
          class="col-12"
          nao-retrair
          :titulo="$t('modulos.calibracao.valores.modal.instrumento_atual')"
        >
          <div
            v-if="instrumentoSelecionado"
            class="primary-grey-50 pa-3"
          >
            <span class="w600">{{ instrumentoSelecionado.codigo }}</span>
            <span class="w400 ml-3">
              {{ instrumentoSelecionado.tipoInstrumento.nome }}
            </span>
            <span class="w400 ml-1">
              -
              {{
                formatarFaixas([instrumentoSelecionado.faixaSelecionada])[0]
                  .text
              }}
            </span>
          </div>
        </accordion-padrao>
      </form>
      <!-- <div
        v-if="!!formulaVariavelId && !!participanteId"
        class="row align-end mb-4 mx-1"
      >
        <div class="row align-center">
          <input-text
            v-model="filtro.codigo"
            :label="$t('componentes.input_instrumento_padrao.codigo')"
            class="col-12 col-md-4"
          />
          <input-text
            v-model="filtro.tipoInstrumento"
            :label="$t('componentes.input_instrumento_padrao.nome')"
            class="col-12 col-md-4"
          />
          <input-text
            v-model="filtro.setor"
            :label="$t('componentes.input_instrumento_padrao.setor')"
            class="col-12 col-md-4"
          />
        </div>
        <botao-padrao
          outlined
          color="secondary"
          class="ml-2"
          @click="filtrar"
        >
          {{ $t('geral.botoes.filtrar') }}
        </botao-padrao>
      </div> -->
    </div>
    <tabela-padrao-prime-vue
      v-if="!!formulaVariavelId && !!participanteId"
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      style="min-height: 300px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      linha-hover
      filtros-com-colunas
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="true"
      ajustar-altura-linha
      @paginar="listar"
      @filtrarGlobal="filtrar"
      @filtroGlobal="(v) => (filtro.filter = v)"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
    >
      <template v-slot:faixas="{ slotProps }">
        <div>
          <input-select
            v-model="slotProps.data.faixaSelecionada"
            :options="formatarFaixas(slotProps.data.faixas)"
            sem-label
            class="input-tabela my-1"
            placeholder="Selecione"
            @change="selecionarInstrumento(slotProps.data)"
          />
        </div>
      </template>
    </tabela-padrao-prime-vue>

    <!-- <tabela-padrao
      v-if="!!formulaVariavelId && !!participanteId"
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      selecionar-apenas-um
      @paginar="listar"
    >
      <template v-slot:item.faixas="{ item }">
        <div>
          <input-select
            v-model="item.item.faixaSelecionada"
            :options="formatarFaixas(item.value)"
            sem-label
            class="input-tabela my-1"
            placeholder="Selecione"
            @change="selecionarInstrumento(item.item)"
          />
        </div>
      </template>
    </tabela-padrao> -->
  </div>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import FormulaService from '@common/services/cadastros/FormulaService';
import _ from 'lodash';
import helpers from '@common/utils/helpers';

export default {
  props: {
    value: { type: Array, default: () => [] },
    instrumentos: { type: Array, default: () => [] },
    unidadeMedidaId: { type: String, default: null },
  },
  data() {
    return {
      participanteId: null,
      formulaVariavelId: null,
      form: null,
      instrumentoSelecionado: null,
      grandezaFisicaId: null,

      filtro: {},
      opcoes: {
        participantes: [],
        variaveis: [],
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('componentes.input_instrumento_padrao.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('componentes.input_instrumento_padrao.tabela.tipo'),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t('componentes.input_instrumento_padrao.tabela.setor'),
            sortable: false,
          },
          {
            value: 'faixas',
            text: this.$t('componentes.input_instrumento_padrao.tabela.faixas'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    selecionados: {
      handler() {
        this.emitirSelecionado();
      },
    },

    participanteId: function () {
      this.buscarInstrumentoSelecionado();
    },
    formulaVariavelId: function () {
      this.buscarInstrumentoSelecionado();
    },
  },
  mounted() {
    this.listarParticipantes();
  },
  methods: {
    emitirSelecionado: function () {
      if (!this.participanteId || !this.formulaVariavelId) return;
      if (!Array.isArray(this.selecionados))
        this.selecionados = [this.selecionados];

      let selecionado = null;
      if (this.selecionados.length > 0)
        selecionado = this.selecionados.map((el) => {
          return {
            id: el.id,
            codigo: el.codigo,
            tipoInstrumento: el.tipoInstrumento,
            faixaSelecionada: el.faixas.find(
              (f) => f.id == el.faixaSelecionada
            ),
          };
        })[0];

      let variavel = this.opcoes.variaveis.find(
        (el) => el.value == this.formulaVariavelId
      );

      this.form = {
        value: helpers.gerarNumeroAleatorio(),
        text:
          selecionado != null
            ? `${variavel.simbolo} = ${selecionado?.codigo} - ${selecionado?.faixaSelecionada?.nome}`
            : '',
        participanteId: this.participanteId,
        formulaVariavel: variavel,
        instrumentoPadrao: selecionado,
      };

      this.$emit('input', this.selecionados);
    },
    selecionarInstrumento: function (item) {
      let ctx = this;
      setTimeout(function () {
        ctx.selecionados = [item];
        ctx.emitirSelecionado();
      }, 500);
    },
    getForm: function () {
      return this.form;
    },
    formatarFaixas: function (faixas) {
      return faixas.map((el) => {
        return {
          value: el.id,
          text: el.nome,
        };
      });
    },
    inicializarModal: function () {
      this.participanteId =
        this.$store.getters['Autenticacao/participanteSelecionado'];
      this.formulaVariavelId = null;
      this.filtro = {};
      this.form = null;
      this.instrumentoSelecionado = null;
      this.selecionados = [];
      this.tabela.dados = [];
    },
    buscarInstrumentoSelecionado: function () {
      if (!this.participanteId || !this.formulaVariavelId) return '';

      this.grandezaFisicaId = this.opcoes.variaveis.find(
        (el) => el.value == this.formulaVariavelId
      )?.grandezaFisicaId;
      this.listar();

      this.instrumentoSelecionado = this.instrumentos.find(
        (el) =>
          el.participanteId == this.participanteId &&
          el.formulaVariavel.value == this.formulaVariavelId
      )?.instrumentoPadrao;

      if (!this.instrumentoSelecionado) return;

      this.selecionados = [];
      this.$emit('input', this.selecionados);
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listarParticipantes: function () {
      let { participantes } = this.$store.getters['Autenticacao/usuario'];
      this.opcoes.participantes = participantes;
    },
    listarVariaveis: function (id) {
      FormulaService.variaveis(id).then((response) => {
        this.opcoes.variaveis = response.data.map((el) => {
          return {
            value: el.id,
            text: `${el.simbolo} - ${el.unidadeMedida.nome}`,
            simbolo: `${el.simbolo}`,
            grandezaFisicaId: el.grandezaFisicaId,
          };
        });
      });
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ParticipanteId: this.participanteId,
        ...this.filtroAplicado,
      };
      if (this.grandezaFisicaId)
        parametros.FaixaGrandezaFisicaId = this.grandezaFisicaId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.selecionados = [];
      InstrumentoService.listarPadrao(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items.map((el) => {
            el.faixas = [...el.instrumentoFaixas];
            el.faixaSelecionada =
              el.faixas.length == 1 ? el.faixas[0].id : null;
            return el;
          });
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
