<template>
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo d-flex"
    >
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio && !semLabel"
        class="obrigatorio ml-1"
      >*</span>
    </span>

    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :transition="transition"
      offset-y
      max-width="290px"
      min-width="auto"
      :nudge-right="nudge"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="conteudo"
          v-mask="mask"
          :label="labelEhDentro ? labelComputada : ''"
          :placeholder="placeholder"
          :dense="dense"
          :rules="regras"
          :disabled="disabled"
          :background-color="backgroundColor"
          :clearable="podeLimparData"
          outlined
          hide-details="auto"
          :append-icon="disabled ? '' : '$calendar'"
          v-bind="attrs"
          readonly
          @click:append="menu = true"
          @blur="data = parseDate(conteudo)"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="data"
        :min="
          apenasDatasFuturas
            ? new Date().toISOString().slice(0, 10)
            : apenasDatasFuturasSemHoje
              ? semDataDeHoje
              : dataMinima
                ? dataMinima
                : null
        "
        :max="
          apenasDatasPassadas ? new Date().toISOString().slice(0, 10) : null
        "
        :allowed-dates="filterAllowedDates"
        locale="pt-BR"
        no-title
        :type="type"
        @input="menu = false"
      />
    </v-menu>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
import moment from 'moment';
export default {
  name: 'InputDate',
  mixins: [InputMixin],
  props: {
    type: { type: String, default: 'date' },
    mask: { type: String, default: null },
    nudge: { type: String, default: '' },
    transition: { type: String, default: 'scale-transition' },
    apenasDatasFuturas: { type: Boolean, default: false },
    apenasDatasPassadas: { type: Boolean, default: false },
    apenasDatasFuturasSemHoje: { type: Boolean, default: false },
    semLabel: { type: Boolean, default: false },
    allowedDays: { type: Array, default: () => [], required: false },
    dataReferencia: { type: String, default: null },
    mensagemErro: { type: String, default: 'A data selecionada não é válida.' },
    dataMinima: { type: String, default: null },
    podeLimparData: { type: Boolean, default: false },
    /** Props de validação */
  },
  data() {
    return {
      conteudo: '',
      menu: false,
      data: null,
      semDataDeHoje: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .slice(0, 10),
    };
  },
  computed: {
    regras() {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      else if (regras.includes(this.validacaoObrigatorio)) {
        regras.splice(regras.indexOf(this.validacaoObrigatorio), 1);
      }
      if (this.apenasDatasPassadas) regras.push(this.validacaoDatasPassadas);
      regras.push(this.validacaoCustomizada);
      return regras;
    },
    filterAllowedDates() {
      if (!this.allowedDays || this.allowedDays.length === 0) {
        return null;
      }
      return (date) => {
        var dataFormatada = new Date(date);
        const dayOfWeek = dataFormatada.getDay();
        return this.allowedDays.includes(dayOfWeek);
      };
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          if (this.type == 'month') return;
          this.conteudo = moment(value).format('DD/MM/YYYY');
        } else {
          this.conteudo = '';
        }
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      if (this.type == 'month') {
        this.$emit('input', moment(conteudo, 'MM/YYYY').format('YYYY-MM'));

        return;
      }
      if (conteudo?.length == 10) {
        this.$emit(
          'input',
          moment(conteudo, 'DD/MM/YYYY').format('YYYY-MM-DDT12:mm:ss.SSS')
        );
      } else {
        this.$emit('input', '');
      }
    },
    data() {
      this.conteudo = this.formatarData(this.data);
    },
  },
  methods: {
    validacaoCustomizada: function (conteudo) {
      if (this.conteudo) {
        const [day, month, year] = conteudo.split('/');
        const dataSelecionada = new Date();
        const dataReferencia = new Date(this.dataReferencia);
        dataSelecionada.setFullYear(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(day, 10)
        );

        if (dataSelecionada < dataReferencia) return this.mensagemErro;
      }

      return true;
    },

    validacaoDatasPassadas: function (conteudo) {
      if (!conteudo) return true;
      const [day, month, year] = conteudo.split('/');
      let data = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      if (new Date(data) > new Date())
        return this.$t('geral.validacao.data_futura');
      return true;
    },
    formatarData(date) {
      if (!date) return null;

      if (this.type == 'month') {
        const [year, month] = date.split('-');
        return `${month}/${year}`;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      if (this.type == 'month') {
        const [month, year] = date.split('/');
        return `${year}-${month.padStart(2, '0')}`;
      }

      const [day, month, year] = date.split('/');
      let data = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      if (this.apenasDatasPassadas && new Date(data) > new Date()) return '';
      return data;
    },
    allowedDates() {
      if (!this.diasSemana || this.diasSemana.length === 0) {
        return true;
      }
      return (date) => {
        const dayOfWeek = date.getDay();
        return this.diasSemana.includes(dayOfWeek);
      };
    },
  },
};
</script>
