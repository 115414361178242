export default {
  titulos: {
    listagem: 'Classificações Fiscais',
    novo: 'Nova Classificação Fiscal',
    editar: 'Editar Classificação Fiscal',
  },
  cadastro_sucesso: 'Classificação fiscal salva com sucesso!',
  exclusao_sucesso: 'Classificação fiscal excluída com sucesso!',
  tabela: {
    codigo: 'Código',
    aliquotaIpi: 'Alíquota de IPI (%)',
    descricao: 'Descrição',
  },
  formulario: {
    codigo: 'Código',
    aliquotaIpi: 'Alíquota de IPI (%)',
    descricao: 'Descrição',
    descricao_completa: 'Descrição Completa',
  },
  filtro: {
    codigo: 'Código',
    aliquotaIpi: 'Alíquota de IPI (%)',
    descricao: 'Descrição',
    descricaoComplementar: 'Descrição Complementar',
  },
  erros: {
    id_invalido: 'O id da classificação fiscal é inválido',
  },
  validacoes: {
    formulario_invalido: 'Por favor, verifique os campos!',
  },
};
