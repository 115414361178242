import { AuthApi } from '@common/api';
import store from '@store';
const AutenticacaoService = {
  buscar: function (id) {
    return AuthApi.get()(`/usuarios/${id}`);
  },
  autenticar: function (form) {
    return AuthApi.post()('/login', form, false);
  },
   refreshToken: function () {
    return AuthApi.post()('/refreshToken', {
      refreshToken: store.getters['Autenticacao/refreshToken'],
    });
  },
};

export default AutenticacaoService;
