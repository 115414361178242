import { AuthApi, BasicoApi, CalculosApi } from '@common/api';
const RelatorioService = {
  listar: function (parametros) {
    return BasicoApi.get()('/relatorio', parametros);
  },
  buscar: function (relatorioId, codigoRelatorio, sistema) {
    let link = `/relatorio/${
      relatorioId ? relatorioId : '00000000-0000-0000-0000-000000000000'
    }?1=1`;
    if (codigoRelatorio) link += `&codigo=${codigoRelatorio}`;
    if (sistema) link += `&sistema=${sistema}`;
    return BasicoApi.get()(link);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/relatorio/${form.id}`, form.request);
    return BasicoApi.post()('/relatorio', form.request);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/relatorio/${id}`);
  },
  buscarDatasource: function (api, verboHttp, { url, form }) {
    let requestApi = null;
    switch (api) {
      case 'BasicRecords':
        requestApi = BasicoApi;
        break;
      case 'Calculation':
        requestApi = CalculosApi;
        break;
      case 'Admin':
        requestApi = AuthApi;
        break;
    }
    switch (verboHttp) {
      case 'Get':
        return requestApi.get()(url);
      case 'Post':
        return requestApi.post()(url, form);
    }
  },
};

export default RelatorioService;
