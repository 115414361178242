export default {
  titulos: {
    exerucaoRoteiro: 'Execução do Roteiro',
    iniciarRoteiro: 'Iniciar Execução do Roteiro {data}',
    checkin: 'Check-in',
    entrega: 'Entrega {codigo}',
    recebimento: 'Recebimento {codigo}',
    reagendamento: 'Reagendamento',
    recebimentoSemAgendamento: 'Recebimento sem Agendamento',
    cancelamento: 'Cancelamento',
    EntregaRecebimento: 'Entrega e Recebimento',
    finalizar: 'Finalizar Execução do Roteiro {data}',
    roteiro: 'Roteiro {data}',


  },
  mensagem: {
    recebimentoSemAgendamentoSucesso: 'Recebimento Sem Agendamento realizado com sucesso',
    checkinSucesso: 'Check-in realizado com sucesso',
    checkoutSucesso: 'Check-out realizado com sucesso',
    checkOutCancelamento: 'Check-out por Cancelamento realizado com sucesso',
    checkOutReagendamento: 'Check-out por Reagendamento realizado com sucesso',
    kmchegadaNaoPodeSerMenor: 'Km de Chegada não pode ser menor que Km de Saída: {kmSaida}',
    naoPodeDecimal: 'Não pode ser decimal',
    preenchaTodosCampos: 'Preencha todos os campos obrigatórios do formulário',
    selecioneTodos: 'Selecione todos os itens do checklist ou escreva uma observação',
    naoTemKmChegada: 'Não foi informado o km de chegada do veículo no ultimo roteiro',
    QRLidoSucesso: 'QR Code lido com sucesso',
    QRInvalido: 'Não foi possível ler o QR Code',
  },

  formulario: {
    veiculo: 'Veículo',
    kmSaida: 'Km de Saída',
    kmChegada: 'Km de Chegada',
    observacao: 'Observação',
    checklistVeiculo: 'Checklist do Veículo',
    checklistMensagem: 'Selecione para indicar que os itens estão OK',
    tabela: {
      selecionarTodos: 'Selecionar Todos',
      nivelOleo: 'Nível do Óleo',
      aguaRadiador: 'Água do Radiador',
      aguaParabrisa: 'Água do Parabrisa',
      freiosPneus: 'Freios e Pneus',
      faroisLuzes:
        'Faróis e Luzes',
    }
  },
  botoes: {
    iniciar: 'Iniciar',
    checkin: 'Check-in',
    visualizarRoteiro: 'Visualizar Roteiro',
    desfazerCheckin: 'Desfazer Check-in',
    checkOut: 'Check-out',
    checkOutReagendamento: 'Check-Out por Reagendamento',
    checkOutCancelamento: 'Check-Out por Cancelamento',
    checkOutSemAgendamento: 'Receber sem Agendamento',
    escaneie_qrcode: 'Escaneie o QR Code',
    visualizarInstrumentos: 'Visualizar Instrumentos',
    voltar_para_roteiro: 'Voltar para Roteiro de Envio de Material'
  },


  checkin: {
    cliente: 'Cliente:',
    endereco: 'Endereço:',
    qtdEntregas: 'Qtd. Entregas:',
    qtdRecebimentos: 'Qtd. Recebimentos:',
  },

  entregaRecebimento: {
    codigo: 'Código',
    volume: 'Volume',
    situacao: 'Situação',
    formulario: {
      contato: 'Contato do Atendimento',
      cliente: 'Cliente',
      observacao: 'Observação'
    },
    modalInstrumento :{
      codigo: 'Código',
      tipoInstrumento: 'Tipo de Instrumento'
    }
  },

  recebimentoSemAgendamento: {
    cliente: 'Cliente',
    contato: 'Contato do Solicitante',
    qtdVolumes: 'Qtd. Volumes',
  },

  reagendamentoOuCancelamento: {
    dataAgendamento: 'Data do Agendamento',
    motivoCancelamento: 'Motivo do Cancelamento',
    contato: 'Contato do Atendimento',
    observacao: 'Observação',
    envioMaterial: 'Envio de Material',
    cliente: 'Cliente',
    endereco: 'Endereço',
    codigo: 'Código',
    dataSolicitacao: 'Data da Solicitação',
    volume: 'Volume(s)',
  },


  modal: {
    titulo: 'Novo Contato de Atendimento',
    instrumento: "Visualizar Instrumentos",
    nome: 'Nome',
    telefone: 'Telefone',
    email: 'E-mail',
    setor: 'Setor',
    cargo: 'Cargo',
    responsabilidades: 'Responsabilidades',
    voltar: 'Voltar'
  },

  popup: {
    desfazerCheckin: 'Deseja realmente desfazer o Check-in do cliente?'
  },

  visualizar: {
    cliente: 'Cliente',
    situacao: 'Situação',
    visualizar: 'Visualizar',
    checkIn: 'Check-in',
  }

};
