export const MascarasMixin = {
  methods: {
    mascaraDocumento: function (campo) {
      if (!campo) return '###.###.###-##';
      if (campo.length > 14) return '##.###.###/####-##';
      return '###.###.###-##';
    },
    mascaraTelefone: function (campo) {
      if (!campo) return '(##) ####-####';
      if (campo.length > 14) return '(##) #####-####';
      return '(##) ####-####';
    }
  },
};
