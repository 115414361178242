import { CalculosApi } from '@common/api';
const ProcedimentoEletronicoService = {
  listar: function (parametros) {
    return CalculosApi.get()('/ProcedimentoEletronico', parametros);
  },
  buscar: function (id) {
    return CalculosApi.get()(`/ProcedimentoEletronico/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return CalculosApi.put()(
        `/ProcedimentoEletronico/${form.id}`,
        form.request
      );
    return CalculosApi.post()('/ProcedimentoEletronico', form.request);
  },
  excluir: function (id) {
    return CalculosApi.delete()(`/ProcedimentoEletronico/${id}`);
  },
  publicar: function (id) {
    return CalculosApi.put()(`/ProcedimentoEletronico/${id}/publicar`);
  },

  /** Revisões */
  listarRevisoes: function (id) {
    return CalculosApi.get()(`/ProcedimentoEletronico/${id}/revisoes`);
  },
  buscarPorRevisao: function (id) {
    return CalculosApi.get()(`/ProcedimentoEletronico/revisoes/${id}`);
  },
  excluirRevisao: function (id) {
    return CalculosApi.delete()(`/ProcedimentoEletronico/revisoes/${id}`);
  },
  criarRevisao: function (id) {
    return CalculosApi.put()(`/ProcedimentoEletronico/${id}/revisar`);
  },

  /** Tabela de registro */
  listarTabelas: function (id) {
    return CalculosApi.get()(`/ProcedimentoEletronico/revisoes/${id}/tabelas`);
  },
  listarTabelasAuxiliares: function (id) {
    return CalculosApi.get()(`/ProcedimentoEletronico/revisoes/${id}/tabelas?flagTabelaAuxiliar=true`);
  },
  buscarTabelaRegistro: function (id) {
    return CalculosApi.get()(`/ProcedimentoEletronico/revisoes/tabela/${id}`);
  },
  salvarTabelaRegistro: function (form, id) {
    if (form.id)
      return CalculosApi.put()(
        `/ProcedimentoEletronico/revisoes/tabelas/${form.id}`,
        form.request
      );
    return CalculosApi.post()(
      `/ProcedimentoEletronico/${id}/revisoes/tabelas`,
      form.request
    );
  },
  excluirTabelaRegistro: function (id) {
    return CalculosApi.delete()(
      `/ProcedimentoEletronico/revisoes/tabelas/${id}`
    );
  },
  salvarOrdenacaoTabelaRegistro: function (id, tabela) {
    return CalculosApi.patch()(
      `/ProcedimentoEletronico/revisoes/${id}/tabelas/sequencia`,
      {
        tabelasIds: tabela.map((el) => {
          return el.id;
        }),
      }
    );
  },
  buscarTabelasCalibracao: function (id) {
    return CalculosApi.get()(
      `/ProcedimentoEletronico/revisoes/tabelas/calibracao/${id}`
    );
  },

  /*** Certificado */
  buscarCertificado: function (id, mostrarErro = true) {
    return CalculosApi.get()(
      `/ProcedimentoEletronico/${id}/desenho-certificado`,
      undefined,
      mostrarErro
    );
  },
  buscarDataSource: function (id) {
    return CalculosApi.get()(
      // `/ProcedimentoEletronico/${id}/desenho-certificado-dados-template`
      `/ProcedimentoelEtronico/revisoes/${id}/desenho-certificado-dados-template` //Verificar com o Vini
    );
  },
  buscarCertificadoPorRevisao: function (id) {
    return CalculosApi.get()(
      `/ProcedimentoEletronico/revisoes/${id}/desenho-certificado`
    );
  },
  atualizarCertificado: function (id, certificado) {
    return CalculosApi.put()(
      `/ProcedimentoEletronico/${id}/desenho-certificado`,
      { certificado: certificado }
    );
  },
  duplicarTabelaRegistro: function (id) {
    return CalculosApi.post()(`/ProcedimentoEletronico/tabela/duplicar/${id}`);
  },
  duplicar: function (id) {
    return CalculosApi.post()(`/ProcedimentoEletronico/duplicar/${id}`);
  },
  copiarProcedimento : function (procedimentoId, clienteId) {
    return CalculosApi.post()(`/ProcedimentoEletronico/copiar-para-cliente`, {
      procedimentoEletronicoId: procedimentoId,
      participanteId: clienteId
    });
  },

  associarUsuarios: function (id, payload) {
    return CalculosApi.patch()(
      `/ProcedimentoEletronico/${id}/associar-usuarios`,
      payload
    );
  },
};

export default ProcedimentoEletronicoService;
