<template lang="">
  <div
    class="input-padrao input-modal"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo d-flex align-center"
    >
      <span class="label">{{ label }}</span>
      <span v-if="obrigatorio" class="obrigatorio ml-1">*</span>
      <span>
        <slot name="depois-label" />
      </span>
      <v-tooltip v-if="!!toolTipInformacao" top content-class="tooltip-top">
        <template v-slot:activator="{ on }">
          <v-icon class="ml-2" size="20" v-on="on"> $infoCircleOutline </v-icon>
        </template>
        <span> {{ toolTipInformacao }} </span>
      </v-tooltip>
    </span>

    <div :class="{ 'd-flex': comBotaoAdicionar }">
      <v-select
        v-model="conteudo"
        append-icon="$search"
        :label="labelEhDentro ? labelComputada : ''"
        :style="estiloBotaoAdicionar ? 'border-radius: 8px 0px 0px 8px;' : ''"
        :items="items"
        :placeholder="placeholder"
        :dense="dense"
        :rules="regras"
        :obrigatorio="obrigatorio"
        :disabled="disabled"
        :multiple="multiplos"
        :return-object="retornarObjeto"
        hide-details="auto"
        no-data-text=""
        :menu-props="{ contentClass: 'menu-oculto' }"
        :outlined="outlined"
        :flat="flat"
        :solo="solo"
        :error="error"
        :error-messages="errorMessages"
        :background-color="backgroundColor"
        :loading="loading"
        @click="abrirModal"
        :prefix="prefixo"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip
            v-if="multiplos"
            close
            label
            outlined
            color="cinza200"
            close-icon="$closeBoxOutline"
            @click:close="removerOpcaoPrevent(index)"
            @click="abrirModal"
          >
            <span>{{ textoOpcao(item) }}</span>
          </v-chip>
          <span v-if="!multiplos">{{ textoOpcao(item) }}</span>
        </template>
      </v-select>
      <div>
        <slot v-if="comBotaoAdicionar" name="botaoAdicionar" />
      </div>
    </div>
    <slot name="modal">
      <modal-padrao
        ref="modal-input"
        :max-width="modalWidth"
        :titulo="tituloModal"
        :titulo-ok="tituloModalOk"
        :titulo-cancelar="tituloModalCancelar"
      >
        <slot />
      </modal-padrao>
    </slot>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  name: 'InputModal',
  mixins: [InputMixin],
  props: {
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    modalWidth: { type: Number },
    tituloModal: { type: String },
    estiloBotaoAdicionar: { type: Boolean, default: false },
    tituloModalOk: { type: String },
    tituloModalCancelar: { type: String },
    error: { type: Boolean, default: false },
    errorMessages: {},
    obrigatorio: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    preventAcoes: { type: Boolean, default: false },
    comBotaoAdicionar: { type: Boolean, default: false },
    toolTipInformacao: { type: String, default: '' },
    prefixo: { type: String, default: '' },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      else if (regras.includes(this.validacaoObrigatorio)) {
        regras.splice(regras.indexOf(this.validacaoObrigatorio), 1);
      }

      return regras;
    },
    items: function () {
      if (!Array.isArray(this.conteudo)) return [this.conteudo];
      return this.conteudo;
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value == null && this.conteudo == null) return 
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    validacaoObrigatorio: function () {
      if (this.multiplos)
        return this.conteudo?.length > 0 || this.$t('inputs.rules.required');
      if (!this.multiplos)
        return !!this.conteudo || this.$t('inputs.rules.required');
    },
    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcaoPrevent: function (indice) {
      if (this.preventAcoes) {
        this.$emit('remover-opcao', indice);
        return;
      }

      this.removerOpcao(indice);
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function (e) {
      e.stopPropagation();
      this.$emit('abrir-modal');

      if (this.preventAcoes) return;

      if (this.$refs['modal-input']) this.$refs['modal-input'].abrirModal();
    },
  },
};
</script>
<style lang="scss">
.menu-oculto {
  display: none !important;
}
</style>
