export default {
  solicitacoesCadastros: {
    SolicitacoesCadastrosBodyPage:{
      html: {
        title: "Solicitações de cadastros"
      }
    },
    CreateSolicitacoesCadastrosPage:{
      title: "Nova solicitação de cadastros",
      html: {
        title: "Criar uma nova solicitação de cadastros"
      }
    },
    EditSolicitacoesCadastrosPage:{
      title: "Editar solicitação de cadastros",
      html: {
        title: "Editar solicitação de cadastros"
      }
    },
    ShowSolicitacoesCadastrosPage:{
      html: {
        title: "Listando solicitações de cadastros"
      },
      baseTable: {
        tableName: "Solicitações de cadastros",
        newItemButtonText: "Nova solicitação de cadastros",
        headers: {
          tipoCadastro: "Tipo de cadastro",
          texto: "Texto",
          usuarioSolicitante: "Usuário solicitante",
          dataSolicitacao: "Data de solicitação",
          status: "Status"
        }
      }
    },
    inputs: {
      tipoCadastro: {
        text: "Tipo de cadastro*"
      },
      texto: {
        text: "Texto*",
        placeholder: "Texto da solicitação de cadastros"
      },
      dataSolicitacao: {
        text: "Data da solicitação"
      },
      status: {
        text: "Status"
      },
      observacao: {
        text: "Observações",
        placeholder: "Observações da solicitação de cadastros"
      }
    },
    alerts: {
      createSuccess: "Solicitação de cadastros criado com sucesso",
      createError: "Falha ao criar nova solicitação de cadastros. Tente novamente!",
      editSuccess: "Solicitação de cadastros editado com sucesso",
      editError: "Falha ao editar uma solicitação de cadastros. Tente novamente!",
      deleteSuccess: "Solicitação(ões) de cadastros(os) excluído(s) com sucesso",
      deleteWarning: "Excluindo solicitação(ões) de cadastros(os), você será notificado quando finalizado",
      deleteError: "Ocorreu um erro durante a exclusão da(s) solicitação(ões) de cadastros(os), por favor refaça a operação"
    }
  }
}
