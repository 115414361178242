import { BasicoApi } from '@common/api';
const ComentarioService = {
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/comentario/${form.id}`, form);
    return BasicoApi.post()('/comentario', form);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/comentario/${id}`);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/comentario/${id}`);
  },
  atualizar: function (id) {
    return BasicoApi.put()(`/comentario/${id}`);
  },
  encerrar: function (id) {
    return BasicoApi.put()(`/comentario/encerrar/${id}`);
  },
};

export default ComentarioService;
