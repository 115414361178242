import helpers from '@router/helpers';
const menuId = 'colaborador';

export default [
  helpers.criarRota(menuId, 'colaborador', menuId, () =>
    import('@/views/modulos/cadastros/colaborador')
  ),
  helpers.criarRota(menuId, 'colaborador-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/colaborador/formulario')
  ),
  helpers.criarRota(
    menuId,
    'colaborador-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/colaborador/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'colaborador-ausencia',
    `${menuId}/ausencia/:id?`,
    () => import('@/views/modulos/cadastros/colaborador/ausencia'),
    true
  ),
];
