var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-padrao",class:_vm.emFiltro ? 'v-input--em-filtro' : ''},[(_vm.labelExterno && !_vm.semLabel)?_c('span',{staticClass:"mb-3 d-block v-label--externo d-flex"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]),(_vm.obrigatorio && !_vm.semLabel)?_c('span',{staticClass:"obrigatorio ml-1"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":_vm.transition,"offset-y":"","max-width":"290px","min-width":"auto","nudge-right":_vm.nudge},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"label":_vm.labelEhDentro ? _vm.labelComputada : '',"placeholder":_vm.placeholder,"dense":_vm.dense,"rules":_vm.regras,"disabled":_vm.disabled,"background-color":_vm.backgroundColor,"clearable":_vm.podeLimparData,"outlined":"","hide-details":"auto","append-icon":_vm.disabled ? '' : '$calendar',"readonly":""},on:{"click:append":function($event){_vm.menu = true},"blur":function($event){_vm.data = _vm.parseDate(_vm.conteudo)}},model:{value:(_vm.conteudo),callback:function ($$v) {_vm.conteudo=$$v},expression:"conteudo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.apenasDatasFuturas
          ? new Date().toISOString().slice(0, 10)
          : _vm.apenasDatasFuturasSemHoje
            ? _vm.semDataDeHoje
            : _vm.dataMinima
              ? _vm.dataMinima
              : null,"max":_vm.apenasDatasPassadas ? new Date().toISOString().slice(0, 10) : null,"allowed-dates":_vm.filterAllowedDates,"locale":"pt-BR","no-title":"","type":_vm.type},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }