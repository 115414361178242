import helpers from '@router/helpers';
const menuId = 'criterio-aceitacao';

export default [
  helpers.criarRota(menuId, 'criterio-aceitacao', menuId, () =>
    import('@/views/modulos/cadastros/criterio_aceitacao')
  ),
  helpers.criarRota(
    menuId,
    'criterio-aceitacao-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/criterio_aceitacao/formulario')
  ),
  helpers.criarRota(
    menuId,
    'criterio-aceitacao-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/criterio_aceitacao/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'criterio-aceitacao-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/criterio_aceitacao/formulario'),
    true
  ),
];
