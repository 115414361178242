import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Listar'),
    value: 'Listar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Inserir'),
    value: 'Inserir',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Editar'),
    value: 'Editar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Deletar'),
    value: 'Deletar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Deletar'),
    value: 'Deletar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Desabilitado'),
    value: 'Desabilitado',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Anonimizado'),
    value: 'Anonimizado',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.VisualizarRevisao'),
    value: 'VisualizarRevisao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.VisualizarHistorico'),
    value: 'VisualizarHistorico',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Visualizar'),
    value: 'Visualizar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.ResponderComentario'),
    value: 'ResponderComentario',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Reprovar'),
    value: 'Reprovar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Reprovar'),
    value: 'RegistrarEvento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Redefinir'),
    value: 'Redefinir',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Programar'),
    value: 'Programar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.PreAssinar'),
    value: 'PreAssinar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.PlanoInspecao'),
    value: 'PlanoInspecao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Ordenar'),
    value: 'Ordenar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.OrdemServicoParcial'),
    value: 'OrdemServicoParcial',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.MovimentarSituacao'),
    value: 'MovimentarSituacao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Liberar'),
    value: 'Liberar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Imprimir'),
    value: 'Imprimir',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.GerarQrCode'),
    value: 'GerarQrCode',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.GerarPDF'),
    value: 'GerarPDF',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.GerarOrcamento'),
    value: 'GerarOrcamento',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Executar'),
    value: 'Executar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.ExcluirComentario'),
    value: 'ExcluirComentario',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Excluir'),
    value: 'Excluir',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.EnviarAoCliente'),
    value: 'EnviarAoCliente',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Entrega'),
    value: 'Entrega',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.EncerrarComentario'),
    value: 'EncerrarComentario',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Encerrar'),
    value: 'Encerrar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.EditarComoCliente'),
    value: 'EditarComoCliente',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Duplicar'),
    value: 'Duplicar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.DefinirDerivas'),
    value: 'DefinirDerivas',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.CriarRevisao'),
    value: 'CriarRevisao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.CriarCopiaParaCliente'),
    value: 'CriarCopiaParaCliente',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.CriarAditivo'),
    value: 'CriarAditivo',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Confirmar'),
    value: 'Confirmar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Configurar'),
    value: 'Configurar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Comentar'),
    value: 'Comentar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.CancelarESubstituir'),
    value: 'CancelarESubstituir',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Cancelar'),
    value: 'Cancelar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Reprovar'),
    value: 'Reprovar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Calibracao'),
    value: 'Calibracao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AvaliarCertificado'),
    value: 'AvaliarCertificado',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Ausencia'),
    value: 'Ausencia',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Ativar'),
    value: 'Ativar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AssociarUsuario'),
    value: 'AssociarUsuario',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Assinar'),
    value: 'Assinar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AprovarGarantia'),
    value: 'AprovarGarantia',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.Aprovar'),
    value: 'Aprovar',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AnaliseTecnica'),
    value: 'AnaliseTecnica',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AnaliseCritica'),
    value: 'AnaliseCritica',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AlterarTecnico'),
    value: 'AlterarTecnico',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AlterarSituacao'),
    value: 'AlterarSituacao',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AlterarSenha'),
    value: 'AlterarSenha',
  },
  {
    text: i18n.t('enums.EnumFuncionalidadeAcao.AjustarValores'),
    value: 'AjustarValores',
  },
];