import helpers from '@router/helpers';
const menuId = 'duvida-solicitacao-participante';

export default [
  helpers.criarRota(
    menuId,
    'duvida-solicitacao-participante',
    menuId,
    () => import('@/views/modulos/cadastros/duvida_solicitacao_participante')
  ),
  helpers.criarRota(
    menuId,
    'duvida-solicitacao-participante-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/duvida_solicitacao_participante/formulario')
  ),
  helpers.criarRota(
    menuId,
    'duvida-solicitacao-participante-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/duvida_solicitacao_participante/formulario'),
    true
  ),
];
