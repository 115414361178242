// Here we import all component modules keys

import BaseClassificacaoInstrumentoTreeview from './BaseClassificacaoInstrumentoTreeview'
import BaseDialog from './BaseDialog'
import BaseModal from './BaseModal'
import BaseTable from './BaseTable'
import TheDashboardDrawer from './TheDashboardDrawer'
import BaseContatoParticipanteForm from './BaseContatoParticipanteForm'
import BaseParticipanteContatosTable from './BaseParticipanteContatosTable'
import BaseOperacaoTable from './BaseOperacaoTable'
import TheTipoModeloInstrumentoModal from './TheTipoModeloInstrumentoModal'

export default {
  components: {
    ...BaseClassificacaoInstrumentoTreeview,
    ...BaseDialog,
    ...BaseModal,
    ...BaseTable,
    ...TheDashboardDrawer,
    ...BaseContatoParticipanteForm,
    ...BaseParticipanteContatosTable,
    ...BaseOperacaoTable,
    ...TheTipoModeloInstrumentoModal

  }
}
