export default {
  nome: 'Nome',
  local: 'Local',
  tabela: {
    codigo: 'Código',
    acreditacao: 'Acreditação',
    nome: 'Nome',
    local: 'Local',
    tipoCalibracao: 'Tipo de Calibração',
  },
  titulo_modal: 'Escopos de Serviço',
};
