<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      scroll-height="290px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @paginar="listar"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
    />
  </div>
</template>
<script>
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import _ from 'lodash';
import helpers from '@common/utils/helpers';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    atualizaListagem: { type: Number, default: 1 },
    flagFornecedorLaboratorio: { type: Boolean, default: false },
    flagParticipanteConta: { type: Boolean, default: false },
    //value: { type: Array, default: () => [] },
    value:{ type: [Array, Object], default: () => [] }
  },
  data() {
    return {
      filtro: {
        nome: '',
        nomeMunicipioSede: '',
        documento: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
        {
            value: 'apelido',
            text: this.$t('componentes.input_laboratorio.tabela.apelido'),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t('componentes.input_laboratorio.tabela.nome'),
            sortable: false,
          },
          {
            value: 'municipioSede.nome',
            text: this.$t('componentes.input_laboratorio.tabela.cidade'),
            sortable: false,
          },
          {
            value: 'documento',
            text: this.$t('componentes.input_laboratorio.tabela.documento'),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDocumento(v);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    atualizaListagem() {
      this.listar(1, 10);
    },
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
},
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: 'Nome',
      };

      if (this.flagParticipanteConta) {
        parametros.flagParticipanteConta = this.flagParticipanteConta;
      }

      this.$store.dispatch('Layout/iniciarCarregamento');

      if (this.flagFornecedorLaboratorio) {
        ParticipanteService.listarParticipanteFornecedorLaboratorio(parametros)
          .then((res) => {
            this.tabela.dados = res.data.items;
            this.tabela.quantidadeItens = res.data.totalItems;
            this.tabela.quantidadePaginas = res.data.totalPages;
            this.tabela.paginaAtual = paginaAtual;
            this.tabela.porPagina = porPagina;

          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });

        return;
      }

      ParticipanteService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;

        })
        .catch(() => {
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    // focusInput: function(inputRef){
    //   this.$refs[inputRef].focus();
    // },
    filtrarGlobal() {
      this.listar();
    },
  },
};
</script>
