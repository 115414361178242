import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.papel_fornecedor.laboratorio'),
    value: 'flagLaboratorio',
  },
  {
    text: i18n.t('enums.papel_fornecedor.fabricante'),
    value: 'flagFabricante',
  },
  {
    text: i18n.t('enums.papel_fornecedor.transportadora'),
    value: 'flagTransportador',
  },
];
