<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :estilo-botao-adicionar="estiloBotaoAdicionar"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    :flag-participante-conta="flagParticipanteConta"
    @abrir-modal="abrirModal"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="$t('componentes.input_ordem_servico.titulo_modal')"
        :titulo-ok="$t('geral.botoes.confirmar')"
        @ok="aplicarSelecionados"
      >
        <modal
          v-model="selecionados"
          refs="modal"
          :key="componentKey"
          :multiplos="multiplos"
          :flag-participante-conta="flagParticipanteConta"
          :listar-atualizacao="atualizaListagem"
          :filtros="filtros"
          :instrumento-id="instrumentoId"
          @fechar-modal-click-linha="aplicarSelecionados"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import _ from 'lodash';
import { InputMixin } from '../../InputMixin';
import Modal from './modal.vue';
export default {
  name: 'InputTipoCliente',
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    multiplos: { type: Boolean, default: false },
    retornarObjeto: { type: Boolean, default: true },
    flagParticipanteConta: { type: Boolean, default: false },
    estiloBotaoAdicionar: { type: Boolean, default: false },
    filtros: { type: Object, default: null },
    instrumentoId: { type: String, default: null },
  },
  data: function () {
    return {
      componentKey: 0,
      conteudo: [],
      selecionados: [],
      atualizaListagem: 1,
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit('input', this.conteudo);
    },
    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      this.componentKey++;
      if (!this.conteudo) return false;
      let conteudoClonado = _.cloneDeep(this.conteudo);
      if (!this.multiplos) conteudoClonado = [conteudoClonado];

      this.selecionados = conteudoClonado.map((el) => {
        return {
          id: el.value,
          codigo: el.text,
        };
      });
    },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      let selecionados = new DropdownModel(this.selecionados, 'codigo');
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropdownModel(this.selecionados, 'codigo')[0];
      }

      this.conteudo = selecionados;
    },
  },
};
</script>
