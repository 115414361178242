import { BasicoApi } from '@common/api';
const TipoInstrumentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/tipoinstrumento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tipoinstrumento/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tipoinstrumento/${form.id}`, form);
    return BasicoApi.post()('/tipoinstrumento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tipoinstrumento/${id}`);
  },
  buscarUsuariosExecutantes(id){
    return BasicoApi.get()(`/tipoinstrumento/${id}/usuarios-executantes`);
  }
};

export default TipoInstrumentoService;
