<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    @abrir-modal="abrirModal"
    @input="emitirInput"
  >
    <template #depois-label>
      <slot name="depois-label" />
    </template>
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="$t('componentes.input_procedimento_eletronico.titulo_modal')"
        :titulo-ok="$t('geral.botoes.confirmar')"
        @ok="aplicarSelecionados"
      >
        <modal v-model="selecionados" refs="modal" :multiplos="multiplos" />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { InputMixin } from '../../InputMixin';
import { DropdownProcedimento } from '@common/models/geral/DropdownProcedimento';
import Modal from './modal.vue';
import _ from 'lodash';
export default {
  name: 'InputProcedimento',
  components: {
    Modal,
  },
  mixins: [InputMixin],
  props: {
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: [],
      selecionados: [],
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput: function () {
      this.$emit('input', this.conteudo);
    },
    emitirOk: function () {
      this.$emit('ok', this.conteudo);
    },
    textoOpcao: function (opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcao: function (indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      if (!this.conteudo) return false;

      let conteudoClonado = _.cloneDeep(this.conteudo);
      if (!this.multiplos) conteudoClonado = [conteudoClonado];

      this.selecionados = conteudoClonado.map((el) => {
        return {
          id: el.value,
          codigo: el.text,
        };
      });
    },
    aplicarSelecionados: function () {
      this.emitirOk();
      this.$refs['modal-input'].fecharModal();
      let selecionados = new DropdownProcedimento(this.selecionados, 'codigo');
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropdownProcedimento(
            this.selecionados,
            'codigo'
          )[0];
      }

      this.conteudo = selecionados;
    },
  },
};
</script>
