import { BasicoApi } from '@common/api';
const SetorService = {
  listar: function (parametros) {
    return BasicoApi.get()('/setor', parametros);
  },
  listarSetoresParticipantesConta: function (params) {
    return BasicoApi.get()('/setor/participante-conta', params)
  },
  listarSetoresParticipantesContaCalibracaoOuManutencao: function (params) {
    return BasicoApi.get()('/Setor/setores-manutencoes-calibracoes', params)
  },
  buscar: function (id) {
    return BasicoApi.get()(`/setor/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/setor/${form.id}`, form);
    return BasicoApi.post()('/setor', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/setor/${id}`);
  },
};

export default SetorService;
