import moment from 'moment';
export default {
  estaAutenticado: (state) => state.token != null,
  token: (state) => (!state.token ? '' : `Bearer ${state.token}`),
  usuario: (state) => state.usuario,
  refreshToken: (state) => state.refreshToken,
  buscandoRefreshToken: (state) => state.buscandoRefreshToken,
  participanteSelecionado: (state) => state.participanteSelecionado,
  refreshTokenValidity: (state) =>
    !state.refreshTokenValidity
      ? null
      : moment(state.refreshTokenValidity, 'YYYY-MM-DD[T]HH:mm:ss').subtract(
          2,
          'hours'
        ),
  participanteLogadoConta: (state) => state.participanteLogadoConta,
  encodedData: (state) => state.encodedData,
  colaborador:(state) => state.colaborador,
};
