import helpers from '@router/helpers';
const menuId = 'servico-manutencao';

export default [
  helpers.criarRota(menuId, 'servico-manutencao', menuId, () =>
    import('@/views/modulos/cadastros/servico_manutencao')
  ),
  helpers.criarRota(
    menuId,
    'servico-manutencao-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/servico_manutencao/formulario')
  ),
  helpers.criarRota(
    menuId,
    'servico-manutencao-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/servico_manutencao/formulario'),
    true
  ),
];
