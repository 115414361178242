export default {
  titulos: {
    listagem: 'Serviços de Calibração',
    novo: 'Novo Serviço de Calibração',
    editar: 'Editar Serviços de Calibração',
  },
  cadastro_sucesso: 'Serviços de calibração salvo com sucesso!',
  exclusao_sucesso: 'Serviços de calibração excluído com sucesso!',
  filtro: {
    apelido:'Apelido',
    nome: 'Nome',
    codigo: 'Código',
    tipo_calibracao: 'Tipo de Calibração',
    local_execucao: 'Local de Execução',
    tipo_instrumento: 'Tipo de Instrumento',
    modelo_instrumento: 'Modelo de Instrumento',
  },
  tabela: {
    apelido:'Apelido',
    codigo: 'Código',
    nome: 'Nome',
    quantidade_tipo: 'Qtd tipo de Instrumento',
    quantidade_modelo: 'Qtd Modelo de Instrumento',
    tipo_calibracao: 'Tipo de Calibração',
    valor_servico: 'Valor do Serviço',
    escopo:'Escopo',
    tipos_instrumentos: 'Tipos de Instrumentos',
    modelos_instrumentos: 'Modelos de Instrumentos',
  },
  formulario: {
    abas: {
      dados_servico: 'Dados do Serviço',
      tipo_modelo_instrumento: 'Tipos e Modelos de Instrumento',
    },
    codigo: 'Código',
    codigo_placeholder: 'Código',
    nome: 'Nome',
    escopo_servico: 'Escopo do Serviço',
    nome_placeholder: 'Nome',
    tipo_calibracao: 'Tipo de Calibração',
    descricao: 'Descrição',
    descricao_placeholder: 'Descrição',
    local_execucao: 'Local de Execução',
    laboratorio: 'Laboratório',
    minimo_pontos: 'Quantidade de Pontos',
    unidade_medida: 'Unidade de Medida',
    amplitude: 'Amplitude',
    amplitude_min: 'Mínima',
    amplitude_max: 'Máxima',
    valor_servico: 'Valor do Serviço',
    valor_servico_placeholder: 'Valor do Serviço',
    valor_ponto: 'Valor por Ponto',
    valor_ponto_placeholder: 'Valor por Ponto',
    valor_hora: 'Valor por Hora',
    valor_hora_placeholder: 'Valor por Hora',
    tempo_execucao: 'Horas de Execução Padrão',
    desconto: 'Desconto Liberado %',
    desconto_placeholder: 'Desconto Liberado',
    tipo_exibicao: 'Modo de Exibição',
    a: 'a',
    tipo_modelo_instrumento: {
      botoes: {
        novo: 'Selecionar Tipos e Modelos de Instrumento',
      },
      tabela: {
        modelo_instrumento: 'Modelo de Instrumento',
        tipo_instrumento: 'Tipo de Instrumento',
      },
    },
    erros: {
      codigo_existente: 'Código já utilizado',
      amplitude_maxima: 'Amplitude máxima deve ser maior que amplitude mínima',
      unidade_medida: 'Prencha unidade de medida',
    },
    confirmar_alteracao_dados: "Ao alterar os escopos de serviços, os campos Tipo de Calibração, Local de Execução e Unidade de Medida terão seus conteúdos apagados. Deseja continuar?",
    confirmar_alteracao_dados_unidade_medida: "Ao alterar a unidade de medida, será necessário selecionar novamente os Tipos e Modelos de Instrumentos. Deseja prosseguir?"
  },
  erros: {
    id_invalido: 'O id do tipo de envio é inválido',
    amplitudeMaximaNaoPodeSerMaiorQueAmplitudeMaximaPermitida: 'Amplitude máxima fora da faixa do Escopo de Serviço',
    amplitudeMinimaNaoPodeSerMenorQueAmplitudeMinimaPermitida: 'Amplitude mínima fora da faixa do Escopo de Serviço',

  },
  validacoes: {
    formulario_invalido: 'Preencha o formulário para liberar o botão',
    tipoModelo_invalido:
      'Adicione ao menos um tipo e modelo de instrumento para salvar',
  },
  botoes: {
    duplicar_servico: 'Duplicar Serviço',
    duplicar: 'Duplicar',
    atualizar_preco: 'Atualizar Preço',
  },
  modal_duplicar: {
    titulo: 'Duplicar Serviço',
    input: {
      codigo: 'Código',
    },
  },
  modal_atualizar_preco: {
    titulo: 'Atualizar Preço',
    inputs: {
      percentual_atualização: 'Percentual de Atualização',
      arredondamento: 'Arredondamento',
      valores_atualizar: 'Quais Valores Atualizar?',
      valor_serviço: 'Valor do Serviço',
      valor_ponto: 'Valor por Ponto',
      valor_hora: 'Valor por Hora',
    },
  }
};
