import { BasicoApi, CalculosApi } from '@common/api';
const InstrumentoService = {
  listar: function (parametros, tiposInstrumentosIds) {
    return BasicoApi.get()(
      `/instrumento${tiposInstrumentosIds ? `?${tiposInstrumentosIds}` : ''}`,
      parametros
    );
  },
  listarPadrao: function (parametros) {
    return CalculosApi.get()(`/instrumento/padroes`, parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/instrumento/${id}`);
  },
  buscarProcedimentoEletronico: function (id) {
    return BasicoApi.get()(`/instrumento/${id}/procedimento-eletronico`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/instrumento/${form.id}`, form);
    return BasicoApi.post()('/instrumento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/instrumento/${id}`);
  },
  buscarPontos: function (instrumentoId, faixaId) {
    return BasicoApi.get()(
      `/instrumento/${instrumentoId}/faixa/${faixaId}/ponto`
    );
  },
  salvarPontos: function (instrumentoId, faixaId, pontos) {
    return BasicoApi.put()(
      `/instrumento/${instrumentoId}/faixa/${faixaId}/ponto`,
      pontos
    );
  },
  listarPadroesProcedimentosEletronicos: function (id) {
    return CalculosApi.get()(`/instrumento/${id}/procedimento-eletronico`);
  },
  duplicar: function (id, form) {
    return BasicoApi.post()(`/instrumento/${id}/duplicar`, form);
  },
  buscaChecklist: function (id) {
    return BasicoApi.get()(`/instrumento/${id}/checklist`);
  },
  buscarAnaliseTecnicaPorOrdemServico: function (
    instrumentoId,
    ordemServicoId
  ) {
    return CalculosApi.get()(
      `/instrumento/${instrumentoId}/ordemServico/${ordemServicoId}/analisetecnica`,
      {},
      false
    );
  },
  listarTipoServicosManutencaoPecas: function (stringInstrumento = '') {
    return BasicoApi.get()(
      `/instrumento/servico-manutencao-pecas?${stringInstrumento}`
    );
  },
  alterarStatusInstrumento: function (form) {
    if (form.id)
      return BasicoApi.post()(
        `/instrumento/${form.id}/ativo/${form.flagAtivo}`,
        form
      );
  },
  buscarCriterioAceitacao: function (id) {
    return BasicoApi.get()(`/instrumento/${id}/faixa/criteriosaceitacao`);
  },
  buscarUltimaMovimentacao: function (id) {
    return BasicoApi.get()(`/instrumento/${id}/setor-ultima-movimentacao`);
  },
  buscaUltimoServicoETipoCalibracao: function (id) {
    return BasicoApi.get()(`/instrumento/${id}/ultima-analise-tecnica`);
  },
  checkInstrumentoCertificado: function (id) {
    return CalculosApi.get()(
      `/instrumento/check-instrumento-certificado/${id}`
    );
  },
  buscarCriteriosAceitacaoInstrumento: function (id) {
    return BasicoApi.get()(
      `/instrumento/${id}/criterios-aceitacao-analise-tecnica`
    );
  },
  buscarGraficoTendencia : function(instrumentoId, faixaId, certificadosIds = [], tabelaId){
    let link = `/instrumento/${instrumentoId}/grafico-tendencia/?faixaId=${faixaId}`;
    if(certificadosIds.length > 0) link = `${link}&certificadosIds=${certificadosIds.join('&certificadosIds=')}`;
    if(tabelaId) link = `${link}&tabelaId=${tabelaId}`;
    return CalculosApi.get()(link);
  },
  buscarCertificados: function (id, parametros) {
    return BasicoApi.get()(`/instrumento/${id}/certificados`,parametros);
  },
  buscarHistorico: function (id, parametros) {
    return BasicoApi.get()(`/instrumento/${id}/historico`,parametros);
  },
  buscarHistoricoServicosPecas: function (id) {
    return BasicoApi.get()(`/instrumento/${id}/historico-servicos-pecas`);
  }
};

export default InstrumentoService;
