export default {
  atualizarRoteiro({ commit }, form) {
    commit('ATUALIZAR_ROTEIRO', form);
  },
  atualizarEstadoCliente({ commit }, form) {
    commit('ATUALIZAR_ESTADO_CLIENTE', form);
  },
  atualizarClienteDaVez({ commit }, form) {
    commit('ATUALIZAR_CLIENTE_DA_VEZ', form);
  },
  atualizarClienteDaVezIndex({ commit }, form) {
    commit('ATUALIZAR_CLIENTE_DA_VEZ_INDEX', form);
  },
  atualizarEnvioMaterialDaVez({ commit }, form) {
    commit('ATUALIZAR_ENVIO_MATERIAL_DA_VEZ', form);
  },
  limparRoteiro(context, payload) {
    context.commit('LIMPAR_ROTEIRO', payload);
  },
  limparClienteDaVez(context, payload) {
    context.commit('LIMPAR_CLIENTE_DA_VEZ', payload);
  },
  
};
