<template>
  <tabela-padrao-prime-vue
    v-model="selecionados"
    filtro-geral
    class="mt-2"
    :colunas="tabela.colunas"
    :dados="tabela.dados"
    :mostrar-acoes="false"
    :quantidade-itens="tabela.quantidadeItens"
    :por-pagina="tabela.porPagina"
    :quantidade-paginas="tabela.quantidadePaginas"
    :filters="filters"
    :global-filters="globalFilters"
    selecionar-apenas-um
    :pagina="tabela.paginaAtual"
    @paginar="listar"
    @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
  />
</template>
<script>
import VeiculoService from '@common/services/cadastros/VeiculoService';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: [Array, Object], default: () => [] },
    apenasDisponivel: { type: Boolean, default: false },
  },
  data() {
    return {
      edicao: null,
      globalFilters: [
        'modelo',
        'placa',
        'fabricante',
        'principalCondutorColaborador.nome',
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'modelo',
            text: this.$t('componentes.input_veiculos.tabela.modelo'),
            sortable: false,
          },
          {
            value: 'fabricante',
            text: this.$t('componentes.input_veiculos.tabela.fabricante'),
            sortable: false,
          },
          {
            value: 'placa',
            text: this.$t('componentes.input_veiculos.tabela.placa'),
            sortable: false,
          },
          {
            value: 'principalCondutorColaborador.nome',
            text: this.$t('componentes.input_veiculos.tabela.condutor'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };

      if (this.apenasDisponivel) {
        parametros.flagEmUso == false;
      }

      this.$store.dispatch('Layout/iniciarCarregamento');
      VeiculoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.length;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
