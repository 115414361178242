export default {
  codigo: 'Código',
  nome: 'Nome',
  setor: 'Setor',
  participante: 'Participante',
  variavel: 'Variável',
  instrumento_padrao_atual: 'Instrumento Padrão Atual',
  tabela: {
    codigo: 'Código',
    tipo: 'Tipo do Instrumento',
    setor: 'Setor',
    faixas : 'Faixas'
  },
  titulo_modal: 'Instrumentos Padrão',
};
