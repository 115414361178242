export default {
  titulos: {
    listagem: 'Orçamentos',
    novo: 'Novo Orçamento',
    editar: 'Editar Orçamento',
    visualizar: 'Visualizar Orçamento',
    impressao: 'Orçamento',
  },
  cadastro_sucesso: 'Orçamento salvo com sucesso!',
  finalizar_orcamento: 'Deseja finalizar o orçamento?',
  email_reenviado_sucesso: 'Email Reenviado com sucesso!',
  dados_aba_requisitos:
    'Os dados de requisitos podem ser visualizados a qualquer momento em "Requisitos".',
  exclusao_sucesso: 'Orçamento excluído com sucesso!',
  reenviar_email: 'Reenviar Email',
  revisao_sucesso: 'Revisão do orçamento criada com sucesso!',
  reprovacao_sucesso: 'Reprovação do orçamento feita com sucesso!',
  contatos_cliente: 'Contatos Cliente',
  justificativa_sucesso: 'Justificativa criada com com sucesso!',
  visualizar_comentarios: 'Visualizar Comentários',
  marque_os_itens_remover_orcamento:
    'Marque os Itens que Deseja Remover do Orçamento',
  adicionar_instrumento_orcamento: 'Adicionar Instrumento(s) no Orçamento',
  novo_comentario: 'Novo Comentário',
  selecionar_instrumento: 'Selecionar Instrumento',
  exclusao_justificativa_sucesso:
    'Justificativa da exclusão feita com sucesso!',
  tipo_desconto: 'Tipo de Desconto',
  alerta_tipo_desconto:
    'Se realizar esta mudança os valores de desconto serão zerados, tem certeza que deseja continuar?',
  alerta_local_execucao:
    'Ao alterar  o local de execução será necessário selecionar o serviço de calibração e manutenção novamente, deseja confirmar a operação ?',
  alerta_cliente:
    'As informações de contato e instrumentos precisarão ser selecionadas novamente. Confirma?',

  situacao: 'Situação',
  filtro: {
    codigo: 'Código',
    cliente: 'Cliente',
    status: 'Situação',
    mes: 'Mês',
    ano: 'Ano',
    ocorrencia: 'Ocorrência',
    de: 'De',
    ate: 'Até',
    tipo_data: 'Tipo de Data',
    nome_cliente: 'Cliente',
    ordem_servico: 'Ordem de Serviço',
  },
  tabela: {
    status: 'Status',
    codigo: 'Código',
    cliente: 'Cliente',
    numeroOs: 'N° da OS',
    tag: 'Tag',
    tipo_instrumento: 'Tipo de Instrumento',
    codigo_servico: 'Código de Serviço',
    valor_total: 'Valor Total',
    data_envio: 'Data de Envio',
    data_atualizacao: 'Data de Atualização',
    data_emissao: 'Data de Emissão',
    data_vencimento: 'Data de Vencimento',
    valor: 'Valor',
    telefone: 'Telefone',
    email: 'Email',
    situacao: 'Situação',
    ordem_servico: 'Ordem de Serviço',
    remetente: 'Remetente',
    corpo_email: 'Corpo do Email',
    destinatario: 'Destinatário',
    assunto: 'Assunto',
    enviado: 'Enviado',
    setor: 'Setor',
    cargo: 'Cargo',
    nome: 'Nome',
    orcamento: 'Orçamento',
    data: 'Data',
    classificacao: 'Classificação',
    vencido: 'Vencido',
    comentario: 'Comentário',
    modal_add_revisao: {
      titulo: 'Atenção',
      mensagem:
        'Não é possível editar um orçamento que já foi liberado para o cliente, deseja criar uma Revisão deste orçamento?',
    },
    modal_list_revisao: {
      titulo: 'Revisões do Orçamento',
      tabela: {
        codigo: 'Código',
        data_emissao: 'Data de Emissão',
        usuario: 'Usuário',
        valor: 'Valor',
      },
    },
    modal_comentario: {
      titulo_nova: 'Nova Revisão',
      titulo_excluir: 'Excluir Revisão',
      mensagem_nova: 'Informe uma justificativa para criar uma nova revisão',
      mensagem_excluir:
        'Informe uma justificativa para excluir a revisão selecionada',
      form: {
        tipo_comentario: 'Tipo do Comentário',
        justificativa: 'Justificativa',
      },
    },
  },
  formulario: {
    abas: {
      requisitos: 'Requisitos',
      dados_orcamento: 'Dados do Orçamento',
      itens_orcamento: 'Itens do Orçamento',
      mensagens: 'Mensagens',
      comentarios: 'Comentários',
      anexos: 'Anexos',
      anexos_bloqueado:
        'É necessário salvar os dados do Orçamento antes de inserir um anexo.',
    },
    mensagem_para_usuario:
      'Selecione os instrumentos, serviços e peças que deseja aprovar',
    tipo_orcamento: 'Tipo de orçamento',
    codigo: 'Código',
    dados_cadastro: 'Dados de cadastro',
    titulo_modal_instrumentos: 'Instrumentos',
    titulo_modal_instrumentos_os: 'Instrumentos da OS',
    titulo_modal_servicos_calibracao: 'Serviços de Calibração',
    titulo_modal_servicos_manutencao: 'Serviços de Manutenção',
    titulo_modal_pecas: 'Peças',
    selecionar_mensagem: 'Selecionar Mensagem',
    mensagem: 'Mensagem',
    anexar_arquivo: 'Anexar Arquivo',
    especificacao: 'Especificação',
    ocorrencia: 'Ocorrência',
    fabricante: 'Fabricante',
    tipo_instrumento: 'Tipo de Instrumento',
    faixa: 'Faixa',
    laboratorio: 'Laboratório',
    valor: 'Valor',
    titulo_modal_responsavel: 'Responsável / Colaborador',
    nome: 'Nome',
    cliente: 'Cliente',
    cod_os: 'Ordem de Serviço',
    aos_cuidados_de: 'Aos Cuidados de',
    apelido: 'Apelido',
    fornecedor: 'Fornecedor',
    local: 'Local',
    prazo: 'Prazo',
    qtd: 'Qtd',
    valor_unitario: 'Valor Unitário',
    aprovar_orcamento: 'Aprovar Orçamento',
    valor_total: 'Valor Total',
    valor_aprovado: 'Valor Aprovado',
    adicionar: 'Adicionar',
    desconto: 'Desconto',
    desconto_icone_percent: 'Desconto %',
    frete: 'Frete',
    despesas: 'Despesas',
    condicao_pagamento_contrato: 'Condição de Pagto por Contrato',
    condicao_pagamento: 'Condição de Pagamento',
    parcelas: 'Parcelas',
    pedido_compra: 'Pedido de Compra',
    como_solicitado: 'Como foi Solicitado',
    tipo_desconto: 'Tipo de Desconto',
    em_elaboracao: 'Em Elaboração',
    em_negociacao: 'Em Negociação',
    aguardando_aprovacao: 'Aguardando Aprovação',
    observacao: 'Observação',
    numero: 'Número',
    instrumento: 'Instrumento',
    descricao: 'Descrição',
    responsavel: 'Responsável',
    data_emissao: 'Data de Emissão',
    garantia_dias: 'Prazo de Garantia (dias)',
    validade_dias: 'Validade do Orçamento (dias)',
    tipo_pessoa: 'Tipo',
    documento: 'Documento',
    telefone: 'Telefone',
    email: 'Email',
    site: 'Site',
    inscricao_estadual: 'Incrição estadual',
    inscricao_municipal: 'Inscrição municipal',
    dia_coleta: 'Dia(s) de coleta',
    prazo_entrega: 'Prazo de entrega',
    papel_fornecedor: 'Papel do fornecedor',
    adicionar_arquivo: 'Adicionar Arquivo',
    tipoCalibracao: 'Tipo de Calibração',
    enderecos: {
      botoes: {
        novo: 'Novo endereço',
      },
      tipo: 'Tipo',
      uf: 'UF',
      municipio: 'Municipio',
      logradouro: 'Logradouro',
      numero: 'Numero',
      complemento: 'Complemento',
      bairro: 'Bairro',
      cep: 'CEP',
    },
    itensOrcamento: {
      botoes: {
        novoItemOrcamento: 'Novo Item de Orçamento',
        visualizar_ultimos_precos_orcados:
          'Visualizar os Últimos Preços Orçados',
        ultimos_precos_orcados: 'Últimos Preços Orçados',
      },
      nome: 'Nome',
      telefone: 'Telefone',
      email: 'Email',
      setor: 'Setor',
      cargo: 'Cargo',
    },
    requisitos: {
      botoes: {
        novo: 'Novo requisito',
      },
      tipo_requisito: 'Tipo de requisito',
      descricao: 'Descrição',
    },
    comentarios: {
      novo_comentario: 'Novo Comentário',
    },
  },
  erros: {
    id_invalido: 'O id do orçamento é invalido',
    orcamento_invalido: 'O orçamento é invalido',
    contrato_invalido: 'O contrato é invalido',
    orcamento_sem_anexos: 'Este orçamento não possui anexos',
  },
  modais: {
    servico_calibracao: {
      codigo: 'Código',
      nome: 'Nome',
      faixa: 'Faixa',
      local: 'Local',
      laboratorio: 'Laboratório',
      valor: 'Valor',
    },
    servico_manutencao: {
      titulo: 'Serviços de Manutenção',
      codigo: 'Código',
      nome: 'Nome',
      faixa: 'Faixa',
      local: 'Local',
      laboratorio: 'Laboratório',
      valor: 'Valor',
    },
    pecas: {
      titulo: 'Peças',
      codigo: 'Código',
      descricao: 'Descricao',
      fabricante: 'Fabricante',
      quantidade: 'Qtd',
      valor: 'Valor',
      desconhecido: 'Desconhecido',
    },
  },
  botoes: {
    comentar: 'Comentar',
  },
  badges: {
    codigo: 'Código',
    cliente: 'Cliente',
    data_emissao: 'Data de Emissão',
  },
  orcamento_aprovado: 'Orçamento aprovado com sucesso',
  orcamento_reprovado: 'Orçamento reprovado com sucesso',

  aprovar_reprovar_orcamento: {
    aprovar_titulo: 'Aprovar Orçamento',
    reprovar_titulo: 'Reprovar Orçamento',
    aprovar_mensagem: 'Deseja aprovar o orçamento?',
    reprovar_mensagem: 'Deseja reprovar o orçamento?',
  },
};
