import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_contrato.contrato_aberto'),
    value: 'ContratoAberto',
  },
  {
    text: i18n.t('enums.tipo_contrato.contrato_fechado'),
    value: 'ContratoFechado',
  },
  {
    text: i18n.t('enums.tipo_contrato.cliente_especial'),
    value: 'ClienteEspecial',
  },
];
