import { BasicoApi } from '@common/api';
const VeiculoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/veiculo', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/veiculo/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/veiculo/${form.id}`, form);
    return BasicoApi.post()('/veiculo', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/veiculo/${id}`);
  },
  buscarHistorico: function (id) {
    return BasicoApi.get()(`/veiculo/${id}/ultimo-historico`);
  },
  listarHistoricos: function (id, parametros) {
    return BasicoApi.get()(`/veiculo/${id}/historicos`, parametros);
  }
};

export default VeiculoService;
