import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumTipoPonto.ValorFixo'),
    value: 'ValorFixo',
  },
  {
    text: i18n.t('enums.EnumTipoPonto.Percentual'),
    value: 'Percentual',
  },
];
