import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_data.data_emissao'),
    value: 'dataEmissao',
  },
  {
    text: i18n.t('enums.tipo_data.data_vencimento'),
    value: 'dataVencimento',
  },
  {
    text: i18n.t('enums.tipo_data.data_envio'),
    value: 'dataEnvio',
  },
];
