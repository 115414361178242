<template lang="">
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo  d-flex"
    >
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio"
        class="obrigatorio ml-1"
      >*</span>
    </span>

    <vuetify-money
      v-model="conteudo"
      :rules="regras"
      :label="labelEhDentro ? labelComputada : ''"
      :placeholder="placeholder"
      
      :disabled="disabled"
      outlined
      :options="options"
      :hide-details="hideDetails"
      dense
      @keyup.native="keyUp"
      @blur="blur"
    />
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  name: 'InputMoney',
  mixins: [InputMixin],
  props: {
    type: { type: String, default: '' },
    options: {
      type: Object,
      default: () => {
        return {
          locale: 'pt-BR',
          prefix: 'R$',
          suffix: '',
          precision: 2,
        };
      },
    },
    mascara: {},
    hideDetails: { type: Boolean, default: false },
    /** Props de validação */
  },
  data: function () {
    return {
      conteudo: '',
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      if (this.min) regras.push(this.validacaoMinimo);
      if (this.max) regras.push(this.validacaoMaximo);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    keyUp() {
      this.$emit('keyUp');
    },
    blur() {
      this.$emit('blur');
    },
    change() {
      this.$emit('change.native', this.conteudo);
    },
  },
};
</script>
