export default {
  nome: 'Nome',
  grandeza: 'Grandeza Física',
  tabela: {
    nome: 'Nome',
    simbolo: 'Símbolo',
    grandeza: 'Grandeza Física',
  },
  titulo_modal: 'Unidades de Medida',
};
