
export class DropdownEscopoServico {
  constructor(lista) {
    if (Array.isArray(lista)) {
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.id;
        novoObj.text = el.codigo;
        novoObj.faixas = el?.faixas;
        novoObj.tiposCalibracoes = el?.tiposCalibracoes;
        novoObj.locaisExecucoes = el?.locaisExecucoes;
        return novoObj
      })
    }
    return {
      value: lista.id,
      text: lista.nome,
      faixas: lista.faixas,
      tiposCalibracoes: lista.tiposCalibracoes,
      locaisExecucoes: lista.locaisExecucoes
    }
  }
}
