import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumTipoEnvioMaterial.Entrega'),
    value: 'Entrega',
  },
    {
        text: i18n.t('enums.EnumTipoEnvioMaterial.Recebimento'),
        value: 'Recebimento',
    },
    {
        text: i18n.t('enums.EnumTipoEnvioMaterial.RecebimentoSemAgendamento'),
        value: 'RecebimentoSemAgendamento',
    },
]