<template>
  <div class="input-padrao">
    <span>
      <span class="mb-3 d-block v-label--externo  d-flex">
        <span class="label"> {{ label }} </span>
      </span>
    </span>
    <v-file-input
      ref="input"
      v-model="imagem"
      accept="image/png, image/jpeg, image/bmp"
      :placeholder="placeholder"
      prepend-icon=""
      prepend-inner-icon="$fileUploadOutline"
      prefix="|"
      outlined="outlined"
      :flat="false"
      :solo="false"
      :dense="true"
      hide-details="auto"
      :disabled="disabled"
      @change="onFileChanged"
    />
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  mixins: [InputMixin],
  data() {
    return {
      imagem: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          if (this.value.indexOf('base64') > -1) {
            this.base64ParaObjetoFile(this.value);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    onFileChanged: function (imagem) {
      if (imagem) {
        const reader = new FileReader();
        reader.readAsDataURL(imagem);
        reader.onload = () => {
          this.$emit('input', reader.result);
        };
      }
    },
    base64ParaObjetoFile(dataUrl) {
      fetch(dataUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const type = dataUrl.split(',')[0].match(/:(.*?);/)[1];
          this.imagem = new File([blob], 'Imagem', { type });
        });
    },
  },
};
</script>
