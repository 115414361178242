export default {
  nome: 'Nome',
  cidade: 'Cidade',
  documento: 'Documento',
  tabela: {
    nome: 'Nome',
    apelido: 'Apelido',
    cidade: 'Cidade',
    documento: 'Documento',
  },
  titulo_modal: 'Contratante',
};
