export default {
  titulos: {
    listagem: 'Participantes',
    novo: 'Novo Participante',
    editar: 'Editar Participante',
  },
  cadastro_sucesso: 'Participante salvo com sucesso!',
  criterio_aprovacao: 'Critério de Aprovação',
  novo_criterio_aprovacao: 'Novo Critério de Aprovação',
  exclusao_sucesso: 'Participante excluído com sucesso!',
  filtro: {
    nome: 'Nome',
    tipo_participante: 'Cliente/Fornecedor',
    telefone: 'Telefone',
    email: 'Email',
    documento: 'Documento',
    apelido: 'Apelido',
    municipio: 'Município',
    bairro: 'Bairro',
  },
  tabela: {
    nome: 'Nome',
    tipo_participante: 'Cliente/Fornecedor',
    telefone: 'Telefone',
    email: 'Email',
    documento: 'Documento',
    apelido: 'Apelido',
    municipio: 'Município',
    bairro: 'Bairro'
  },
  criterios_aprovacao: {
    seq: 'Seq.',
    operador_logico: 'Operador Lógico',
    tipo_criterio: 'Tipo de Critério',
    operador_rel: 'Operador Rel.',
    tipo_valor: 'Tipo Valor',
    valor_num: 'Valor Num.',
    valor_perc: 'Valor Perc.',
    valor_logico: 'Valor Lógico',
    sem_dados: 'Sem Informações',
  },
  formulario: {
    abas: {
      dados_participante: 'Dados do Participante',
      dados_contato: 'Dados do Contato',
      usuario_sistema: 'Usuário do Sistema',
      criterios_aprovacao_automatica: 'Critérios de Aprovação Automática',
      campos_adicionais: 'Campos Adicionais',
      enderecos: 'Endereços',
      contatos: 'Contatos',
      requisitos: 'Requisitos',
    },
    tipo_participante: 'Cliente / Fornecedor',
    dados_cadastro: 'Dados de Cadastro',
    dias_envio_material: 'Dias de Envio de Material',
    nome: 'Nome',
    participante_conta: 'Participante Conta',
    apelido: 'Apelido',
    tipo_pessoa: 'Tipo',
    documento: 'Documento',
    telefone: 'Telefone',
    email: 'Email',
    imagem_cabecalho_relatorio: 'Imagem de Cabeçalho do Relatório',
    site: 'Site',
    inscricao_estadual: 'Incrição Estadual',
    inscricao_municipal: 'Inscrição Municipal',
    dia_coleta: 'Dia(s) de Coleta',
    prazo_dias: 'Em Dias',
    dia_envio_material: 'Dia(s) de Envio de Material',
    prazo: 'Prazo de Entrega',
    papel_fornecedor: 'Papel do Fornecedor',
    validacao_certificados: 'Validação dos Certificados',
    participante_responsavel_faturamento:
      'Participante Responsável Faturamento',
    participante_responsavel: 'Participante Responsável',
    campos_adicionais: {
      nome_campo_adicional: 'Nome do Campo Adicional',
      valor_modelo: 'Valor no Modelo',
      valor_padrao: 'Valor Padrão',
      true: 'Sim',
      false: 'Não',
    },
    cnpj:{
      titulo_modal:"Consulta CNPJ",
      cnpj:'CNPJ',
      cnpj_sucesso:'CNPJ localizado com sucesso!',
    },
    enderecos: {
      titulo_modal_novo: 'Novo Endereço',
      endereco: 'Endereço',
      titulo_modal_editar: 'Editar Endereço',
      botoes: {
        novo: 'Novo Endereço',
      },
      tipo: 'Tipo',
      uf: 'UF',
      municipio: 'Município',
      logradouro: 'Logradouro',
      numero: 'Número',
      complemento: 'Complemento',
      bairro: 'Bairro',
      cep: 'CEP',
    },
    contatos: {
      titulo_modal_novo: 'Novo Contato',
      contato: 'Contato',
      titulo_modal_editar: 'Editar Contato',
      botoes: {
        novo: 'Novo Contato',
      },

      nome: 'Nome',
      telefone: 'Telefone',
      email: 'Email',
      setor: 'Setor',
      cargo: 'Cargo',
      responsabilidade: 'Responsabilidade',
      usuario_sistema: 'Usuário do Sistema',
    },
    requisitos: {
      botoes: {
        excluir_novo_campos_adicionais: 'Excluir Campo Adicional',
        excluir_novo_texto: 'Excluir Texto',
        excluir_novo_tipos_documentos: 'Excluir Tipos de Doc.',
        novo_campos_adicionais: 'Adicionar Campo Adicional',
        novo_texto: 'Adicionar Texto',
        novo_tipos_documentos: 'Adicionar Tipos de Doc.',
        novo_requisito_texto: 'Adicionar Requisito Texto',
        gerar_requisito_texto: 'Gerar Requisitos Texto',
      },

      tipo_requisito: 'Tipo de Requisito',
      Orcamento: 'Orçamento',
      NotasFiscais: 'Notas Fiscais',
      AnaliseTecnica: 'Análise Técnica',
      Recebimento: 'Recebimento',
      Ensaio: 'Ensaio',
      Calibracao: 'Calibração',
      Expedicao: 'Expedição',
      deve_marcar_tabela: 'Selecione um Item da tabela',
      campo_adicional: 'Campo Adicional',
      tipo_documento: 'Tipo de Documento',
      descricao: 'Descrição',
      ocorrencia: 'Ocorrência',
      campos_adicionais: 'Requisitos Campos Adicionais',
      texto: 'Requisitos Texto',
      tipos_documentos: 'Requisitos Tipos de Documentos',
      gerar_requisito_texto: 'Gerar Requisitos Texto',
      modal_gerar_requisito: {
        nome: ' Nome',
        resposta: 'Resposta',
        observacao: 'Observação',
        requisito: 'Requisito',
        tipo_requisito: 'Tipo Requisito'
      }
    },
  },
  erros: {
    id_invalido: 'O id do participante é invalido',
    endereco_preencher: 'Cadastre um Endereço',
    participante_sem_criterios_aceitacao:
      'Participante não possui Critérios de Aceitação',
    verificar_campos_adicionais: 'Verifique os campos adicionais',
    cep_invalido: 'CEP inválido!',
    cnpj_invalido:'CNPJ inválido!',
    participante_responsavel:
      'O Participante responsável faturamento não pode ter um responsável pelo faturamento',
  },
  validacoes: {
    enderecos_vazio: 'Precisa cadastrar um endereço!',
    formulario_invalido: 'Por favor, verifique os campos!',
    endereco_sede: 'Obrigatório um endereço do tipo sede!',
    um_endereco_sede: 'Permitido somente um endereço do tipo sede!',
    ja_existe_sede: 'Já existe um endereço Sede',
    primeiro_endereco_sede:
      'Não tem endereço Sede cadastrado, obrigatório ter um!',
      cnpj_invalido:'CNPJ inválido!'
  },
  valores:{
    alertas:{
      confirmar_buscar_cnpj:'Todos os dados já preenchidos serão apagados. Deseja continuar?'
    }
  }
};
