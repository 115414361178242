export default {
  titulos: {
    listagem: 'Tipos de Visita',
    novo: 'Novo Tipo de Visita',
    editar: 'Editar Tipo de Visita',
  },
  cadastro_sucesso: 'Tipo de Visita salvo com sucesso!',
  exclusao_sucesso: 'Tipo de Visita excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    descricao: 'Descrição',
    acessivel_cliente: 'Acessível para Clientes',
  },
  formulario: {
    nome: 'Nome',
    descricao: 'Descrição',
    acessivel_cliente: 'Acessível para Clientes',
  },
  filtro: {
    nome: 'Nome',
    descricao: 'Descrição',
  },
  erros: {
    id_invalido: 'O id do Tipo de Visita é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
};
