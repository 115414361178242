export default {
  titulos: {
    listagem: 'Locais de Execução',
    novo: 'Novo Local de Execução',
    editar: 'Editar Local de Execução',
  },
  cadastro_sucesso: 'Local de execução salvo com sucesso!',
  exclusao_sucesso: 'Local de execução excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    instalacaoCliente: 'Instalações do Cliente',
    descricaoCertificado: 'Descrição no Certificado',
    os_a_partir_de_envio_de_material: 'OS a partir de Envio de Material',
    os_a_partir_de_agendamento: 'OS a partir de Agendamento',
  },
  tooltip: {
    os_a_partir_de_envio_de_material: 'Instalações do Cliente deve ser Não',
    os_a_partir_de_agendamento: 'Instalações do Cliente deve ser Sim', 
  },
  formulario: {
    nome: 'Nome',
    instalacaoCliente: 'Instalações do Cliente',
    descricao_certificado: 'Descrição no Certificado',
    os_a_partir_de_envio_de_material: 'OS a partir de Envio de Material',
    os_a_partir_de_agendamento: 'OS a partir de Agendamento',    
  },
  filtro: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id do local de execução é inválido',
  },
  validacoes: {
    formulario_invalido: 'Formulário inválido',
  },
};
