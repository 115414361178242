import { BasicoApi } from '@common/api';
const LocalExecucaoService = {
  listar: function (parametros, escoposIds = []) {
    let link = '/localexecucao';
    if(escoposIds.length > 0) link = `${link}?escoposIds=${escoposIds.join('&escoposIds=')}`
    return BasicoApi.get()(link, parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/localexecucao/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/localexecucao/${form.id}`, form);
    return BasicoApi.post()('/localexecucao', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/localexecucao/${id}`);
  },
};

export default LocalExecucaoService;
