import { BasicoApi } from '@common/api';

const EndpointsService = {
  listar: function (parametros) {
    return BasicoApi.get()('/Endpoint', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/Endpoint/${id}`);
  },
  salvar: function (form) {

    if (form.id) return BasicoApi.put()(`/Endpoint/${form.id}`, form);
    return BasicoApi.post()('/Endpoint', form);
  },
  listarSql: function (parametros) {
    return BasicoApi.get()('/Endpoint/sql', parametros);
  },
 excluir: function (id) {
    return BasicoApi.delete()(`/Endpoint/${id}`);
  },
};

export default EndpointsService;
