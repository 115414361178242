export default [
  {
    meta: { menuId: 'procedimentos-eletronicos' },
    name: 'procedimento-eletronico-certificado',
    path: 'procedimento_eletronico/certificado/:id?',
    props: true,
    component: () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/Certificado'),
  },
  {
    meta: { menuId: 'procedimentos-eletronicos' },
    name: 'procedimento-eletronico-certificado-revisao',
    path: 'procedimento_eletronico/certificado/revisao/:id?',
    props: true,
    component: () =>
      import('@/views/modulos/cadastros/procedimentos_eletronicos/Certificado'),
  },
  {
    name: 'stimusoft',
    path: 'stimusoft',
    props: true,
    component: () => import('@/views/StimuSoft'),
  },
];
