<template lang="">
  <div>
    <div class="d-flex align-end">
      <div class="row">
        <input-text
          v-model="filtro.nome"
          :label="$t('componentes.input_area_acreditacao.nome')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.email"
          :label="$t('componentes.input_area_acreditacao.email')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.setor"
          :label="$t('componentes.input_area_acreditacao.setor')"
          class="col-12 col-md-4"
        />
      </div>
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      :selecionar-apenas-um="!multiplos"
      @paginar="listar"
    />
  </div>
</template>
<script>
import UsuarioService from '@common/services/cadastros/UsuarioService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    areaAcreditacaoExecutanteId: {type : String, default: null},
    areaAcreditacaoSignatarioId : {type : String, default: null}
  },
  data() {
    return {
      filtro: {
        nome: '',
        email:'',
        setor:''
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_area_acreditacao.tabela.nome'),
            sortable: false,
          },
          {
            value: 'email',
            text: this.$t('componentes.input_area_acreditacao.tabela.email'),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t('componentes.input_area_acreditacao.tabela.setor'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        flagParticipanteConta: true,
        ...this.filtroAplicado,
      };
      if(this.areaAcreditacaoExecutanteId) parametros.areaAcreditacaoExecutanteId = this.areaAcreditacaoExecutanteId;
      if(this.areaAcreditacaoSignatarioId) parametros.areaAcreditacaoSignatarioId = this.areaAcreditacaoSignatarioId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      UsuarioService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
