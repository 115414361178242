import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumContatoResponsabilidade.Certificado'),
    value: 'Certificado',
  },
  {
    text: i18n.t('enums.EnumContatoResponsabilidade.NotaFiscal'),
    value: 'NotaFiscal',
  },
  {
    text: i18n.t('enums.EnumContatoResponsabilidade.Orcamento'),
    value: 'Orcamento',
  },
  {
    text: i18n.t('enums.EnumContatoResponsabilidade.Contrato'),
    value: 'Contrato',
  },
];
