<template lang="">
  <div>
    <div
      v-if="aprovacaoEnvioMaterialDado?.id"
      class="mt-2"
    >
      <span>{{ $t('modulos.envio_material.aprovacao_informacao')}}</span>
      <div class="row my-1">
        <input-text
          :value="aprovacaoEnvioMaterialDado?.codigoTag"
          :label="$t('modulos.envio_material.instrumentos.tabela.codigo')"
          class="col-12 col-md-2"
          disabled
        />
        <input-text
          :value="aprovacaoEnvioMaterialDado?.tipoInstrumento?.nome"
          :label="$t('modulos.envio_material.instrumentos.tabela.tipo_instrumento')"
          class="col-12 col-md-3"
          disabled
        />
        <input-text
          :value="aprovacaoEnvioMaterialDado?.marca?.nome"
          :label="$t('modulos.envio_material.instrumentos.tabela.marca')"
          class="col-12 col-md-2"
          disabled
        />
        <input-text
          :value="aprovacaoEnvioMaterialDado?.modeloInstrumento?.nome"
          :label="$t('modulos.envio_material.instrumentos.tabela.modelo_instrumento')"
          class="col-12 col-md-3"
          disabled
        />
        <input-text
          :value="aprovacaoEnvioMaterialDado?.numeroSerie"
          :label="$t('modulos.envio_material.instrumentos.tabela.numero_serie')"
          class="col-12 col-md-2"
          disabled
        />
      </div>
    </div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="selecionados"
      class="mt-2"
      style="min-height: 300px"
      scroll-height="290px"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      linha-hover
      filtros-com-colunas
      filtro-global-focus
      :mostrar-acoes="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :selecionar-apenas-um="!multiplos"
      ajustar-altura-linha
      @paginar="listar"
      @filtrarGlobal="filtrar"
      @filtroGlobal="(v) => (filtro.filter = v)"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
    />
  </div>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    clienteSelecionado: { type: Object, default: null },
    tiposInstrumentos: { type: [Array, Object], default: null },
    verificacaoIntermediaria: { type: Boolean, default: false },
    participanteId: { type: String, default: '' },
    somenteAtivos: { type: Boolean, default: false },
    instrumentoPadrao: { type: Boolean, default: null },
    aprovacaoEnvioMaterialDado: { type: Object, default: () => { } },
  },
  data() {
    return {
      filtro: {
        codigoTag: '',
        nomeTipoInstrumento: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('componentes.input_instrumento.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.tipo'),
            sortable: false,
          },
          {
            value: 'marca.nome',
            text: this.$t('componentes.input_instrumento.tabela.marca'),
            sortable: false,
          },
          {
            value: 'modeloInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.modelo'),
            sortable: false,
          },
          {
            value: 'numeroSerie',
            text: this.$t('componentes.input_instrumento.tabela.numero_serie'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    focusFiltroGlobal: async function () {
      await this.$nextTick();
      await this.$nextTick();
      this.$refs['tabela'].focusFiltroGlobal();
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: 'codigoTag',
        ...this.filtroAplicado,
      };
      if (this.clienteSelecionado)
        parametros.ParticipanteId = this.clienteSelecionado?.value;

      if (this.verificacaoIntermediaria)
        parametros.flagVerificacaoIntermediaria = true;

      if (this.participanteId) parametros.participanteId = this.participanteId;

      let TiposInstrumentosIds = [];

      if (this.tiposInstrumentos) {
        TiposInstrumentosIds = `TiposInstrumentosIds=${this.tiposInstrumentos.value}`;
      }

      if (this.somenteAtivos) {
        parametros.flagAtivo = true;
      }

      if (this.instrumentoPadrao) {
        parametros.FlagPadrao = this.instrumentoPadrao;
      }

      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.listar(parametros, TiposInstrumentosIds)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
