export default {
  titulos: {
    titulo:"Programações por Tipo de Instrumento",
    listagem:"Kanban - Programação Manual",
  },
  cabecalho:{
    setor:"Setor",
    situacao:"Situação",
    data:"Data",
    horas_disponiveis:"Total de horas disponíveis",
    horas_alocadas:"Total de horas alocadas",

  },
  tabela:{
    tipo_instrumento:"Tipo de Instrumento",
    quantidade:"Quantidade",
    horas:"Horas Alocadas",
  }
};
