import modulos from './modulos';
import RotasBlankLayout from './RotasBlankLayout';

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/Login'),
    meta: { requiresAuth: false },
  },

  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@layouts/full-layout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@views/system/DashboardPage'),
      },
      ...modulos,
    ],
  },
  {
    path: '/',
    component: () => import('@layouts/blank-layout'),
    meta: { requiresAuth: true },
    children: [...RotasBlankLayout],
  },
  {
    path: '*',
    redirect: '/login',
  },
];
