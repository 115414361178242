export default {
  codigo: 'Código',
  data_recebimento: 'Recebimento',
  cliente_participante: 'Cliente',
  cliente_contato: 'Contato',
  tabela: {
    codigo: 'Código',
    data_recebimento: 'Recebimento',
    cliente_participante: 'Cliente',
    cliente_contato: 'Contato',
    participante_solicitante: 'Solicitante',
  },
  titulo_modal: 'Ordens de Serviço',
};
