import { BasicoApi } from '@common/api';
const CargoFuncaoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/cargoFuncao', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/cargoFuncao/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/cargoFuncao/${form.id}`, form);
    return BasicoApi.post()('/cargoFuncao', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/cargoFuncao/${id}`);
  },
};

export default CargoFuncaoService;
