export default {
  titulos: {
    listagem: 'Tipos de Instrumentos',
    novo: 'Novo Tipo de Instrumento',
    editar: 'Editar Tipo de Instrumento',
    faixas: 'Faixas',
    visualizar: 'Visualizar Tipo de Instrumento',
    tipo_instrumento: 'Tipo de Instrumento',
    guia_navegador: 'Instrumentos',
  },
  cadastro_sucesso: 'Tipo de Instrumento salvo com sucesso!',
  edicao_sucesso: 'Tipo de Instrumento editado com sucesso!',
  exclusao_sucesso: 'Tipo de Instrumento excluído com sucesso!',
  ponto_sucesso: 'Ponto de Calibração salvo com sucesso!',
  faixas: 'Faixas',
  tabela: {
    nome: 'Nome',
    tags: 'Tags',
    procedimento: 'Procedimento',
    setor_responsavel: 'Setor Responsável',
    somente_faixas_padronizadas: 'Somente Faixas Padronizadas',
    temperatura_requerida: 'Temperatura Requerida',
    pontos_de_calibracao: 'Pontos de Calibração',
    faixas: {
      descricao: 'Descrição',
      faixa: 'Faixa',
      resolucao: 'Resolução',
      valor: 'Valor',
      amplitude_minima: 'Amplitude Mínima',
      amplitude_maxima: 'Amplitude Máxima',
      unidade_medida: 'Unidade de Medida',
      quantidade_pontos: 'Quantidade de Pontos',
    },
    sequencia_de_setores: {
      associar_desassociar: 'Setores',
      nome: 'Nome',
      tipo: 'Tipo',
      posicao: 'Posição',
      sigla: 'Sigla',
      participante: 'Participante',
      local_execucao: 'Local de Execução',
      gatilhos_movimentacao: 'Gatilhos de Movimentação',
    },
    procedimentos: {
      codigo: 'Código',
      titulo: 'Título',
      procedimentos: 'Procedimentos',
    },
    pontos: {
      valor: 'Valor',
      descricao: 'Descrição',
      tipo_ponto: 'Tipo de ponto',
      origem_ponto: 'Origem do Ponto',
    },
  },
  formulario: {
    nome: 'Nome',
    buscar: 'Buscar',
    procedimento: 'Procedimento',
    tipo_setor: 'Tipo Setor',
    executa_manutencao: 'Executa Manutenção',
    executa_calibracao: 'Executa Calibração',
    sigla: 'Sigla',
    setor_responsavel: 'Setor Responsável',
    somente_faixas_padronizadas: 'Somente Faixas Padronizadas',
    temperatura_requerida: 'Temperatura Requerida',
    umidade_requerida: 'Umidade Requerida',
    pressao_requerida: 'Pressão Requerida',
    permitir_coluna_angular: 'Permitir Coluna Angular',
    calcular_resultado_final_leitura: 'Calcular Resultado Final Leitura',
    calcular_resultado_manualmente: 'Calcular Resultado Manualmente',
    classificacao: 'Classificação',
    area_de_acreditacao: 'Área de Acreditação',
    pontos_duplicados: 'Permite Pontos Duplicados',
    criar_pontos_tipo: 'Permite Criar Pontos somente no Tipo',
    observacao: 'Observação',
    tags: 'Tags',
    tag: 'Tag',
    descricao: 'Descrição',
    tooltip: 'Preencha amplitudes, resolução e unidade de medida',
    abas: {
      dados_do_tipo_de_instrumento: 'Dados do Tipo de Instrumento',
      procedimentos: 'Procedimentos',
      faixas: 'Faixas',
      sequencia_de_setores: 'Sequência de Setores',
    },
    faixa: {
      nova_faixa: 'Nova Faixa',
      editar_faixa: 'Editar Faixa',
      amplitude_minima: 'Amplitude Mínima',
      amplitude_maxima: 'Amplitude Máxima',
      resolucao: 'Resolução',
      unidade_de_medida: 'Unidade de Medida',
      valor: 'Valor',
    },
    sequencia_de_setores: {
      nome: 'Nome',
      tipo: 'Tipo',
      posicao: 'Posição',
      sigla: 'Sigla',
    },
    pontos: {
      valor: 'Valor',
      descricao: 'Descrição',
      tipo_ponto: 'Tipo de ponto',
      origem_ponto: 'Origem do Ponto',
    },
  },
  validacoes: {
    formulario_invalido: 'Por favor, revise os campos',
    obrigatorio_faixa: 'Obrigatório inserir uma faixa',
    obrigatorio_procedimento: 'Obrigatório inserir um Procedimento',
    obrigatorio_setor: 'Obrigatório ter no mínimo dois setores',
    sequencia_setor:
      'O primeiro Setor deve ter uma posição inicial, e o último uma posição final!',
    amplitude_maxima: 'Amplitude máxima deve ser maior que a mínima',
    valor: 'Valor deve estar dentro da faixa selecionada',
    valor_percentual: 'Valor percentual deve estar entre 0 e 100',
    ponto_existente: 'Já existe um ponto cadastrado para este tipo e valor'
  },
  erros: {
    id_invalido: 'O id do Tipo de Instrumento é inválido',
    selecione_faixa: 'Por favor selecione uma Faixa',
  },
  botoes: {
    adicionar_faixa: 'Nova Faixa',
    procedimentos: 'Selecionar Procedimentos',
    associar_desassociar: 'Selecionar Setores',
    novo_ponto: 'Novo Ponto de Calibração',
    ponto_calibracao: 'Ponto de Calibração',
    selecionar_pontos: 'Selecionar Pontos de Calibração',
  },
};
