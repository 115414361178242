import { BasicoApi } from '@common/api';
const OrcamentoAnexoService = {
  listar: function (id) {
    return BasicoApi.get()(`/OrcamentoAnexo/orcamento/${id}`);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/OrcamentoAnexo/${id}`);
  },
  salvar: function (anexo) {
    return BasicoApi.post()(`/OrcamentoAnexo`, anexo);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`OrcamentoAnexo/${id}`);
  },
};
export default OrcamentoAnexoService;
