import { BasicoApi } from '@common/api';
const OrcamentosService = {
  listar: function (parametros) {
    return BasicoApi.get()('/orcamento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/orcamento/${id}`);
  },
  buscaUltimosPrecos: function (id, params) {
    return BasicoApi.post()(`/orcamento/ultimo-preco/cliente/${id}`, params);
  },
  duplicarOrcamento: function (id) {
    return BasicoApi.post()(`/orcamento/duplicar/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/orcamento/${form.id}`, form);
    return BasicoApi.post()('/orcamento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/orcamento/${id}`);
  },
  alteraStatusOrcamento: function (
    idOrcamento,
    status,
    orcamentosItens = null,
    email = false,
    comentario = null,
    pedidoCompra = null
  ) {
    let params = {
      id: idOrcamento,
      statusOrcamento: status,
      enviaEmail: email,
      orcamentosItens: orcamentosItens ? [...orcamentosItens] : null,
      comentario: comentario,
      pedidoCompra: pedidoCompra,
    };
    return BasicoApi.put()(`/orcamento/alterar-status`, params);
  },
  alteraStatusOrcamentoLista: function (params) {
    return BasicoApi.put()(`/orcamento/alterar-lista-status`, params);
  },
  revisaoOrcamento: function (id) {
    return BasicoApi.post()(`/orcamento/revisao/${id}`);
  },
  buscarRevisoes: function (id) {
    return BasicoApi.get()(`/orcamento/${id}/revisoes/`);
  },
  alterarOrcamentoCliente: function (id, params) {
    return BasicoApi.put()(`/orcamento/${id}/alterar-pelo-cliente`, params);
  },
  aprovarOrcamento: function (form) {
    return BasicoApi.put()(`/orcamento/alterar-status`, {...form, statusOrcamento: 2, enviaEmail: false});
  },
  reprovarOrcamento: function (form) {
    return BasicoApi.put()(`/orcamento/alterar-status`, { ...form, statusOrcamento: 3, enviaEmail: false});
  },
};

export default OrcamentosService;
