export default {
  titulos: {
    listagem: 'Prioridades',
    subtitulo:"As prioridades estão ordenadas da maior para a menor prioridade",
    novo: 'Nova Prioridade',
    editar: 'Editar Prioridade',
  },
  cadastro_sucesso: 'Prioridade salva com sucesso!',
  exclusao_sucesso: 'Prioridade excluída com sucesso!',
  tabela: {
    nome: 'Nome',
  },
  formulario: {
    nome: 'Nome',
  },
  filtro: {
    nome: 'Nome',
  },
  erros: {
    id_invalido: 'O id da prioridade é inválido',
  },
};
