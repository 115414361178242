<template>
  <div>
    <v-expansion-panels v-model="conteudoComputado" flat :readonly="naoRetrair">
      <v-expansion-panel
        v-model="conteudoComputado"
        class="accordion-padrao"
        :class="naoRetrair ? 'nao-retrair' : ''"
      >
        <v-expansion-panel-header
          :hide-actions="naoRetrair"
          :style="alterarCor ? 'color: #0d47a1' : ''"
        >
          <div class="d-flex align-end">
            <span
              class="mr-2 input-label"
              :style="corAzulTexto ? `color: ${corTexto} !important` : ''"
              >{{ titulo }}</span
            >
            <v-divider :color="alterarCor ? '#0d47a1' : ''" />
          </div>

          <template v-slot:actions>
            <v-icon class="ml-4" :color="alterarCor ? corTexto : ''">
              $downCircleOutline
            </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <slot />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    titulo: {
      type: String,
      default: 'Titulo',
    },
    corTexto: {
      type: String,
      default: '#0d47a1',
    },
    naoRetrair: {
      type: Boolean,
      default: false,
    },
    alterarCor: {
      type: Boolean,
      default: false,
    },
    corAzulTexto: {
      type: Boolean,
      default: false,
    },
    iniciarRetraido: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      conteudo: 0,
    };
  },
  computed: {
    conteudoComputado: {
      set: function (valor) {
        this.conteudo = valor;
      },
      get: function () {
        if (this.iniciarRetraido) return null;
        if (this.naoRetrair) return 0;
        return 0;
      },
    },
  },
};
</script>
<style lang="scss">
.accordion-padrao {
  .v-expansion-panel-header {
    padding-left: 0;
    padding-right: 0;
    min-height: 48px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
.v-expansion-panel {
  background: transparent !important;
}

.cor-azul-texto {
  color: #0d47a1 !important;
}
</style>
