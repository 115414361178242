var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.labelExterno && !_vm.semLabel)?_c('span',{staticClass:"mb-3 d-block v-label--externo d-flex"},[(!_vm.tooltip)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.label))])]}}],null,false,2075065689)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]),(_vm.obrigatorio && _vm.label)?_c('span',{staticClass:"obrigatorio ml-1"},[_vm._v("*")]):_vm._e()],1):_vm._e(),_c('div',{ref:"input-div",class:`input-ide ${_vm.erros.length > 0 || _vm.regraObrigatorio ? 'input-ide__error' : ''
    }`},[_c('div',{staticClass:"input-ide__wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.conteudo),expression:"conteudo"}],ref:"input",staticClass:"input-ide__textarea",class:_vm.disabled ? 'disabled' : '',staticStyle:{"height":"32px"},attrs:{"autocomplete":"off","autocorrect":"off","autocapitalize":"off","spellcheck":"false","rows":"1","disabled":_vm.disabled},domProps:{"value":(_vm.conteudo)},on:{"focus":_vm.focus,"blur":_vm.blur,"keydown":_vm.keyDown,"click":() => {
      this.sugestoesAberto = false;
      this.errosAberto = false;
    },"input":function($event){if($event.target.composing)return;_vm.conteudo=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"input-ide__codigos"},_vm._l((_vm.codigosHtml),function(tags,i){return _c('span',{key:`tag-${i}`,class:`input-ide__${tags.tipo}`},[_vm._v(_vm._s(tags.expressao))])}),0),_c('div',{staticClass:"input-ide__icon__erro"},[_c('v-icon',{staticClass:"mr-2 mt-1",staticStyle:{"height":"14px","cursor":"pointer"},on:{"click":_vm.abrirErros}},[_vm._v(" $alertWarningtOutline ")])],1)])]),(_vm.erros.length > 0 && _vm.errosAberto)?_c('div',{staticClass:"input-ide__erros"},[_c('div',{staticClass:"input-ide__erros_wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t('componentes.input_ide.mensagem_erro'))+":")]),_vm._l((_vm.erros),function(erro,i){return _c('div',{key:`erro-${i}`},[_c('div',{staticClass:"input-ide__mensagem_erro"},[_vm._v(" "+_vm._s(_vm.$t(`componentes.input_ide.erros.${erro.tipo.replace('-', '_')}`))+" ")]),_c('div',{staticClass:"input-ide__erros_expressao"},[_vm._v(" "+_vm._s(erro.expressao)+" ")])])})],2)]):_vm._e(),(_vm.regraObrigatorio)?_c('div',{staticClass:"v-text-field__details mt-1 ml-3"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(this.$t('inputs.rules.required'))+" ")])])])]):_vm._e(),_c('div',{class:`input-select-ide ${!(_vm.sugestoesAberto && !_vm.errosAberto) ? 'sem-display' : ''
    }`},[_c('v-select',{ref:"selectMenu",attrs:{"items":_vm.options,"label":"Selecione uma opção","dense":"","flat":"","solo":"","no-data-text":"","menu-props":{ contentClass: 'menu-input-ide' }},on:{"change":_vm.adicionarTexto2},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"w-100"},[(!item.value.funcao)?_c('div',[_c('v-icon',{staticClass:"ml-2 mr-1",staticStyle:{"width":"15px"}},[_vm._v(" $variable ")]),_vm._v(" "+_vm._s(item.text)+" ")],1):_vm._e(),(item.value.funcao)?_c('div',{staticClass:"input-ide__funcao"},[_c('div',{staticClass:"row input-ide select"},[_c('div',{staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"ml-2 mr-1",staticStyle:{"width":"15px"}},[_vm._v(" $function ")]),_vm._v(" "+_vm._s(item.text)+" ")],1)]),_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" Fórmula: ")]),_c('div',{staticClass:"input-ide__wrapper flex-fill"},[_c('div',{staticClass:"input-ide__codigos"},_vm._l((_vm.formatarTagsHtml(
    _vm.tokenizeExpression(item.value.elemento.texto)
  )),function(tags,i){return _c('span',{key:`tag-${i}`,class:`input-ide__${tags.tipo}`},[_vm._v(_vm._s(tags.expressao))])}),0)])],1)]),_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" Exemplo: ")]),_c('div',{staticClass:"input-ide__wrapper flex-fill"},[_c('div',{staticClass:"input-ide__codigos"},_vm._l((_vm.formatarTagsHtml(
    _vm.tokenizeExpression(item.value.elemento.exemplo)
  )),function(tags,i){return _c('span',{key:`tag-${i}`,class:`input-ide__${tags.tipo}`},[_vm._v(_vm._s(tags.expressao))])}),0)])],1)])])]):_vm._e(),(item.value.funcao)?_c('div',{staticStyle:{"font-size":"11px","padding-left":"10px","padding-bottom":"5px"}},[_vm._v(" "+_vm._s(item.value.elemento.ajuda)+" ")]):_vm._e()])]}}]),model:{value:(_vm.conteudoSelect),callback:function ($$v) {_vm.conteudoSelect=$$v},expression:"conteudoSelect"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }