// BaseTable.js

export default {
  baseTable: {
    inputs: {
      search: {
        placeholder: 'Procurar'
      }
    },
    slots: {
      loading: 'Carregando...',
      noResults: 'Nenhum registro encontrado',
      noData: 'Nenhum registro disponível'
    },
    registros: 'nenhum registro | 1 registro | {count} registros',
    itemGroupLength: '1 item | {count} itens',
    tooltips: {
      filters: 'Filtros'
    }
  }
}
