// buttons.js

export default {
  buttons: {
    yes: 'Sim',
    cancel: 'Cancelar',
    save: 'Salvar',
    add: 'Adicionar',
    create: 'Criar',
    delete: 'Excluir',
    edit: 'Editar',
    login: 'Acessar',
    logout: 'Sair',
    search: 'Pesquisar',
    confirm: 'Confirmar',
    filter: 'Filtrar'
  }
}
