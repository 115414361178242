<template lang="">
  <div>
    <div class="d-flex align-end pt-2 pb-3">
      <input-text
        ref="input"
        v-model="filtro.nome"
        :auto-focus="true"
        :placeholder="$t('componentes.input_usuarios.nome_placeholder')"
        :label="$t('componentes.input_usuarios.nome')"
        class="flex-fill"
        @enter="filtrar"
      />
      <botao-padrao outlined color="secondary" class="ml-2" @click="filtrar">
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      scroll-height="290px"
      selecionar-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :mostra-header="false"
      :selecionar-apenas-um="!multiplos"
      :mostrar-acoes="false"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @paginar="listar"
    />
  </div>
</template>
<script>
import UsuarioService from '@common/services/cadastros/UsuarioService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    areaAcreditacaoExecutanteId: { type: String, default: null },
    areaAcreditacaoSignatarioId: { type: String, default: null },
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_usuarios.tabela.nome'),
            sortable: false,
          },
          {
            value: 'login',
            text: this.$t('componentes.input_usuarios.tabela.login'),
            sortable: true,
          },
          {
            value: 'email',
            text: this.$t('componentes.input_usuarios.tabela.email'),
            sortable: false,
          },
          {
            value: 'papel.nome',
            text: this.$t('componentes.input_usuarios.tabela.papel'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
    show: {
      handler() {
        this.filtro.nome = '';
        this.filtrar();
        this.$refs.input.$el.querySelector('input').focus();
      },
    },
  },
  mounted() {
    this.listar();
    this.$nextTick(function () {
      this.$refs.input.$el.querySelector('input').focus();
    });
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: this.tabela.porPagina | porPagina,
        flagParticipanteConta: true,
        sort: 'Nome',
        nome: this.filtroAplicado.nome?.trim() || '',
        ...this.filtroAplicado,
      };
      if (this.areaAcreditacaoExecutanteId)
        parametros.areaAcreditacaoExecutanteId =
          this.areaAcreditacaoExecutanteId;
      if (this.areaAcreditacaoSignatarioId)
        parametros.areaAcreditacaoSignatarioId =
          this.areaAcreditacaoSignatarioId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      UsuarioService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
