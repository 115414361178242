import { BasicoApi } from '@common/api';

const NotificacaoService = {
  listar(parametros) {
    return BasicoApi.get()('/notificacaoSistema', parametros);
  },
  listarPorId(id) {
    return BasicoApi.get()(`/notificacaoSistema/${id}`);
  },
  marcarComoLida(id, params) {
    return BasicoApi.patch()(`/notificacaoSistema/${id}`, params);
  },
};

export default NotificacaoService;
