import { AuthApi } from '@common/api';
const PapelService = {
  listar: function (parametros) {
    return AuthApi.get()('/papel', parametros);
  },
  buscar: function (id) {
    return AuthApi.get()(`/papel/${id}`);
  },
  salvar: function (form) {
    if (form.id) return AuthApi.put()(`/papel/${form.id}`, form);
    return AuthApi.post()('/papel', form);
  },
  excluir: function (id) {
    return AuthApi.delete()(`/papel/${id}`);
  },
};

export default PapelService;
