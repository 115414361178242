<template lang="">
  <div>
    <div class="d-flex align-end">
      <input-text
       ref="input"
        v-model="filtro.filter"
        :label="$t('componentes.input_tabela_registro.filtrar')"
        class="flex-fill"
        @enter="filtrar"
      />
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      scroll-height="290px"
      selecionar-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :mostra-header="false"
      :selecionar-apenas-um="!multiplos"
      :mostrar-acoes="false"
      @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
      @paginar="listar"
    />
  </div>
</template>
<script>
import TabelaService from '@common/services/cadastros/TabelaService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: false },
    listarAtualizacao: { type: Number, default: 1 },
    value: { type: Array, default: () => [] },
    tiposInstrumentos: { type: Object, default: null },
    flagUltimaPublicada: { type: Boolean, default: false },

  },
  data() {
    return {
      filtro: {
        nomeProcedimentoEletronico: '',
        nomeTabela: '',
        descricaoFaixa: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'procedimentoEletronico',
            text: this.$t('componentes.input_tabela_registro.tabela.procedimento'),
            sortable: false,
            formatter: (proc) => {
              if(proc){
                return `${proc.codigo} - ${proc.nome}`
              }

            },
          },
          {
            value: 'nome',
            text: this.$t('componentes.input_tabela_registro.tabela.tabela_registro'),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('componentes.input_tabela_registro.tabela.faixa'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    listarAtualizacao() {
      this.listar()
    },
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      if (this.flagUltimaPublicada) {
        parametros.flagUltimaPublicada = this.flagUltimaPublicada;
      }

      this.$store.dispatch('Layout/iniciarCarregamento');
      TabelaService.listarPorInstrumento(parametros, this.tiposInstrumentos.value)
        .then((res) => {
          const dados = [];

          for (const dado of res.data.items) {
            dados.push({
              ...dado,
              faixa: `(${dado.amplitudeMinima} a ${dado.amplitudeMaxima}) ${dado.unidadeMedida.nome}`,
            });
          }

          this.tabela.dados = dados;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
