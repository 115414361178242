export default {
  titulos: {
    titulo: 'Kanban',
  },
  cadastro_sucesso: 'Coluna salva com sucesso!',
  exclusao_sucesso: 'Coluna de orçamento excluida com sucesso!',
  ordenacao_sucesso: 'Ordenação das colunas salva com sucesso!',
  erros: {
    id_invalido: 'O id da coluna é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
};
