export default {
  titulos: {
    listagem: 'Conversor de Unidades',
  },
  tabela: {
    para: 'Para',
    valor: 'Valor',
    exponencial: 'Exponencial',
  },
  formulario: {
    valor: 'Valor',
    unidade_medida: 'Unidade de Medida',
    arredondamento: 'Arredondamento',
  },
  modal: {
    titulo: 'Fatores de Conversão',
    unidade_medida: 'Unidade de Medida',
    prefixo: 'De:',
    nome: 'Para',
    simbolo: 'Símbolo',
    fator: 'Fator',
  },
  filtro: {
    nome: 'Nome',
    simbolo: 'Símbolo',
    grandezaFisica: 'Grandeza Física',
  },
  validacoes: {
    formulario_invalido: 'Preencher Formulário',
  },
  erros: {
    id_invalido: 'O id da unidade de medida é inválido',
  },
};
