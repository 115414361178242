import { BasicoApi } from '@common/api';
const GrandezaFisicaService = {
  listar: function (parametros) {
    return BasicoApi.get()('/grandezafisica', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/grandezafisica/${id}`);
  },
  buscarGrandezaFisicaUnidadeMedida: function (id, parametros) {
    return BasicoApi.get()(`/grandezaFisica/${id}/unidades-medidas`, parametros);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/grandezafisica/${form.id}`, form);
    return BasicoApi.post()('/grandezafisica', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/grandezafisica/${id}`);
  },
};

export default GrandezaFisicaService;
