import { BasicoApi } from '@common/api';
const ChecklistService = {
  listar: function (parametros) {
    return BasicoApi.get()('/checklist', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/checklist/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/checklist/${form.id}`, form.request);
    return BasicoApi.post()('/checklist', form.request);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/checklist/${id}`);
  },
  buscarByTipoInstrumentoAndTipoCheckList: function (tipoInstrumentoId, tipoChecklist) {
    return BasicoApi.get()(`/checklist/tipoChecklist/${tipoChecklist}/tipoInstrumento/${tipoInstrumentoId}`)
  }
};

export default ChecklistService;
