export default {
  nome: 'Nome',
  tabela: {
    modelo: 'Modelo',
    fabricante: 'Fabricante',
    placa: 'Placa',
    condutor: 'Principal Condutor',
    agendamento: 'Agendamento'
  },
  titulo_modal: 'Veículos',
};
