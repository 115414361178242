import { mapActions, mapGetters } from 'vuex';
import { datetimeNow } from '@helpers/datetime';

export const myMixins = {
  data() {
    return {
      alertMessage: '',
      alertType: 'error',
      alertIsAutoClose: false,
    };
  },
  computed: {
    ...mapGetters(['blockUi', 'spinnerStatus']),
    ...mapGetters('ModuleErrors', ['errorsList']),
    currentUser() {
      return this.user;
    },
    currentRoute() {
      return this.$route;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    todaySpacetime() {
      const today = datetimeNow('', '{year}-{iso-month}-{date-pad}');

      return today.date;
    },
    currentMoneySufix() {
      switch (this.$i18n.locale) {
        case 'pt-BR':
          return 'R$';
        case 'en':
          return '$';
        default:
          return 'R$';
      }
    },
  },
  methods: {
    ...mapActions(['changeBlockUi', 'changeSpinner']),
    ...mapActions('ModuleErrors', ['changeError', 'clearErrorStore']),
    setBlockUi(value) {
      if (value) {
        this.changeBlockUi(value);
      } else {
        const timer = 200;

        setTimeout(() => {
          this.changeBlockUi(value);
        }, timer);
      }
    },
    async setAlert(ojbError) {
      if (this.errorsList.length) await this.clearErrorStore();

      this.changeError(ojbError);
    },
    setSpinner(message, status) {
      this.changeSpinner({ message, status });
    },
    closeAlert() {
      this.setBlockUi(false);
      this.clearErrorStore();

      this.alertMessage = '';
      this.alertIsAutoClose = false;
    },
    goToRouteName(routeName) {
      if (routeName !== this.$route.name)
        this.$router.push({ name: routeName });
    },
    scrollToElement(ref) {
      this.$refs[ref].$el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    },
    formatDocument(value) {
      const size = value.length;

      return size === 14 ? '##.###.###/####-##' : '###.###.###-##';
    },
    formatToCurrency(value) {
      let currentCurrency = 'BRL';
      if (this.$i18n.locale == 'en') currentCurrency = 'USD';

      const formatter = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: currentCurrency,
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
    telefoneMask(tel) {
      const splitedTelefone = tel && tel !== '' ? tel.split('') : tel;

      if (tel && splitedTelefone.length >= 5 && splitedTelefone[5] === '9')
        return '(##) #####-####';
      return '(##) ####-####';
    },
  },
  filters: {
    textUpperCase(string) {
      return string.toUpperCase();
    },
    textCapitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    textCapitalize(string) {
      return string.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    },
    formataValorPrecisaoDois(value) {
      if (!value) return value;
      return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    },
  },
};
