export default {
  titulos: {
    listagem:"Kanban - Em Execução",
    titulo:"Kanban em execução"
  },
  cabecalho:{
    setor:"Setor",
    situacao:"Situação"
  },
  tabela:{
    colaborador:"Colaborador",
    ipColaborador:"Ip do Colaborador",
    nomeMaquinaColaborador:"Nome da Máquina do colaborador",
    os:"Os",
    instrumento:"Instrumento",
    tipoInstrumento:"Tipo de Instrumento",
    tempoPadrao:"Tempo Padrão",
    tempoEmExecucao:"Tempo em Execução",
  }
};
