// Here we import all i18n modules in Portuguese

/*** remover */
import components from './modules/components';
import enumsAntigo from './modules/enums';
import misc from './modules/misc';
import pages from './modules/pages';

import geral from './geral';
import componentes from './componentes';
import modulos from './modulos';
import menu from './menu';
import login from './login';
export default {
  geral,
  componentes,
  //enums, /** todo descomentar quando terminar refatoracao */
  modulos,
  menu,
  login,
  sistema: {
    alterarParticipanteMensagem:
      'Deseja realmente alterar o participante? Todos dados não salvos serão perdidos.',
  },

  /** todo remover */
  ...enumsAntigo,
  ...components,
  ...misc,
  ...pages,
};
