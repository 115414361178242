import { BasicoApi } from '@common/api';
const KanbanProgramaManualmenteService = {
  listar: function (parametros) {
    return BasicoApi.get()('/KanbanProgramacaoManual', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/KanbanConfiguracao/${id}`);
  },
  ultimaSequencia: function (id) {
    return BasicoApi.get()(`/KanbanConfiguracao?SetorId=${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/KanbanConfiguracao`, form);
    return BasicoApi.post()('/KanbanConfiguracao', form);
  },
  salvarCartaoEmNovaColuna: function (parametros) {
    return BasicoApi.post()(`/KanbanProgramacaoManual/movimentar-cartao`,parametros);
  }

};

export default KanbanProgramaManualmenteService;
