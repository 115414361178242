import i18n from '@/i18n';

export default [
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.SemGatilho'),
        value: "SemGatilho",
    },
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.OrdemServicoCriada'),
        value: "OrdemServicoCriada",
    },
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.ManutencaoFinalizada'),
        value: "ManutencaoFinalizada",
    },
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.CalibracaoFinalizada'),
        value: "CalibracaoFinalizada",
    },
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.PlanoInspecaoFinalizado'),
        value: "PlanoInspecaoFinalizado",
    },
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.FaturamentoFinalizado'),
        value: "FaturamentoFinalizado",
    },
    {
        text: i18n.t('enums.EnumGatilhoMovimentacao.EntregaFinalizada'),
        value: "EntregaFinalizada",
    },
];