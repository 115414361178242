import helpers from '@router/helpers';
const menuId = 'formula';

export default [
  helpers.criarRota(menuId, 'formula', menuId, () =>
    import('@/views/modulos/cadastros/formula')
  ),
  helpers.criarRota(menuId, 'formula-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/formula/formulario')
  ),
  helpers.criarRota(
    menuId,
    'formula-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/formula/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'nova-formula-filha',
    `${menuId}/filha/novo`,
    () => import('@/views/modulos/cadastros/formula/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'formula-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/formula/formulario'),
    true
  ),
];
