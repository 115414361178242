<template lang="">
  <div
    class="input-padrao"
  >
    <span
      class="mb-3 d-block v-label--externo d-flex"
    >
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio && label"
        class="ml-1"
      >*</span>
    </span>
    <vue-editor
      v-model="conteudo"
      :editor-toolbar="defaultToolbar"
      :class="obrigatorio ? 'obrigatorio' : ''"
      style="border-radius: 8px"
      @text-change="removerEspacos"
    />
    <p
      v-if="obrigatorio && label"
      class="textoVermelho"
    >
      {{ $t('inputs.rules.required') }}
    </p>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
import { VueEditor } from "vue2-editor";
export default {
  name: 'InputText',
  components: { VueEditor },
  mixins: [InputMixin],
  props: {
    value: {
      type: String,
      default: ''
    },
    desabilitarAlign: {
      type: Boolean,
      default: false
    },
    desabilitarMedia: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      conteudo: this.value,
      defaultToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        this.desabilitarAlign ? undefined : [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"],
        this.desabilitarMedia ? undefined : ['image', 'video'],
        ["clean"]
      ]
    };
  },
  watch: {
    value: function () {
      this.conteudo = this.value
    }
  },
  methods: {
    adicionarClasse: function () {
      return this.obrigatorio ? 'obrigatorio' : ''
    },
    removerEspacos() {
      let regex = /^<p>\s*(.*?)\s*<\/p>$/;
      this.conteudo = this.conteudo.replace(regex, "<p>$1</p>");
      this.$emit('input', this.conteudo);
    },
  },
};
</script>
<style lang="scss" scoped>
.obrigatorio {
  border: 2px solid #ff5252 !important;
}

.semBorda {
  border: 4px solid #5266ff !important;
}

.textoVermelho {
  color: #ff5252 !important;
  font-size: 12px;
  margin-left: 10px;
}
</style>
