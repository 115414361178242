export default {
  titulos: {
    listagem: 'Roteiros de Envio de Material',
    novo: 'Novo Roteiro de Envio de Material',
    editar: 'Programar Roteiro de Envio de Material',
  },
  cadastro_sucesso: 'Roteiro de Envio de Material salvo com sucesso!',
  exclusao_sucesso: 'Roteiro de Envio de Material excluído com sucesso!',
  tabela: {
    codigo: 'Código',
    data_agendamento: 'Data de Agendamento',
    situacao: 'Situação',
  },
  formulario: {
    codigo: 'Código',
    data_agendamento: 'Data de Agendamento',
    adicionar_cliente: 'Adicionar Cliente',
    tabela: {
      ordem: 'Ordem',
      cliente: 'Cliente',
      endereco: 'Endereço',
      qtd_entregas: 'Qtd. Entregas',
      qtd_recebimentos: 'Qtd. Recebimentos',
      data_solicitacao_mais_antiga: 'Data da Solicitação Mais Antiga',
      dias_cliente_permite_envio_material:
        'Dias que o Cliente permite Envio de Material',
    },
  },
  erros: {
    id_invalido: 'O id do Roteiro é inválido',
  },
  filtro: {
    codigo: 'Código',
    data_agendamento: 'Data de Agendamento',
    situacao: 'Situação',
  },
  modal: {
    titulo: 'Clientes',
    tabela: {
      nome: 'Nome',
      endereco: 'Endereço',
      qtd_entregas: 'Qtd. Coletas Entregas',
      qtd_recebimentos: 'Qtd. Coletas Recebimentos',
      data_solicitacao_mais_antiga: 'Data da Solicitação Mais Antiga',
    },
    filtro: {
      nome: 'Nome',
      endereco: 'Endereço',
      data_solicitacao_mais_antiga: 'Data da Solicitação Mais Antiga',
    },
    reagendar_popup: {
      titulo: 'Reagendar Cliente',
      descricao: 'Informe a nova data de agendamento',
      data_agendamento: 'Data de Agendamento',
    },
  },
  historico: {
    Criado: 'Criado',
    Programado: 'Programado',
    Alterado: 'Alterado',
    Checkin: 'Check-in',
    Cancelado: 'Check-out por cancelamento',
    Reagendado: 'Check-out por reagendamento',
    Concluido: 'Check-out',
    SemAgendamento: 'Criado Recebimento sem Agendamento',
    EmExecucao: 'Iniciado',
    Entrega: 'da Entrega {codigo}',
    Recebimento: 'do Recebimento {codigo}',
    roteiro: {
      Criado: 'Criado',
      Programado: 'Programado',
      Alterado: 'Alterado',
      EmExecucao: 'Iniciado',
      Cancelado: 'Cancelado',
      Reagendado: 'Reagendado',
      Finalizado: 'Finalizado'
    },
    texto: {
      envio: 'Envio',
      por: 'por',
      agendamento: 'Agendamento',
      participante: 'Participante',
      no_cliente: 'no cliente por',
    },
  }
}
