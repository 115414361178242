import { BasicoApi } from '@common/api';

const FeriadoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/feriado', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/feriado/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/feriado/${form.id}`, form);
    return BasicoApi.post()('/feriado', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/feriado/${id}`);
  },
};

export default FeriadoService;
