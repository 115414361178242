import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumMetodo.GET'),
    value: 'GET',
  },
  {
    text: i18n.t('enums.EnumMetodo.POST'),
    value: 'POST',
  },
  {
    text: i18n.t('enums.EnumMetodo.PUT'),
    value: 'PUT',
  },
  {
    text: i18n.t('enums.EnumMetodo.DELETE'),
    value: 'DELETE',
  },
];