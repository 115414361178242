export default {
  titulos: {
    listagem: 'Tipos de Comentários',
    novo: 'Novo Tipo de Comentário',
    editar: 'Editar Tipo de Comentário',
  },
  cadastro_sucesso: 'Tipo de Comentário salvo com sucesso!',
  exclusao_sucesso: 'Tipo de Comentário excluído com sucesso!',
  tabela: {
    nome: 'Nome',
    icone: 'Ícone',
  },
  formulario: {
    nome: 'Nome',
    add_imagem: 'Adicionar Imagem',
    anexar_arquivo: 'Anexar Arquivo',
    anexar: 'Anexar',
    nome_extensao_imagem: 'Nome e Extensão da Imagem',
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido:
      'É necessário validar o formulário antes de salvar o registro.',
  },
  filtro: {
    nome: 'Nome',
  },
};
