export default {
  tabela: {
    campo: 'Campo',
    valor: 'Valor',
  },
  titulo_modal: 'Campos adicionais',
  informacoes:
    'Preencha apenas os campos que gostaria de adicionar como campo adicional.',
  importante: {
    importante: 'Importante:',
    informacao:
      ' ao preencher mais de um Campo Adicional, o Instrumento precisará possuir exatamente os mesmos Campos Adicionais com os mesmos valores.',
  },
  input_tipo: 'Digite um valor caso existir',
};
