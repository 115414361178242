import { BasicoApi } from '@common/api';
const AreaAcreditacaoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/areaAcreditacao', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/areaAcreditacao/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/areaAcreditacao/${form.id}`, form);
    return BasicoApi.post()('/areaAcreditacao', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/areaAcreditacao/${id}`);
  },
};

export default AreaAcreditacaoService;
