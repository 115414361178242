import helpers from '@router/helpers';
const menuId = 'servico-calibracao';

export default [
  helpers.criarRota(menuId, 'servico-calibracao', menuId, () =>
    import('@/views/modulos/cadastros/servico_calibracao')
  ),
  helpers.criarRota(
    menuId,
    'servico-calibracao-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/servico_calibracao/formulario')
  ),
  helpers.criarRota(
    menuId,
    'servico-calibracao-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/servico_calibracao/formulario'),
    true
  ),
];
