import { BasicoApi } from '@common/api';
const TipoSetoresService = {
  listar: function (parametros) {
    return BasicoApi.get()('/tiposetores', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/tiposetores/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/tiposetores/${form.id}`, form);
    return BasicoApi.post()('/tiposetores', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/tiposetores/${id}`);
  },
};

export default TipoSetoresService;
