import { BasicoApi} from '@common/api';
const EnvioMaterialService = {
  listar: function (parametros) {
    return BasicoApi.get()('/enviomaterial', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/enviomaterial/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/enviomaterial/${form.id}${form.flagAprovado ? '/aprovar' : ''}`, form);
    return BasicoApi.post()('/enviomaterial', form);
  },
  excluir: function (ids) {
    return BasicoApi.delete()(`/enviomaterial`, ids);
  },
  buscarClienteRoteiro: function(parametros){
    return BasicoApi.get()('/enviomaterial/clientes-roteiro', parametros);
  },
  setarOrdemServico: function(id, ordemServicoId){
    return BasicoApi.put()(`/enviomaterial/${id}/ordem-servico/${ordemServicoId}`);
  },
  gerarQRCode: function(envioMaterialId){
    return BasicoApi.post()(`/enviomaterial/${envioMaterialId}/qrcode`);
  },
  lerQRCode: function(envioMaterialId, qrCodeId){
    return BasicoApi.patch()(`/enviomaterial/${envioMaterialId}/qrcode/${qrCodeId}`);
  }
};

export default EnvioMaterialService;
