import { CalculosApi } from '@common/api';
const CertificadoService = {
  listar: function (parametros, niveis) {
    return CalculosApi.get()(`/certificado${niveis ? `?${niveis}` : ''
      }`, parametros);
  },
  buscarCertificado: function (id) {
    return CalculosApi.get()(`/certificado/${id}`);
  },
  assinarCertificado: function (id) {
    return CalculosApi.patch()(`/certificado/${id}/assinar`);
  },
  cancelarCalibracao: function (id, substituir = false) {
    return CalculosApi.patch()(`/certificado/${id}/cancelar?flagSubstituiCertificado=${substituir}`);
  },
  calcularConformidade: function (calibracaoId, faixaId, payload) {
    return CalculosApi.post()(
      `/certificado/${calibracaoId}/faixa/${faixaId}/conformidade-calcular`,
      payload
    );
  },
  buscarConformidadeItems: function () {
    return CalculosApi.get()('/ConformidadeItem');
  },
  salvarConformidade: function (calibracaoId, faixaId, payload) {
    return CalculosApi.post()(
      `/certificado/${calibracaoId}/faixa/${faixaId}/conformidade`,
      payload
    );
  },
  salvarConformidades: function (calibracaoId, payload) {
    return CalculosApi.put()(
      `/certificado/${calibracaoId}/conformidade-itens`,
      payload
    );
  },
  buscarConformidades: function (calibracaoId) {
    return CalculosApi.get()(
      `/certificado/${calibracaoId}/conformidade-itens`,
      {},
      false
    );
  },
  buscarIndicadores: function (calibracaoId) {
    return CalculosApi.get()(
      `/certificado/${calibracaoId}/conformidade-indicadores`,
      {},
      false
    );
  },
  salvarIndicadores: function (calibracaoId, payload) {
    return CalculosApi.put()(`/certificado/${calibracaoId}/conformidade-indicadores`,
      {
        certificadosIds: payload.certificadosIds.map(el => el.value),
        indicadores: payload.indicadores.map(el => {
          return {
            indicadorDeriva: el.indicador,
            faixaId: el.id,
            valorDeriva: el.valorDeriva
          }
        })
      }
    );
  },
  calcularIndicadores: function (calibracaoId, payload) {
    return CalculosApi.post()(
      `/certificado/${calibracaoId}/conformidade-indicadores-calcular`,
      { certificadosIds: payload.certificadosIds.map(el => el.value) }
    );
  },
  buscarConformidade: function (calibracaoId, faixaId) {
    return CalculosApi.get()(
      `/certificado/${calibracaoId}/faixa/${faixaId}/conformidade`,
      {},
      false
    );
  },
  finalizarDeriva: function (calibracaoId) {
    return CalculosApi.put()(`/certificado/${calibracaoId}/conformidade-finalizar`);
  },
  buscarAvaliacao: function (calibracaoId) {
    return CalculosApi.get()(`/certificado/${calibracaoId}/avaliacao`);
  },
  avaliarCertificado: function (calibracaoId, payload) {
    return CalculosApi.put()(`/certificado/${calibracaoId}/avaliacao`, payload);
  },
  revalidarCertificado: function (calibracaoId, payload) {
    return CalculosApi.put()(
      `/certificado/${calibracaoId}/avaliacao/revalidar`,
      payload
    );
  },
  buscarUltimosTresEAtualCeritifcados: function (instrumentoId, parametros) {
    return CalculosApi.get()(`/instrumento/${instrumentoId}/ultimos-certificados`, parametros);
  }
};

export default CertificadoService;
