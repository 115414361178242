import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumSituacaoCertificado.EmAndamento'),
    value: 'EmAndamento',
    cor: 'primary-warning-500',
  },
  {
    text: i18n.t('enums.EnumSituacaoCertificado.NaoAvaliado'),
    value: 'NaoAvaliado',
    cor: 'primary-grey-400',
  },
  {
    text: i18n.t('enums.EnumSituacaoCertificado.Assinado'),
    value: 'Assinado',
    cor: 'primary-success-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoCertificado.Cancelado'),
    value: 'Cancelado',
    cor: 'primary-error-700',
  },
  {
    text: i18n.t('enums.EnumSituacaoCertificado.PendenteAssinatura'),
    value: 'PendenteAssinatura',
    cor: 'primary-grey-400'
  }
];
