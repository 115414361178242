export default {
  cancelar: 'Cancelar',
  cancelar_modal: 'Cancelar (Esc)',
  ok: 'Ok',
  aplicar: 'Aplicar',
  atualizar_listagem: 'Atualizar Listagem',
  avancar: 'Avançar',
  confirmar: 'Confirmar',
  confirmar_f2: 'Confirmar (f2)',
  download: 'Download',
  novo: 'Novo',
  editar: 'Editar',
  duplicar: 'Duplicar',
  ajustar_atualizar_valores: 'Ajustar / Atualizar Valores',
  renovar_duplicar_contrato: 'Renovar / Duplicar Contrato',
  renovar_contrato: 'Renovar Contrato',
  remover: 'Remover',
  liberar: 'Liberar',
  assinar: 'Assinar',
  pre_assinado: 'Pré Assinado',
  encerrar: 'Encerrar',
  excluir: 'Excluir',
  liberar_orcamentos_lote: 'Liberar Orçamentos em Lote',
  enviar_email_lote: 'Enviar por Email em Lote',
  reenviar_email: 'Reenviar Email',
  sim: 'Sim',
  nao: 'Não',
  adicionar: 'Adicionar',
  entrega: 'Entrega',
  salvar: 'Salvar',
  salvar_f2: 'Salvar (F2)',
  salvarSair: 'Salvar e sair',
  salvarContinuar: 'Salvar e Continuar',
  filtrar: 'Filtrar',
  voltar: 'Voltar',
  enviar: 'Enviar',
  finalizar: 'Finalizar',
  visualizar: 'Visualizar',
  ativar: 'Ativar',
  inativar: 'Inativar',
  gerar_relatorio: 'Gerar Relatório',
  validar: 'Validar',
  gerar_pdf: 'Gerar PDF',
  buscar: 'Buscar',
  imprimir: 'Imprimir',
  //Aqui deveria ter somente traduções de botões que fossem usados de forma GENERICA
  //(Ex: redefinir_orcamento provavelmente é usado somente em orçamento então deveria estar dentro de modulos.orcamento)
  servicos_manutencao: 'Serviços de Manutenção',
  servicos_calibracao: 'Serviços de Calibração',
  criar_componente: 'Criar componente',
  pecas: 'Peças',
  alterar_situacao: 'Alterar Situação',
  movimentar: 'Movimentar',
  certificado: 'Certificado',
  alterar_status: 'Alterar Status',
  enviar_cliente: 'Enviar ao Cliente',
  redefinir_orcamento: 'Redefinir Orçamento',
  liberar_orcamento: 'Liberar Orçamento',
  aprovar_orcamento: 'Aprovar Orçamento',
  reprovar_orcamento: 'Reprovar Orçamento',
  redefinir_contrato: 'Redefinir Contrato',
  liberar_contrato: 'Liberar Contrato',
  aprovar_contrato: 'Aprovar Contrato',
  reprovar_contrato: 'Reprovar Contrato',
  enviar_email: 'Enviar por Email',
  marcar_como_enviado: 'Marcar como Enviado',
  atualizar: 'Atualizar',
  publicar: 'Publicar',
  visualizar_revisoes: 'Visualizar Revisões',
  visualizar_contatos: 'Visualizar Contatos',
  reprovar: 'Reprovar',
  aprovar: 'Aprovar',
  aprovacao: 'Aprovação',
  continuar: 'Continuar',
  exportar_pdf: 'Exportar PDF',
  excluir_linha: 'Excluir Linha',
  validar_formula: 'Validar Fórmula',
  fechar: 'Fechar',
  visualizar_orcamento: 'Visualizar Orçamento',
  visualizar_contrato: 'Visualizar Contrato',
  nova_revisao: 'Nova Revisão',
  salvar_ordenacao: 'Salvar Ordenação',
  salvar_continuar: 'Salvar e continuar',
  salvar_sair: 'Salvar e sair',
  salvar_voltar: 'Salvar e voltar',
  salvar_fechar: 'Salvar e fechar',
  responder_comentario: 'Responder Comentário',
  encerrar_comentario: 'Encerrar Comentário',
  excluir_comentario: 'Excluir Comentário',
  ativar_usuario: 'Ativar Usuário',
  inativar_usuario: 'Inativar Usuário',
  criar: 'Criar',
  em_analise: 'Em Análise',
  em_processo_resolucao: 'Em Processo de Resolução',
  resolvido: 'Resolvido',
  concluido: 'Concluído',
  simbolo_soma: '+',
  nova_pesquisa: 'Nova Pesquisa',
  exibir_todos: 'Exibir Todos',
  exibir_selecionados: 'Exibir Selecionados',
  gerar_qr_code: 'Gerar QR Code',
  criar_os: 'Criar OS',
  programar: 'Programar',
  iniciar_execucao: 'Iniciar Execução',
  retomar_execucao: 'Retomar Execução',
  finalizar_execucao: 'Finalizar Execução',
  visualizar_roteiro: 'Visualizar Roteiro',
  visualizar_calibracao: 'Visualizar Calibração',
  visualizar_calibracao_terceiro: 'Visualizar Calibração de Terceiro',
  visualizar_calibracao_interna: 'Visualizar Calibração Interna',
  verificacao_intermediaria: 'Verificação Intermediária',
  visualizar_intermediaria: 'Visualizar Verificação',
  visualizar_pdf: 'Visualizar PDF',
  executar: 'Executar',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  iniciar_atendimento: 'Iniciar Atendimento',
  finalizar_atendimento: 'Finalizar Atendimento',
  iniciar_viagem: 'Iniciar Viagem',
  finalizar_viagem: 'Finalizar Viagem',
  enviar_imagem:'Carregar Imagem',
  consulta_cnpj:'Consultar CNPJ'
};
