import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_desconto.valor_real'),
    value: 'Valor',
  },
  {
    text: i18n.t('enums.tipo_desconto.percentual'),
    value: 'Percentual',
  },
];
