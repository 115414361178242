export default {
  nome: 'Nome',
  tipoSetor: "Tipo",
  sigla: "Sigla",
  buscar: "Buscar",
  tabela: {
    nome: 'Nome',
    descricao: 'Descrição',
    sigla: 'Sigla',
    posicao: 'Posição',
    participante: 'Participante',
  },
  titulo_modal: 'Setores',
};
