export default {
  titulos: {
    listagem: 'Unidades de Medidas',
    novo: 'Nova Unidade de Medida',
    editar: 'Editar Unidade de Medida',
    fatorConversao: 'Fatores de Conversão',
  },
  cadastro_sucesso: 'Unidade de Medida salva com sucesso!',
  exclusao_sucesso: 'Unidade de Medida excluída com sucesso!',
  tabela: {
    nome: 'Nome',
    simbolo: 'Símbolo',
    grandezaFisica: 'Grandeza Física',
    unidadeMedidaPadrao: 'Unidade de Medida Padrão',
    sistema: 'Sistema',
  },
  formulario: {
    nome: 'Nome',
    simbolo: 'Símbolo',
    grandezaFisica: 'Grandeza Física',
    sistema: 'Sistema',
  },
  modal: {
    titulo: 'Fatores de Conversão',
    unidade_medida: 'Unidade de Medida',
    prefixo: 'De',
    nome: 'Para',
    fatorConversao: 'Fator de Conversão',
    fatorAdicao: 'Fator de Adição'
  },
  filtro: {
    nome: 'Nome',
    simbolo: 'Símbolo',
    grandezaFisica: 'Grandeza Física',
    unidadeMedidaPadrao: 'Unidade de Medida Padrão',
    sistema: 'Sistema',
  },
  validacoes: {
    formulario_invalido: 'Preencher Formulário',
  },
  erros: {
    id_invalido: 'O id da unidade de medida é inválido',
  },
  modal_confirmacao: {
    titulo: 'Atenção: Alteração da Unidade de Medida Padrão',
    descricao: 'Ao alterar a Unidade de Medida Padrão, todas as conversões de unidade devem ser revisadas. Deseja continuar?'
  },
  modal_confirmacao_grandeza_fisica: {
    titulo: 'Atenção: Alteração da Grandeza Física',
    descricao: 'Deseja salvar os fatores de conversão da grandeza física anterior?'
  }
};
