<template>
  <modal-padrao
    ref="modal-define-colunas"
    :max-width="maxWidthComputada"
    :titulo="$t('geral.tabela.colunas')"
    titulo-cancelar="Fechar"
    titulo-ok="Confirmar"
    @ok="enviaColunasSelecionadas"
  >
    <data-table
      :value="tabela.dados"
      show-gridlines
      class="p-datatable-sm"
      :rows="10"
      :scrollable="true"
      data-key="coluna"
      :row-hover="true"
      :selection.sync="tabela.selecionados"
      filter-display="menu"
      responsive-layout="scroll"
    >
      <Column
        selection-mode="multiple"
        :styles="{ 'max-width': '8%' }"
      />
      <Column
        field="coluna"
        :header="$t('geral.tabela.colunas')"
      />
    </data-table>
  </modal-padrao>
</template>
<script>
export default {
  props: {
    colunas: { type: Array, default: () => [] },
    selecionadas: { type: Array, default: () => [] },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        colunasSelecionadas: [],
        dados: [],
        colunas: [
          {
            value: 'coluna',
            text: this.$t('geral.tabela.colunas'),
            sortable: true,
            width: '150px',
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    maxWidthComputada() {
      return this.$vuetify.breakpoint.smAndDown ? '90vw' : '40%';
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs['modal-define-colunas'].abrirModal();
      this.tabela.dados = [];
      this.colunasSelecionadas = [];
      this.colunas.forEach((itemColuna) => {
        this.tabela.dados.push({
          coluna: itemColuna.header,
        });
      });
      this.tabela.dados.forEach((itemColuna) => {
        if (this.selecionadas.length) {
          this.selecionadas.forEach((selecionado) => {
            if (itemColuna.coluna == selecionado.header) {
              this.colunasSelecionadas.push({
                coluna: itemColuna.coluna,
              });
            }
          });
        }
      });

      if (!this.selecionadas.length) {
        this.tabela.selecionados = this.tabela.dados;
      } else {
        this.tabela.selecionados = this.colunasSelecionadas;
      }
    },
    enviaColunasSelecionadas() {
      this.$refs['modal-define-colunas'].fecharModal();
      this.$emit('envia-colunas-selecionadas', this.tabela.selecionados);
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>
