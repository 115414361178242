export default [
    {
    text: 'Admin',
    value: 'Admin',
    },
    {
    text: 'BasicRecords',
    value: 'BasicRecords',
    },
    {
    text: 'Calculation',
    value: 'Calculation',
    },
    {
    text: 'SQL',
    value: 'Sql',
    }
];
