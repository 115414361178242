import helpers from '@router/helpers';
const menuId = 'papel';

export default [
  helpers.criarRota(menuId, 'papel', menuId, () =>
    import('@/views/modulos/cadastros/papel')
  ),
  helpers.criarRota(menuId, 'papel-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/papel/formulario')
  ),
  helpers.criarRota(
    menuId,
    'papel-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/papel/formulario'),
    true
  ),
];
