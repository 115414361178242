import helpers from '@router/helpers';
const menuId = 'local-estocagem';

export default [
  helpers.criarRota(menuId, 'local-estocagem', menuId, () =>
    import('@/views/modulos/cadastros/local_estocagem')
  ),
  helpers.criarRota(
    menuId,
    'local-estocagem-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/local_estocagem/formulario')
  ),
  helpers.criarRota(
    menuId,
    'local-estocagem-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/local_estocagem/formulario'),
    true
  ),
];
