<template>
  <main>
    <section>
      <section id="designer" />
    </section>
  </main>
</template>
<script>
import StimulsoftDesign from 'stimulsoft-reports-js/Scripts/stimulsoft.designer';
import Stimulsoft from 'stimulsoft-reports-js/Scripts/stimulsoft.reports';
export default {
  props: {
    emitirDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      designer: null,
      dataSource: null,
    };
  },
  methods: {
    ehStringJson(str) {
      try {
        var json = JSON.parse(str);
        return typeof json === 'string';
      } catch (e) {
        return false;
      }
    },
    montarComponente: function (arquivoJson, arquivoNavegador = null) {
      Stimulsoft.Stimulsoft.Base.StiLicense.Key = `${process.env.VUE_APP_STIMULSOFT_LICENSE_KEY}`;

      let options = new StimulsoftDesign.StiDesignerOptions();
      options.appearance.fullScreenMode = true;

      const designer = new Stimulsoft.StiDesigner(options, 'StiDesigner', false);

      let report = new Stimulsoft.Stimulsoft.Report.StiReport();

      let dataSet = new Stimulsoft.Stimulsoft.System.Data.DataSet('KeL');
      dataSet.readJson(this.dataSource);

      if (arquivoJson) {
        let ehStringJson = this.ehStringJson(arquivoJson);
        let arq = arquivoJson;
        if (ehStringJson) arq = JSON.parse(arquivoJson);
        report.load(arq);
      }
      if (arquivoNavegador) report.load(arquivoNavegador);

      report.dictionary.synchronize();
      report.dictionary.databases.clear();
      report.regData(dataSet.dataSetName, '', dataSet);

      report.dictionary.synchronize();
      designer.report = report;

      let ctx = this;
      designer.onSaveReport = function (args) {
        const json = JSON.stringify(args.report.saveToJsonString());
        const blob = new Blob([args.report.saveToJsonString()], {
          type: 'application/json',
        });
        if (ctx.emitirDownload) {
          const url = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = args.fileName.replace('.mrt', '.json');
          a.click();

          URL.revokeObjectURL(url);
        } else {
          ctx.$emit('salvar', json, blob);
        }
      };
      designer.onOpenReport = function () {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.addEventListener('change', function (event) {
          const arquivo = event.target.files[0];
          const reader = new FileReader();

          reader.onload = function (evt) {
            const conteudo = evt.target.result;
            ctx.montarComponente(null, conteudo);
          };
          reader.readAsText(arquivo);
        });
        fileInput.click();
      };
      designer.renderHtml('designer');
    },
    // carregarArquivo: function (arquivoJson) {
    //   let report = new Stimulsoft.Stimulsoft.Report.StiReport();
    //   report.load(JSON.parse(arquivoJson));
    //   this.designer.report = report;
    // },
    carregarDataSource: function (dataSource) {
      this.dataSource = dataSource;
    },
    // salvar: function () {
    //   const json = JSON.stringify(this.designer.report.saveToJsonString());
    //   const blob = new Blob([this.designer.report.saveToJsonString()], {
    //     type: 'application/json',
    //   });
    //   this.$emit('salvar', json, blob);
    // },
  },
};
</script>
<style scoped>
:deep(.stiDesignerClearAllStyles) {
  border-color: rgba(0, 0, 0, 0);
}

:deep(.stiDesignerGroupBlockTableCell) {
  border-color: rgba(0, 0, 0, 0);
}
:deep(.stiDesignerGroupBlockCaption) {
  border-color: rgba(0, 0, 0, 0);
}
</style>