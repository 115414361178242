import { BasicoApi } from '@common/api';
const OmierService = {
  buscaDadosOmie: function (stringCodigo = '', parametros) {
    return BasicoApi.get()(
      `/omie/listPosicaoEstoque?${stringCodigo}`,
      parametros
    );
  },
};

export default OmierService;
