import axios from 'axios';
import store from '@store';
import i18n from '@/i18n/index';
import route from '@router';

axios.defaults.headers.post['Content-Type'] = 'application/json-patch+json';

const baseAuthURL = process.env.VUE_APP_AUTH_API;
const baseURL = process.env.VUE_APP_API;
const baseCalculoCalibracaoURL = process.env.VUE_APP_CALCULO_CALIBRACAO_API;

export const authApi = axios.create({
  baseURL: baseAuthURL,
  headers: { 'Content-Type': 'application/json-patch+json' },
});

export const api = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json-patch+json' },
});

export const calculoCalibracao = axios.create({
  baseURL: baseCalculoCalibracaoURL,
  headers: { 'Content-Type': 'application/json-patch+json' },
});

// Função que exibe a mensagem de token espirado
function logoutExpiredToken() {
  route.replace({ path: '/' }).catch(() => {});

  setTimeout(() => {
    store.dispatch('ModuleErrors/changeError', {
      message: i18n.t('pages.website.loginPage.errors.expiredToken'),
      type: 'error',
    });

    store.dispatch('Autenticacao/limparAutenticacao');
  }, 1000);
}

authApi.interceptors.request.use((config) => {
  const token = store.getters['Autenticacao/token'];
  if (token) config.headers['Authorization'] = token;

  return config;
});

authApi.interceptors.response.use(
  (config) => {
    const token = store.getters['Autenticacao/token'];
    if (token) config.headers['Authorization'] = token;

    return config;
  },
  (error) => {
    const token = store.getters['Autenticacao/token'];

    if (token) {
      switch (error.response.status) {
        case 401:
          logoutExpiredToken();
          break;
      }
    }

    return Promise.reject(error);
  }
);

api.interceptors.request.use((config) => {
  const token = store.getters['Autenticacao/token'];
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        route.push({ path: '/' });
        logoutExpiredToken();
        break;
    }
    return Promise.reject(error);
  }
);

calculoCalibracao.interceptors.request.use((config) => {
  const token = store.getters['Autenticacao/token'];
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
});

calculoCalibracao.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        route.push({ path: '/' });
        logoutExpiredToken();
        break;
    }
    return Promise.reject(error);
  }
);
