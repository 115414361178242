<template>
  <tabela-padrao-prime-vue
    ref="tabela-grandeza-fisica"
    v-model="selecionados"
    class="mt-2"
    :colunas="tabela.colunas"
    :dados="tabela.dados"
    filtros-com-colunas
    :mostrar-acoes="false"
    :linha-hover="!multiplos"
    :quantidade-itens="tabela.quantidadeItens"
    filtro-global-focus
    :por-pagina="tabela.porPagina"
    :quantidade-paginas="tabela.quantidadePaginas"
    :pagina="tabela.paginaAtual"
    :selecionar-apenas-um="!multiplos"
    :filters="filters"
    :global-filters="globalFilters"
    ajustar-altura-linha
    @paginar="listar"
    @filtrarGlobal="listar"
    @filtroGlobal="(v) => (filtroAplicado.filter = v)"
    @fechar-modal-click-linha="() => $emit('fechar-modal-click-linha')"
  />
</template>
<script>
import GrandezaFisicaService from '@common/services/cadastros/GrandezaFisicaService';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    listarAtualizacao: { type: Number, default: 1 },
    value: { type: [Array, Object], default: null },
  },
  data() {
    return {
      edicao: null,
      globalFilters: ['nome'],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      filtro: {
        nome: '',
        cidade: '',
        documento: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('componentes.input_grandeza_fisica.tabela.nome'),
            sortable: false,
          },
          {
            value: 'descricao',
            text: this.$t('componentes.input_grandeza_fisica.tabela.descricao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    listarAtualizacao() {
      this.listar();
    },
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        if (Array.isArray(this.selecionados)) {
          this.$emit('input', this.selecionados);
          return;
        }
        this.$emit('input', [this.selecionados]);
      },
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    focusFiltroGlobal(){
          this.$refs.tabela?.focusFiltroGlobal(); 
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    listar: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      const flagParticipanteConta =
        this.$store.getters['Autenticacao/participanteLogadoConta'];
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        flagParticipanteConta: flagParticipanteConta ? '' : false,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      GrandezaFisicaService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>