import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.tipo_requisitos.recebimento'),
    value: 'Recebimento',
  },
  {
    text: i18n.t('enums.tipo_requisitos.analise_tecnica'),
    value: 'AnaliseTecnica',
  },
  {
    text: i18n.t('enums.tipo_requisitos.calibracao'),
    value: 'Calibracao',
  },
  {
    text: i18n.t('enums.tipo_requisitos.ensaio'),
    value: 'Ensaio',
  },
  {
    text: i18n.t('enums.tipo_requisitos.expedicao'),
    value: 'Expedicao',
  },
  {
    text: i18n.t('enums.tipo_requisitos.orcamento'),
    value: 'Orcamento',
  },
  {
    text: i18n.t('enums.tipo_requisitos.notas_fiscais'),
    value: 'NotasFiscais',
  },
];
