import helpers from '@router/helpers';
const menuId = 'grandeza-fisica';

export default [
  helpers.criarRota(menuId, 'grandeza-fisica', menuId, () =>
    import('@/views/modulos/cadastros/grandeza_fisica')
  ),
  helpers.criarRota(
    menuId,
    'grandeza-fisica-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/grandeza_fisica/formulario'),
    false,
    false
  ),
  helpers.criarRota(
    menuId,
    'grandeza-fisica-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/grandeza_fisica/formulario'),
    true,
    false
  ),
];
