<template lang="">
  <div
    class="input-padrao"
    :class="emFiltro ? 'v-input--em-filtro' : ''"
  >
    <span
      v-if="labelExterno && !semLabel"
      class="mb-3 d-block v-label--externo  d-flex"
    >
      <span class="label">{{ label }}</span>
      <span
        v-if="obrigatorio"
        class="obrigatorio ml-1"
      >*</span>
    </span>
    <div :class="{'d-flex':comBotaoAdicionar }">
      <v-select
        :id="id"
        v-model="conteudo"
        :append-icon="icone"
        :label="labelEhDentro ? labelComputada : ''"
        :items="options"
        :placeholder="placeholder"
        :dense="dense"
        :rules="regras"
        :disabled="disabled"
        :chips="chips"
        :multiple="multiplos"
        :return-object="retornarObjeto"
        :clearable="clearable"
        :outlined="outlined"
        :flat="flat"
        :solo="solo"
        :height="height"
        :background-color="backgroundColor"
        :error="error"
        :error-messages="errorMessages"
        hide-details="auto"
        no-data-text="Sem dados"
        :prefix="prefixo"
        :item-text="itemText"
        :item-value="itemValue"
        :loading="loading"
        :style="estiloCss"
        @change="emitirChange"
      >
        <template
          v-for="slot in slots"
          v-slot:[`${slot}`]="{ item }"
        >
          <slot
            :name="slot"
            :item="item"
          />
          <span :key="slot" />
        </template>
      </v-select>
      <slot name="botaoAdicionar" />
    </div>
  </div>
</template>
<script>
import { InputMixin } from './InputMixin';
export default {
  name: 'InputSelect',
  mixins: [InputMixin],
  props: {
    options: { type: Array, default: () => [], required: true },
    multiplos: { type: Boolean, default: false },
    inputAdicionar: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    retornarObjeto: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    height: { type: String, default: 'auto' },
    errorMessages: {},
    prefixo: {type: String, default: ''},
    estiloCustomizado: {type: String, default: ''},
    comBotaoAdicionar:{ type: Boolean, default: false },
    semLabel:{ type: Boolean, default: false },
    /** Props de validação */
    icone: { type: String, default: '$downArrow' },
    itemValue: { type: String, default: 'value' },
    itemText: { type: String, default: 'text' },
  },
  data: function () {
    return {
      conteudo: '',
      conteudoAntigo: '',
    };
  },
  computed: {
    regras: function () {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      else if (regras.includes(this.validacaoObrigatorio)) {
        regras.splice(regras.indexOf(this.validacaoObrigatorio), 1);
      }
      return regras;
    },
    estiloCss: function () {
      const cssAdicionar = this.inputAdicionar ? 'border-radius: 8px 0px 0px 8px;' : '';
      return cssAdicionar + this.estiloCustomizado;
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value == null && this.conteudo == null) return 
        this.conteudo = value;
        this.conteudoAntigo = value;
      },
      deep: true,
      immediate: true,
    },
    conteudo(conteudo) {
      this.$emit('input', conteudo);
    },
  },
  methods: {
    emitirChange: function (e) {
      this.$emit('change', e, this.conteudoAntigo);
    },
  },
};
</script>
<style >
.input-adicionar {
    border-radius: 8px 0px 0px 8px;
}
</style>
