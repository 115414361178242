import helpers from '@router/helpers';
const menuId = 'condicao-pagamento';

export default [
  helpers.criarRota(menuId, 'condicao-pagamento', menuId, () =>
    import('@/views/modulos/cadastros/condicao_pagamento')
  ),
  helpers.criarRota(
    menuId,
    'condicao-pagamento-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/condicao_pagamento/formulario')
  ),
  helpers.criarRota(
    menuId,
    'condicao-pagamento-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/condicao_pagamento/formulario'),
    true
  ),
];
