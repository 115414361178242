export default {
  ATUALIZAR_ROTEIRO(state, payload) {
    let {
      clientes,
      roteiroId,
      situacao,
      totalClientes,
      ultimoClienteCheckout
    } = payload;
    state.clientes = clientes;
    state.roteiroId = roteiroId;
    state.situacao = situacao;
    state.totalClientes = totalClientes;
    state.ultimoClienteCheckout = ultimoClienteCheckout

  },
  ATUALIZAR_ESTADO_CLIENTE(state, payload) {
    let { clientes } = payload;
    state.clientes = clientes;
  },

  LIMPAR_ROTEIRO(state) {
    state.clientes = null;
    state.roteiroId = null;
    state.situacao = null;
    state.totalClientes = null;
    state.clienteDaVez = null;
    state.clienteDaVezIndex = null;
    state.envioMaterialDaVez = null;
    state.ultimoClienteCheckout = null;
  },
  LIMPAR_CLIENTE_DA_VEZ(state) {
    state.clienteDaVez = null
  },
  ATUALIZAR_CLIENTE_DA_VEZ(state, payload) {
    state.clienteDaVez = payload;
  },
  ATUALIZAR_CLIENTE_DA_VEZ_INDEX(state, payload) {
    state.clienteDaVezIndex = payload;
  },
  ATUALIZAR_ENVIO_MATERIAL_DA_VEZ(state, payload) {
    state.envioMaterialDaVez = payload;
  }
};
