import helpers from '@router/helpers';
const menuId = 'solicitacao-cadastro';

export default [
  helpers.criarRota(
    menuId,
    'solicitacao-cadastro',
    menuId,
    () => import('@/views/modulos/cadastros/solicitacao_cadastro')
  ),
  helpers.criarRota(
    menuId,
    'solicitacao-cadastro-novo',
    `${menuId}/novo`,
    () => import('@/views/modulos/cadastros/solicitacao_cadastro/formulario')
  ),
  helpers.criarRota(
    menuId,
    'solicitacao-cadastro-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/solicitacao_cadastro/formulario'),
    true
  ),
];
