import { BasicoApi } from '@common/api';
const CondicaoPagamentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/condicaopagamento', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/condicaopagamento/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/condicaopagamento/${form.id}`, form);
    return BasicoApi.post()('/condicaopagamento', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/condicaopagamento/${id}`);
  },
};

export default CondicaoPagamentoService;
