import { routes } from '@router/routes';
import store from '@store';
import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'router-link-active',
  linkExactActiveClass: 'router-link-exact-active',
  routes,
});


const obterHoraAtual = ()=>{
  const date = new Date()
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  let time = new Intl.DateTimeFormat('en-US', options).format(date)
  time = time.substring(0,5)
  return time
 }
const converterHorasMinutos = (data)=> {
  const [horas, minutos] = data.split(':').map(Number);
  return horas * 60 + minutos;
}
const horaAtual = obterHoraAtual();

  const colaborador = store.state.colaborador;
  //const jwtExp = store.state.encodedData.exp;
  /* const data = new Date(jwtExp * 1000);
  const horas = data.getUTCHours().toString().padStart(2, '0');
  const minutos = data.getUTCMinutes().toString().padStart(2, '0');

  const horaExpirarToken = `${horas}:${minutos}`; */

router.beforeEach(async (to, from, next) => {
  if (to.matched.every((record) => !record.meta.requiresAuth)) {
    next();
    return;
  }

  if (!store.getters['Autenticacao/estaAutenticado']) {
    next({ name: 'login', params: { redirect: to?.path }, });
    return;
  }

  if (
    !store.getters['Autenticacao/participanteLogadoConta'] &&
    to.meta.acessoCliente === false
  ) {
    next({ name: 'dashboard' });
    return;
  }
if(colaborador){
  if ( converterHorasMinutos(horaAtual) > converterHorasMinutos(colaborador.horarioTerminoTrabalho)
        && (converterHorasMinutos(horaAtual) >converterHorasMinutos(colaborador.horarioInicioIntervalo)
          || converterHorasMinutos(horaAtual) < converterHorasMinutos(colaborador.horarioTerminoIntervalo))){

        store.dispatch('clearStore')
        store.dispatch('Autenticacao/limparAutenticacao');
          next({ name: 'login' });
          return;
        }
      }



  let paginasQueVaoParaInicio = ['login'];
  if (paginasQueVaoParaInicio.includes(to.name)) {
    next({ name: 'dashboard' });
    return;
  }
  next();
});

export default router;
