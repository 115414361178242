export default {
  titulos: {
    listagem: 'Requisitos',
    novo: 'Novo Requisito',
    editar: 'Editar Requisito',
  },
  cadastro_sucesso: 'Requisito salvo com sucesso!',
  exclusao_sucesso: 'Requisito excluído com sucesso!',
  tabela: {
    sequencia: 'Sequência',
    nome: 'Nome',
    tipo_requisito: 'Tipos de Requisito',
    requisito_sim: 'Requisito para Sim',
    requisito_nao: 'Requisito para Não',
    texto_entre_requisito_observacao: 'Texto entre Requisito e Observação'
  },
  formulario: {
    nome: 'Nome',
    tipo_requisito: 'Tipos de Requisito',
    requisito_sim: 'Requisito para Sim',
    requisito_nao: 'Requisito para Não',
    texto_entre_requisito_observacao: 'Texto entre Requisito e Observação'
  },
  erros: {
    id_invalido: 'O id do procedimento é inválido',
  },
  validacoes: {
    formulario_invalido: 'É necessário validar o formulário antes de salvar o registro.'
  },
  filtro: {
    nome: 'Nome',
    tipo_requisito:'Tipos de Requisito'
  },
};
