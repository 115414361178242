import i18n from '@/i18n';

export default [
  {
    text: i18n.t('enums.EnumTipoAprovacaoAnaliseTecnica.Contrato'),
    value: 1,
  },
  {
    text: i18n.t('enums.EnumTipoAprovacaoAnaliseTecnica.Garantia'),
    value: 2,
  },
  {
    text: i18n.t('enums.EnumTipoAprovacaoAnaliseTecnica.Orcamento'),
    value: 3,
  },
  {
    text: i18n.t('enums.EnumTipoAprovacaoAnaliseTecnica.Automatica'),
    value: 4,
  },
];
