export default {
  codigo: 'Código',
  codigo_instrumento: 'Cód. do Instrumento',
  tipo_instrumento: 'Tipo Instrumento',
  tabela: {
    codigo: 'Cód do certificado',
    codigo_instrumento: 'Cód. do Instrumento',
    tipo_instrumento: 'Tipo Instrumento',
    data: 'Data',
    situacao: 'Situação Avaliação',
    tipo: 'Tipo',
    certificado: 'Calibração',
    certificado_terceiro: 'Certificado de terceiro',
    certificado_interno: 'Certificado interno',
  },
  titulo_modal: 'Certificados',
};
