import helpers from '@router/helpers';
const menuId = 'veiculo';

export default [
  helpers.criarRota(menuId, 'veiculo', menuId, () =>
    import('@/views/modulos/cadastros/veiculo')
  ),
  helpers.criarRota(menuId, 'veiculo-novo', `${menuId}/novo`, () =>
    import('@/views/modulos/cadastros/veiculo/formulario')
  ),
  helpers.criarRota(
    menuId,
    'veiculo-editar',
    `${menuId}/:id?`,
    () => import('@/views/modulos/cadastros/veiculo/formulario'),
    true
  ),
  helpers.criarRota(
    menuId,
    'veiculo-visualizar',
    `${menuId}/visualizar/:id?`,
    () => import('@/views/modulos/cadastros/veiculo/formulario'),
    true
  ),
];
