<template lang="">
  <input-modal
    v-model="conteudo"
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :regras="regras"
    :disabled="disabled"
    :multiplos="multiplos"
    :retornar-objeto="retornarObjeto"
    :obrigatorio="obrigatorio"
    :flat="flat"
    :solo="solo"
    :outlined="outlined"
    :sem-label="semLabel"
    :titulo-modal="tituloModal"
    :background-color="backgroundColor"
    :prefixo="prefixo"
    @abrir-modal="abrirModalPrevent"
    @input="emitirInput"
  >
    <template #modal>
      <modal-padrao
        ref="modal-input"
        :max-width="1000"
        :titulo="$t(tituloModal)"
        :titulo-ok="$t('geral.botoes.confirmar')"
        @ok="aplicarSelecionados"
      >
        <modal-new
          ref="tabela"
          v-model="selecionados"
          :multiplos="multiplos"
          :busca-unidade-medida-grandeza-fisica="
            buscaUnidadeMedidaGrandezaFisica
          "
          :listar-unidade-medida-por-grandezas-fisicas-ids="
            listarUnidadeMedidaPorGrandezasFisicasIds
          "
          :grandezas-fisicas-ids="grandezasFisicasIds"
          :id-grandeza-fisica="idGrandezaFisica"
          @fechar-modal="fecharModal"
          @fechar-modal-click-linha="aplicarSelecionados"
        />
      </modal-padrao>
    </template>
  </input-modal>
</template>
<script>
import { InputMixin } from '../../InputMixin';
import { DropDownIdNomeSimbolo } from '@/common/models/geral/DropDownIdNomeSimbolo';
// import Tabela from './modal.vue';
import cloneDeep from 'lodash/cloneDeep';
import modalNew from './modal-new.vue';

export default {
  name: 'InputUnidadeMedida',
  components: {
    modalNew,
    // Tabela,
  },
  mixins: [InputMixin],
  props: {
    tituloModal: {
      type: String,
      default: 'componentes.input_unidade_medida.titulo_modal',
    },
    multiplos: { type: Boolean, default: true },
    retornarObjeto: { type: Boolean, default: true },
    buscaUnidadeMedidaGrandezaFisica: { type: Boolean, default: false },
    listarUnidadeMedidaPorGrandezasFisicasIds: {
      type: Boolean,
      default: false,
    },
    idGrandezaFisica: { type: String, default: '' },
    preventAcoes: { type: Boolean, default: false },
    grandezasFisicasIds: { type: Array, default: () => [] },
    prefixo: {type: String, default: ''},
    confirmacao: { type: Object, default: null }
    /** Props de validação */
  },
  data() {
    return {
      conteudo: [],
      selecionados: [],
    };
  },
  computed: {
    regras() {
      let regras = this.regrasPersonalizadas;
      if (this.obrigatorio) regras.push(this.validacaoObrigatorio);
      return regras;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.conteudo = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitirInput() {
      this.$emit('input', this.conteudo);
    },
    textoOpcao(opcao) {
      if (!opcao) return '';
      return opcao.text || opcao;
    },
    removerOpcao(indice) {
      this.conteudo.splice(indice, 1);
    },
    abrirModalPrevent: function () {
      if (this.preventAcoes) {
        this.$emit('prevent-abrir-modal');
        return;
      }
      this.abrirModal();
    },
    removerOpcaoPrevent: function (indice) {
      if (this.preventAcoes) {
        this.$emit('prevent-remover-opcao', indice);
      }
    },
    abrirModal() {
      this.$refs['modal-input'].abrirModal();
      this.selecionados = [];
      this.listar();
      if (!this.conteudo) return false;

      let conteudoClonado = cloneDeep(this.conteudo);
      if (!this.multiplos) conteudoClonado = [conteudoClonado];

      this.selecionados = conteudoClonado.map((el) => {
        return {
          id: el.value,
          nome: el.text,
          simbolo: el.simbolo,
        };
      });
    },
    listar: async function(){
      await this.$nextTick();
      this.$refs['tabela']?.listar();
      setTimeout(this.$refs['tabela']?.focusFiltroGlobal, 300);
    },
    async aplicarSelecionados() {
      let continua = true;
      if (this.confirmacao) {
        await this.confirmar(this.confirmacao.titulo, this.confirmacao.descricao)
          .catch(() => continua = false);
      }
      if (!continua) return;
      this.$emit('aplicar-selecionados', [...this.selecionados]);
      this.$refs['modal-input'].fecharModal();

      let selecionados = new DropDownIdNomeSimbolo(this.selecionados);
      if (!this.multiplos) {
        selecionados = null;
        if (this.selecionados.length > 0)
          selecionados = new DropDownIdNomeSimbolo(this.selecionados)[0];
      }
      this.conteudo = selecionados;
    },
    fecharModal(unidadeSelecionada) {
      this.selecionados = [unidadeSelecionada];
      this.aplicarSelecionados();
    },
  },
};
</script>
