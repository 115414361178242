export default {
  classificacaoInstrumento: {
    ClassificacaoInstrumentoBodyPage:{
      html: {
        title: 'Classificação de instrumentos'
      }
    },
    CreateClassificacaoInstrumentoPage:{
      title: 'Nova classificação',
      html: {
        title: 'Criar um nova classificação'
      }
    },
    EditClassificacaoInstrumentoPage:{
      title: 'Editar classificação',
      html: {
        title: 'Editar classificação'
      }
    },
    ShowClassificacaoInstrumentoPage:{
      html: {
        title: 'Listando classificação de instrumentos'
      },
      baseTreeview: {
        treeviewName: 'Classificação de instrumentos',
        newItemButtonText: 'Nova classificação'
      }
    },
    tooltips: {
      children: 'Clique para carregar os dados.'
    },
    inputs: {
      descricao: {
        text: 'Descrição*',
        placeholder: 'Descrição da classificação de instrumento'
      }
    },
    alerts: {
      createSuccess: 'Classificação de instrumento criado com sucesso',
      createError: 'Falha ao criar nova classificação de instrumento. Tente novamente!',
      editSuccess: 'Classificação de instrumento editado com sucesso',
      editError: 'Falha ao editar uma classificação de instrumento. Tente novamente!',
      deleteSuccess: 'Classificação(ões) de instrumento(os) excluída(s) com sucesso',
      deleteWarning: 'Excluindo classificação(ões) de instrumento(os), você será notificado quando finalizado',
      deleteError: 'Ocorreu um erro durante a exclusão da(s) classificação(ões) de instrumento(os), por favor refaça a operação'
    }
  }
}
